const CompanyRoleTable = [
    'Amministratore/ Socio',
    'Direttore/ Manager',
    'Team Leader/ Capo Squadra',
    'Responsabile Vendite',
    'Responsabile Personale',
    'Responsabile Operativo',
]

export default CompanyRoleTable
