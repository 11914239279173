const title_type = [
    {
        "label": "Scuole Elementari",
        "value": 1
    },
    {
        "label": "Scuole Medie",
        "value": 2
    },
    {
        "label": "Attestato o Qualifica Operatore Professionale",
        "value": 3
    },
    {
        "label": "Diploma o Licenza di Scuola Superiore",
        "value": 4
    },
    {
        "label": "Diploma di Tecnico Superiore",
        "value": 5
    },
    {
        "label": "Laurea Triennale",
        "value": 6
    },
    {
        "label": "Diploma accademico di Primo livello",
        "value": 6
    },
    {
        "label": "Master Universitario di Primo Livello",
        "value": 7
    },
    {
        "label": "Laurea Magistrale o a Ciclo Unico/Vecchio Ordinamento",
        "value": 7
    },
    {
        "label": "Diploma Accademico di Secondo livello",
        "value": 7
    },
    {
        "label": "Master Universitario di Secondo livello",
        "value": 8
    },
    {
        "label": "Diploma di Specializzazione",
        "value": 8
    },
    {
        "label": "Dottorato di Ricerca (PhD)",
        "value": 8
    },
    {
        "label": "Diploma Accademico di formazione alla ricerca",
        "value": 8
    }
]

export default title_type