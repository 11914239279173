import KangratsLogo from 'app/components/Icons/KangratsLogo'
import React from 'react'

function OnboardingLayout({ children }) {
    return (
        <div className="onboarding-main-container">
            <header>
                <KangratsLogo
                    width={265}
                    height={70}
                    cssClasses={'margin-inline-auto'}
                />
            </header>
            <main className="onboarding-main">
                {children}

                {/* <footer className='d-flex align-center justify-center'>
              <small>© Kangrats 2024</small>
            </footer> */}
            </main>
        </div>
    )
}

export default OnboardingLayout
