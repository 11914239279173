import React from 'react'
import history from 'history.js'

const CommonElement = (props) => {
    return (
        <div className="commonElement">
            <div className="buttun-link">
                <button
                    className="salta"
                    onClick={() => history.push('/signup')}
                >
                    {' '}
                    Iscriviti gratuitamente
                </button>
                <a href="/login">Hai già un account? Accedi</a>
            </div>
        </div>
    )
}

export default CommonElement
