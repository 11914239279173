import {
    Checkbox,
    FormControl,
    InputAdornment,
    InputLabel,
    Select,
    TextareaAutosize,
    TextField,
    Switch,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import TitleDataTree from '../../Tables/title_table'
import ApiService from 'app/ApiService'
// react dropdown
import title_type from '../../Tables/title_type'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import Skeleton from '@material-ui/lab/Skeleton'
import EditFieldDialog from './FooterProfile/EditField'

function Istruzione({ titlelist, candidateguid, getTitleList, loading }) {
    // per dialog
    const [open, setOpen] = useState(false)
    const descriptionElementRef = useRef(null)
    // per dialog di conferma (vuoi eliminare definitivamente?)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [titleList, setTitleList] = useState([])
    // const [appState, setAppState] = useState(false);
    const [titleObjects, setTitleObjects] = useState([])
    const [checked, setChecked] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const initUserInfo = {
        candidate_guid: candidateguid || '',
        eqf_label: '',
        eqf_value: 0,
        title_name: '',
        parent_title: '',
        other_title: '',
        school: '',
        erasmus: 'false',
        erasmus_description: '',
        publications: 'false',
        publications_description: '',
        graduation_grade: null,
        laude: 'false',
        current_title: false,
        title_date_start: '',
        title_date_end: '',
        description: '',
        created: '',
        lastUpdate: '',
    }

    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        eqf_label,
        eqf_value,
        title_name,
        parent_title,
        other_title,
        school,
        erasmus,
        erasmus_description,
        publications,
        publications_description,
        graduation_grade,
        laude,
        current_title,
        title_date_start,
        title_date_end,
        description,
        created,
        lastUpdate,
    } = userInfo

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }
    const handleClickOpen = () => () => {
        // chiudo le card in caso ce ne sono di aperte, se no è un problema coi dati
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setTitleObjects(newTitleObjects)

        setDialogText('Aggiungi')
        setOpen(true)
        setUserInfo({
            ...initUserInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClickUpdateDialogOpen = (titleInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        setUserInfo({
            ...userInfo,
            id: titleInfo.id,
            title_name: titleInfo.title_name || '',
            parent_title: titleInfo.parent_title,
            school: titleInfo.school,
            eqf_label: titleInfo.eqf_label,
            eqf_value: titleInfo.eqf_value,
            other_title: titleInfo.other_title,
            erasmus: titleInfo.erasmus.toString(),
            erasmus_description: titleInfo.erasmus_description,
            // publications: titleInfo[9].toString(),
            // publications_description: titleInfo[10],
            graduation_grade: titleInfo.graduation_grade,
            // laude: titleInfo[10],
            current_title: titleInfo.current_title,
            title_date_start: new Date(titleInfo.title_date_start),
            title_date_end: new Date(titleInfo.title_date_end),
            description: titleInfo.description,
            lastUpdate: new Date(),
        })
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        resetValue(TitleDataTree)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const handleDateChangeStart = (date) => {
        setUserInfo({ ...userInfo, title_date_start: date })
    }

    const handleDateChangeEnd = (date) => {
        setUserInfo({ ...userInfo, title_date_end: date })
    }

    // const onNodeSelect = (currentNode, selectedNodes, event) => {
    //     setUserInfo({ ...userInfo, title_name: currentNode.value });
    //     console.log(selectedNodes);
    // };

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    // const onSelect = (event, value) => {
    //     console.log(value)
    //     setUserInfo({
    //         ...userInfo,
    //         title_name: value?.key,
    //         parent_title: value?.parent_key
    //     })
    //     console.log(userInfo);
    // };

    const onSelect = (value) => {
        console.log(value)
        setUserInfo({
            ...userInfo,
            other_title: value,
        })
        console.log(userInfo)
    }

    const onChecked = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.checked,
        })
        console.log(userInfo)
    }

    const onEqfSelect = (event) => {
        const val = title_type.filter((x) => x.label === event.target.value)[0]
        if (val.value > 3)
            setUserInfo({
                ...userInfo,
                eqf_label: val.label,
                eqf_value: val.value,
            })
        else
            setUserInfo({
                ...userInfo,
                eqf_label: val.label,
                eqf_value: val.value,
                other_title: null,
                title_name: null,
                parent_title: null,
            })
        console.log(userInfo)
    }

    const handleAddTitle = async () => {
        const res = await ApiService.create_candtitle(userInfo)
        if (res.ok) getTitleList()
        handleClose()
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candtitle(userInfo.id)
        if (res.ok) getTitleList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        const res = await ApiService.update_candtitle(userInfo)
        if (res.ok) getTitleList()
        handleClose()
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}
        const hideTitleDetails = eqf_value <= 3 && eqf_value > 0
        const hideTitleDetailsName = eqf_value <= 4 && eqf_value > 0
        if (!eqf_label) errors.eqf_label = 'Questo campo non può essere nullo'
        if (!school) errors.school = 'Questo campo non può essere nullo'
        if (!hideTitleDetails && !other_title)
            errors.other_title = 'Questo campo non può essere nullo'
        // if (!hideTitleDetailsName && (!other_title) ) errors.other_title = "Questo campo non può essere nullo"
        if (!title_date_start)
            errors.title_date_start = 'Questo campo non può essere nullo'
        if (!title_date_end && !current_title)
            errors.title_date_end = 'Questo campo non può essere nullo'
        if (
            title_date_start &&
            new Date(title_date_start).toString() === 'Invalid Date'
        )
            errors.title_date_start = 'Inserisci una data valida '
        if (
            title_date_end &&
            new Date(title_date_end).toString() === 'Invalid Date'
        )
            errors.title_date_end = 'Inserisci una data valida '
        else if (title_date_start > new Date())
            errors.title_date_start =
                'La data non può essere successiva al giorno corrente'
        else if (title_date_end > new Date())
            errors.title_date_end =
                'La data non può essere successiva al giorno corrente'
        else if (title_date_end && title_date_end < title_date_start)
            errors.title_date_end =
                'La data di fine non può essere infeririore alla data di inizio'
        return errors
    }

    const toggleOpen = (index) => {
        //  così possibile avere + form aperti ma da problemi
        // const newTitleObjects = [...titleObjects];
        // newTitleObjects[index].open = !newTitleObjects[index].open;
        // setTitleObjects(newTitleObjects);

        //  così possibile avere solo 1 form aperto
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setTitleObjects(newTitleObjects)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddTitle()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        setTitleList(titlelist)

        const newTitleObjects = titlelist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setTitleObjects(newTitleObjects)
    }, [titlelist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    const handleFieldChange = (event) => {
        setIsEdited(true)
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const renderForm = (index) => {
        return (
            <div className="dropdown-form">
                <div className="d-flex align-center m1">
                    <h3 className="m0">
                        Descrivi questa esperienza, ci aiuterà a presentarti
                        meglio
                    </h3>
                    <button onClick={handleDialogClose} className="close-card">
                        <CloseIcon />
                    </button>
                </div>

                <div className="dialog-layout istruzioneBoxes">
                    <div>
                        <FormControl
                            variant="filled"
                            fullWidth
                            className="select-arr"
                        >
                            <InputLabel
                                required
                                htmlFor="filled-age-native-simple"
                            >
                                Titolo di studio (diploma/laurea..)
                            </InputLabel>
                            <Select
                                native
                                fullWidth
                                required
                                name="eqf"
                                value={eqf_label}
                                onChange={onEqfSelect}
                                inputProps={{
                                    name: 'eqf',
                                    // className: 'smaller-text',
                                }}
                            >
                                <option hidden aria-label="None" value={null} />
                                {title_type.map((title) => {
                                    return (
                                        <option
                                            key={title.label}
                                            value={title.label}
                                        >
                                            {title.label}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <p className="input-error">{formErrors.eqf_label}</p>
                    </div>
                </div>
                <div className="dialog-layout istruzioneBoxes ptop">
                    <div>
                        <TextField
                            label="Presso quale istituto/scuola"
                            variant="filled"
                            name="school"
                            value={school}
                            onChange={handleFieldChange}
                            fullWidth
                            required
                            color="primary"
                        />
                        <p className="input-error">{formErrors.school}</p>
                    </div>
                </div>
                <div className="dialog-layout istruzioneBoxes ptop">
                    {!(eqf_value <= 2 && eqf_value > 0) && (
                        <>
                            <div>
                                <Autocomplete
                                    parentValue={other_title}
                                    className="custom-autocomplete"
                                    onChange={onSelect}
                                    label={
                                        'Indirizzo del percorso di studi (ragioneria/scienze economiche)'
                                    }
                                    apiRequest={(newValue) =>
                                        ApiService.get_titlelist(
                                            newValue,
                                            eqf_value
                                        )
                                    }
                                    required
                                    style={{ background: '#fff' }}
                                    disabled={eqf_value <= 2}
                                />
                                <p className="input-error">
                                    {formErrors.other_title}
                                </p>
                            </div>
                        </>
                    )}
                </div>

                {erasmus === 'true' && !(eqf_value <= 5 && eqf_value > 0) ? (
                    <div className="dialog-layout">
                        <TextField
                            // id="filled-basic"
                            label="Descrizione erasmus"
                            variant="filled"
                            name="erasmus_description"
                            value={erasmus_description}
                            onChange={handleFieldChange}
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                            color="primary"
                            multiline
                            rows={3}
                        />
                    </div>
                ) : null}

                {!(eqf_value <= 4 && eqf_value > 0) && (
                    <>
                        <h3>Votazione</h3>
                        <div className="dialog-layout">
                            <TextField
                                label="Voto finale (es. 80/100; 105/110)"
                                variant="filled"
                                name="graduation_grade"
                                type="text"
                                value={graduation_grade || ''}
                                onChange={onChange}
                                fullWidth
                                color="primary"
                            />
                            {!((eqf_value <= 5) /* && eqf_value > 0 */) && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleChange}
                                            className="switch-slider"
                                            sx={{
                                                '& .MuiSwitch-switchBase.Mui-checked':
                                                    {
                                                        color: '#FFFFFF',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                'rgba(255, 255, 255, 0.08)',
                                                        },
                                                    },
                                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                    {
                                                        backgroundColor:
                                                            '#F06031',
                                                    },
                                                '& .MuiSwitch-switchBase': {
                                                    color: '#FFFFFF',
                                                },
                                                '& .MuiSwitch-track': {
                                                    backgroundColor: '#EEEEEE',
                                                },
                                                '& .MuiSwitch-thumb': {
                                                    boxShadow: 'none',
                                                },
                                            }}
                                        />
                                    }
                                    label="Esperienza all'estero"
                                    labelPlacement="start"
                                    sx={{
                                        justifyContent: 'space-between',
                                        '.MuiFormControlLabel-label': {
                                            color: '#333333',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}

                <h3>Periodo di svolgimento</h3>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={current_title}
                            onChange={onChecked}
                            value={current_title}
                            name="current_title"
                        />
                    }
                    label="Sto attualmente studiando qui"
                />

                <div className="dialog-layout">
                    <div className="custom-input-padding">
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={itLocale}
                        >
                            <KeyboardDatePicker
                                views={['year', 'month']}
                                margin="normal"
                                // id="date-picker-dialog"
                                label="Data inizio (mese/anno)"
                                format="MM/yyyy"
                                value={title_date_start || null}
                                onChange={handleDateChangeStart}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                locale="it-IT"
                                autoOk
                                fullWidth
                                required
                                disableFuture
                                invalidDateMessage=""
                                maxDateMessage=""
                                minDateMessage=""
                            />
                        </MuiPickersUtilsProvider>
                        <p className="input-error">
                            {formErrors.title_date_start}
                        </p>
                    </div>
                    {!current_title ? (
                        <div className="custom-input-padding">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={itLocale}
                            >
                                <KeyboardDatePicker
                                    views={['year', 'month']}
                                    margin="normal"
                                    // id="date-picker-dialog"
                                    label="Data fine (mese/anno)"
                                    format="MM/yyyy"
                                    value={title_date_end || null}
                                    onChange={handleDateChangeEnd}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    locale="it-IT"
                                    autoOk
                                    fullWidth
                                    required
                                    disableFuture
                                    invalidDateMessage=""
                                    maxDateMessage=""
                                    minDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                            <p className="input-error">
                                {formErrors.title_date_end}
                            </p>
                        </div>
                    ) : (
                        <div className="hideOnMobile" />
                    )}
                </div>

                <h3>Racconta la tua esperienza</h3>
                <div className="dialog-layout">
                    <div>
                        <TextField
                            label="Descrizione dell'esperienza (facoltativo)"
                            variant="filled"
                            name="description"
                            value={description}
                            onChange={handleFieldChange}
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                            color="primary"
                            multiline
                            rows={3}
                        />
                        <p
                            className={
                                description?.length < 500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 500 massimo
                        </p>
                    </div>
                </div>

                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                >
                    {dialogText}
                </Button>
            </div>
        )
    }

    // console.log(titleObjects);

    return (
        <>
            <section>
                <div>
                    <div className="section-title">
                        <h2>Cosa hai studiato?</h2>
                        <button
                            className="icon-btn"
                            hidden={
                                open || titleObjects.some((item) => item.open)
                            }
                            onClick={handleClickOpen()}
                        >
                            <AddIcon />
                        </button>
                    </div>

                    {open && renderForm()}

                    {!loading && titleList.length <= 0 && !open && (
                        <div className="p1">
                            <div className="placeholder-card">
                                <div className="bg-el"></div>
                                <div className="d-flex flex-column justify-center align-center text-center h-100">
                                    <p className="accent-text-color">
                                        Inserisci la tua formazione per avere
                                        più successo
                                    </p>
                                    <button
                                        hidden={
                                            open ||
                                            titleObjects.some(
                                                (item) => item.open
                                            )
                                        }
                                        onClick={handleClickOpen()}
                                        className="primary-text-color"
                                    >
                                        Inizia qui
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="box-preview">
                        {loading ? (
                            <div className="card-preview new-box">
                                <div
                                    className="section-lightGray"
                                    id="skeltonExperience"
                                >
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="50%"
                                            height="35px"
                                        />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="70%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="90%" />
                                    </div>

                                    <div>
                                        {' '}
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            titleList.map((title, index) => (
                                <div
                                    className="card-preview new-box"
                                    key={index}
                                >
                                    {titleObjects[index].open ? (
                                        renderForm(index)
                                    ) : (
                                        <div className="lightgray-studies">
                                            <div className="section-lightGray studies">
                                                <div className="d-flex actions-btns">
                                                    <button
                                                        onClick={handleClickUpdateDialogOpen(
                                                            title,
                                                            index
                                                        )}
                                                        className="icon-btn"
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleClickOpenConfirm(
                                                                title.id
                                                            )
                                                        }
                                                        className="icon-btn"
                                                    >
                                                        <BinIcon />
                                                    </button>
                                                </div>
                                                <h3 className="bold text-ellipsis-2">
                                                    🎓 {title.eqf_label}
                                                </h3>
                                            </div>
                                            <div>
                                                <div className="studies">
                                                    <div className="studies-left">
                                                        <p className="bold text-ellipsis-1">
                                                            {title.school && (
                                                                <>
                                                                    🏫{' '}
                                                                    {
                                                                        title.school
                                                                    }
                                                                </>
                                                            )}
                                                        </p>
                                                        <p className="bold">
                                                            📚{' '}
                                                            {title.other_title}
                                                        </p>
                                                    </div>
                                                    <div className="studies-right">
                                                        {title.graduation_grade && (
                                                            <p>
                                                                ⭐️ Votazione{' '}
                                                                {
                                                                    title.graduation_grade
                                                                }
                                                            </p>
                                                        )}
                                                        <p>
                                                            📅 Tempo di
                                                            conseguimento{' '}
                                                            {format(
                                                                new Date(
                                                                    title.title_date_start
                                                                ),
                                                                'yyyy'
                                                            )}{' '}
                                                            -{' '}
                                                            {!title.current_title
                                                                ? format(
                                                                      new Date(
                                                                          title.title_date_end
                                                                      ),
                                                                      'yyyy'
                                                                  )
                                                                : 'In corso'}
                                                        </p>
                                                    </div>
                                                    <p>{title.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>
            {/* DIALOG PER ELIMINARE LA CARD */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default Istruzione
