import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { NavLink } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import { authRoles } from 'app/auth/authRoles'
import TroviamoUnLavoratoreIcon from '../Icons/TroviamoUnLavoratoreIcon'
import LikesIcon from '../Icons/LikesIcon'
import MatchIcon from '../Icons/MatchIcon'
import Badge from '../././../Layouts/Badge'

export const NavBottomFixed = ({ showlikeBadge, showmatchBadge }) => {
    // const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png")
    const { role } = useAuth()

    const navLinks = [
        {
            to: authRoles.recruiter.includes(role)
                ? '/jump-recruiter'
                : '/jump',
            icon: <TroviamoUnLavoratoreIcon />,
            text: authRoles.recruiter.includes(role)
                ? 'Trova Persone'
                : 'Trova Lavoro',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/likes-recruiter'
                : '/likes',
            icon: showlikeBadge ? (
                <Badge color="secondary">
                    <LikesIcon />
                </Badge>
            ) : (
                <LikesIcon />
            ),
            text: 'Like',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/match-recruiter'
                : '/match',
            icon: showmatchBadge ? (
                <Badge color="secondary" sx={{ top: '0 !important' }}>
                    <MatchIcon />
                </Badge>
            ) : (
                <MatchIcon />
            ),
            text: 'Messaggi',
        },
        {
            to: authRoles.recruiter.includes(role)
                ? '/profile-recruiter'
                : '/profile',
            icon: (
                <img
                    src="/images/prof.png"
                    alt="Immagine profilo"
                    style={{
                        borderRadius: '50%',
                        width: '2.5rem',
                        height: '2.5rem',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                    }}
                />
            ),
            text: 'Profilo',
        },
    ]

    return (
        <Box sx={{ width: '100%', position: 'sticky', bottom: 0 }}>
            <nav className="nav-bottom">
                <ul className="nav-links-bottom">
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <NavLink to={link.to} className="jump-link">
                                {link.icon}
                                <span className="text-ellipsis-1">
                                    {link.text}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </Box>
    )
}
