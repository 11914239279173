import React, { useEffect, useRef, useState } from 'react'
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ApiService from 'app/ApiService'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import EditFieldDialog from '../FooterProfile/EditField'

function JobCardHardSkills({
    languagelist,
    offerguid,
    getLanguageList,
    appState,
}) {
    const [open, setOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [languageList, setLanguageList] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false)

    const defaultLanguages = [
        'Italiano',
        'Tedesco',
        'Inglese',
        'Hindi',
        'Cinese',
        'Francese',
        'Spagnolo',
        'Portoghese',
        'Arabo',
        'Olandese',
    ]

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        if (unsavedChanges) {
            setConfirmOpen(true)
        } else {
            setOpen(false)
            setFormErrors({})
            getLanguageList()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            setOpen(false)
            setFormErrors({})
            getLanguageList()
        }
    }

    const handleAddSkill = async () => {
        // const skillsArray = Array.from(languageList)
        // const updatedSkills = skillsArray.map((skill) => {
        //     // Find if the skill already exists in languageList
        //     const existingSkill = languagelist.find(
        //         (item) => item.skill_name === skill
        //     )

        //     return {
        //         id: existingSkill ? existingSkill.id : undefined,
        //         offer_guid: offerguid,
        //         skill_name: skill,
        //         created: new Date(),
        //         lastUpdate: new Date(),
        //     }
        // })

        const otherSoftLv2Set = new Set(
            languagelist.map((item) => item.skill_name)
        )

        const skillsArray = Array.from(languageList)
        const updatedArray = skillsArray.map((skill) => {
            return {
                id: otherSoftLv2Set.has(skill)
                    ? languagelist.find((item) => item.skill_name === skill).id
                    : undefined,
                offer_guid: offerguid,
                checked: otherSoftLv2Set.has(skill),
                skill_name: skill,
                created: new Date(),
                lastUpdate: new Date(),
            }
        })

        languagelist.forEach((otherSkill) => {
            if (!skillsArray.some((skill) => skill === otherSkill.skill_name)) {
                updatedArray.push({
                    id: otherSkill.id,
                    offer_guid: offerguid,
                    checked: false,
                    skill_name: otherSkill.skill_name,
                    // created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        try {
            const res = await ApiService.create_multipleofferLang(updatedArray)
            if (res.ok) {
                await getLanguageList()
                setUnsavedChanges(false)
                handleClose()
            } else {
                const errorText = await res.text()
                console.error(`Failed to save skills: ${errorText}`)
                // setFormErrors({ api: 'Error saving skills' })
            }
        } catch (error) {
            console.error(`Unexpected error saving skills`, error)
            // setFormErrors({ api: 'An unexpected error occurred' })
        }
    }

    const handleDelete = async (id) => {
        const res = await ApiService.delete_offerLang(id)
        if (res.ok) {
            await getLanguageList()
        } else {
            console.error(`Failed to delete skill: ${id}`, res)
        }
    }

    const handleTagClick = (language) => {
        const newlanguageList = new Set(languageList)
        if (newlanguageList.has(language)) {
            newlanguageList.delete(language)
        } else {
            newlanguageList.add(language)
        }
        setLanguageList(newlanguageList)
        setUnsavedChanges(true)
    }

    const onSelect = (event, value) => {
        if (!value) return
    }

    useEffect(() => {
        if (Array.isArray(languagelist)) {
            setLanguageList(new Set(languagelist.map((x) => x.skill_name)))
        }
    }, [languagelist])

    return (
        <>
            <div>
                <h3 style={{ color: '#5058ed' }}>Deve sapere queste lingue.</h3>
                <div className="section-title">
                    <p> Clicca sul + per aggiungerne altre.</p>
                    <button
                        disabled={languageList.size > 12}
                        className="icon-btn"
                        onClick={handleClickOpen}
                    >
                        <AddIcon />
                    </button>
                </div>

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3>Quali di queste lingue deve sapere?</h3>
                            <button
                                onClick={handleClose}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="d-flex align-center langrecprofile">
                            {defaultLanguages.map((language) => (
                                <p
                                    key={language}
                                    onClick={() => handleTagClick(language)}
                                    className="tags"
                                    style={{
                                        boxShadow: languageList.has(language)
                                            ? '0 1px 10px -3px #5058ed'
                                            : 'none',
                                        backgroundColor: languageList.has(
                                            language
                                        )
                                            ? 'rgba(80, 88, 237, 0.1)'
                                            : '#fff',
                                        cursor: 'pointer',
                                        width: '8rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {language}
                                </p>
                            ))}
                        </div>
                        <div style={{ width: '90%', marginBottom: '2rem' }}>
                            <Autocomplete
                                noOptionsText={'Nessun Risultato'}
                                id="grouped-demo"
                                options={languageList}
                                getOptionLabel={(option) => option}
                                onChange={onSelect}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleziona lingua *"
                                    />
                                )}
                            />
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end">
                            <p className="input-error">{formErrors.api}</p>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleAddSkill()
                                }}
                                color="primary"
                                className="btn-like-mui m0"
                            >
                                Salva
                            </Button>
                        </div>
                    </div>
                )}

                <ul className="btns-card grid-2-columns skill-container tags-container">
                    {appState ? (
                        <div className="new-box">
                            <Skeleton variant="rect" width={100} height={30} />
                        </div>
                    ) : (
                        languagelist.map((skill, index) => (
                            <div key={index} className="new-box">
                                <div className="d-flex align-center tags">
                                    <p>{skill.skill_name}</p>
                                    <button
                                        onClick={() => handleDelete(skill.id)}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </ul>
            </div>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardHardSkills
