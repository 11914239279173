import React from 'react'
import { Route } from 'react-router-dom'
// import IntroLayout from 'app/Layouts/IntroLayout/IntroLayout';
import SignupLayout from 'app/Layouts/SignupLayout'

const FreeRoutingGuard = ({ component: Component, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return (
                        // QUESTE SONO LE PAGINE DEL FORM PRINCIPALE DI ISCRIZIONE AL SITO
                        <SignupLayout>
                            <Component {...props} />
                        </SignupLayout>
                    )
                }}
            />
        </>
    )
}

export default FreeRoutingGuard
