import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const ICON_SIZE = 50
const VIEW_BOX = '0 0 800 800'
const STROKE_WIDTH = 35
const STROKE_MITERLIMIT = 10
const STROKE_COLOR = '#4b4c55'

const LikesIcon = (props) => {
    return (
        <svg
            width={props.width ?? ICON_SIZE}
            height={props.height ?? ICON_SIZE}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox={props.viewBox ?? VIEW_BOX}
        >
            <g>
                <path
                    className="likes-icon"
                    style={{
                        strokeWidth: props.strokeWidth ?? STROKE_WIDTH,
                        strokeMiterlimit:
                            props.strokeMiterlimit ?? STROKE_MITERLIMIT,
                        stroke: props.stroke ?? STROKE_COLOR,
                        fill: 'none',
                    }}
                    d="M679,299.41c0,5.62,0,11.24,0,16.87
            c-0.49,4.17-0.91,8.34-1.47,12.5c-4.88,36.17-19.99,67.45-45.74,93.27
            c-76.47,76.68-153.12,153.19-229.69,229.78
            c-0.63,0.63-1.01,1.53-1.51,2.3c-0.35-0.02-0.7-0.05-1.04-0.07
            c-0.52-0.74-0.95-1.58-1.58-2.22
            c-24.25-24.27-48.52-48.52-72.77-72.78
            c-52.31-52.33-104.69-104.58-156.87-157.03
            c-25.85-25.99-41.05-57.37-45.85-93.74
            c-0.59-4.48-0.98-9-1.47-13.49
            c0-4.63,0-9.26,0-13.89
            c0.16-1.05,0.34-2.09,0.47-3.14
            c0.9-7.12,1.35-14.33,2.74-21.36
            c16.46-83.17,94.26-139.55,178.29-129.33
            c31.39,3.82,59.3,15.85,83.73,35.91
            c4.78,3.92,9.22,8.25,13.8,12.37
            c4.49-4.04,8.84-8.34,13.58-12.17
            c37.63-30.4,80.51-42.37,128.34-35.3
            c32.49,4.8,60.93,18.74,84.99,41.14
            c28.51,26.54,45.3,59.28,50.59,97.88
            C678.1,291.05,678.51,295.24,679,299.41z"
                />
            </g>
        </svg>
    )
}

export default LikesIcon
