import IntroPage from 'app/components/LoginComponents/IntroPage'
import SignupForm from 'app/components/LoginComponents/SignupForm'
import LoginForm from 'app/components/LoginComponents/LoginForm'
import Logout from 'app/components/LoginComponents/Logout'
import MailCheck from '../components/LoginComponents/MailCheck'
import PasswordCheck from '../components/LoginComponents/PasswordCheck'
import PasswordReset from '../components/LoginComponents/PasswordReset'

const loginRoutes = [
    {
        path: '/login',
        component: LoginForm,
    },
    {
        path: '/logout',
        component: Logout,
    },
    {
        path: '/signup',
        component: SignupForm,
    },
    {
        path: '/forgot-password',
        component: PasswordReset,
    },
    {
        path: '/well-done',
        component: MailCheck,
    },
    {
        path: '/password-done',
        component: PasswordCheck,
    },
    {
        path: '/start',
        component: IntroPage,
    },
    // {
    //     path: '/session/404',
    //     component: NotFound,
    // },
]

export default loginRoutes
