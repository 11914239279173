import React, { useState, useEffect } from 'react'
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Dialog from '@material-ui/core/Dialog'
import EditIcon2 from '@material-ui/icons/Edit'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link, useLocation } from 'react-router-dom'

const progressChecks = {
    photo: false,
    education: false,
    exp: false,
    soft: false,
    hard: false,
    langs: false,
    bio: false,
}

const progressChecksRecruiter = {
    photo: false,
    sector: false,
    description: false,
    anagrafica: false,
    experience: false,
    education: false,
    softSkills: false,
    hardSkills: false,
    language: false,
}

const tasks = [
    {
        id: 'photo',
        label: 'inserisci la foto profilo',
        completed: false,
        link: '/',
    },
    {
        id: 'education',
        label: 'inserisci un titolo di studio completo',
        completed: false,
        link: '/',
    },
    {
        id: 'exp',
        label: "inserisci un'esperienza professionale completa",
        completed: false,
        link: '/',
    },
    {
        id: 'soft',
        label: 'inserisci 5 competenze trasversali',
        completed: false,
        link: '/',
    },
    {
        id: 'hard',
        label: 'inserisci 5 competenze tecniche',
        completed: true,
        link: '/',
    },
    { id: 'langs', label: 'inserisci una lingua', completed: false, link: '/' },
    { id: 'bio', label: 'inserisci il sommario', completed: false, link: '/' },
]
const recruiterTasks = [
    {
        id: 'photo',
        label: 'inserisci la foto profilo',
        completed: false,
        link: '/profile-recruiter/photo',
    },
    {
        id: 'sector',
        label: "inserisci almeno un settore in cui opera l'azienda",
        completed: false,
        link: '/profile-recruiter/sector',
    },
    {
        id: 'description',
        label: 'inserisci la descrizione della tua azienda',
        completed: false,
        link: '/profile-recruiter/description',
    },
    {
        id: 'anagrafica',
        label: "completa anagrafica dell'offerta attiva",
        completed: false,
        link: '/profile-recruiter/anagrafica',
    },
    {
        id: 'experience',
        label: "aggiungi un'esperienza professionale se richiesta",
        completed: false,
        link: '/profile-recruiter/experience',
    },
    {
        id: 'education',
        label: 'aggiungi un titolo di studio se richiesto',
        completed: false,
        link: '/profile-recruiter/education',
    },
    {
        id: 'softSkills',
        label: 'aggiungi 5 competenze trasversali',
        completed: false,
        link: '/profile-recruiter/soft-skills',
    },
    {
        id: 'hardSkills',
        label: 'aggiungi 5 competenze tecniche',
        completed: false,
        link: '/profile-recruiter/hard-skills',
    },
    {
        id: 'language',
        label: 'aggiungi almeno una lingua richiesta',
        completed: false,
        link: '/profile-recruiter/language',
    },
]

function ProfileCompletion({ progressChecks }) {
    const [taskList, setTaskList] = useState([])
    const [open, setOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:600px)')
    const location = useLocation()
    const isRecruiterPage = location.pathname.includes('profile-recruiter')

    const handleCloseConfirm = () => {
        setOpen(false)
    }

    useEffect(() => {
        const isRecruiterPage = location.pathname.includes('profile-recruiter')
        const selectedTasks = isRecruiterPage ? recruiterTasks : tasks
        const selectedProgressChecks = isRecruiterPage
            ? progressChecksRecruiter
            : progressChecks

        const updatedTasks = selectedTasks.map((task) => ({
            ...task,
            completed: selectedProgressChecks[task.id] ?? false,
        }))

        setTaskList(updatedTasks)
    }, [location.pathname])

    return (
        <>
            <div className="btn-profile orange" onClick={() => setOpen(true)}>
                <EditIcon2 />
                {isRecruiterPage
                    ? 'Completa il profilo'
                    : 'Completa il profilo'}
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                className={
                    isMobile
                        ? 'small-dialog mobile'
                        : 'small-dialog profCompletion'
                }
            >
                <div className="d-flex space-between dialog-title profile-page recCompletprofilo">
                    <DialogTitle className="prof-dialog-title rec">
                        <EditIcon2 />
                        <Typography
                            sx={{
                                fontSize: isRecruiterPage ? '0.8rem' : '1rem',
                            }}
                        >
                            {isRecruiterPage
                                ? 'Completa la tua offerta attiva, aumenta i risultati'
                                : 'Completa il profilo'}
                        </Typography>
                    </DialogTitle>

                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        className="close-dialog"
                    >
                        <Closebtnblue />
                    </Button>
                </div>
                {taskList.map((task) => (
                    <Box
                        key={task.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: isRecruiterPage ? '0.3rem' : '1rem',
                        }}
                    >
                        {task.completed ? (
                            <CheckCircleIcon
                                className="iconCloseProf"
                                sx={{ color: 'green', marginRight: 1 }}
                            />
                        ) : (
                            <Closebtnblue className="NotComplete" />
                        )}

                        <Typography
                            variant="body1"
                            component={Link}
                            to={task.link}
                            style={{
                                fontSize: isRecruiterPage ? '0.8rem' : '0.9rem',
                                textDecoration: 'none',
                                color: 'inherit',
                                cursor: 'pointer',
                            }}
                        >
                            {task.label}
                        </Typography>
                    </Box>
                ))}
            </Dialog>
        </>
    )
}

export default ProfileCompletion
