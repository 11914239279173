import React, { createContext, useEffect, useReducer, useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import ApiService from 'app/ApiService'
// import { MatxLoading } from 'app/components'

const initialState = {
    isJWTAuthenticated: true,
    isJWTInitialised: true
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() /1000
    // console.log(decodedToken)
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isJWTAuthenticated } = action.payload

            return {
                ...state,
                isJWTAuthenticated,
                isJWTInitialised: true
            }
        }
        case 'LOGIN': {
            return {
                ...state,
                isJWTAuthenticated: true
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isJWTAuthenticated: false
            }
        }

        default: {
            return { ...state }
        }
    }
}

const AuthJWTContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {}
})

export const AuthJWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (name) => {
        try {
        const response = await ApiService.login({
            name
        })
        
        const { accessToken } = await response.json();

        setSession(accessToken)

        dispatch({
            type: 'LOGIN',
            payload: {
            },
        })
        }
        catch (e) {

        }
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    const memorizedValue = useMemo(
        () => ({
            ...state,
            method: 'JWT',
            login,            
            logout           
        }),
        [state]
    )

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    // console.log(accessToken)
                    setSession(accessToken)                    

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isJWTAuthenticated: true                            
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isJWTAuthenticated: false
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isJWTAuthenticated: false
                    },
                })
            }
        })()
    }, [])

    // if (!state.isJWTInitialised) {
    //     return <MatxLoading />
    // }

    return (
        <AuthJWTContext.Provider
            value={memorizedValue}
        >
            {children}
        </AuthJWTContext.Provider>
    )
}

export default AuthJWTContext
