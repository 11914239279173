import React from 'react'
import Home from '../components/LandingPages/Pages/home'
import Page1 from '../components/LandingPages/Pages/chi-siamo'
import Page2 from '../components/LandingPages/Pages/dai-lavoro'
import Page3 from '../components/LandingPages/Pages/cerchi-lavoro'
import Tutorial from '../components/LandingPages/Pages/Tutorial'
import Pagestemplet from '../components/LandingPages/Pagestemplate'

const landingRoutes = [
    // {
    //     path: '/home',
    //     component: Home,
    // },
    // {
    //     path: '/aboutus',
    //     component: () => (
    //         <Pagestemplet>
    //             <Page1 />
    //         </Pagestemplet>
    //     ),
    // },
    // {
    //     path: '/dai-lavoro',
    //     component: () => (
    //         <Pagestemplet>
    //             <Page2 />
    //         </Pagestemplet>
    //     ),
    // },
    // {
    //     path: '/cerchi-lavoro',
    //     component: () => (
    //         <Pagestemplet>
    //             <Page3 />
    //         </Pagestemplet>
    //     ),
    // },
    {
        path: '/tutorial',
        component: Tutorial,
    },
]

export default landingRoutes
