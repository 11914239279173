import React, { useState } from 'react'
// import '../../Styles/Variables.css'
// import '../../Styles/SignupSteps.css'
// import '../../Styles/Intro.css'

function FooterIntro() {
    return <footer>© Kangrats 2024</footer>
}

export default FooterIntro
