import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SendIcon from '@material-ui/icons/Send'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import ReplyIcon from '@material-ui/icons/Reply'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import useFirestore from 'app/hooks/useFirestore'
import useAuth from 'app/hooks/useAuth'
import CloseIconWhite from 'app/components/Icons/CloseIconWhite'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LoadingAnimation from 'app/components/Animation/LoadingAnimation'
import Slider from 'react-slick'
import { authRoles } from 'app/auth/authRoles'

function PricingPage({ open, setOpen }) {
    const { pathname } = useLocation()
    const location = useLocation()
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const isMobile = useMediaQuery('(max-width:500px)')
    const { getActiveProducts, createPaymentSession, getActiveSubscription } =
        useFirestore()
    const { user, role } = useAuth()

    const candidateContent = [
        { icon: <SendIcon />, text: 'Più candidature' },
        { icon: <RemoveRedEyeIcon />, text: 'Vedi a chi interessi' },
        { icon: <ReplyIcon />, text: 'Torna indietro' },
    ]

    const recruiterContent = [
        { icon: <SendIcon />, text: 'Più interessi al giorno' },
        { icon: <RemoveRedEyeIcon />, text: 'Vedi chi si candida' },
        { icon: <ReplyIcon />, text: 'Torna indietro' },
    ]

    const styles = {
        root: {
            backgroundColor: '#6C6C6C',
            display: isMobile ? 'grid' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '2rem',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            gap: isMobile ? '0' : '1rem',
            textAlign: 'center',
        },
        closeButton: {
            position: 'absolute',
            top: '1rem',
            right: '2rem',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            color: '#fff !important',
            cursor: 'pointer',
        },
        header: {
            textAlign: 'center',
            color: '#ffffff',
            marginBottom: isMobile ? '0rem' : '1rem',
            fontWeight: '500',
            position: 'relative',
            top: isMobile ? '0rem' : '0.5rem',
        },
        cardContainer: {
            display: isMobile ? 'grid' : 'flex',
            gridTemplateColumns: isMobile ? '24rem' : undefined,
            justifyContent: 'center',
            gap: isMobile ? '1rem' : '10%',
            marginBottom: isMobile ? '0' : '-2rem',
            width: isMobile ? '100%' : '80%',
            maxHeight: isMobile ? '18rem' : undefined,
            overflowY: isMobile ? 'auto' : 'visible',
            height: isMobile ? 'auto' : undefined,
            width: isMobile ? '25rem' : undefined,
            zIndex: 11111,
        },
        card: {
            width: isMobile ? '240px' : '200px',
            padding: '1.8rem',
            borderRadius: '12px',
            backgroundColor: '#ffffff',
            textAlign: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            height: isMobile ? '16rem' : '100%',
            cursor: 'pointer',
            zIndex: 1002,
        },
        cardPopular: {
            border: '4px solid #ff6b00',
        },
        price: {
            fontSize: isMobile ? '3rem' : '2rem',
            color: '#5058ED',
            fontWeight: 'bold',
            margin: '1rem auto',
        },
        priceBLUE: {
            fontSize: isMobile ? '3rem' : '2rem',
            color: '#5058ED',
            fontWeight: 'bold',
            margin: '1rem auto',
        },
        planText: {
            marginBottom: '0.5rem',
            fontSize: isMobile ? '1rem' : '0.7rem',
            color: '#777',
        },
        button: {
            backgroundColor: '#664cff',
            color: '#ffffff',
            border: 'none',
            borderRadius: '20px',
            padding: '0.2rem 0.5rem',
            fontSize: isMobile ? '1rem ' : '0.7rem',
            cursor: 'pointer',
            position: 'absolute',
            bottom: '-0.7rem',
            visibility: 'visible',
            top: 'auto',
            right: isMobile ? '1%' : undefined,
            left: isMobile ? '1%' : undefined,
            margin: isMobile ? 'auto' : undefined,
            width: isMobile ? 'fit-content' : 'auto',
            zIndex: 1003,
        },
        buttonHidden: {
            visibility: 'hidden',
        },
        buttonHover: {
            backgroundColor: '#593bcc',
        },
        buttonPremium: {
            marginTop: isMobile ? '0rem ' : '2rem',
            backgroundColor: '#fff',
            color: '#ea6c3a',
            border: 'none',
            borderRadius: '20px',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            cursor: 'pointer',
            fontWeight: '400',
            top: 'auto',
            right: 'auto',
            position: 'relative',
            cursor: 'pointer',
        },
        subtext: {
            marginTop: '1rem',
            color: '#fff',
            fontSize: isMobile ? '1rem' : '0.7rem',
        },
        subtextRecruiter: {
            marginTop: '1rem',
            color: '#fff',
            fontSize: '0.7rem',
            textAlign: 'center',
        },
    }
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
    const customGridStyles = `
      .slick-slider.slick-initialized {
           display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    height: 7rem;
    top: 0rem;
    justify-items: end;
    align-items: center;
        
     
        .slick-dots li button:before{
        color:rgb(80, 88, 237)}
        .slick-dots{
      display:block !important ;

     
}}
      .slick-slider .slick-list {
    transform: translateZ(0);
    width:100%;
    display:flex
}
     @media only screen and (max-width: 500px) {
   .slick-slider.slick-initialized {
     top: -4rem;
   }
 }

        `

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'lg'}
            scroll={'paper'}
            className="MUIDialog custom-dialog"
        >
            <style>{customGridStyles}</style>
            <div className="d-flex space-between dialog-title profile-page">
                <Button
                    color="primary"
                    className=" price"
                    onClick={() => setOpen(false)}
                >
                    <CloseIconWhite />
                </Button>
                <DialogContent dividers={true}>
                    <div style={styles.root} className="popupcommingsoon">
                        <h1 style={styles.header}>
                            Funzionalità disponibili a breve!
                        </h1>
                        {isMobile ? (
                            <Slider>
                                {authRoles.recruiter.includes(role)
                                    ? recruiterContent.map((item, index) => (
                                          <div
                                              key={index}
                                              className="PlanCard pricePage"
                                          >
                                              <div className="PlanCardcomponent pricePage">
                                                  {item.icon}
                                                  <h4>{item.text}</h4>
                                              </div>
                                          </div>
                                      ))
                                    : candidateContent.map((item, index) => (
                                          <div
                                              key={index}
                                              className="PlanCard pricePage"
                                          >
                                              <div className="PlanCardcomponent pricePage">
                                                  {item.icon}
                                                  <h4>{item.text}</h4>
                                              </div>
                                          </div>
                                      ))}
                            </Slider>
                        ) : (
                            <div className="PlanCard pricePage">
                                {authRoles.recruiter.includes(role)
                                    ? recruiterContent.map((item, index) => (
                                          <div
                                              key={index}
                                              className="PlanCardcomponent pricePage"
                                          >
                                              {item.icon}
                                              <h4>{item.text}</h4>
                                          </div>
                                      ))
                                    : candidateContent.map((item, index) => (
                                          <div
                                              key={index}
                                              className="PlanCardcomponent pricePage"
                                          >
                                              {item.icon}
                                              <h4>{item.text}</h4>
                                          </div>
                                      ))}
                            </div>
                        )}

                        <div className="buttons-premim">
                            <button
                                style={styles.buttonPremium}
                                // onClick={() => createPaymentSession()}
                                onClick={() =>
                                    window.open(
                                        'https://hylkxrsnso4.typeform.com/to/Uc4BCWV2',
                                        '_blank'
                                    )
                                }
                            >
                                {authRoles.recruiter.includes(role)
                                    ? 'Mostra interesse ora'
                                    : authRoles.candidate.includes(role)
                                    ? 'Scopri di più'
                                    : null}
                            </button>
                            <p style={styles.subtext}>e provale in anteprima</p>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    )
}

export default PricingPage
