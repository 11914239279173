import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const CardsPlaceholder = (props) => {
    return (
        <article
            className="profile-card section-lightGray"
            id="placeholder-bioRecCard"
        >
            <Skeleton variant="text" width={60} />
            <Skeleton variant="text" width={133} />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="60%" />
        </article>
    )
}

export default CardsPlaceholder
