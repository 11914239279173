import React from 'react'

function EditIcon() {

    const accentColor = '#5058ed' 
    
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="none"/>
        <g clipPath="url(#clip0_3_59)">
            <path d="M29.214 35H16.6553C14.0882 35 12 32.9683 12 30.4705V18.2518C12 15.7541 14.0882 13.7224 16.6553 13.7224H25.6418C26.0664 13.7224 26.411 14.0577 26.411 14.4708C26.411 14.8839 26.0664 15.2192 25.6418 15.2192H16.6553C14.9369 15.2192 13.5384 16.5798 13.5384 18.2518V30.4705C13.5384 32.1425 14.9369 33.5032 16.6553 33.5032H29.214C30.9324 33.5032 32.3308 32.1425 32.3308 30.4705V22.4799C32.3308 22.0668 32.6755 21.7315 33.1001 21.7315C33.5247 21.7315 33.8693 22.0668 33.8693 22.4799V30.4705C33.8693 32.9683 31.7811 35 29.214 35Z" fill={accentColor}/>
            <path d="M19.7651 28.8033C19.4912 28.8033 19.2302 28.699 19.0333 28.5074C18.8056 28.2859 18.6974 27.98 18.7369 27.6692L19.2738 23.4596C19.3492 22.8663 19.6302 22.307 20.0646 21.8844L29.4126 12.7891C30.4936 11.7373 32.252 11.7378 33.3325 12.7891L35.1883 14.5943C35.7119 15.1037 36.0001 15.7813 36.0001 16.5012C36.0001 17.2217 35.7119 17.8988 35.1883 18.4082L25.8409 27.5035C25.4065 27.9262 24.8316 28.1996 24.2219 28.2729L19.8953 28.7953C19.8517 28.8003 19.8082 28.8033 19.7651 28.8033ZM31.373 13.4966C31.0571 13.4966 30.7413 13.6133 30.5008 13.8473L21.1527 22.9422C20.9594 23.1303 20.8348 23.3792 20.801 23.6432L20.343 27.2331L24.0322 26.7876C24.3034 26.7546 24.5593 26.6334 24.7527 26.4453L34.1007 17.35C34.5817 16.8819 34.5817 16.1205 34.1007 15.6525L32.2448 13.8473C32.0043 13.6133 31.6884 13.4966 31.373 13.4966Z" fill={accentColor}/>
        </g>
        <defs>
            <clipPath id="clip0_3_59">
            <rect width="24" height="23" fill="none" transform="translate(12 12)"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default EditIcon