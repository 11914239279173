import {
    FormControl,
    InputLabel,
    Select,
    TextareaAutosize,
    TextField,
} from '@material-ui/core'
import React, { useEffect, useRef, useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FotoProfilo from './crop/utils/FotoProfilo'
import Container from '../../TreeSelect/DropdownContainer'
import SectorDataTree from '../../Tables/ateco_table'
import useStorage from 'app/hooks/useStorage'
import CloseIcon from 'app/components/Icons/CloseIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import StorageContext from '../../../contexts/FirebaseStorageContext'
import { Autocomplete } from '@material-ui/lab'
import Sectors from '../../Tables/sectors_simplified'
import Chip from '@material-ui/core/Chip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@mui/material/CircularProgress'
import ProfileCompletion from './ProfileCompletion/ProfileCompletionRecruiter'
import EditFieldDialog from './FooterProfile/EditField'

function GradientCircularProgress({ value, imageSrc }) {
    const classes = useStyles()

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {/* Background Circle */}
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    color: 'gray',
                }}
                size="9rem"
                thickness={3}
            />
            {/* Gradient Progress */}
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: '#ea6c3a',
                    position: 'absolute',
                    left: 0,
                    borderRadius: '50%',
                    width: '9rem !important',
                    height: '9rem  !important',
                    [`& .MuiCircularProgress-circle`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size="9rem"
                thickness={3}
            />

            {/* Center Image */}
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={imageSrc}
                    alt="Profile"
                    style={{
                        borderRadius: '50%',
                        width: '75%',
                        height: '75%',
                        objectFit: 'cover',
                        position: 'absolute',
                        margin: 'auto',
                        left: '1%',
                        right: '1%',
                    }}
                />

                {value === 100 && (
                    <CheckCircleIcon className={classes.customIcon} />
                )}
            </Box>

            {value !== 100 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: -5,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        background: '#ea6c3a',
                        width: '2rem',
                        borderRadius: '5px',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
const useStyles = makeStyles(() => ({
    customIcon: {
        backgroundColor: '#FFF',
        fontSize: '1.5rem',
        borderRadius: '150px',
    },
}))

function BioCompany({
    user,
    userInfo,
    onChange,
    onNodeSelect,
    handleSubmit,
    onOpen,
    onClose,
    setUserInfo,
    loading,
    progress,
    progressChecksRecruiter,
}) {
    const { photoURL, setPhotoURL } = useContext(StorageContext)
    const [localPhotoURL, setLocalPhotoURL] = useState(photoURL)
    //per dialog
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setSubmit] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [selectedSectors, setSelectedSectors] = useState(
        userInfo.sector || []
    )
    // PER CROP

    const [photo, setPhoto] = useState()
    const {
        recruiter_guid,
        name,
        surname,
        birth_date,
        company_name,
        headquarters,
        phone,
        gender,
        email,
        size,
        description,
        sector,
        photo_url,
        created,
        lastUpdate,
    } = userInfo

    useEffect(() => {
        setLocalPhotoURL(photoURL)
    }, [photoURL])

    const { uploadPhoto } = useContext(StorageContext)
    useEffect(() => {
        setLocalPhotoURL(photoURL)
    }, [photoURL])

    const handleClickOpen = (scrollType) => () => {
        setOpen(true)
        onOpen()
        setScroll(scrollType)
    }

    const handleClose = () => {
        setPhoto(null)
        if (user?.photoURL) {
            setPhotoURL(user.photoURL)
            setLocalPhotoURL(user.photoURL)
        } else {
            setPhotoURL('images/prof.png')
            setLocalPhotoURL('images/prof.png')
        }
        onClose()
        setOpen(false)
        setFormErrors({})
    }

    const updatePhoto = async (event) => {
        event.preventDefault()
        if (photo) {
            const res = await uploadPhoto(photo)
            if (res) {
                setUserInfo({ ...userInfo, photo_url: res })
                setPhotoURL(res)
                setLocalPhotoURL(res)
            }
        }
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}

        if (!company_name)
            errors.company_name = 'Questo campo non può essere nullo'
        if (!headquarters)
            errors.headquarters = 'Questo campo non può essere nullo'
        // if (!size) errors.size = 'Questo campo non può essere nullo'

        return errors
    }

    useEffect(() => {
        if (user?.photoURL) {
            setLocalPhotoURL(user.photoURL)
            setPhotoURL(user.photoURL)
        }
    }, [user, setPhotoURL])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleSubmit()
                handleClose()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.photoURL) setPhotoURL(user.photoURL)
    }, [user])

    useEffect(() => {
        if (sector) sector.map((sec) => findValue(SectorDataTree, sec))
    }, [userInfo])

    const handleFieldChange = (e) => {
        setIsEdited(true)
        onChange(e)
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }

    const handleSectorChange = (newSectors) => {
        setSelectedSectors(newSectors)
        setUserInfo({ ...userInfo, sector: newSectors })
    }
    const SimpleAutocomplete = ({ options, selectedOptions, onChange }) => {
        const handleSelect = (value) => {
            if (value) {
                onChange([...selectedOptions, value])
            }
        }

        const handleDelete = (optionToDelete) => {
            onChange(
                selectedOptions.filter((option) => option !== optionToDelete)
            )
        }

        return (
            <div>
                <div className="d-flex flex-wrap">
                    {selectedOptions.map((option, index) => (
                        <div key={index} className="new-box tags-container">
                            <div
                                className="d-flex align-center tags "
                                style={{ background: '#f0f0f3' }}
                            >
                                <p>{option}</p>
                                <button
                                    className="icon-btn"
                                    onClick={() => handleDelete(option)}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <Autocomplete
                    options={options}
                    freeSolo
                    onChange={(event, newValue) => handleSelect(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Scrivi una parola chiave"
                        />
                    )}
                />
            </div>
        )
    }
    return (
        <>
            <article className="profile-card section-lightGray">
                <div className="d-flex align-center">
                    <div>
                        {loading ? (
                            <Skeleton variant="circle">
                                <img alt="" className="profile-photo" />
                            </Skeleton>
                        ) : (
                            <>
                                <GradientCircularProgress
                                    value={progress}
                                    imageSrc={localPhotoURL}
                                />
                            </>
                        )}
                    </div>
                    <ul>
                        {/* <li>{name} {surname}</li> */}

                        {loading ? (
                            <div>
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={110} />
                                <Skeleton variant="text" width={120} />
                                <Skeleton variant="text" width={130} />
                                <Skeleton variant="text" width={210} />
                            </div>
                        ) : (
                            <div>
                                <>
                                    <li>📌 {headquarters}</li>
                                    {size && <li>🏭 {`${size} dipendenti`}</li>}
                                    <li>
                                        🗂{' '}
                                        {sector.map((sec, index) => (
                                            <span key={index}>{sec} </span>
                                        ))}
                                    </li>
                                    {loading ? (
                                        <div className="buttons-Candidate rec">
                                            <Skeleton
                                                variant="rectangular"
                                                width={180}
                                                height={50}
                                                style={{ marginRight: 16 }}
                                            />
                                            <Skeleton
                                                variant="rectangular"
                                                width={180}
                                                height={50}
                                                style={{ marginRight: 16 }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                progress === 100
                                                    ? 'button-candidate rec'
                                                    : 'buttons-Candidate rec'
                                            }
                                        >
                                            {progress !== 100 && (
                                                <ProfileCompletion
                                                    progressChecks={
                                                        progressChecksRecruiter
                                                    }
                                                    type="recruiter"
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                    </ul>
                    <button
                        className="icon-btn shift-icon"
                        onClick={handleClickOpen('paper')}
                    >
                        <EditIcon />
                    </button>
                </div>
                <div>
                    {loading ? (
                        <div>
                            {/* Skeleton for the paragraph text */}
                            <Skeleton variant="text" width={300} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={280} />
                        </div>
                    ) : (
                        <p className="pre-space hyphens">{description}</p>
                    )}
                </div>

                <Dialog
                    open={open}
                    onClose={handleDialogClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className="MUIDialog"
                >
                    <div className="d-flex space-between dialog-title profile-page">
                        <DialogTitle id="scroll-dialog-title">
                            Presentazione Azienda
                        </DialogTitle>
                        <Button
                            onClick={() => {
                                setSubmit(false)
                                handleDialogClose()
                            }}
                            color="primary"
                            className="close-dialog"
                        >
                            <CloseIcon />
                        </Button>
                    </div>

                    <DialogContent dividers={scroll === 'paper'}>
                        <div className="mt1">
                            <FotoProfilo
                                file={photo}
                                photoUrl={localPhotoURL}
                                setPhotoURL={setPhotoURL}
                                setFile={setPhoto}
                                {...{ photoURL }}
                            />
                        </div>
                        <h3 style={{ marginTop: '3rem' }}>Dati azienda</h3>
                        <div className="dialog-layout">
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Nome azienda"
                                    variant="filled"
                                    value={company_name}
                                    name="company_name"
                                    fullWidth
                                    required
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {formErrors.company_name}
                                </p>
                            </div>

                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Sede principale azienda"
                                    variant="filled"
                                    value={headquarters}
                                    name="headquarters"
                                    fullWidth
                                    required
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {formErrors.headquarters}
                                </p>
                            </div>

                            <div>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="filled-age-native-simple">
                                        Numero dipendenti
                                    </InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        required
                                        value={size}
                                        onChange={handleFieldChange}
                                        inputProps={{
                                            name: 'size',
                                            // id: 'filled-age-native-simple',
                                        }}
                                    >
                                        <option
                                            value=""
                                            disabled
                                            hidden
                                            defaultValue
                                        ></option>
                                        <option value="0-9">1-9</option>
                                        <option value="10-49">10-49</option>
                                        <option value="50-249">50-249</option>
                                        <option value="250+">250+</option>
                                    </Select>
                                </FormControl>
                                <p className="input-error">{formErrors.size}</p>
                            </div>
                        </div>
                        <div className="sectorRecCompany">
                            <h3>SETTORE</h3>
                            <p>
                                Scegli quelle che ti servono dal menu a tendina.
                            </p>
                            <div
                                className="dialog-layout m0"
                                style={{ gridTemplateColumns: '1fr' }}
                            >
                                <SimpleAutocomplete
                                    options={Sectors}
                                    selectedOptions={selectedSectors}
                                    onChange={handleSectorChange}
                                />
                                <div className="hideOnMobile"></div>
                            </div>
                        </div>
                        <h3>Altre informazioni</h3>
                        <div className="dialog-layout">
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Sito Web"
                                    variant="filled"
                                    //value={}
                                    name="Sito Web"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {/* {formErrors.} */}
                                </p>
                            </div>

                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Facebook"
                                    variant="filled"
                                    //value={}
                                    name="Pagina Facebook"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {formErrors.headquarters}
                                </p>
                            </div>

                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Instagram"
                                    variant="filled"
                                    //value={}
                                    name="Pagina Instagram"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {/* {formErrors.} */}
                                </p>
                            </div>
                            <div>
                                <TextField
                                    id="filled-basic"
                                    label="Pagina Linkedin"
                                    variant="filled"
                                    //value={headquarters}
                                    name="Pagina Linkedin"
                                    fullWidth
                                    type="text"
                                    color="primary"
                                    onChange={handleFieldChange}
                                />
                                <p className="input-error">
                                    {/* {formErrors.} */}
                                </p>
                            </div>
                        </div>
                        {/* <div className="options-selected-container">
                        {sector.map((jobSectorSelected, index) => (
                            <div
                                className="d-flex align-center tags new-box grayTags"
                                key={index}
                            >
                                <p>{jobSectorSelected}</p>
                                <button
                                    className="tag-closebtn"
                                    onClick={(e) => {
                                        handleDeleteSector(e, index)
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        ))}
                    </div> */}

                        <h3>Raccontaci la tua azienda</h3>
                        {/* <div>
                        <TextareaAutosize
                            // minRows={100}
                            className='textarea'
                            // maxRows={5}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua."
                        />
                    </div> */}

                        <TextField
                            // id="filled-basic"
                            label="Racconta chi sei (max 500 caratteri), esempio: Sono un lavoratore con grande esperienza nel ruolo di barista e cameriere. Sono una persona molto volenterosa e disponibile, anche rispetto ad orari e mansioni. Mi piacerebbe lavorare nella GDO. "
                            variant="filled"
                            name="description"
                            value={description}
                            fullWidth
                            // required
                            color="primary"
                            multiline
                            rows={4}
                            onChange={handleFieldChange}
                            inputProps={{ maxLength: 1500 }}
                        />
                        <p
                            className={
                                description?.length < 1500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 1500 massimo
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={
                                async (e) => {
                                    e.preventDefault()
                                    await updatePhoto(e)
                                    setSubmit(true)
                                    // handleClose()
                                }
                                // handleSubmit(e);
                                // setOpen(false);}
                            }
                            color="primary"
                        >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog>
                {confirmOpen && (
                    <EditFieldDialog
                        open={confirmOpen}
                        onClose={handleConfirmClose}
                    />
                )}
            </article>
        </>
    )
}

export default BioCompany
