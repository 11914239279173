const TitleDataTree = [
  {
    "checked": false,
    "label": "INGEGNERIA",
    "value": "00_INGEGNERIA",
    "key": "00",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Civile",
        "parent": "INGEGNERIA",
        "value": "LM/23_Ingegneria Civile",
        "key": "LM/23"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "LM/33_Ingegneria Meccanica",
        "key": "LM/33"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Aerospaziale e Astronautica",
        "parent": "INGEGNERIA",
        "value": "LM/20_Ingegneria Aerospaziale e Astronautica",
        "key": "LM/20"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria della Sicurezza",
        "parent": "INGEGNERIA",
        "value": "LM/26_Ingegneria della Sicurezza",
        "key": "LM/26"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Pianificazione Territoriale Urbanistica e Ambientale",
        "parent": "INGEGNERIA",
        "value": "LM/48_Pianificazione Territoriale Urbanistica e Ambientale",
        "key": "LM/48"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Tecniche e Metodi per la Società dell'Informazione",
        "parent": "INGEGNERIA",
        "value": "LM/91_Tecniche e Metodi per la Società dell'Informazione",
        "key": "LM/91"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Architettura e Ingegneria Edile-Architettura",
        "parent": "INGEGNERIA",
        "value": "LM/04_Architettura e Ingegneria Edile-Architettura",
        "key": "LM/04"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecniche dell’Edilizia",
        "parent": "INGEGNERIA",
        "value": "L/23_Scienze e Tecniche dell’Edilizia",
        "key": "L/23"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Informatica",
        "parent": "INGEGNERIA",
        "value": "LM/32_Ingegneria Informatica",
        "key": "LM/32"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria dell'Automazione",
        "parent": "INGEGNERIA",
        "value": "LM/25_Ingegneria dell'Automazione",
        "key": "LM/25"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Biomedica",
        "parent": "INGEGNERIA",
        "value": "LM/21_Ingegneria Biomedica",
        "key": "LM/21"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria dei Sistemi Edilizi",
        "parent": "INGEGNERIA",
        "value": "LM/24_Ingegneria dei Sistemi Edilizi",
        "key": "LM/24"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Chimica",
        "parent": "INGEGNERIA",
        "value": "LM/22_Ingegneria Chimica",
        "key": "LM/22"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria delle Telecomunicazioni",
        "parent": "INGEGNERIA",
        "value": "LM/27_Ingegneria delle Telecomunicazioni",
        "key": "LM/27"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Civile e Ambientale",
        "parent": "INGEGNERIA",
        "value": "L/07_Ingegneria Civile e Ambientale",
        "key": "L/07"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Modellistica Matematico-Fisica per l'Ingegneria",
        "parent": "INGEGNERIA",
        "value": "LM/44_Modellistica Matematico-Fisica per l'Ingegneria",
        "key": "LM/44"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Disegno Industriale",
        "parent": "INGEGNERIA",
        "value": "L/04_Disegno Industriale",
        "key": "L/04"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Elettrica",
        "parent": "INGEGNERIA",
        "value": "LM/28_Ingegneria Elettrica",
        "key": "LM/28"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria dell’Informazione",
        "parent": "INGEGNERIA",
        "value": "L/08_Ingegneria dell’Informazione",
        "key": "L/08"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Elettronica",
        "parent": "INGEGNERIA",
        "value": "LM/29_Ingegneria Elettronica",
        "key": "LM/29"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Energetica e Nucleare",
        "parent": "INGEGNERIA",
        "value": "LM/30_Ingegneria Energetica e Nucleare",
        "key": "LM/30"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Gestionale",
        "parent": "INGEGNERIA",
        "value": "LM/31_Ingegneria Gestionale",
        "key": "LM/31"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Industriale",
        "parent": "INGEGNERIA",
        "value": "L/09_Ingegneria Industriale",
        "key": "L/09"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria per l'Ambiente e il Territorio",
        "parent": "INGEGNERIA",
        "value": "LM/35_Ingegneria per l'Ambiente e il Territorio",
        "key": "LM/35"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Ingegneria Navale",
        "parent": "INGEGNERIA",
        "value": "LM/34_Ingegneria Navale",
        "key": "LM/34"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Pianificazione Territoriale, Urbanistica, Paesaggistica e Ambientale",
        "parent": "INGEGNERIA",
        "value": "L/21_Scienze della Pianificazione Territoriale, Urbanistica, Paesaggistica e Ambientale",
        "key": "L/21"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie della Navigazione (80M)",
        "parent": "INGEGNERIA",
        "value": "LM/72_Scienze e Tecnologie della Navigazione (80M)",
        "key": "LM/72"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/55_Ingegneria Meccanica",
        "key": "D/55"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/56_Ingegneria Meccanica",
        "key": "D/56"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/57_Ingegneria Meccanica",
        "key": "D/57"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/58_Ingegneria Meccanica",
        "key": "D/58"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/59_Ingegneria Meccanica",
        "key": "D/59"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/60_Ingegneria Meccanica",
        "key": "D/60"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/61_Ingegneria Meccanica",
        "key": "D/61"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/62_Ingegneria Meccanica",
        "key": "D/62"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Elettrica",
        "parent": "INGEGNERIA",
        "value": "D/63_Ingegneria Elettrica",
        "key": "D/63"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Elettronica",
        "parent": "INGEGNERIA",
        "value": "D/64_Ingegneria Elettronica",
        "key": "D/64"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Energetica e Nucleare",
        "parent": "INGEGNERIA",
        "value": "D/65_Ingegneria Energetica e Nucleare",
        "key": "D/65"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Gestionale",
        "parent": "INGEGNERIA",
        "value": "D/66_Ingegneria Gestionale",
        "key": "D/66"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Informatica",
        "parent": "INGEGNERIA",
        "value": "D/67_Ingegneria Informatica",
        "key": "D/67"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/77_Ingegneria Meccanica",
        "key": "D/77"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/78_Ingegneria Meccanica",
        "key": "D/78"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/79_Ingegneria Meccanica",
        "key": "D/79"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Civile e Ambientale",
        "parent": "INGEGNERIA",
        "value": "D/82_Ingegneria Civile e Ambientale",
        "key": "D/82"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Civile e Ambientale",
        "parent": "INGEGNERIA",
        "value": "D/83_Ingegneria Civile e Ambientale",
        "key": "D/83"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Aerospaziale e Astronautica",
        "parent": "INGEGNERIA",
        "value": "D/84_Ingegneria Aerospaziale e Astronautica",
        "key": "D/84"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Aerospaziale e Astronautica",
        "parent": "INGEGNERIA",
        "value": "D/85_Ingegneria Aerospaziale e Astronautica",
        "key": "D/85"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/86_Ingegneria Meccanica",
        "key": "D/86"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Civile e Ambientale",
        "parent": "INGEGNERIA",
        "value": "D/97_Ingegneria Civile e Ambientale",
        "key": "D/97"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Elettrica",
        "parent": "INGEGNERIA",
        "value": "D/98_Ingegneria Elettrica",
        "key": "D/98"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Civile e Ambientale",
        "parent": "INGEGNERIA",
        "value": "D/101_Ingegneria Civile e Ambientale",
        "key": "D/101"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/102_Ingegneria Meccanica",
        "key": "D/102"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Ingegneria Meccanica",
        "parent": "INGEGNERIA",
        "value": "D/104_Ingegneria Meccanica",
        "key": "D/104"
      }
    ]
  },
  {
    "checked": false,
    "label": "DISCIPLINE UMANISTICHE",
    "value": "01_DISCIPLINE UMANISTICHE",
    "key": "01",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Archeologia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/02_Archeologia",
        "key": "LM/02"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Beni Culturali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/01_Beni Culturali",
        "key": "L/01"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Design",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/12_Design",
        "key": "LM/12"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Filologia, Letterature e Storia dell'Antichità",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/15_Filologia, Letterature e Storia dell'Antichità",
        "key": "LM/15"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Antropologia Culturale ed Etnologia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/01_Antropologia Culturale ed Etnologia",
        "key": "LM/01"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Storia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/42_Storia",
        "key": "L/42"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Psicologia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/51_Psicologia",
        "key": "LM/51"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Conservazione e Restauro dei Beni Culturali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/11_Conservazione e Restauro dei Beni Culturali",
        "key": "LM/11"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Archivistica e Biblioteconomia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/05_Archivistica e Biblioteconomia",
        "key": "LM/05"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze per la Cooperazione allo Sviluppo",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/81_Scienze per la Cooperazione allo Sviluppo",
        "key": "LM/81"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Storia dell'Arte",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/89_Storia dell'Arte",
        "key": "LM/89"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/03_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "L/03"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dello Spettacolo e Produzione Multimediale",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/65_Scienze dello Spettacolo e Produzione Multimediale",
        "key": "LM/65"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Tecnologie per la Conservazione e il Restauro dei Beni Culturali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/43_Tecnologie per la Conservazione e il Restauro dei Beni Culturali",
        "key": "L/43"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Filosofiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/78_Scienze Filosofiche",
        "key": "LM/78"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Studi Europei",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/90_Studi Europei",
        "key": "LM/90"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Lingue e Culture Moderne",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/11_Lingue e Culture Moderne",
        "key": "L/11"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Filologia Moderna",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/14_Filologia Moderna",
        "key": "LM/14"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Lettere",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/10_Lettere",
        "key": "L/10"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Cognitive",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/55_Scienze Cognitive",
        "key": "LM/55"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Informazione e Sistemi Editoriali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/19_Informazione e Sistemi Editoriali",
        "key": "LM/19"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Comunicazione",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/20_Scienze della Comunicazione",
        "key": "L/20"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dei Servizi Giuridici",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/14_Scienze dei Servizi Giuridici",
        "key": "L/14"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Teorie della Comunicazione",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/92_Teorie della Comunicazione",
        "key": "LM/92"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Mediazione Linguistica",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/12_Mediazione Linguistica",
        "key": "L/12"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Lingue Moderne per la Comunicazione e la Cooperazione Internazionale",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/38_Lingue Moderne per la Comunicazione e la Cooperazione Internazionale",
        "key": "LM/38"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell’Amministrazione e dell’Organizzazione",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/16_Scienze dell’Amministrazione e dell’Organizzazione",
        "key": "L/16"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell'Educazione degli Adulti e della Formazione Continua",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/57_Scienze dell'Educazione degli Adulti e della Formazione Continua",
        "key": "LM/57"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Pedagogiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/85_Scienze Pedagogiche",
        "key": "LM/85"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Programmazione e Gestione dei Servizi Educativi",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/50_Programmazione e Gestione dei Servizi Educativi",
        "key": "LM/50"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Sociali per la Cooperazione, lo Sviluppo e la Pace",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/37_Scienze Sociali per la Cooperazione, lo Sviluppo e la Pace",
        "key": "L/37"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Progettazione e Gestione dei Sistemi Turistici",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/49_Progettazione e Gestione dei Sistemi Turistici",
        "key": "LM/49"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Religioni",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/64_Scienze delle Religioni",
        "key": "LM/64"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Linguistica",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/39_Linguistica",
        "key": "LM/39"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Metodologie Informatiche per le Discipline Umanistiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/43_Metodologie Informatiche per le Discipline Umanistiche",
        "key": "LM/43"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell’Educazione e della Formazione",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/19_Scienze dell’Educazione e della Formazione",
        "key": "L/19"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Relazioni Internazionali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/52_Relazioni Internazionali",
        "key": "LM/52"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Giurisprudenza",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LMG/01_Giurisprudenza",
        "key": "LMG/01"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Musicologia e Beni Culturali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/45_Musicologia e Beni Culturali",
        "key": "LM/45"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecniche Psicologiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/24_Scienze e Tecniche Psicologiche",
        "key": "L/24"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/05_Filosofia",
        "key": "L/05"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Servizio Sociale e Politiche Sociali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/87_Servizio Sociale e Politiche Sociali",
        "key": "LM/87"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Teorie e Metodologie dell'E-Learning e della Media Education",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/93_Teorie e Metodologie dell'E-Learning e della Media Education",
        "key": "LM/93"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Difesa e della Sicurezza",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/DS_Scienze della Difesa e della Sicurezza",
        "key": "LM/DS"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Lingue e Letterature Moderne Europee e Americane",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/37_Lingue e Letterature Moderne Europee e Americane",
        "key": "LM/37"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze del Turismo",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/15_Scienze del Turismo",
        "key": "L/15"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Geografiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/80_Scienze Geografiche",
        "key": "LM/80"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Traduzione Specialistica e Interpretariato",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/94_Traduzione Specialistica e Interpretariato",
        "key": "LM/94"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Lingue e Letterature dell'Africa e dell'Asia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/36_Lingue e Letterature dell'Africa e dell'Asia",
        "key": "LM/36"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Organizzazione e Gestione dei Servizi per lo Sport e le Attività Motorie",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/47_Organizzazione e Gestione dei Servizi per lo Sport e le Attività Motorie",
        "key": "LM/47"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Geografia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "L/06_Geografia",
        "key": "L/06"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Storiche",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "LM/84_Scienze Storiche",
        "key": "LM/84"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filologia, Letterature e Storia dell'Antichità",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/01_Filologia, Letterature e Storia dell'Antichità",
        "key": "D/01"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Lingue e Culture Moderne",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/03_Lingue e Culture Moderne",
        "key": "D/03"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/04_Filosofia",
        "key": "D/04"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/05_Filosofia",
        "key": "D/05"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/06_Filosofia",
        "key": "D/06"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/07_Filosofia",
        "key": "D/07"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/08_Filosofia",
        "key": "D/08"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/09_Filosofia",
        "key": "D/09"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Lingue e Culture Moderne",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/10_Lingue e Culture Moderne",
        "key": "D/10"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/11_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/11"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/12_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/12"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/13_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/13"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/14_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/14"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/15_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/15"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/16_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/16"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/17_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/17"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/18_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/18"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/19_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/19"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/20_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/20"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/21_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/21"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/22_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/22"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/23_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/23"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/24_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/24"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/25_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/25"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/26_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/26"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/27_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/27"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/28_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/28"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/44_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/44"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/45_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/45"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/46_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/46"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/88_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/88"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/89_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/89"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Filosofia",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/90_Filosofia",
        "key": "D/90"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Musicologia e Beni Culturali",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/105_Musicologia e Beni Culturali",
        "key": "D/105"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent": "DISCIPLINE UMANISTICHE",
        "value": "D/106_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "key": "D/106"
      }
    ]
  },
  {
    "checked": false,
    "label": "DISCIPLINE ECONOMICO FINANZIARIE",
    "value": "02_DISCIPLINE ECONOMICO FINANZIARIE",
    "key": "02",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Economico-Aziendali",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/77_Scienze Economico-Aziendali",
        "key": "LM/77"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "L/18_Scienze dell’Economia e della Gestione Aziendale",
        "key": "L/18"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Pubbliche Amministrazioni",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/63_Scienze delle Pubbliche Amministrazioni",
        "key": "LM/63"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell'Economia",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/56_Scienze dell'Economia",
        "key": "LM/56"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Statistica",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "L/41_Statistica",
        "key": "L/41"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Finanza",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/16_Finanza",
        "key": "LM/16"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Statistiche",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/82_Scienze Statistiche",
        "key": "LM/82"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Economiche per l'Ambiente e la Cultura",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/76_Scienze Economiche per l'Ambiente e la Cultura",
        "key": "LM/76"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Economiche",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "L/33_Scienze Economiche",
        "key": "L/33"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Comunicazione Pubblica, d'Impresa e Pubblicità",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/59_Scienze della Comunicazione Pubblica, d'Impresa e Pubblicità",
        "key": "LM/59"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Statistiche Attuariali e Finanziarie",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "LM/83_Scienze Statistiche Attuariali e Finanziarie",
        "key": "LM/83"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/47_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/47"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/48_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/48"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/49_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/49"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/50_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/50"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/51_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/51"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/52_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/52"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/53_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/53"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/54_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/54"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/91_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/91"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/92_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/92"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/93_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/93"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/94_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/94"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent": "DISCIPLINE ECONOMICO FINANZIARIE",
        "value": "D/95_Scienze dell’Economia e della Gestione Aziendale",
        "key": "D/95"
      }
    ]
  },
  {
    "checked": false,
    "label": "DISCIPLINE SCIENTIFICHE",
    "value": "03_DISCIPLINE SCIENTIFICHE",
    "key": "03",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Zootecniche e Tecnologie delle Produzioni Animali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/38_Scienze Zootecniche e Tecnologie delle Produzioni Animali",
        "key": "L/38"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/54_Scienze Chimiche",
        "key": "LM/54"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Fisica",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/17_Fisica",
        "key": "LM/17"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie della Chimica Industriale",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/71_Scienze e Tecnologie della Chimica Industriale",
        "key": "LM/71"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Agrarie e Forestali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/25_Scienze e Tecnologie Agrarie e Forestali",
        "key": "L/25"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Agrarie",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/69_Scienze e Tecnologie Agrarie",
        "key": "LM/69"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Biotecnologie Agrarie",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/07_Biotecnologie Agrarie",
        "key": "LM/07"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Nutrizione Umana",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/61_Scienze della Nutrizione Umana",
        "key": "LM/61"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie per l’Ambiente e la Natura",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/32_Scienze e Tecnologie per l’Ambiente e la Natura",
        "key": "L/32"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie per l'Ambiente e il Territorio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/75_Scienze e Tecnologie per l'Ambiente e il Territorio",
        "key": "LM/75"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Geofisiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/79_Scienze Geofisiche",
        "key": "LM/79"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/31_Scienze e Tecnologie Informatiche",
        "key": "L/31"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Matematica",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/40_Matematica",
        "key": "LM/40"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/03_Architettura del Paesaggio",
        "key": "LM/03"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell’Architettura",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/17_Scienze dell’Architettura",
        "key": "L/17"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Informatica",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/18_Informatica",
        "key": "LM/18"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Fisiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/30_Scienze e Tecnologie Fisiche",
        "key": "L/30"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze dell'Universo",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/58_Scienze dell'Universo",
        "key": "LM/58"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecniche delle Attività Motorie Preventive e Adattate",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/67_Scienze e Tecniche delle Attività Motorie Preventive e Adattate",
        "key": "LM/67"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Biotecnologie Mediche, Veterinarie e Farmaceutiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/09_Biotecnologie Mediche, Veterinarie e Farmaceutiche",
        "key": "LM/09"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Biologia",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/06_Biologia",
        "key": "LM/06"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Natura",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/60_Scienze della Natura",
        "key": "LM/60"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Biotecnologie",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/02_Biotecnologie",
        "key": "L/02"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Biotecnologie Industriali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/08_Biotecnologie Industriali",
        "key": "LM/08"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Biologiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/13_Scienze Biologiche",
        "key": "L/13"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/27_Scienze e Tecnologie Chimiche",
        "key": "L/27"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Farmacia e Farmacia Industriale",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/13_Farmacia e Farmacia Industriale",
        "key": "LM/13"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie della Navigazione",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/28_Scienze e Tecnologie della Navigazione",
        "key": "L/28"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Forestali ed Ambientali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/73_Scienze e Tecnologie Forestali ed Ambientali",
        "key": "LM/73"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Farmaceutiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/29_Scienze e Tecnologie Farmaceutiche",
        "key": "L/29"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/70_Scienze e Tecnologie Alimentari",
        "key": "LM/70"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Agro-Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/26_Scienze e Tecnologie Agro-Alimentari",
        "key": "L/26"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Geologiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/34_Scienze Geologiche",
        "key": "L/34"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Zootecniche e Tecnologie Animali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/86_Scienze Zootecniche e Tecnologie Animali",
        "key": "LM/86"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecnologie Geologiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/74_Scienze e Tecnologie Geologiche",
        "key": "LM/74"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienza e Ingegneria dei Materiali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/53_Scienza e Ingegneria dei Materiali",
        "key": "LM/53"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Matematiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/35_Scienze Matematiche",
        "key": "L/35"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze e Tecniche dello Sport",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/68_Scienze e Tecniche dello Sport",
        "key": "LM/68"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Attività Motorie e Sportive",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "L/22_Scienze delle Attività Motorie e Sportive",
        "key": "L/22"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Sicurezza Informatica",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "LM/66_Sicurezza Informatica",
        "key": "LM/66"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Fisica",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/02_Fisica",
        "key": "D/02"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/29_Architettura del Paesaggio",
        "key": "D/29"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/30_Architettura del Paesaggio",
        "key": "D/30"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/31_Architettura del Paesaggio",
        "key": "D/31"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/32_Architettura del Paesaggio",
        "key": "D/32"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/33_Architettura del Paesaggio",
        "key": "D/33"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/34_Architettura del Paesaggio",
        "key": "D/34"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/35_Architettura del Paesaggio",
        "key": "D/35"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/36_Architettura del Paesaggio",
        "key": "D/36"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/37_Architettura del Paesaggio",
        "key": "D/37"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/38_Architettura del Paesaggio",
        "key": "D/38"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/39_Architettura del Paesaggio",
        "key": "D/39"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/40_Architettura del Paesaggio",
        "key": "D/40"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/41_Architettura del Paesaggio",
        "key": "D/41"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/42_Architettura del Paesaggio",
        "key": "D/42"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/43_Architettura del Paesaggio",
        "key": "D/43"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/68_Scienze e Tecnologie Informatiche",
        "key": "D/68"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/69_Scienze e Tecnologie Informatiche",
        "key": "D/69"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/70_Scienze e Tecnologie Informatiche",
        "key": "D/70"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/71_Scienze e Tecnologie Chimiche",
        "key": "D/71"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/72_Scienze e Tecnologie Chimiche",
        "key": "D/72"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/73_Scienze e Tecnologie Chimiche",
        "key": "D/73"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/74_Scienze e Tecnologie Chimiche",
        "key": "D/74"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/75_Scienze e Tecnologie Chimiche",
        "key": "D/75"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/76_Scienze e Tecnologie Chimiche",
        "key": "D/76"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agro-Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/80_Scienze e Tecnologie Agro-Alimentari",
        "key": "D/80"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agro-Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/81_Scienze e Tecnologie Agro-Alimentari",
        "key": "D/81"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Architettura del Paesaggio",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/87_Architettura del Paesaggio",
        "key": "D/87"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Chimiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/96_Scienze e Tecnologie Chimiche",
        "key": "D/96"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/99_Scienze e Tecnologie Informatiche",
        "key": "D/99"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Informatiche",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/100_Scienze e Tecnologie Informatiche",
        "key": "D/100"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agro-Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/103_Scienze e Tecnologie Agro-Alimentari",
        "key": "D/103"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze delle Attività Motorie e Sportive",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/107_Scienze delle Attività Motorie e Sportive",
        "key": "D/107"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agro-Alimentari",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/111_Scienze e Tecnologie Agro-Alimentari",
        "key": "D/111"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agrarie e Forestali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/112_Scienze e Tecnologie Agrarie e Forestali",
        "key": "D/112"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze e Tecnologie Agrarie e Forestali",
        "parent": "DISCIPLINE SCIENTIFICHE",
        "value": "D/113_Scienze e Tecnologie Agrarie e Forestali",
        "key": "D/113"
      }
    ]
  },
  {
    "checked": false,
    "label": "DISCIPLINE SOCIALI E POLITICHE",
    "value": "04_DISCIPLINE SOCIALI E POLITICHE",
    "key": "04",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Sociologia e Ricerca Sociale",
        "parent": "DISCIPLINE SOCIALI E POLITICHE",
        "value": "LM/88_Sociologia e Ricerca Sociale",
        "key": "LM/88"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Sociologia",
        "parent": "DISCIPLINE SOCIALI E POLITICHE",
        "value": "L/40_Sociologia",
        "key": "L/40"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Politiche e delle Relazioni Internazionali",
        "parent": "DISCIPLINE SOCIALI E POLITICHE",
        "value": "L/36_Scienze Politiche e delle Relazioni Internazionali",
        "key": "L/36"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze della Politica",
        "parent": "DISCIPLINE SOCIALI E POLITICHE",
        "value": "LM/62_Scienze della Politica",
        "key": "LM/62"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Servizio Sociale",
        "parent": "DISCIPLINE SOCIALI E POLITICHE",
        "value": "L/39_Servizio Sociale",
        "key": "L/39"
      }
    ]
  },
  {
    "checked": false,
    "label": "DISCIPLINE SANITARIE",
    "value": "05_DISCIPLINE SANITARIE",
    "key": "05",
    "children": [
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Professioni Sanitarie della Prevenzione",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/SNT04_Scienze delle Professioni Sanitarie della Prevenzione",
        "key": "LM/SNT04"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Professioni Sanitarie Tecniche",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/SNT03_Scienze delle Professioni Sanitarie Tecniche",
        "key": "LM/SNT03"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Odontoiatria e Protesi Dentaria",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/46_Odontoiatria e Protesi Dentaria",
        "key": "LM/46"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze delle Professioni Sanitarie della Riabilitazione",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/SNT02_Scienze delle Professioni Sanitarie della Riabilitazione",
        "key": "LM/SNT02"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Scienze Infermieristiche e Ostetriche",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/SNT01_Scienze Infermieristiche e Ostetriche",
        "key": "LM/SNT01"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Medicina e Chirurgia",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/41_Medicina e Chirurgia",
        "key": "LM/41"
      },
      {
        "checked": false,
        "eqf": [
          5,
          6,
          7,
          8
        ],
        "label": "Medicina Veterinaria",
        "parent": "DISCIPLINE SANITARIE",
        "value": "LM/42_Medicina Veterinaria",
        "key": "LM/42"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze delle Professioni Sanitarie della Riabilitazione",
        "parent": "DISCIPLINE SANITARIE",
        "value": "D/108_Scienze delle Professioni Sanitarie della Riabilitazione",
        "key": "D/108"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze delle Professioni Sanitarie della Riabilitazione",
        "parent": "DISCIPLINE SANITARIE",
        "value": "D/109_Scienze delle Professioni Sanitarie della Riabilitazione",
        "key": "D/109"
      },
      {
        "checked": false,
        "eqf": [
          4
        ],
        "label": "Scienze delle Professioni Sanitarie della Riabilitazione",
        "parent": "DISCIPLINE SANITARIE",
        "value": "D/110_Scienze delle Professioni Sanitarie della Riabilitazione",
        "key": "D/110"
      }
    ]
  }
]
export default TitleDataTree