import React, { useState, useEffect } from 'react'
import useAuth from 'app/hooks/useAuth'
import history from 'history.js'
import validator from "validator";

import { TextField } from '@material-ui/core';


function PasswordReset() {
  const { forgotPassword } = useAuth()
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setSubmit] = useState(false)
  const [userInfo, setUserInfo] = useState({
    email: ''
  })

  const {
    username,
    firstName,
    creditCard,
    mobile,
    password,
    confirmPassword,
    gender,
    date,
    email,
  } = userInfo

  const handleChange = (event) => {
    event.persist()
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async () => {
    const res = await forgotPassword(email);
    if (res) history.push('/password-done')
    else setFormErrors({ ...formErrors, email: "La mail inserita non è valida" })
  }

  const validate = () => {
    const errors = {}
    if (!email) errors.email = "Questo campo non può essere nullo"
    else if (!validator.isEmail(email)) errors.email = "Inserire una email in formato valido"
    return errors;
  }

  useEffect(() => {
    if (isSubmit) {
      setFormErrors(validate())
      if (Object.keys(validate()).length === 0)
        handleSubmit()
      setSubmit(false)
    }
  }, [isSubmit, userInfo])

  const goback = () => {
    try {
      history.push('/login')
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <>
      <div className='overlay'></div>
      <main className='main-container-login'>
        <div className="login-container password-reset">
          <button onClick={goback} className='close-btn mr-auto'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="19.9" cy="19.9" r="19.9" fill="none"/>
                <path d="M25 10L15 20.1923L25 30.3846" stroke="#5058ed" strokeWidth="4"/>
            </svg>          
          </button>
          <h1>Recupero Password</h1>
          <p>Ti invieremo per mail un link con il quale potrai accedere</p>
          <div>
            <div>
              <p className='input-error'>{formErrors.email}</p>
              <TextField
                label="La tua email" 
                variant="filled"
                name='email'
                fullWidth
                required
                color="primary"
                onChange={handleChange}
              />
            </div>

            <button
              onClick={(e) => {
                e.preventDefault()
                setSubmit(true)
              }}
              className='primary-btn btn big-border-radius w-100'
            >
              Invia email
            </button>
          </div>
        </div>
      </main>
    </>

  )
}

export default PasswordReset