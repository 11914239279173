import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import ApiService from 'app/ApiService'
import AlertDialog from './AlertDialog/AlertDialog'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Autocomplete from '../../Autocomplete/AsyncAutocomplete'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from 'app/components/Icons/EditIcon'
import EditFieldDialog from './FooterProfile/EditField'

function HardSkills({ hslist, candidateguid, getHsList, loading }) {
    // per dialog
    const [open, setOpen] = useState(false)
    // POPOVER
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClosePopover = () => {
        setAnchorEl(null)
    }
    const openPopover = Boolean(anchorEl)
    const skillsPopover = open ? 'simple-popover' : undefined
    const [dialogText, setDialogText] = useState('Salva')
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    // const [appState, setAppState] = useState(false);
    const [hsList, setHsList] = useState([])
    const [hsMappedList, setHsMappedList] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showUnsavedDialog, setShowUnsavedDialog] = useState(false)
    const [backupHsMappedList, setBackupHsMappedList] = useState([])
    const descriptionElementRef = useRef(null)

    const initUserInfo = {
        candidate_guid: candidateguid || '',
        skill_name: '',
        isced: '',
        score: 5,
        created: '',
        lastUpdate: '',
        id: null,
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)

    const { candidate_guid, skill_name, isced, score, created, lastUpdate } =
        userInfo

    const handleClickOpen = (scrolType) => () => {
        setDialogText('Salva')
        setOpen(true)
        setBackupHsMappedList([...hsMappedList])
        setUserInfo({
            ...userInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = () => {
        if (unsavedChanges) {
            setShowUnsavedDialog(true)
        } else {
            closeDialog()
        }
    }
    const closeDialog = () => {
        setOpen(false)
        setUserInfo(initUserInfo)
        setFormErrors({})
        setUnsavedChanges(false)
    }

    const handleAddSkill = async () => {
        const updatedArray = hsList.map((skill) => {
            if (
                hsMappedList.some(
                    (otherSkill) => skill.skill_name === otherSkill
                )
            ) {
                return {
                    id: skill.id,
                    candidate_guid: candidate_guid,
                    score: skill.score,
                    checked: true,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            } else {
                return {
                    id: skill.id,
                    candidate_guid: candidate_guid,
                    score: skill.score,
                    checked: false,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            }
        })

        hsMappedList.forEach((skillName) => {
            if (!hsList.some((skill) => skill.skill_name === skillName)) {
                updatedArray.push({
                    candidate_guid: candidateguid,
                    skill_name: skillName,
                    score: 5,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        const res = await ApiService.create_multiplecandhs(updatedArray)
        if (res.ok) {
            await getHsList()
        }
        closeDialog()
    }

    const handleDelete = (id) => async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candhs(id)
        if (res.ok) getHsList()
    }

    const handleSubmit = async () => {
        const errors = validate()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            await handleAddSkill()
            closeDialog()
        }
    }

    const onSelect = (value) => {
        if (!value || value === '') return

        setUnsavedChanges(true)

        setHsMappedList((prevList) => {
            const isAlreadySelected = prevList.includes(value)

            if (isAlreadySelected) {
                return prevList.filter((skill) => skill !== value)
            } else {
                if (prevList.length >= 12) {
                    setFormErrors({
                        ...formErrors,
                        skill_name:
                            'Puoi aggiungere massimo 12 competenze, rimuovi quelle che non sono realmente importanti per te',
                    })
                    return prevList
                } else return [...prevList, value]
            }
        })
    }

    const validate = () => {
        const errors = {}
        return errors
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (isSubmit) {
            handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit])

    useEffect(() => {
        setHsList(hslist)
        setHsMappedList(hslist.map((x) => x.skill_name))
    }, [hslist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    const handleDialogConfirm = (confirm) => {
        if (confirm) {
            setHsMappedList(backupHsMappedList)
            closeDialog()
        }
        setShowUnsavedDialog(false)
    }
    return (
        <>
            <div className="skills-container">
                <div className="section-title">
                    <div className="d-flex popoverSkills">
                        <h2>Quali sono le tue competenze tecniche?</h2>
                        <p>Scegli quelle che ti appartengono.</p>
                        <div className="popover">
                            <Popover
                                id={skillsPopover}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <p>Competenze tecniche</p>
                                <p>
                                    Abilità connesse al tuo lavoro, quello che
                                    sai fare come utilizzo del computer, prepare
                                    la pizza, guidare il muletto, vendita in
                                    negozio etc. Consigliamo di aggiungere
                                    almeno 5 competenze
                                </p>
                            </Popover>
                        </div>
                    </div>

                    <div className="d-flex align-center">
                        <button
                            className="icon-btn"
                            hidden={open}
                            onClick={handleClickOpen('paper')}
                        >
                            <EditIcon />
                        </button>
                    </div>
                </div>

                {!loading && hsList.length <= 0 && !open && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Inserisci le tue competenze tecniche per
                                    avere più successo
                                </p>
                                <button
                                    onClick={handleClickOpen('paper')}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3 className="m0">
                                Aggiungi una o più competenze tecniche
                            </h3>

                            <button
                                onClick={handleClose}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="skill-container tags-container smallwidth">
                            {loading ? (
                                <div className="new-box">
                                    <div className="d-flex align-center tags">
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                hsMappedList.map((skill, index) => (
                                    <div key={index} className="new-box">
                                        <div className="d-flex align-center tags TagWhite">
                                            <p> {skill} </p>
                                            <button
                                                className="icon-btn"
                                                onClick={() => onSelect(skill)}
                                            >
                                                <CloseIcon />
                                            </button>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end">
                            <div>
                                <Autocomplete
                                    parentValue={skill_name}
                                    onChange={onSelect}
                                    label={'Cerca una parola chiave'}
                                    apiRequest={ApiService.get_hslist}
                                    filterOptions={hsMappedList}
                                    clearOnSelect={true}
                                />
                                <p className="input-error">
                                    {formErrors.skill_name}
                                </p>
                            </div>
                        </div>
                        <Button
                            id="wideSavebtn"
                            onClick={(e) => {
                                e.preventDefault()
                                setSubmit(true)
                            }}
                            color="primary"
                            className="btn-like-mui"
                        >
                            {dialogText}
                        </Button>
                    </div>
                )}
                {!open && (
                    <div className="skill-container tags-container">
                        {loading ? (
                            <div className="new-box">
                                <div className="d-flex align-center tags ">
                                    <div className="d-flex align-center tags ">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            hsList.map((skill, index) => (
                                <div key={index} className="new-box">
                                    <div className="d-flex align-center tags">
                                        <p> {skill.skill_name} </p>
                                        <button
                                            className="icon-btn"
                                            onClick={handleDelete(skill.id)}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            {showUnsavedDialog && (
                <EditFieldDialog
                    open={showUnsavedDialog}
                    onClose={handleDialogConfirm}
                />
            )}
        </>
    )
}

export default HardSkills
