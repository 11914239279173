import React, { useEffect, useState } from 'react'
import { Redirect, useLocation, Route } from 'react-router-dom'
import useJWTAuth from 'app/hooks/useJWTAuth'
import OnboardingLayout from 'app/Layouts/OnboardingLayout/OnboardingLayout'
import useAuth from 'app/hooks/useAuth'
import { authRoles } from './authRoles'
import LoadingAnimation from 'app/components/Animation/LoadingAnimation'

const OnboardingGuard = ({ component: Component, ...rest }) => {
    const { isJWTAuthenticated } = useJWTAuth()

    const {
        isAuthenticated,
        isInitialised,
        role,
        // user
    } = useAuth()

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()

    // const { routes } = useContext(AppContext);
    // const isUserRoleAuthenticated = getUserRoleAuthStatus(pathname, role, routes);
    // let authenticated = isAuthenticated && isUserRoleAuthenticated;

    // useEffect(() => {
    //     console.log(isAuthenticated);
    // }, [isAuthenticated])

    // console.log(role)
    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isInitialised) {
                    return (
                        //   <div className="loading-component">
                        //     <p>Loading...</p>
                        //   </div>

                        <div className="app-swipe d-flex flex-column justify-center align-center">
                            <div className="d-flex flex-column justify-center align-center">
                                <LoadingAnimation />
                            </div>
                        </div>
                    )
                }

                if (
                    isAuthenticated &&
                    isJWTAuthenticated &&
                    (role === '' ||
                        role === undefined ||
                        authRoles.admin.includes(role))
                    // change admin or canditate or recruiter
                )
                    return (
                        <OnboardingLayout>
                            <Component {...props} />
                        </OnboardingLayout>
                    )
                // else if (!isAuthenticated && isJWTAuthenticated) return (
                //     <Redirect
                //         to={{
                //             pathname: '/start',
                //             state: { redirectUrl: previouseRoute },
                //         }}
                //     />
                // )
                else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/jump',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                }
            }}
        />
    )
}

export default OnboardingGuard
