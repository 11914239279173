import React from 'react'

const Landscape = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100vh',
                textAlign: 'center',
            }}
        >
            <img
                src="/logo2.png"
                alt="Instruction Image"
                // style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
            />
            <p
                style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    margin: '20px 0 10px 0',
                }}
            >
                Ruota il dispositivo in verticale per un'esperienza migliore
            </p>
            <p style={{ fontSize: '16px', color: 'grey' }}>
                Divertiti a fare swipe tenendo il dispositivo in verticale.
            </p>
        </div>
    )
}

export default Landscape
