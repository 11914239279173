import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import React, { useEffect, useState } from 'react'
import Container from '../../TreeSelect/DropdownContainer'
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import TitleDataTree from '../../Tables/title_table_container'
import title_type from '../../Tables/title_type'
import title_type_onboarding from '../../Tables/title_type_onboarding'
import ApiService from 'app/ApiService'
import CloseIcon from '../../Icons/CloseIcon'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

const SectionTitles = ['È richiesto alcun titolo di studio?']

function OnboardingEducationRequired({
    offer,
    stepOnboarding,
    setStepOnboarding,
}) {
    const [stepOnboardingEducation, setStepOnboardingEducation] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [showMore, setShowMore] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [appState, setAppState] = useState(false)
    const initofferInfo = {
        offer_guid: '',
        eqf_label: '',
        eqf_value: 0,
        // title_name: [""],
        title_name: [],
        other_title: '',
        erasmus: 'false',
        publications: 'false',
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initofferInfo)
    const {
        offer_guid,
        eqf_label,
        eqf_value,
        title_name,
        other_title,
        erasmus,
        publications,
        created,
        lastUpdate,
    } = offerInfo

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_offerstitlebyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            if (body?.length > 0) {
                let titleInfo = body[0]
                setOfferInfo({
                    ...offerInfo,
                    id: titleInfo.id,
                    title_name: JSON.parse(titleInfo.title_name),
                    eqf_label: titleInfo.eqf_label,
                    eqf_value: titleInfo.eqf_value,
                    erasmus: titleInfo.erasmus,
                    // publications: titleInfo[7].toString(),
                    lastUpdate: new Date(),
                })

                if (
                    title_type_onboarding.showMore.filter(
                        (x) => x.label === titleInfo.eqf_label
                    ).length > 0
                )
                    setShowMore(true)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const onEqfSelect = (event) => {
        const val = title_type.find(
            (x) =>
                x.label.toLowerCase() ===
                event.target.value.trim().toLowerCase()
        )
        if (val.value > 3)
            setOfferInfo({
                ...offerInfo,
                eqf_label: val.label,
                eqf_value: val.value,
            })
        else
            setOfferInfo({
                ...offerInfo,
                eqf_label: val.label,
                eqf_value: val.value,
                other_title: null,
                title_name: [],
                parent_title: null,
            })
    }

    const onNodeSelect = (currentNode, selectedNodes) => {
        // console.log(currentNode)
        // console.log(selectedNodes)

        const alreadyExists = title_name?.includes(currentNode.key)

        if (!alreadyExists) {
            let updatedSelectedNodes = [currentNode.key]

            const parentNode = TitleDataTree.find(
                (node) => node.key === currentNode.key
            )

            if (parentNode) {
                // Remove child nodes from the title_name in offerInfo
                setOfferInfo((prevOfferInfo) => {
                    const updatedTitleName = prevOfferInfo.title_name.filter(
                        (node) =>
                            !parentNode.children ||
                            !parentNode.children.some(
                                (child) => child.key === node
                            )
                    )

                    return {
                        ...prevOfferInfo,
                        title_name: [
                            ...updatedTitleName,
                            ...updatedSelectedNodes,
                        ],
                    }
                })
            } else {
                setOfferInfo((prevOfferInfo) => ({
                    ...prevOfferInfo,
                    title_name: [
                        ...prevOfferInfo.title_name,
                        ...updatedSelectedNodes,
                    ],
                }))
            }
        }

        // console.log(offerInfo)
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    let getValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                return obj
            }
            if (obj.children) {
                let result = getValue(obj.children, val)
                if (result) {
                    return result
                }
            }
        }
        return undefined
    }

    // per titolo
    const handleDeleteTitle = (e, index) => {
        e.preventDefault()
        // console.log("target.", index)
        let arrayProvvisorio = [...title_name]
        arrayProvvisorio.splice(index, 1)
        setOfferInfo({ ...offerInfo, title_name: arrayProvvisorio })
        // console.log(userInfo)
    }

    const filterDataByEqfValue = () => {
        const filteredChildren = TitleDataTree.reduce((filtered, parent) => {
            const childrenWithEqf = parent.children.filter((child) =>
                child.eqf.includes(eqf_value)
            )
            if (childrenWithEqf.length > 0) {
                filtered.push({ ...parent, children: childrenWithEqf })
            }
            return filtered
        }, [])
        setFilteredData(filteredChildren) // Update state
    }

    const filteredChildren = TitleDataTree.reduce((filtered, parent) => {
        const childrenWithEqf = parent.children.filter((child) =>
            child.eqf.includes(eqf_value)
        )
        if (childrenWithEqf.length > 0) {
            filtered.push({ ...parent, children: childrenWithEqf })
        }
        return filtered
    }, [])

    async function handleNext() {
        if (offerInfo.eqf_value > 0) {
            const res = await ApiService.create_offertitle(offerInfo)
            if (!res.ok) return //error
        } else if (offerInfo?.id) {
            const res = await ApiService.delete_offertitle(offerInfo?.id)
            if (!res.ok) return //error
        }
        setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
    }

    const validate = () => {
        const errors = {}
        // if (!eqf_label) errors.eqf_label = "Questo campo non può essere nullo"
        // if (!(eqf_value <= 3 && eqf_value >= 0) && title_name?.length <= 0) errors.title_name = "Questo campo non può essere nullo"
        return errors
    }

    async function getData() {
        await getTitleList()
        setAppState(false)
    }

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            }
            setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        if (title_name?.length > 0)
            title_name.map((sec) => findValue(TitleDataTree, sec))
    }, [offerInfo])

    useEffect(() => {
        filterDataByEqfValue()
    }, [eqf_value])

    useEffect(() => {
        if (Object.keys(offer)?.length > 0) {
            setOfferInfo({
                ...offerInfo,
                offer_guid: offer?.offer_guid,
            })
            setAppState(true)
        }
    }, [offer])

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className="onboarding-grid">
                    <h2 style={{ margin: '0 auto' }}>{SectionTitles[0]}</h2>

                    {(() => {
                        switch (stepOnboardingEducation) {
                            case 0:
                                return (
                                    <>
                                        <p className="input-error text-center">
                                            {formErrors.eqf_label}
                                        </p>
                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                            style={{ width: '100%' }}
                                        >
                                            {/* <FormLabel component="legend">Istruzione</FormLabel> */}
                                            <RadioGroup
                                                aria-label="Istruzione"
                                                name="istruzione"
                                                value={eqf_label}
                                                onChange={onEqfSelect}
                                            >
                                                <div className="container-Study">
                                                    <div className="first-choice-container">
                                                        <FormControlLabel
                                                            key={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            value={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            control={<Radio />}
                                                            label={
                                                                title_type_onboarding
                                                                    .show[0]
                                                                    .label
                                                            }
                                                            className="large-button"
                                                        />
                                                    </div>
                                                    <div className="educationOtheChoice">
                                                        <div className="scuole-and-triennale-container">
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[1]
                                                                        .label
                                                                }
                                                                className="scuole-medie-button"
                                                            />
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[2]
                                                                        .label
                                                                }
                                                                className="laurea-triennale-button"
                                                            />
                                                        </div>

                                                        <div className="other-choices-container">
                                                            <FormControlLabel
                                                                key={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                value={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    title_type_onboarding
                                                                        .show[3]
                                                                        .label
                                                                }
                                                                className="custom-radio-label"
                                                            />
                                                            <div>
                                                                <button
                                                                    onClick={() => {
                                                                        setShowMore(
                                                                            !showMore
                                                                        )
                                                                    }}
                                                                    className={
                                                                        showMore
                                                                            ? 'underline-text'
                                                                            : 'underline-text'
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        border: 'none',
                                                                        boxShadow:
                                                                            'none',
                                                                        outline:
                                                                            'none',
                                                                        color: '#4b4c55',
                                                                        fontweight:
                                                                            '600',
                                                                        gap: '0',
                                                                    }}
                                                                >
                                                                    Altro{' '}
                                                                    {showMore ? (
                                                                        <ArrowDropUpIcon />
                                                                    ) : (
                                                                        <ArrowDropDownIcon />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <button onClick={() => { setShowMore(!showMore) }}>Altro  {showMore ? "\u2BC5" : "\u2BC6"}  </button> */}
                                                <div className="show-more-grid">
                                                    {showMore &&
                                                        title_type_onboarding.showMore.map(
                                                            (title) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={
                                                                            title.label
                                                                        }
                                                                        value={
                                                                            title.label
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            title.label
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                </div>
                                            </RadioGroup>
                                        </FormControl>

                                        <div className="input-container">
                                            {!(
                                                eqf_value <= 3 && eqf_value >= 0
                                            ) && (
                                                <div>
                                                    <h2>
                                                        {
                                                            'È richiesto un titolo in particolare?'
                                                        }
                                                    </h2>
                                                    {title_name?.length > 0 && (
                                                        <div className="tags-container">
                                                            {title_name.map(
                                                                (
                                                                    titleSelected,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className="d-flex align-center tags new-box"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                getValue(
                                                                                    TitleDataTree,
                                                                                    titleSelected
                                                                                )
                                                                                    ?.label
                                                                            }
                                                                        </p>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                handleDeleteTitle(
                                                                                    e,
                                                                                    index
                                                                                )
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    <Container
                                                        data={filteredChildren}
                                                        onChange={onNodeSelect}
                                                        value={title_name}
                                                        className="mdl-demo hide"
                                                        mode="multiSelect"
                                                        texts={{
                                                            placeholder:
                                                                'Nome titolo',
                                                            noMatches:
                                                                'Nessun risultato',
                                                        }}
                                                    />
                                                    <p className="input-error">
                                                        {formErrors.title_name}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )
                        }
                    })()}
                </div>

                <OnboardingButton
                    stepOnboarding={stepOnboarding}
                    setStepOnboarding={setStepOnboarding}
                    setSubmit={setSubmit}
                    submit={isSubmit}
                />
            </form>
        </>
    )
}

export default OnboardingEducationRequired
