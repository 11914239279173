import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const MatchIcon = (props) => {
    return (
        <svg
            width={props.width ?? '50'}
            height={props.height ?? '50'}
            viewBox={props.viewBox ?? '0 0 50 50'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="activeClass stroke-dark fill-dark"
                d="M20.1661 0L17.4871 9.59913C16.8675 11.8187 17.3111 14.0831 18.5124 15.8516L0 20.9896L3.80147 34.606L7.26116 47C9.67734 44.1036 11.8125 41.0112 13.2633 37.5496C13.8837 36.0676 15.1538 34.9531 16.7048 34.5225L26.2567 31.8709C30.28 30.7548 32.6338 26.5975 31.5137 22.5858L31.1301 21.2124L32.2952 21.5359C33.847 21.9664 35.1163 23.0809 35.7375 24.5629C37.1875 28.0245 39.3227 31.1169 41.7396 34.0133L45.1993 21.6193L49 8.00295L20.1661 0ZM29.0265 27.3082C28.3034 28.5857 27.1242 29.5065 25.7065 29.9001L16.1547 32.5509C15.0962 32.8445 14.1016 33.3829 13.2775 34.107C12.4527 34.8319 11.7928 35.7496 11.3697 36.761C10.5724 38.6633 9.51947 40.5397 8.13733 42.5082L5.77877 34.0574L2.52747 22.4119L20.084 17.5391L21.3635 17.1841L22.853 16.7709L27.4075 15.5069L28.1787 18.2687L28.8213 20.5717L29.5364 23.1344C29.9311 24.548 29.7503 26.03 29.0265 27.3082ZM43.222 21.0707L40.8627 29.5215C39.4805 27.553 38.4283 25.6766 37.6311 23.7743C37.2072 22.7629 36.5473 21.8452 35.7225 21.1203C34.8992 20.3962 33.9038 19.8578 32.8453 19.5642L30.4876 18.9102L28.8339 12.9867L20.6429 15.2598C20.3903 14.974 20.1661 14.66 19.9735 14.3216C19.2505 13.0434 19.0689 11.5613 19.4636 10.1477L21.5932 2.5202L46.4725 9.42519L43.222 21.0707Z"
            />
        </svg>
    )
}

export default MatchIcon
