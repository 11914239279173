import React from 'react'

function HomeWorkIcon() {

    const primaryColor = '#ea6c3a';

  return (
    <svg className='card-icon primaryColor'viewBox="0 0 22 22" fill="#ea6c3a" xmlns="http://www.w3.org/2000/svg">
        <path stroke={primaryColor} d="M8.17 5.7L1 10.48V21h5v-8h4v8h5V10.25z" />
        <path stroke={primaryColor} d="M10 3v1.51l2 1.33L13.73 7H15v.85l2 1.34V11h2v2h-2v2h2v2h-2v4h6V3H10zm9 6h-2V7h2v2z"></path>
    </svg>  )
}

export default HomeWorkIcon
