import React, { useEffect, useRef, useState } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    ListItemText,
    MenuItem,
    Checkbox,
    TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IscedDataTree from '../../../Tables/isced_table'
import SectorDataTree from '../../../Tables/ateco_table'
import Container from '../../../TreeSelect/DropdownContainer'
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import EditIcon from 'app/components/Icons/EditIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import DateFnsUtils from '@date-io/date-fns'
import Skeleton from '@material-ui/lab/Skeleton'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import { format } from 'date-fns'
import { Autocomplete } from '@material-ui/lab'
import Sectors from '../../../Tables/sectors_simplified'
import Chip from '@material-ui/core/Chip'
import EditFieldDialog from '../FooterProfile/EditField'
import JobCardEmpty from '../JobCardEmpty'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import history from 'history.js'

const patenteData = [
    'Auto/Moto',
    'Camion',
    'Rimorchio',
    'Autobus',
    'Muletto',
    'Gru',
    'Nautica',
]

function JobCardInfo({
    offerInfo,
    onChange,
    onSelect,
    handleDateChange,
    setGeoLocationInfo,
    onSectorSelect,
    handleSubmit,
    onOpen,
    getOffer,
    setOfferInfo,
    loading,
    offerGuid = null,
}) {
    // per dialog
    const [open, setOpen] = useState(false)
    const descriptionElementRef = useRef(null)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const {
        recruiter_guid,
        offer_guid,
        job_name,
        sector,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        isced,
        ral,
        ral_type,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        start_job_date,
        isAvailable,
        isFindable,
        created,
        lastUpdate,
    } = offerInfo

    const handleClickOpen = () => () => {
        setOpen(true)
        onOpen()
    }

    const handleClose = () => {
        setOpen(false)
        resetValue(IscedDataTree)
        resetValue(SectorDataTree)
        getOffer()
        setFormErrors({})
    }

    // è per i tag del settore
    const handleDeleteSector = (e, index) => {
        e.preventDefault()
        console.log('target.', index)
        let arrayProvvisorio = [...sector]
        arrayProvvisorio.splice(index, 1)
        setOfferInfo({ ...offerInfo, sector: arrayProvvisorio })
        console.log(offerInfo)
        // NON LO SO
    }

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}

        if (!job_name) errors.job_name = 'Questo campo non può essere nullo'
        if (!location) errors.location = 'Questo campo non può essere nullo'
        if (!job_contract)
            errors.job_contract = 'Questo campo non può essere nullo'
        if (!job_type) errors.job_type = 'Questo campo non può essere nullo'
        if (!ral) errors.ral = 'Questo campo non può essere nullo'
        if (start_job_date && start_job_date < new Date())
            errors.start_job_date =
                'La data non può essere precedente al giorno corrente'
        // if (!start_job_date) errors.start_job_date = "Questo campo non può essere nullo"
        if (
            start_job_date &&
            new Date(start_job_date).toString() === 'Invalid Date'
        )
            errors.start_job_date = 'Inserisci una data valida'
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleSubmit()
                setOpen(false)
            }
            setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (sector?.lenght > 0)
            sector.map((sec) => findValue(SectorDataTree, sec))
        if (isced) findValue(IscedDataTree, isced)
    }, [offerInfo])

    // SONO DA INSERIRE PER LO SLIDER DELLA DISTANZA??
    // const getUserInfo = async () => {
    //     const response = await ApiService.get_offerfiltersbyid(recruiter_guid);
    //     const body = await response.json();
    //     // console.log(body)

    //     try {
    //         setUserInfo({
    //             ...userInfo,
    //             distance_filter: body.distance_filter
    //         });
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // };

    // const handleSubmit = async () => {
    //     const res = await ApiService.update_offerfiltersbyid(userInfo);
    //     handleClose();
    // };

    // const handleChangeSlider = (event, newValue) => {
    //     setOfferInfo({
    //         ...offerInfo,
    //         distance_filter: newValue
    //     })
    // };
    const handleFieldChange = (e) => {
        setIsEdited(true)
        onChange(e)
    }

    const handleDialogClose = () => {
        if (isEdited) {
            // console.log('Opening confirmation dialog')
            setConfirmOpen(true)
        } else {
            // console.log('Closing main dialog directly')
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }
    const goback = () => {
        try {
            history.push('/profile-recruiter')
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {offerInfo?.offer_guid === undefined ? (
                <>
                    <JobCardEmpty getOffer={getOffer} offerGuid={offerGuid} />
                </>
            ) : open ? (
                <div className="dropdown-form m1 mt2">
                    <div className="d-flex align-center">
                        <h3>
                            Qui puoi descrivere i vantaggi di questa posizione.
                        </h3>
                        <button
                            onClick={handleDialogClose}
                            className="close-card"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div>
                        <TextField
                            // id="filled-basic"
                            label="Nome del ruolo (es. cuoco, operaio, impiegato amministrativo..)"
                            variant="filled"
                            name="job_name"
                            fullWidth
                            required
                            color="primary"
                            value={job_name}
                            onChange={handleFieldChange}
                        />
                        {formErrors.job_name && (
                            <p className="input-error">{formErrors.job_name}</p>
                        )}
                    </div>

                    <div>
                        <PlaceAutocomplete
                            setSetlected={setGeoLocationInfo}
                            defaultValue={location}
                            label={'Luogo del lavoro'}
                            onChange={handleFieldChange}
                        />
                        {formErrors.location && (
                            <p className="input-error">{formErrors.location}</p>
                        )}
                    </div>

                    <div
                        className="dialog-layout m1 profilerec"
                        style={{ width: '100%' }}
                    >
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Tipologia di lavoro
                                </InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={isAvailable}
                                    onChange={handleFieldChange}
                                    inputProps={{
                                        name: 'isAvailable',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />
                                    <option value={true}>Attiva</option>
                                    <option value={false}>Disattiva</option>
                                </Select>
                            </FormControl>
                            {formErrors.job_type && (
                                <p className="input-error">
                                    {formErrors.job_type}
                                </p>
                            )}
                        </div>

                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Tipologia di lavoro
                                </InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={job_type}
                                    onChange={handleFieldChange}
                                    inputProps={{
                                        name: 'job_type',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />
                                    <option value={'In sede'}>In sede</option>
                                    <option value={'Ibrido'}>Ibrido</option>
                                    <option value={'Remoto'}>Remoto</option>
                                </Select>
                            </FormControl>
                            {formErrors.job_type && (
                                <p className="input-error">
                                    {formErrors.job_type}
                                </p>
                            )}
                        </div>
                        {/* </div>

                    <div className="dialog-layout"> */}
                        <div>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={itLocale}
                            >
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Data di inizio desiderata"
                                    format="dd/MM/yyyy"
                                    value={start_job_date || null}
                                    autoOk
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    fullWidth
                                    disablePast
                                    invalidDateMessage=""
                                    maxDateMessage=""
                                    minDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                            <p className="input-error">
                                {formErrors.start_job_date}
                            </p>
                        </div>
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Impegno orario
                                </InputLabel>
                                <TextField
                                    label=""
                                    variant="filled"
                                    fullWidth
                                    color="primary"
                                    multiline
                                    name="Impegno orario"
                                    // onChange={handleFieldChange}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="dialog-layout m2" style={{ width: '100%' }}>
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Tipologia di contratto
                                </InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={job_contract}
                                    onChange={handleFieldChange}
                                    inputProps={{
                                        name: 'job_contract',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />
                                    <option value={'Part time'}>
                                        Part time
                                    </option>
                                    <option value={'Apprendistato'}>
                                        Apprendistato
                                    </option>
                                    <option value={'Tempo determinato'}>
                                        Tempo determinato
                                    </option>
                                    <option value={'Tempo indeterminato'}>
                                        Tempo indeterminato
                                    </option>
                                    <option value={'Freelance'}>
                                        Freelance
                                    </option>
                                    <option value={'Stage'}>Stage</option>
                                </Select>
                            </FormControl>
                            {formErrors.job_contract && (
                                <p className="input-error">
                                    {formErrors.job_contract}
                                </p>
                            )}
                        </div>
                        <div>
                            <div className="input-select d-flex">
                                <TextField
                                    value={ral}
                                    label="Range di salario (20k annuo...)"
                                    name="ral"
                                    variant="filled"
                                    fullWidth
                                    required
                                    color="primary"
                                    onChange={handleFieldChange}
                                    className="custom-radius-text"
                                />
                                <FormControl
                                    variant="filled"
                                    fullWidth
                                    className="custom-radius-select"
                                >
                                    {/* <InputLabel htmlFor="salario-select">salario</InputLabel> */}
                                    <Select
                                        native
                                        // fullWidth
                                        value={ral_type || 'false'}
                                        name="ral_type"
                                        onChange={handleFieldChange}
                                        inputProps={{
                                            name: 'ral_type',
                                            // name: '',
                                            // id: '',
                                        }}
                                        style={{
                                            width: '10rem',
                                        }}
                                    >
                                        {/* <option hidden aria-label="None" value={null} />                                     */}
                                        <option value={false}>
                                            Annuo (lordo)
                                        </option>
                                        <option value={true}>
                                            Mensile (netto)
                                        </option>
                                    </Select>
                                </FormControl>
                            </div>
                            {formErrors.ral && (
                                <p className="input-error">{formErrors.ral}</p>
                            )}
                        </div>
                    </div>

                    <div>
                        <TextField
                            label=""
                            variant="filled"
                            fullWidth
                            required
                            color="primary"
                            multiline
                            rows={7}
                            name="description"
                            value={description}
                            onChange={handleFieldChange}
                            placeholder="Descrivi i punti di forza di questa posizione, ad esempio buoni pasto gratuiti, agevolazioni sui servizi, flessibilità oraria (max 500 caratteri)"
                            inputProps={{ maxLength: 1500 }}
                        />
                        <p
                            className={
                                description?.length < 1500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 1500 massimo
                        </p>
                    </div>

                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            setSubmit(true)
                        }}
                        color="primary"
                        className="btn-like-mui"
                    >
                        Salva
                    </Button>
                </div>
            ) : (
                <article className="job-general-info">
                    <div className="section-title">
                        {/* <h3>{job_name}</h3> */}
                        <h3></h3>
                        <div>
                            <button
                                className="icon-btn"
                                id="buttonAlineRAL"
                                onClick={handleClickOpen()}
                            >
                                <EditIcon />
                            </button>
                        </div>
                    </div>
                    <div className="two-columns">
                        <div>
                            <h4>✍🏻Ruolo</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {job_name}
                                </p>
                            )}
                        </div>

                        <div>
                            <h4>⏯Stato</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {isAvailable ? 'Attiva' : 'Disattiva'}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="two-columns">
                        <div>
                            <h4>📌 Sede di lavoro</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <>
                                    <p className="tags" id="graytags">
                                        {location}{' '}
                                    </p>
                                    {job_type && (
                                        <p className="tags" id="graytags">
                                            {job_type}
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                        <div>
                            <h4>💰 Range di salario</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {' '}
                                    {ral_type
                                        ? 'Mensile (netto)'
                                        : 'Annuo (lordo)'}{' '}
                                    {ral ? ral : '####'} €
                                </p>
                            )}
                        </div>
                        <div>
                            <h4>🖋️ Tipo di contratto</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {job_contract
                                        ? job_contract
                                        : 'Non specificato'}{' '}
                                </p>
                            )}
                        </div>
                        <div>
                            <h4>📅 Data Inizio</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {start_job_date
                                        ? format(
                                              new Date(start_job_date),
                                              'dd/MM/yyyy'
                                          )
                                        : 'Non specificata'}{' '}
                                </p>
                            )}
                        </div>
                        {/* <div>
                            <h4>Categoria Protetta:</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {protected_category ? 'Sì' : 'No'}{' '}
                                </p>
                            )}
                        </div> */}
                    </div>

                    {/* <div className="two-columns"> */}
                    {/* <div>
                            <h4>Settore:</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p>
                                    {sector
                                        ? sector.map((el, idx) => (
                                              <p
                                                  className="tags"
                                                  key={idx}
                                                  id="graytags"
                                              >
                                                  {' '}
                                                  {el}{' '}
                                              </p>
                                          ))
                                        : 'Non specificato'}
                                </p>
                            )}
                        </div>
                        
                    </div> */}

                    {/* <div className="two-columns">
                        <div>
                            <h4>Tipologia contratto:</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {job_contract
                                        ? job_contract
                                        : 'Non specificato'}{' '}
                                </p>
                            )}
                        </div>
                        <div>
                            <h4>Tipologia lavoro:</h4>
                            {loading ? (
                                <Skeleton variant="text" width={200} />
                            ) : (
                                <p className="tags" id="graytags">
                                    {job_type ? job_type : 'Non specificato'}{' '}
                                </p>
                            )}
                        </div>
                    </div> */}

                    <div>
                        <h4>📝 Descrizione del lavoro</h4>
                        {loading ? (
                            <Skeleton
                                variant="text"
                                width="100%"
                                height={200}
                            />
                        ) : (
                            <p className="pre-space hyphens description">
                                {description
                                    ? description
                                    : 'Nessuna descrizione'}
                            </p>
                        )}
                    </div>
                    {/* </div> */}
                </article>
            )}

            {/* DIALOG DELL'INGRANAGGIO - VALUTAZIONE */}
            {/* <Dialog
                open={openRating}
                onClose={handleCloseRating}
                fullWidth={true}
                maxWidth={'sm'}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className='MUIDialog'
                id='rating-dialog'
            >
                <div className="d-flex space-between dialog-title">
                    <DialogTitle>Valutazione</DialogTitle>
                    <Button onClick={handleCloseRating} color="primary" className='close-dialog'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                    </Button>
                </div>
                <DialogContent dividers={scroll === 'paper'}>
                    <div className="dialog-layout">
                        <div>
                            <h3>Competenze tecniche</h3>
                            <div className="no-min">
                                <Slider
                                    value={hs_weight}
                                    onChange={handleChangeSlider("hs_weight")}
                                    defaultValue={25}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={5}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                        <div>
                            <h3>Competenze trasversali</h3>
                            <div className="no-min">
                                <Slider
                                    value={ss_weight}
                                    onChange={handleChangeSlider("ss_weight")}
                                    defaultValue={25}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={5}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                        <div>
                            <h3>Istruzione</h3>
                            <div className="no-min">
                                <Slider
                                    value={title_weight}
                                    onChange={handleChangeSlider("title_weight")}
                                    defaultValue={25}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={5}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                        <div>
                            <h3>Esperienza</h3>
                            <div className="no-min">
                                <Slider
                                    value={exp_weight}
                                    onChange={handleChangeSlider("exp_weight")}
                                    defaultValue={25}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={5}
                                    min={0}
                                    max={100}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { handleSubmit(e); setOpen(false); }} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog> */}
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default JobCardInfo
