import React, { useState, useMemo, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { debounce } from 'utils'
import { tomtomApiKey } from 'config'

import CircularProgress from '@material-ui/core/CircularProgress'

const PlaceAutocomplete = ({
    setSetlected,
    defaultValue,
    label,
    disabled = false,
    required = true,
    isCandidate = false,
}) => {
    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false)

    const onOpen = () => {
        setOpen(true)
    }

    const fuzzySearch = useMemo(
        () =>
            debounce(async (request) => {
                let newOptions = []

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: '*',
                    },
                }

                // metto questo check per evitare di fetchare quando renderizzo l'elemento
                if (defaultValue !== request) {
                    var geofilter = ''
                    if (isCandidate) geofilter = '&idxSet=Geo'
                    const response = await fetch(
                        'https://api.tomtom.com/search/2/search/' +
                            request +
                            '.json?key=' +
                            tomtomApiKey +
                            '&language=it-IT&typeahead=true' +
                            geofilter,
                        requestOptions
                    )

                    if (response.ok) {
                        const body = await response.json()
                        console.log(body)
                        if (value) {
                            newOptions = [value]
                        }

                        if (body) {
                            newOptions = [...newOptions, ...body.results]
                        }
                    }
                    setOptions(newOptions)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }, 600),
        []
    )

    useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fuzzySearch(inputValue)
        setLoading(true)
    }, [value, inputValue, fetch])

    useEffect(() => {
        if (defaultValue) setValue(defaultValue)
    }, [defaultValue])

    return (
        <Autocomplete
            disabled={disabled}
            id="google-map-demo"
            open={open}
            onOpen={onOpen}
            onClose={() => {
                setOpen(false)
            }}
            sx={{ width: 300 }}
            getOptionLabel={(option) =>
                // typeof option === 'string' ? option : option.description
                option?.type === 'Geography' &&
                option.address.countrySecondarySubdivision
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.countrySecondarySubdivision +
                      ', ' +
                      option.address.country
                    : option?.type === 'Geography'
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.country
                    : option?.type === 'POI'
                    ? option.poi.name +
                      ', ' +
                      option.address.freeformAddress +
                      ', ' +
                      option.address.countrySecondarySubdivision +
                      ', ' +
                      option.address.country
                    : option?.type === 'Street' &&
                      option.address.countrySecondarySubdivision
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.countrySecondarySubdivision +
                      ', ' +
                      option.address.country
                    : option?.type === 'Street'
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.country
                    : option?.type === 'Point Address' &&
                      option.address.countrySecondarySubdivision
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.countrySecondarySubdivision +
                      ', ' +
                      option.address.country
                    : option?.type === 'Point Address'
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.country
                    : option?.type === 'Address Range' &&
                      option.address.countrySecondarySubdivision
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.countrySecondarySubdivision +
                      ', ' +
                      option.address.country
                    : option?.type === 'Address Range'
                    ? option.address.freeformAddress +
                      ', ' +
                      option.address.country
                    : typeof option === 'string'
                    ? option
                    : null
            }
            // commentato perchè se lo lascio mi mette uno sfondo grigio alle opzioni
            // getOptionSelected={(option) =>
            //   // typeof option === 'string' ? option : option.description
            //   option.type ==='Geography' && option.address.countrySecondarySubdivision ? option.address.freeformAddress + ', ' + option.address.countrySecondarySubdivision + ', ' + option.address.country:
            //   option.type ==='Geography' ? option.address.freeformAddress + ', ' +  option.address.country:
            //   option.type ==='POI'? option.poi.name + ', ' + option.address.freeformAddress  + ', ' + option.address.countrySecondarySubdivision + ', ' + option.address.country:
            //   option.type ==='Street' && option.address.countrySecondarySubdivision? option.address.freeformAddress  + ', ' + option.address.countrySecondarySubdivision + ', ' + option.address.country:
            //   option.type ==='Street'? option.address.freeformAddress  + ', ' + option.address.country:
            //   option.type ==='Point Address' && option.address.countrySecondarySubdivision? option.address.freeformAddress  + ', ' + option.address.countrySecondarySubdivision + ', ' + option.address.country:
            //   option.type ==='Point Address'? option.address.freeformAddress  + ', ' + option.address.country:
            //   option.type ==='Address Range' && option.address.countrySecondarySubdivision? option.address.freeformAddress  + ', ' + option.address.countrySecondarySubdivision + ', ' + option.address.country:
            //   option.type ==='Address Range'? option.address.freeformAddress  + ', ' + option.address.country: null
            // }
            filterOptions={(x) => x}
            options={options}
            // autoComplete
            // includeInputInList
            // filterSelectedOptions
            value={value}
            noOptionsText="Nessun risultato"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options)
                setValue(newValue)
                setSetlected(
                    newValue?.type === 'Geography' &&
                        newValue.address.countrySecondarySubdivision
                        ? newValue.address.freeformAddress +
                              ', ' +
                              newValue.address.countrySecondarySubdivision +
                              ', ' +
                              newValue.address.country
                        : newValue?.type === 'Geography'
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'POI' &&
                          newValue.address.countrySecondarySubdivision
                        ? newValue.poi.name +
                          ', ' +
                          newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.countrySecondarySubdivision +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'POI'
                        ? newValue.poi.name +
                          ', ' +
                          newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Street' &&
                          newValue.address.countrySecondarySubdivision
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.countrySecondarySubdivision +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Street'
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Point Address' &&
                          newValue.address.countrySecondarySubdivision
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.countrySecondarySubdivision +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Point Address'
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Address Range' &&
                          newValue.address.countrySecondarySubdivision
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.countrySecondarySubdivision +
                          ', ' +
                          newValue.address.country
                        : newValue?.type === 'Address Range'
                        ? newValue.address.freeformAddress +
                          ', ' +
                          newValue.address.country
                        : null,
                    newValue?.position
                )
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            // renderInput={(params) => (
            //   <TextField {...params} required label={label} fullWidth />
            // )}

            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default PlaceAutocomplete
