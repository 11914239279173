import React from 'react'

function LocationOn() {

    const primaryColor = '#ea6c3a';

  return (
    <svg className='card-icon primaryColor' viewBox="0 0 22 22" fill="#ea6c3a" xmlns="http://www.w3.org/2000/svg">
        <path stroke={primaryColor} d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </svg>  )
}


export default LocationOn
