import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ApiService from 'app/ApiService';
import { DialogContentText, MenuItem } from '@material-ui/core';

const DeleteMatch = React.forwardRef(({ handleOpenMenu, handleCloseMenu, offer_guid, candidate_guid, setAppState, setOpenMessage}, ref) => {

    // pop up elimina match
    const [openConfirm, setOpenConfirm] = useState(false);
    const handleClickOpenConfirm = () => {
        setOpenConfirm(true);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);           
    };

    const handleDelete = async () => {
        const res = await ApiService.remove_match({
            offer_guid: offer_guid,
            candidate_guid: candidate_guid
        })
        if (res.ok) {
            setOpenMessage(false)
            setAppState(true)
        }
        setOpenConfirm(false);
    }

    return (
        <>
            <MenuItem onClick={() => {handleCloseMenu(); handleClickOpenConfirm()}}>Elimina Match</MenuItem>

            {/* DIALOG PER ELIMINARE CHAT E QUINDI MATCH */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='confirm-dialog small-dialog'
            >
                <DialogTitle>{"Sicuro?"}</DialogTitle>
                <DialogContent >
                    <DialogContentText
                    >
                        Confermi di voler eliminare la chat?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>                    
                    <Button  onClick={handleDelete} color="primary">
                        Elimina
                    </Button>
                    <Button onClick={handleCloseConfirm} color="secondary" autoFocus>
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>    
        </>
    )
}
  )
// }

export default DeleteMatch

// import React, { useState } from 'react'
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// // import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Button from '@material-ui/core/Button';

// import { Badge, DialogContentText, Menu, MenuItem, TextField } from '@material-ui/core';


// function DeleteMatch({ handleOpenMenu, handleCloseMenu}) {


//         // pop up elimina match
//         const [openConfirm, setOpenConfirm] = useState(false);
//         const handleClickOpenConfirm = () => {
//             setOpenConfirm(true);
//         };
//         const handleCloseConfirm = () => {
//             setOpenConfirm(false);
//         };


//   return (
//     <>

//             <MenuItem onClick={() => {handleCloseMenu(); handleClickOpenConfirm()}}>Elimina Match</MenuItem>

    
//             {/* DIALOG PER ELIMINARE CHAT E QUINDI MATCH */}
//             <Dialog
//                 open={openConfirm}
//                 onClose={handleCloseConfirm}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//                 className='confirm-dialog small-dialog'
//             >
//                 <DialogTitle>{"Sicuro?"}</DialogTitle>
//                 <DialogContent >
//                     <DialogContentText
//                     >
//                         Confermi di voler eliminare la chat?
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleCloseConfirm} color="secondary" autoFocus>
//                         Annulla
//                     </Button>
//                     <Button  color="primary">
//                         Elimina
//                     </Button>
//                 </DialogActions>
//             </Dialog>
    
//     </>
//   )
// }

// export default DeleteMatch