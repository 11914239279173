import React, { useState, useCallback, useEffect, useRef } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    Slider,
    TextField,
    Switch,
} from '@material-ui/core'
import ApiService from 'app/ApiService'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useAuth from 'app/hooks/useAuth'
import FooterProfile from './ProfileComponents/FooterProfile/FooterProfile'
import SendIcon from '@material-ui/icons/Send'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import ReplyIcon from '@material-ui/icons/Reply'
import useFirestore from 'app/hooks/useFirestore'
import PricingPage from './Pricing/ComingSoonPremPopUp'
import Skeleton from '@material-ui/lab/Skeleton'
import { debounce } from 'lodash'

const job_typology = ['In sede', 'Ibrido', 'Remoto']

const contracts = [
    'Part time',
    'Apprendistato',
    'Tempo determinato',
    'Tempo indeterminato',
    'Freelance',
    'Stage',
]

function FiltersPage() {
    const [activeTab, setActiveTab] = useState('Il mio piano')
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const [appState, setAppState] = useState(false)
    const [sub, setSub] = useState(null)
    const [openPricing, setOpenPricing] = useState(false)
    const [sendFilter, setSendFilter] = useState(false)
    const [duration, setDuration] = useState('1 Mese')

    const { user } = useAuth()
    const { getActiveSubscription } = useFirestore()

    const initUserInfo = {
        candidate_guid: user?.uid || '',
        distance_filter: 200,
        job_type: [],
        job_contract: [],
        protected_cat_filter: '',
        isAvailable: true,
        isFindable: true,
        created: '',
        lastUpdate: new Date(),
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        distance_filter,
        job_type,
        job_contract,
        protected_cat_filter,
        isAvailable,
        isFindable,
        created,
        lastUpdate,
    } = userInfo

    const TabNavItem = ({ title, id, activeTab, setActiveTab }) => {
        const handleClick = () => {
            setActiveTab(id)
        }

        return (
            <li
                className={`tab-item border ${
                    activeTab === id ? 'active' : ''
                }`}
                onClick={handleClick}
            >
                {title}
            </li>
        )
    }

    // Tab Content Component
    const TabContent = ({ id, activeTab, children }) => {
        return activeTab === id ? (
            <div className="tab-content">{children}</div>
        ) : null
    }

    const getUserInfo = async () => {
        const response = await ApiService.get_filtersbyid(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setUserInfo({
                ...userInfo,
                distance_filter: body.distance_filter,
                job_type: JSON.parse(body.job_type) || [],
                job_contract: JSON.parse(body.job_contract) || [],
                protected_cat_filter:
                    body?.protected_cat_filter?.toString() || '',
                isAvailable: body.isAvailable,
                isFindable: body.isFindable,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleDebouncedChange = useCallback(
        debounce(() => {
            setSendFilter(true)
        }, 700),
        []
    )

    const handleClickOpen = (scrollType) => () => {
        setUserInfo({ ...userInfo, lastUpdate: new Date() })
        setOpen(true)
        setScroll(scrollType)
    }

    const handleClose = async () => {
        await getUserInfo()
        setOpen(false)
    }

    const onSelect = (event) => {
        event.preventDefault()
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        setSendFilter(true)
    }

    const handleChangeSlider = (event, newValue) => {
        console.log(newValue)
        setUserInfo({
            ...userInfo,
            distance_filter: newValue,
        })
        handleDebouncedChange() // Call the debounced function
    }

    const handleSubmit = async () => {
        // console.log('invio')
        const res = await ApiService.update_filtersbyid(userInfo)
        //Handle errors here
        // console.log(res);
        // handleClose();
    }

    async function getData() {
        const res = await getActiveSubscription(user.uid)
        setSub(res)
        if (res?.items.length > 0)
            setDuration(res?.items[0]?.price.metadata.duration)
        await getUserInfo()
        setAppState(false)
    }

    const toggleChecked = () => {
        setUserInfo({
            ...userInfo,
            isAvailable: !isAvailable,
            isFindable: !isFindable,
        })

        setSendFilter(true)
    }

    useEffect(() => {
        if (user !== null) {
            setUserInfo({
                ...userInfo,
                candidate_guid: user.uid,
            })
            setAppState(true)
        }
    }, [user])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (sendFilter) {
            handleSubmit()
            setSendFilter(false)
        }
    }, [sendFilter])

    return (
        <>
            <PricingPage open={openPricing} setOpen={setOpenPricing} />
            <div className="Content-scroll-sticky">
                <div className="filter-scroll">
                    <main className="padding-inline filters">
                        <h1 style={{ color: '#4b4c55' }}>Impostazioni</h1>
                        <div className="tab-section">
                            <ul className="tab-menu plan d-flex space-between">
                                <>
                                    <TabNavItem
                                        className="bordertab"
                                        title="Il mio piano"
                                        id="Il mio piano"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        className="bordertab"
                                        title="Impostazioni di ricerca"
                                        id="Impostazioni di ricerca"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        className="bordertab"
                                        title="Account e privacy"
                                        id="Account e privacy"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </>
                            </ul>

                            <div className="content-tab plan">
                                <TabContent
                                    id="Il mio piano"
                                    activeTab={activeTab}
                                >
                                    {appState ? (
                                        <div className="plan">
                                            <Skeleton
                                                variant="text"
                                                className="planCardheaderblack"
                                                width="80%"
                                                height={40}
                                            />
                                            <Skeleton
                                                variant="text"
                                                className="planCardheaderOrange"
                                                width="60%"
                                                height={40}
                                            />
                                            <div className="PlanCard">
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : sub === null ||
                                      sub?.status !== 'active' ? (
                                        <>
                                            <div className="plan">
                                                <h1 className="planCardheaderblack">
                                                    Al momento utilizzi il piano
                                                    standard, gratuito
                                                </h1>
                                                <h1 className="planCardheaderOrange">
                                                    <a
                                                        onClick={() =>
                                                            setOpenPricing(true)
                                                        }
                                                    >
                                                        Passa a Premium.
                                                    </a>
                                                </h1>
                                                <div className="PlanCard">
                                                    <div className="PlanCardcomponent">
                                                        <SendIcon />
                                                        <h3>
                                                            20 candidature al
                                                            giorno
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <RemoveRedEyeIcon />
                                                        <h3>
                                                            Vedi a chi interessi
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <ReplyIcon />
                                                        <h3>Torna indietro</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="plan prentium">
                                                <h1 className="planCardheaderblack">
                                                    Al momento utilizzi il piano
                                                    Premium per la durata di{' '}
                                                    {duration}.
                                                </h1>
                                                <h1 className="planCardheaderOrange">
                                                    Cosa è incluso:
                                                </h1>
                                                <div className="PlanCard">
                                                    <div className="PlanCardcomponent">
                                                        <SendIcon />
                                                        <h3>
                                                            15 candidature al
                                                            giorno
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <RemoveRedEyeIcon />
                                                        <h3>
                                                            Vedi a chi interessi
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <ReplyIcon />
                                                        <h3>Torna indietro</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="InterrompiFilter">
                                                <a
                                                    onClick={() =>
                                                        setOpenPricing(true)
                                                    }
                                                >
                                                    Interrompi abbonamento
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </TabContent>
                                <TabContent
                                    id="Impostazioni di ricerca"
                                    activeTab={activeTab}
                                >
                                    <div className="ricercatab">
                                        <p>
                                            Facci sapere se vuoi essere visto
                                            dalle aziende
                                        </p>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked':
                                                            {
                                                                color: '#FFFFFF',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        'rgba(255, 255, 255, 0.08)',
                                                                },
                                                            },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                            {
                                                                backgroundColor:
                                                                    '#F06031',
                                                            },
                                                        '& .MuiSwitch-switchBase':
                                                            {
                                                                color: '#FFFFFF',
                                                            },
                                                        '& .MuiSwitch-track': {
                                                            backgroundColor:
                                                                '#EEEEEE',
                                                        },
                                                        '& .MuiSwitch-thumb': {
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    checked={isAvailable}
                                                    onChange={toggleChecked}
                                                />
                                            }
                                            label={
                                                isAvailable
                                                    ? 'Sono disponibile!'
                                                    : 'Non sono disponibile'
                                            }
                                            sx={{
                                                justifyContent: 'space-between',
                                                '.MuiFormControlLabel-label': {
                                                    color: '#333333',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        />
                                        <p className="mt1 ricerca">
                                            Aiutaci nella ricerca del lavoro
                                            perfetto per te
                                        </p>
                                        <div className="dialog-layout F0">
                                            <div>
                                                <h3>Distanza in km</h3>
                                                <div>
                                                    <Slider
                                                        // defaultValue={200}
                                                        onChange={
                                                            handleChangeSlider
                                                        }
                                                        // onChangeCommitted={
                                                        //     handleSubmit
                                                        // }
                                                        value={distance_filter}
                                                        aria-labelledby="continuous-slider"
                                                        valueLabelDisplay="on"
                                                        step={1}
                                                        min={2}
                                                        max={200}
                                                    />
                                                </div>
                                            </div>
                                            <div className="dialog-layout F0">
                                                <div className="d-flex flex-column space-between">
                                                    <FormLabel component="h3">
                                                        Tipologia lavoro
                                                    </FormLabel>
                                                    <FormControl
                                                        variant="filled"
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            htmlFor="filled-age-native-simple"
                                                            id="demo-mutiple-checkbox-label"
                                                        >
                                                            Tipologia lavoro
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-mutiple-checkbox-label"
                                                            id="demo-mutiple-checkbox"
                                                            multiple
                                                            value={
                                                                job_type || null
                                                            }
                                                            onChange={onSelect}
                                                            name="job_type"
                                                            // input={<Input />
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ',  '
                                                                )
                                                            }
                                                            // MenuProps={MenuProps}
                                                        >
                                                            {job_typology.map(
                                                                (name) => (
                                                                    <MenuItem
                                                                        key={
                                                                            name
                                                                        }
                                                                        value={
                                                                            name
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                job_type?.indexOf(
                                                                                    name
                                                                                ) >
                                                                                -1
                                                                            }
                                                                        />
                                                                        <ListItemText
                                                                            primary={
                                                                                name
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div>
                                                <FormLabel component="h3">
                                                    Tipologia di contratto
                                                </FormLabel>
                                                <FormControl
                                                    variant="filled"
                                                    fullWidth
                                                >
                                                    <InputLabel
                                                        htmlFor="filled-age-native-simple"
                                                        id="demo-mutiple-checkbox-label"
                                                    >
                                                        Tipologia di contratto
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-mutiple-checkbox-label"
                                                        id="demo-mutiple-checkbox"
                                                        multiple
                                                        renderValue={(
                                                            selected
                                                        ) =>
                                                            selected.join(',  ')
                                                        }
                                                        value={
                                                            job_contract || null
                                                        }
                                                        onChange={onSelect}
                                                        name="job_contract"
                                                    >
                                                        {contracts.map(
                                                            (name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            job_contract?.indexOf(
                                                                                name
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            name
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormLabel component="h3">
                                                    Categoria protetta
                                                </FormLabel>
                                                <FormControl
                                                    variant="filled"
                                                    fullWidth
                                                >
                                                    {protected_cat_filter && (
                                                        <InputLabel htmlFor="filled-age-native-simple">
                                                            Categoria protetta
                                                        </InputLabel>
                                                    )}
                                                    {!protected_cat_filter && (
                                                        <InputLabel
                                                            id="fix-select"
                                                            htmlFor="filled-age-native-simple"
                                                        >
                                                            Categoria protetta
                                                        </InputLabel>
                                                    )}{' '}
                                                    <Select
                                                        fullWidth
                                                        displayEmpty
                                                        value={
                                                            protected_cat_filter
                                                        }
                                                        onChange={onSelect}
                                                        inputProps={{
                                                            name: 'protected_cat_filter',
                                                            // id: 'filled-age-native-simple',
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={''}
                                                            style={{
                                                                paddingRight:
                                                                    '1rem',
                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Mostra tutte le
                                                            offerte
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'true'}
                                                            style={{
                                                                paddingRight:
                                                                    '1rem',

                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Mostra solo offerte
                                                            riservate a
                                                            categoria protetta
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'false'}
                                                            style={{
                                                                paddingRight:
                                                                    '1rem',
                                                                whiteSpace:
                                                                    'normal',
                                                            }}
                                                        >
                                                            Mostra solo offerte
                                                            non riservate a
                                                            categoria protetta
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent
                                    id="Account e privacy"
                                    activeTab={activeTab}
                                >
                                    <div>
                                        {' '}
                                        <FooterProfile />
                                    </div>
                                </TabContent>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default FiltersPage
