import React, { useState, useCallback, useEffect } from 'react'
import BioCandidate from './ProfileComponents/BioCandidate'
import HardSkills from './ProfileComponents/HardSkills'
import SoftSkills from './ProfileComponents/SoftSkills'
import Istruzione from './ProfileComponents/Istruzione'
import Certification from './ProfileComponents/Certification'
import WorkExperience from './ProfileComponents/WorkExperience'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import { makeStyles } from '@material-ui/core/styles'
import TabNavItem from './ProfileComponents/Tab/TabListItem'
import TabContent from './ProfileComponents/Tab/TabContent'
import Skeleton from '@material-ui/lab/Skeleton'
import Slider from 'react-slick'
import LanguageSkills from './ProfileComponents/LanguageSkills'
import { CompleteFormCandidate } from './CompleteForm/CompleteFormCandidate'

const useStyle = makeStyles({
    root: {
        '& .MuiSwitch-edgeEnd': {
            background: '#EA6C3A',
            color: '#EA6C3A',
        },
        '& 	.MuiSwitch-switchBase': {
            color: 'gray',
        },
        '& .Mui-checked': {
            // background: 'violet',
            color: '#EA6C3A',
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'grey',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#EA6C3A',
        },
    },
})

const settings = {
    // dots: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    // centerMode: true,
    // centerPadding: '60px',
    responsive: [
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
            },
        },
    ],
}

function Profile() {
    const [activeTab, setActiveTab] = useState('esperienze-professionali')
    const classes = useStyle()
    const [isChecked, setIsChecked] = useState(false)
    const availabilityState = isChecked
        ? 'Sono disponibile'
        : 'Non sono disponibile'
    const [appState, setAppState] = useState(false)
    const [computeScore, setComputeScore] = useState(false)
    const [hsList, setHsList] = useState([])
    const [ssList, setSsList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [certList, setCertList] = useState([])
    const [expList, setExpList] = useState([])
    const [openPreview, setOpenPreview] = useState(false)
    const [userInfo, setUserInfo] = useState({
        candidate_guid: '',
        name: '',
        surname: '',
        phone: '',
        gender: '',
        summary: '',
        birth_date: '',
        email: '',
        location: '',
        geolocation: {},
        photo_url: '',
        description: '',
        driving_license: [],
        ral: '',
        ral_type: true,
        protected_category: 'false',
        created: '',
        lastUpdate: '',
    })
    const [progress, setProgress] = useState(0)
    const [progressChecks, setProgressChecks] = useState({
        photo: false,
        education: false,
        exp: false,
        soft: false,
        hard: false,
        langs: false,
        bio: false,
    })

    const {
        candidate_guid,
        name,
        surname,
        phone,
        gender,
        summary,
        birth_date,
        email,
        location,
        geolocation,
        photo_url,
        description,
        driving_license,
        ral,
        ral_type,
        protected_category,
        created,
        lastUpdate,
    } = userInfo

    const {
        user,
        // user
    } = useAuth()

    const getUserInfo = async () => {
        try {
            const response = await ApiService.get_candidatebyId(candidate_guid)
            const body = await response.json()
            console.log(body)

            setUserInfo({
                ...userInfo,
                candidate_guid: body.candidate_guid,
                name: body.name,
                surname: body.surname,
                phone: body.phone,
                gender: body.gender,
                summary: body.summary,
                birth_date: new Date(body.birth_date),
                email: body.email,
                location: body.location,
                geolocation: body.geolocation,
                photo_url: body.photo_url,
                description: body.description,
                driving_license: JSON.parse(body.driving_license),
                ral: body.ral,
                ral_type: body?.ral_type || false,
                protected_category:
                    body?.protected_category?.toString() || 'false',
                created: body.created,
                lastUpdate: body.lastUpdate,
                lastLocationUpdate: body.lastLocationUpdate,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const getHsList = async () => {
        try {
            const response = await ApiService.get_candhsbyguid(candidate_guid)
            if (response.ok) {
                const body = await response.json()
                console.log(body)

                setHsList(body)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLanguageList = async () => {
        try {
            const response = await ApiService.get_candLangbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            setLanguageList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getSsList = async () => {
        try {
            const response = await ApiService.get_candssbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_candtitlebyguid(
                candidate_guid
            )
            const body = await response.json()
            console.log(body)

            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getCertList = async () => {
        try {
            const response = await ApiService.get_candCertbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            setCertList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        try {
            const response = await ApiService.get_candexpbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const update_lastUpdate = async () => {
        const res = await ApiService.update_lastUpdate({
            candidate_guid: candidate_guid,
            lastUpdate: new Date(),
        })
        // if (res.ok) console.log("Data updated")
    }

    const getUser = useCallback(async () => {
        await getUserInfo()
        setComputeScore(true)
        // await update_lastUpdate()
    }, [userInfo])

    const gethardList = useCallback(async () => {
        await getHsList()
        await update_lastUpdate()
        setComputeScore(true)
        // await reduceOffers()
    }, [userInfo])

    const getsoftList = useCallback(async () => {
        await getSsList()
        await update_lastUpdate()
        setComputeScore(true)
        // await reduceOffers()
    }, [userInfo])

    // const getlanguageList = useCallback(async () => {
    //   console.log('api');
    //   await getLanguageList()
    //   await update_lastUpdate()
    //   // // await reduceOffers()
    // }, [userInfo]);

    const gettitleList = useCallback(async () => {
        await getTitleList()
        await update_lastUpdate()
        setComputeScore(true)
        // await reduceOffers()
    }, [userInfo])

    const getcertList = useCallback(async () => {
        await getCertList()
        await update_lastUpdate()
        setComputeScore(true)
        // await reduceOffers()
    }, [userInfo])

    const getexpList = useCallback(async () => {
        await getExpList()
        await update_lastUpdate()
        setComputeScore(true)
        // await reduceOffers()
    }, [userInfo])

    function findObjectWithFewestEmptyProperties(objects) {
        if (objects.length === 0) return null // Handle empty array

        return objects.reduce((bestObject, currentObject) => {
            const countEmpty = (obj) =>
                Object.values(obj).filter(
                    (value) => value === null || value === ''
                ).length

            // If bestObject is null (initial case), start with currentObject
            if (!bestObject) return currentObject

            // Compare the number of empty elements in the current object to the best object found so far
            return countEmpty(currentObject) < countEmpty(bestObject)
                ? currentObject
                : bestObject
        }, null)
    }

    function calculateProgress() {
        let bioCheck = 0
        let progressScore = 0
        if (photo_url) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                photo: true,
            }))
        } else {
            setProgressChecks((prevState) => ({
                ...prevState,
                photo: false,
            }))
        }
        if (summary) {
            bioCheck += 1
        }
        if (ral) {
            bioCheck += 1
        }
        if (gender) {
            bioCheck += 1
        }
        if (phone) {
            bioCheck += 1
        }
        if (description) {
            bioCheck += 1
        }

        if (bioCheck >= 5) {
            progressScore += 5
            setProgressChecks((prevState) => ({
                ...prevState,
                bio: true,
            }))
        } else {
            progressScore += bioCheck
            setProgressChecks((prevState) => ({
                ...prevState,
                bio: false,
            }))
        }

        const data = findObjectWithFewestEmptyProperties(expList)
        let dataCheck = 0
        if (data) {
            if (data.job_title) {
                dataCheck += 1
            }
            if (data.company_name) {
                dataCheck += 1
            }
            if (data.location) {
                dataCheck += 1
            }
            if (JSON.parse(data.sector).length > 0) {
                dataCheck += 1
            }
            if (data.job_date_start) {
                dataCheck += 1
            }
            if (data.job_date_end) {
                dataCheck += 1
            }
        }
        if (dataCheck >= 6) {
            progressScore += 6
            setProgressChecks((prevState) => ({
                ...prevState,
                exp: true,
            }))
        } else {
            progressScore += dataCheck
            setProgressChecks((prevState) => ({
                ...prevState,
                exp: false,
            }))
        }

        const edudata = findObjectWithFewestEmptyProperties(titleList)
        let eduCheck = 0
        if (edudata) {
            if (edudata.eqf_label) {
                eduCheck += 1
            }
            if (edudata.school) {
                eduCheck += 1
            }
            if (edudata.eqf_label <= 2) {
                eduCheck += 2
            } else {
                if (edudata.other_title) {
                    eduCheck += 1
                }
                if (edudata.graduation_grade) {
                    eduCheck += 1
                }
            }
            if (edudata.title_date_start) {
                eduCheck += 1
            }
            if (edudata.title_date_end) {
                eduCheck += 1
            }
        }
        if (eduCheck >= 6) {
            progressScore += 6
            setProgressChecks((prevState) => ({
                ...prevState,
                education: true,
            }))
        } else {
            progressScore += eduCheck
            setProgressChecks((prevState) => ({
                ...prevState,
                education: false,
            }))
        }

        if (ssList.length >= 5) {
            progressScore += 5
            setProgressChecks((prevState) => ({
                ...prevState,
                soft: true,
            }))
        } else {
            progressScore += ssList.length
            setProgressChecks((prevState) => ({
                ...prevState,
                soft: false,
            }))
        }

        if (hsList.length >= 5) {
            progressScore += 5
            setProgressChecks((prevState) => ({ ...prevState, hard: true }))
        } else {
            progressScore += hsList.length
            setProgressChecks((prevState) => ({ ...prevState, hard: false }))
        }

        if (languageList.length > 0) {
            progressScore += 1
            setProgressChecks((prevState) => ({
                ...prevState,
                langs: true,
            }))
        } else
            setProgressChecks((prevState) => ({
                ...prevState,
                langs: false,
            }))

        setProgress(progressScore)
        setComputeScore(false)
    }

    async function getData() {
        await getUserInfo()
        await getExpList()
        await getTitleList()
        await getCertList()
        await getSsList()
        await getLanguageList()
        await getHsList()
        setAppState(false)
        setComputeScore(true)
    }

    useEffect(() => {
        if (appState) {
            getData()
        }
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({
                email: user.email,
                candidate_guid: user.uid,
            })
            setAppState(true)
        }
    }, [user])

    useEffect(() => {
        if (computeScore) calculateProgress()
    }, [computeScore])

    return (
        <>
            <div className="Content-scroll-sticky">
                <div className="scroll-profile">
                    <main className="profile padding-inline">
                        <div>
                            <h1 className="m1">
                                {name} {surname}
                            </h1>
                            {/* SWITCH */}
                            {/* <div className='d-flex align-center'>
                <Switch
                    color='primary'
                    size='medium'
                    onChange={getValue}
                    className={classes.root}
                />
                <p className='available-text'>{availabilityState}</p>
            </div> */}
                            <CompleteFormCandidate
                                open={openPreview}
                                setOpen={setOpenPreview}
                                guid={candidate_guid}
                                exp_list={expList}
                                hs_list={hsList}
                                ss_list={ssList}
                                lang_list={languageList}
                                title_list={titleList}
                                cert_list={certList}
                            />

                            <BioCandidate
                                user={user}
                                userinfo={userInfo}
                                getUserInfo={getUser}
                                loading={appState}
                                progress={Math.round((progress / 29) * 100)}
                                progressChecks={progressChecks}
                                setOpenPreview={setOpenPreview}
                            />
                        </div>
                    </main>

                    <div className="tab-section profile">
                        <ul className="tab-menu d-flex space-between">
                            {appState ? (
                                <Slider {...settings}>
                                    <div>
                                        <Skeleton
                                            variant=""
                                            width="100%"
                                            height={50}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            variant="rect"
                                            width="100%"
                                            height={50}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            variant="rect"
                                            width="100%"
                                            height={50}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton
                                            variant="rect"
                                            width="100%"
                                            height={50}
                                        />
                                    </div>
                                </Slider>
                            ) : (
                                <Slider {...settings}>
                                    <TabNavItem
                                        title="ESPERIENZE LAVORATIVE"
                                        id="esperienze-professionali"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />

                                    <TabNavItem
                                        title="ISTRUZIONE E FORMAZIONE"
                                        id="istruzione-formazione"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="COMPETENZE TRASVERSALI"
                                        id="competenze-trasversali"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="COMPETENZE PROFESSIONALI"
                                        id="competenze-tecniche"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </Slider>
                            )}
                        </ul>
                        {appState ? (
                            <ul className="tab-menu-Mobile">
                                <div>
                                    <Skeleton
                                        variant="rect"
                                        width="100%"
                                        height={40}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="rect"
                                        width="100%"
                                        height={40}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="rect"
                                        width="100%"
                                        height={40}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        variant="rect"
                                        width="100%"
                                        height={40}
                                    />
                                </div>
                            </ul>
                        ) : (
                            <ul className="tab-menu-Mobile">
                                <TabNavItem
                                    title="ESPERIENZE PROFESSIONALI"
                                    id="esperienze-professionali"
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                                <TabNavItem
                                    title="ISTRUZIONE E FORMAZIONE"
                                    id="istruzione-formazione"
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                                <TabNavItem
                                    title="COMPETENZE TRASVERSALI"
                                    id="competenze-trasversali"
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                                <TabNavItem
                                    title="COMPETENZE PROFESSIONALI"
                                    id="competenze-tecniche"
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                            </ul>
                        )}

                        <div className="content-tab padding-inline">
                            <TabContent
                                id="competenze-tecniche"
                                activeTab={activeTab}
                            >
                                <LanguageSkills
                                    languagelist={languageList}
                                    candidateguid={candidate_guid}
                                    getLanguageList={getLanguageList}
                                    languageList={languageList}
                                    loading={appState}
                                />
                                <HardSkills
                                    hslist={hsList}
                                    candidateguid={candidate_guid}
                                    getHsList={gethardList}
                                    loading={appState}
                                />
                            </TabContent>
                            <TabContent
                                id="competenze-trasversali"
                                activeTab={activeTab}
                            >
                                <SoftSkills
                                    sslist={ssList}
                                    candidateguid={candidate_guid}
                                    getSsList={getsoftList}
                                    loading={appState}
                                />
                            </TabContent>
                            <TabContent
                                id="istruzione-formazione"
                                activeTab={activeTab}
                            >
                                <Istruzione
                                    titlelist={titleList}
                                    candidateguid={candidate_guid}
                                    getTitleList={gettitleList}
                                    loading={appState}
                                />
                                <Certification
                                    titlelist={certList}
                                    candidateguid={candidate_guid}
                                    getCertList={getcertList}
                                    loading={appState}
                                />
                            </TabContent>
                            <TabContent
                                id="esperienze-professionali"
                                activeTab={activeTab}
                            >
                                <WorkExperience
                                    explist={expList}
                                    candidateguid={candidate_guid}
                                    getExpList={getexpList}
                                    loading={appState}
                                />
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
