const TitleDataTree = [
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Antropologia Culturale ed Etnologia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/01_Antropologia Culturale ed Etnologia",
        "key": "LM/01"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Archeologia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/02_Archeologia",
        "key": "LM/02"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Architettura del Paesaggio",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/03_Architettura del Paesaggio",
        "key": "LM/03"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Architettura e Ingegneria Edile",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/04_Architettura e Ingegneria Edile",
        "key": "LM/04"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Archivistica e Biblioteconomia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/05_Archivistica e Biblioteconomia",
        "key": "LM/05"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Biologia",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/06_Biologia",
        "key": "LM/06"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Biotecnologie Agrarie",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/07_Biotecnologie Agrarie",
        "key": "LM/07"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Biotecnologie Industriali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/08_Biotecnologie Industriali",
        "key": "LM/08"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Biotecnologie Mediche, Veterinarie e Farmaceutiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/09_Biotecnologie Mediche, Veterinarie e Farmaceutiche",
        "key": "LM/09"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Conservazione dei Beni Architettonici e Ambientali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/10_Conservazione dei Beni Architettonici e Ambientali",
        "key": "LM/10"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Conservazione e Restauro dei Beni Culturali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/11_Conservazione e Restauro dei Beni Culturali",
        "key": "LM/11"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Design",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/12_Design",
        "key": "LM/12"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Farmacia e Farmacia Industriale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/13_Farmacia e Farmacia Industriale",
        "key": "LM/13"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Filologia Moderna",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/14_Filologia Moderna",
        "key": "LM/14"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Filologia, Letterature e Storia dell'Antichità",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/15_Filologia, Letterature e Storia dell'Antichità",
        "key": "LM/15"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Finanza",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/16_Finanza",
        "key": "LM/16"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Fisica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/17_Fisica",
        "key": "LM/17"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Informatica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/18_Informatica",
        "key": "LM/18"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Informazione e Sistemi Editoriali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/19_Informazione e Sistemi Editoriali",
        "key": "LM/19"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Aerospaziale e Astronautica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/20_Ingegneria Aerospaziale e Astronautica",
        "key": "LM/20"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Biomedica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/21_Ingegneria Biomedica",
        "key": "LM/21"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Chimica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/22_Ingegneria Chimica",
        "key": "LM/22"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Civile",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/23_Ingegneria Civile",
        "key": "LM/23"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria dei Sistemi Edilizi",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/24_Ingegneria dei Sistemi Edilizi",
        "key": "LM/24"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria dell'Automazione",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/25_Ingegneria dell'Automazione",
        "key": "LM/25"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria della Sicurezza",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/26_Ingegneria della Sicurezza",
        "key": "LM/26"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria delle Telecomunicazioni",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/27_Ingegneria delle Telecomunicazioni",
        "key": "LM/27"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Elettrica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/28_Ingegneria Elettrica",
        "key": "LM/28"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Elettronica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/29_Ingegneria Elettronica",
        "key": "LM/29"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Energetica e Nucleare",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/30_Ingegneria Energetica e Nucleare",
        "key": "LM/30"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Gestionale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/31_Ingegneria Gestionale",
        "key": "LM/31"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Informatica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/32_Ingegneria Informatica",
        "key": "LM/32"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Meccanica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/33_Ingegneria Meccanica",
        "key": "LM/33"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria Navale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/34_Ingegneria Navale",
        "key": "LM/34"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Ingegneria per l'Ambiente e il Territorio",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/35_Ingegneria per l'Ambiente e il Territorio",
        "key": "LM/35"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Lingue e Letterature dell'Africa e dell'Asia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/36_Lingue e Letterature dell'Africa e dell'Asia",
        "key": "LM/36"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Lingue e Letterature Moderne Europee e Americane",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/37_Lingue e Letterature Moderne Europee e Americane",
        "key": "LM/37"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Lingue Moderne per la Comunicazione e la Cooperazione Internazionale",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/38_Lingue Moderne per la Comunicazione e la Cooperazione Internazionale",
        "key": "LM/38"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Linguistica",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/39_Linguistica",
        "key": "LM/39"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Matematica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/40_Matematica",
        "key": "LM/40"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Medicina e Chirurgia",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/41_Medicina e Chirurgia",
        "key": "LM/41"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Medicina Veterinaria",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/42_Medicina Veterinaria",
        "key": "LM/42"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Metodologie Informatiche per le Discipline Umanistiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/43_Metodologie Informatiche per le Discipline Umanistiche",
        "key": "LM/43"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Modellistica Matematico-Fisica per l'Ingegneria",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/44_Modellistica Matematico-Fisica per l'Ingegneria",
        "key": "LM/44"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Musicologia e Beni Culturali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/45_Musicologia e Beni Culturali",
        "key": "LM/45"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Odontoiatria e Protesi Dentaria",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/46_Odontoiatria e Protesi Dentaria",
        "key": "LM/46"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Organizzazione e Gestione dei Servizi per lo Sport e le Attività Motorie",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/47_Organizzazione e Gestione dei Servizi per lo Sport e le Attività Motorie",
        "key": "LM/47"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Pianificazione Territoriale Urbanistica e Ambientale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/48_Pianificazione Territoriale Urbanistica e Ambientale",
        "key": "LM/48"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Progettazione e Gestione dei Sistemi Turistici",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/49_Progettazione e Gestione dei Sistemi Turistici",
        "key": "LM/49"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Programmazione e Gestione dei Servizi Educativi",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/50_Programmazione e Gestione dei Servizi Educativi",
        "key": "LM/50"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Psicologia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/51_Psicologia",
        "key": "LM/51"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Relazioni Internazionali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/52_Relazioni Internazionali",
        "key": "LM/52"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienza e Ingegneria dei Materiali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/53_Scienza e Ingegneria dei Materiali",
        "key": "LM/53"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Chimiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/54_Scienze Chimiche",
        "key": "LM/54"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Cognitive",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/55_Scienze Cognitive",
        "key": "LM/55"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze dell'Economia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/56_Scienze dell'Economia",
        "key": "LM/56"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze dell'Educazione degli Adulti e della Formazione Continua",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/57_Scienze dell'Educazione degli Adulti e della Formazione Continua",
        "key": "LM/57"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze dell'Universo",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/58_Scienze dell'Universo",
        "key": "LM/58"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze della Comunicazione Pubblica, d'Impresa e Pubblicità",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/59_Scienze della Comunicazione Pubblica, d'Impresa e Pubblicità",
        "key": "LM/59"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze della Natura",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/60_Scienze della Natura",
        "key": "LM/60"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze della Nutrizione Umana",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/61_Scienze della Nutrizione Umana",
        "key": "LM/61"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze della Politica",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/62_Scienze della Politica",
        "key": "LM/62"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze delle Pubbliche Amministrazioni",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/63_Scienze delle Pubbliche Amministrazioni",
        "key": "LM/63"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze delle Religioni",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/64_Scienze delle Religioni",
        "key": "LM/64"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze dello Spettacolo e Produzione Multimediale",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/65_Scienze dello Spettacolo e Produzione Multimediale",
        "key": "LM/65"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Sicurezza Informatica",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/66_Sicurezza Informatica",
        "key": "LM/66"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecniche delle Attività Motorie Preventive e Adattate",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/67_Scienze e Tecniche delle Attività Motorie Preventive e Adattate",
        "key": "LM/67"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecniche dello Sport",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/68_Scienze e Tecniche dello Sport",
        "key": "LM/68"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie Agrarie",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/69_Scienze e Tecnologie Agrarie",
        "key": "LM/69"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie Alimentari",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/70_Scienze e Tecnologie Alimentari",
        "key": "LM/70"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie della Chimica Industriale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/71_Scienze e Tecnologie della Chimica Industriale",
        "key": "LM/71"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie della Navigazione (80M)",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/72_Scienze e Tecnologie della Navigazione (80M)",
        "key": "LM/72"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie Forestali ed Ambientali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/73_Scienze e Tecnologie Forestali ed Ambientali",
        "key": "LM/73"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie Geologiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/74_Scienze e Tecnologie Geologiche",
        "key": "LM/74"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze e Tecnologie per l'Ambiente e il Territorio",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/75_Scienze e Tecnologie per l'Ambiente e il Territorio",
        "key": "LM/75"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Economiche per l'Ambiente e la Cultura",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/76_Scienze Economiche per l'Ambiente e la Cultura",
        "key": "LM/76"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Economico-Aziendali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/77_Scienze Economico-Aziendali",
        "key": "LM/77"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Filosofiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/78_Scienze Filosofiche",
        "key": "LM/78"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Geofisiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/79_Scienze Geofisiche",
        "key": "LM/79"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Geografiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/80_Scienze Geografiche",
        "key": "LM/80"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze per la Cooperazione allo Sviluppo",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/81_Scienze per la Cooperazione allo Sviluppo",
        "key": "LM/81"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Statistiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/82_Scienze Statistiche",
        "key": "LM/82"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Statistiche Attuariali e Finanziarie",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/83_Scienze Statistiche Attuariali e Finanziarie",
        "key": "LM/83"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Storiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/84_Scienze Storiche",
        "key": "LM/84"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Pedagogiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/85_Scienze Pedagogiche",
        "key": "LM/85"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Zootecniche e Tecnologie Animali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/86_Scienze Zootecniche e Tecnologie Animali",
        "key": "LM/86"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Servizio Sociale e Politiche Sociali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/87_Servizio Sociale e Politiche Sociali",
        "key": "LM/87"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Sociologia e Ricerca Sociale",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/88_Sociologia e Ricerca Sociale",
        "key": "LM/88"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Storia dell'Arte",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/89_Storia dell'Arte",
        "key": "LM/89"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Studi Europei",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/90_Studi Europei",
        "key": "LM/90"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Tecniche e Metodi per la Società dell'Informazione",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/91_Tecniche e Metodi per la Società dell'Informazione",
        "key": "LM/91"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Teorie della Comunicazione",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/92_Teorie della Comunicazione",
        "key": "LM/92"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Teorie e Metodologie dell'E-Learning e della Media Education",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/93_Teorie e Metodologie dell'E-Learning e della Media Education",
        "key": "LM/93"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Traduzione Specialistica e Interpretariato",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/94_Traduzione Specialistica e Interpretariato",
        "key": "LM/94"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze della Difesa e della Sicurezza",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/DS_Scienze della Difesa e della Sicurezza",
        "key": "LM/DS"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Criminologiche Applicate all'Investigazione e alla Sicurezza",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/SC_Scienze Criminologiche Applicate all'Investigazione e alla Sicurezza",
        "key": "LM/SC"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Giuridiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/SC-GIUR_Scienze Giuridiche",
        "key": "LM/SC-GIUR"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze Infermieristiche e Ostetriche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/SNT01_Scienze Infermieristiche e Ostetriche",
        "key": "LM/SNT01"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze delle Professioni Sanitarie della Riabilitazione",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LM/SNT02_Scienze delle Professioni Sanitarie della Riabilitazione",
        "key": "LM/SNT02"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze delle Professioni Sanitarie Tecniche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/SNT03_Scienze delle Professioni Sanitarie Tecniche",
        "key": "LM/SNT03"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Scienze delle Professioni Sanitarie della Prevenzione",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "LM/SNT04_Scienze delle Professioni Sanitarie della Prevenzione",
        "key": "LM/SNT04"
    },
    {
        "checked": false,
        "eqf": [5, 6, 7, 8],
        "label": "Giurisprudenza",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "LMG/01_Giurisprudenza",
        "key": "LMG/01"
    },
    {
        "checked": false,
        "key": "L/01",
        "label": "Beni Culturali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/01_Beni Culturali",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/02",
        "label": "Discipline relative a: Biotecnologie",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/02_Discipline relative a: Biotecnologie",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/03",
        "label": "Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/03_Discipline delle Arti Figurative, della Musica, dello Spettacolo e della Moda",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/04",
        "label": "Discipline relative a: Disegno Industriale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/04_Discipline relative a: Disegno Industriale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/05",
        "label": "Filosofia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/05_Filosofia",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/06",
        "label": "Geografia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/06_Geografia",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/07",
        "label": "Discipline relative a: Ingegneria Civile e Ambientale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/07_Discipline relative a: Ingegneria Civile e Ambientale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/08",
        "label": "Discipline relative a: Ingegneria dell’Informazione",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/08_Discipline relative a: Ingegneria dell’Informazione",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/09",
        "label": "Discipline relative a: Ingegneria Industriale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/09_Discipline relative a: Ingegneria Industriale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/10",
        "label": "Lettere",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/10_Lettere",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/11",
        "label": "Lingue e Culture Moderne",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/11_Lingue e Culture Moderne",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/12",
        "label": "Mediazione Linguistica",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/12_Mediazione Linguistica",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/13",
        "label": "Discipline relative a: Scienze Biologiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/13_Discipline relative a: Scienze Biologiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/14",
        "label": "Scienze dei Servizi Giuridici",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/14_Scienze dei Servizi Giuridici",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/15",
        "label": "Scienze del Turismo",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/15_Scienze del Turismo",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/16",
        "label": "Scienze dell’Amministrazione e dell’Organizzazione",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/16_Scienze dell’Amministrazione e dell’Organizzazione",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/17",
        "label": "Discipline relative a: Scienze dell’Architettura",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/17_Discipline relative a: Scienze dell’Architettura",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/18",
        "label": "Scienze dell’Economia e della Gestione Aziendale",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/18_Scienze dell’Economia e della Gestione Aziendale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/19",
        "label": "Scienze dell’Educazione e della Formazione",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/19_Scienze dell’Educazione e della Formazione",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/20",
        "label": "Scienze della Comunicazione",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/20_Scienze della Comunicazione",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/21",
        "label": "Discipline relative a: Scienze della Pianificazione Territoriale, Urbanistica, Paesaggistica e Ambientale",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/21_Discipline relative a: Scienze della Pianificazione Territoriale, Urbanistica, Paesaggistica e Ambientale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/22",
        "label": "Discipline relative a: Scienze delle Attività Motorie e Sportive",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/22_Discipline relative a: Scienze delle Attività Motorie e Sportive",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/23",
        "label": "Discipline relative a: Scienze e Tecniche dell’Edilizia",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/23_Discipline relative a: Scienze e Tecniche dell’Edilizia",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/24",
        "label": "Scienze e Tecniche Psicologiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/24_Scienze e Tecniche Psicologiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/25",
        "label": "Discipline relative a: Scienze e Tecnologie Agrarie e Forestali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/25_Discipline relative a: Scienze e Tecnologie Agrarie e Forestali",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/26",
        "label": "Discipline relative a: Scienze e Tecnologie Agro-Alimentari",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/26_Discipline relative a: Scienze e Tecnologie Agro-Alimentari",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/27",
        "label": "Discipline relative a: Scienze e Tecnologie Chimiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/27_Discipline relative a: Scienze e Tecnologie Chimiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/28",
        "label": "Discipline relative a: Scienze e Tecnologie della Navigazione",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/28_Discipline relative a: Scienze e Tecnologie della Navigazione",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/29",
        "label": "Discipline relative a: Scienze e Tecnologie Farmaceutiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/29_Discipline relative a: Scienze e Tecnologie Farmaceutiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/30",
        "label": "Discipline relative a: Scienze e Tecnologie Fisiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/30_Discipline relative a: Scienze e Tecnologie Fisiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/31",
        "label": "Discipline relative a: Scienze e Tecnologie Informatiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/31_Discipline relative a: Scienze e Tecnologie Informatiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/32",
        "label": "Discipline relative a: Scienze e Tecnologie per l’Ambiente e la Natura",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/32_Discipline relative a: Scienze e Tecnologie per l’Ambiente e la Natura",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/33",
        "label": "Scienze Economiche",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/33_Scienze Economiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/34",
        "label": "Discipline relative a: Scienze Geologiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/34_Discipline relative a: Scienze Geologiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/35",
        "label": "Discipline relative a: Scienze Matematiche",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/35_Discipline relative a: Scienze Matematiche",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/36",
        "label": "Scienze Politiche e delle Relazioni Internazionali",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/36_Scienze Politiche e delle Relazioni Internazionali",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/37",
        "label": "Scienze Sociali per la Cooperazione, lo Sviluppo e la Pace",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/37_Scienze Sociali per la Cooperazione, lo Sviluppo e la Pace",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/38",
        "label": "Discipline relative a: Scienze Zootecniche e Tecnologie delle Produzioni Animali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/38_Discipline relative a: Scienze Zootecniche e Tecnologie delle Produzioni Animali",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/39",
        "label": "Servizio Sociale",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/39_Servizio Sociale",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/40",
        "label": "Sociologia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/40_Sociologia",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/41",
        "label": "Statistica",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/41_Statistica",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/42",
        "label": "Storia",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "L/42_Storia",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "L/43",
        "label": "Discipline relative a: Tecnologie per la Conservazione e il Restauro dei Beni Culturali",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "L/43_Discipline relative a: Tecnologie per la Conservazione e il Restauro dei Beni Culturali",
        "eqf": [5, 6, 7, 8]
    },
    {
        "checked": false,
        "key": "D/01",
        "label": "LICEO CLASSICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/01_LICEO CLASSICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/02",
        "label": "LICEO SCIENTIFICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/02_LICEO SCIENTIFICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/03",
        "label": "LICEO LINGUISTICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/03_LICEO LINGUISTICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/04",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO PEDAGOGICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/04_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO PEDAGOGICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/05",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SOCIO-PSICO-PEDAGOGICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/05_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SOCIO-PSICO-PEDAGOGICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/06",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE UMANE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/06_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE UMANE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/07",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE SOCIALI",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/07_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE SOCIALI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/08",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE DELL’EDUCAZIONE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/08_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE DELL’EDUCAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/09",
        "label": "ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE DELLA FORMAZIONE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/09_ISTITUTO DI ISTRUZIONE SUPERIORE - INDIRIZZO SCIENZE DELLA FORMAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/10",
        "label": "LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/10_LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/11",
        "label": "LICEO ARTISTICO - INDIRIZZO FIGURATIVO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/11_LICEO ARTISTICO - INDIRIZZO FIGURATIVO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/12",
        "label": "LICEO ARTISTICO - INDIRIZZO GRAFICO VISIVO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/12_LICEO ARTISTICO - INDIRIZZO GRAFICO VISIVO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/13",
        "label": "LICEO ARTISTICO - INDIRIZZO PITTURA E DECORAZIONE PITTORICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/13_LICEO ARTISTICO - INDIRIZZO PITTURA E DECORAZIONE PITTORICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/14",
        "label": "LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI CULTURA E DECORAZIONE PLASTICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/14_LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI CULTURA E DECORAZIONE PLASTICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/15",
        "label": "LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI RILIEVO E CATALOGAZIONE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/15_LICEO ARTISTICO - INDIRIZZO CATALOGAZIONE E CONSERVAZIONE DEI BENI CULTURALI RILIEVO E CATALOGAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/16",
        "label": "LICEO ARTISTICO - INDIRIZZO ARTI E COMUNICAZIONE VISIVA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/16_LICEO ARTISTICO - INDIRIZZO ARTI E COMUNICAZIONE VISIVA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/17",
        "label": "LICEO ARTISTICO - INDIRIZZO BENI CULTURALI E DELLA CONSERVAZIONE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/17_LICEO ARTISTICO - INDIRIZZO BENI CULTURALI E DELLA CONSERVAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/18",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE PUBBLICITARIA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/18_ISTITUTO D’ARTE INDIRIZZO - ARTE PUBBLICITARIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/19",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA GRAFICA PUBBLICITARIA E FOTOGRAFIA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/19_ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA GRAFICA PUBBLICITARIA E FOTOGRAFIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/20",
        "label": "ISTITUTO D’ARTE INDIRIZZO - FOTOGRAFIA ARTISTICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/20_ISTITUTO D’ARTE INDIRIZZO - FOTOGRAFIA ARTISTICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/21",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTI GRAFICHE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/21_ISTITUTO D’ARTE INDIRIZZO - ARTI GRAFICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/22",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA STAMPA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/22_ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA STAMPA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/23",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DECORAZIONE PITTORICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/23_ISTITUTO D’ARTE INDIRIZZO - DECORAZIONE PITTORICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/24",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DECORAZIONE PLASTICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/24_ISTITUTO D’ARTE INDIRIZZO - DECORAZIONE PLASTICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/25",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DEL MOSAICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/25_ISTITUTO D’ARTE INDIRIZZO - ARTE DEL MOSAICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/26",
        "label": "ISTITUTO D’ARTE INDIRIZZO - GRAFICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/26_ISTITUTO D’ARTE INDIRIZZO - GRAFICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/27",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLE OPERE PITTORICHE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/27_ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLE OPERE PITTORICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/28",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLE OPERE LAPIDEE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/28_ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLE OPERE LAPIDEE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/29",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARCHITETTURA E ARREDO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/29_ISTITUTO D’ARTE INDIRIZZO - ARCHITETTURA E ARREDO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/30",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARCHITETTURA E DESIGN",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/30_ISTITUTO D’ARTE INDIRIZZO - ARCHITETTURA E DESIGN",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/31",
        "label": "ISTITUTO D’ARTE INDIRIZZO - COMPOSIZIONE E PROGETTAZIONE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/31_ISTITUTO D’ARTE INDIRIZZO - COMPOSIZIONE E PROGETTAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/32",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DISEGNATORI DI ARCHITETTURA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/32_ISTITUTO D’ARTE INDIRIZZO - DISEGNATORI DI ARCHITETTURA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/33",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DISEGNATORI DI ARCHITETTURA E ARREDAMENTO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/33_ISTITUTO D’ARTE INDIRIZZO - DISEGNATORI DI ARCHITETTURA E ARREDAMENTO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/34",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARREDAMENTO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/34_ISTITUTO D’ARTE INDIRIZZO - ARREDAMENTO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/35",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DEL MOBILE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/35_ISTITUTO D’ARTE INDIRIZZO - ARTE DEL MOBILE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/36",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA CERAMICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/36_ISTITUTO D’ARTE INDIRIZZO - ARTE DELLA CERAMICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/37",
        "label": "ISTITUTO D’ARTE INDIRIZZO - TECNOLOGIA CERAMICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/37_ISTITUTO D’ARTE INDIRIZZO - TECNOLOGIA CERAMICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/38",
        "label": "ISTITUTO D’ARTE INDIRIZZO - OREFICERIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/38_ISTITUTO D’ARTE INDIRIZZO - OREFICERIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/39",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE DEI METALLI E OREFICERIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/39_ISTITUTO D’ARTE INDIRIZZO - ARTE DEI METALLI E OREFICERIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/40",
        "label": "ISTITUTO D’ARTE INDIRIZZO - MODA E COSTUME",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/40_ISTITUTO D’ARTE INDIRIZZO - MODA E COSTUME",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/41",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DISEGNO INDUSTRIALE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/41_ISTITUTO D’ARTE INDIRIZZO - DISEGNO INDUSTRIALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/42",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLA CERAMICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/42_ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELLA CERAMICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/43",
        "label": "ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELL’ORO E METALLI PREZIOSI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/43_ISTITUTO D’ARTE INDIRIZZO - ARTE E RESTAURO DELL’ORO E METALLI PREZIOSI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/44",
        "label": "ISTITUTO D’ARTE INDIRIZZO - DISEGNO ANIMATO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/44_ISTITUTO D’ARTE INDIRIZZO - DISEGNO ANIMATO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/45",
        "label": "ISTITUTO D’ARTE INDIRIZZO - SCENOTECNICA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/45_ISTITUTO D’ARTE INDIRIZZO - SCENOTECNICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/46",
        "label": "ISTITUTO D’ARTE INDIRIZZO - IMMAGINE FOTOGRAFICA, FILMICA, TELEVISIVA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/46_ISTITUTO D’ARTE INDIRIZZO - IMMAGINE FOTOGRAFICA, FILMICA, TELEVISIVA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/47",
        "label": "ISTITUTO TECNICO COMMERCIALE - INDIRIZZO RAGIONIERI PROGRAMMATORI",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/47_ISTITUTO TECNICO COMMERCIALE - INDIRIZZO RAGIONIERI PROGRAMMATORI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/48",
        "label": "ISTITUTO TECNICO COMMERCIALE - INDIRIZZO PERITI AZIENDALI E CORRISPONDENTI LINGUA ESTERA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/48_ISTITUTO TECNICO COMMERCIALE - INDIRIZZO PERITI AZIENDALI E CORRISPONDENTI LINGUA ESTERA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/49",
        "label": "ISTITUTO TECNICO COMMERCIALE - INDIRIZZO GIURIDICO ECONOMICO AZIENDALE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/49_ISTITUTO TECNICO COMMERCIALE - INDIRIZZO GIURIDICO ECONOMICO AZIENDALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/50",
        "label": "ISTITUTO TECNICO COMMERCIALE - INDIRIZZO SPERIMENTAZIONI AD INDIRIZZO GIURIDICO ECONOMICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/50_ISTITUTO TECNICO COMMERCIALE - INDIRIZZO SPERIMENTAZIONI AD INDIRIZZO GIURIDICO ECONOMICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/51",
        "label": "ISTITUTO TECNICO PER IL TURISMO - INDIRIZZO TURISTICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/51_ISTITUTO TECNICO PER IL TURISMO - INDIRIZZO TURISTICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/52",
        "label": "ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO GENERALE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/52_ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO GENERALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/53",
        "label": "ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO DIRIGENTI DI COMUNITÀ",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/53_ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO DIRIGENTI DI COMUNITÀ",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/54",
        "label": "ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO ECONOMO-DIETISTE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/54_ISTITUTO TECNICO PER LE ATTIVITÀ SOCIALI - INDIRIZZO ECONOMO-DIETISTE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/55",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO MECCANICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/55_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO MECCANICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/56",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TERMOTECNICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/56_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TERMOTECNICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/57",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO MATERIE PLASTICHE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/57_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO MATERIE PLASTICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/58",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO COSTRUZIONI AERONAUTICHE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/58_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO COSTRUZIONI AERONAUTICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/59",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA NAVALMECCANICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/59_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA NAVALMECCANICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/60",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA CARTARIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/60_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA CARTARIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/61",
        "label": "ISTITUTO TECNICO NAUTICO - INDIRIZZO COSTRUZIONI NAVALI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/61_ISTITUTO TECNICO NAUTICO - INDIRIZZO COSTRUZIONI NAVALI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/62",
        "label": "ISTITUTO TECNICO NAUTICO - INDIRIZZO MACCHINISTI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/62_ISTITUTO TECNICO NAUTICO - INDIRIZZO MACCHINISTI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/63",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ELETTROTECNICA ED AUTOMAZIONE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/63_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ELETTROTECNICA ED AUTOMAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/64",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ELETTRONICA E TELECOMUNICAZIONE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/64_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ELETTRONICA E TELECOMUNICAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/65",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ENERGIA NUCLEARE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/65_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ENERGIA NUCLEARE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/66",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO FISICA INDUSTRIALE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/66_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO FISICA INDUSTRIALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/67",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA OTTICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/67_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA OTTICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/68",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INFORMATICA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/68_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INFORMATICA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/69",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ARTI GRAFICHE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/69_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ARTI GRAFICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/70",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ARTI FOTOGRAFICHE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/70_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO ARTI FOTOGRAFICHE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/71",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO CHIMICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/71_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO CHIMICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/72",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TECNOLOGIE ALIMENTARI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/72_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TECNOLOGIE ALIMENTARI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/73",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA TINTORIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/73_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA TINTORIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/74",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO CHIMICA CONCIARIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/74_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO CHIMICA CONCIARIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/75",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO METALLURGIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/75_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO METALLURGIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/76",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA MINERARIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/76_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO INDUSTRIA MINERARIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/77",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TESSILE CON SPECIALIZZAZIONE CONFEZIONE INDUSTRIALE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/77_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TESSILE CON SPECIALIZZAZIONE CONFEZIONE INDUSTRIALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/78",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TESSILE CON SPECIALIZZAZIONE PRODUZIONI TESSILI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/78_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO TESSILE CON SPECIALIZZAZIONE PRODUZIONI TESSILI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/79",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO DISEGNO DI TESSUTI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/79_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO DISEGNO DI TESSUTI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/80",
        "label": "ISTITUTO TECNICO AGRARIO - INDIRIZZO AGRARIO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/80_ISTITUTO TECNICO AGRARIO - INDIRIZZO AGRARIO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/81",
        "label": "ISTITUTO TECNICO AGRARIO - INDIRIZZO VITICOLTURA ED ENOLOGIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/81_ISTITUTO TECNICO AGRARIO - INDIRIZZO VITICOLTURA ED ENOLOGIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/82",
        "label": "ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO EDILIZIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/82_ISTITUTO TECNICO INDUSTRIALE - INDIRIZZO EDILIZIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/83",
        "label": "ISTITUTO TECNICO PER GEOMETRI - INDIRIZZO GEOMETRI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/83_ISTITUTO TECNICO PER GEOMETRI - INDIRIZZO GEOMETRI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/84",
        "label": "ISTITUTO TECNICO AERONAUTICO - INDIRIZZO ASSISTENZA ALLA NAVIGAZIONE AEREA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/84_ISTITUTO TECNICO AERONAUTICO - INDIRIZZO ASSISTENZA ALLA NAVIGAZIONE AEREA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/85",
        "label": "ISTITUTO TECNICO AERONAUTICO - INDIRIZZO NAVIGAZIONE AEREA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/85_ISTITUTO TECNICO AERONAUTICO - INDIRIZZO NAVIGAZIONE AEREA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/86",
        "label": "ISTITUTO TECNICO NAUTICO - INDIRIZZO CAPITANI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/86_ISTITUTO TECNICO NAUTICO - INDIRIZZO CAPITANI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/87",
        "label": "LICEO ARTISTICO - INDIRIZZO ARCHITETTURA, DESIGN E AMBIENTE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/87_LICEO ARTISTICO - INDIRIZZO ARCHITETTURA, DESIGN E AMBIENTE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/88",
        "label": "LICEO ARTISTICO - INDIRIZZO ARTI FIGURATIVE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/88_LICEO ARTISTICO - INDIRIZZO ARTI FIGURATIVE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/89",
        "label": "LICEO ARTISTICO - INDIRIZZO AUDIOVISIVO MULTIMEDIA SCENOGRAFIA",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/89_LICEO ARTISTICO - INDIRIZZO AUDIOVISIVO MULTIMEDIA SCENOGRAFIA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/90",
        "label": "LICEO DELLE SCIENZE UMANE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/90_LICEO DELLE SCIENZE UMANE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/91",
        "label": "LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/91_LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/92",
        "label": "LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE AGRO-ALIMENTARE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/92_LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE AGRO-ALIMENTARE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/93",
        "label": "LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE DEI SERVIZI",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/93_LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE DEI SERVIZI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/94",
        "label": "LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE DEL TURISMO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/94_LICEO ECONOMICO - INDIRIZZO ECONOMICO AZIENDALE: SETTORE DEL TURISMO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/95",
        "label": "LICEO ECONOMICO - INDIRIZZO ECONOMICO ISTITUZIONALE",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/95_LICEO ECONOMICO - INDIRIZZO ECONOMICO ISTITUZIONALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/96",
        "label": "LICEO TECNOLOGICO - INDIRIZZO CHIMICO E MATERIALI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/96_LICEO TECNOLOGICO - INDIRIZZO CHIMICO E MATERIALI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/97",
        "label": "LICEO TECNOLOGICO - INDIRIZZO COSTRUZIONI, AMBIENTE E TERRITORIO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/97_LICEO TECNOLOGICO - INDIRIZZO COSTRUZIONI, AMBIENTE E TERRITORIO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/98",
        "label": "LICEO TECNOLOGICO - INDIRIZZO ELETTRICO - ELETRONICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/98_LICEO TECNOLOGICO - INDIRIZZO ELETTRICO - ELETRONICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/99",
        "label": "LICEO TECNOLOGICO - INDIRIZZO INFORMATICO, GRAFICO E COMUNICAZIONE: PERCORSO GRAFICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/99_LICEO TECNOLOGICO - INDIRIZZO INFORMATICO, GRAFICO E COMUNICAZIONE: PERCORSO GRAFICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/100",
        "label": "LICEO TECNOLOGICO - INDIRIZZO INFORMATICO, GRAFICO E COMUNICAZIONE: PERCORSO INFORMATICO E COMUNICAZIONE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/100_LICEO TECNOLOGICO - INDIRIZZO INFORMATICO, GRAFICO E COMUNICAZIONE: PERCORSO INFORMATICO E COMUNICAZIONE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/101",
        "label": "LICEO TECNOLOGICO - INDIRIZZO LOGISTICA E TRASPORTI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/101_LICEO TECNOLOGICO - INDIRIZZO LOGISTICA E TRASPORTI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/102",
        "label": "LICEO TECNOLOGICO - INDIRIZZO MECCANICO - MECCATRONICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/102_LICEO TECNOLOGICO - INDIRIZZO MECCANICO - MECCATRONICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/103",
        "label": "LICEO TECNOLOGICO - INDIRIZZO PRODUZIONI BIOLOGICHE E BIOTECNOLOGIE ALIMENTARI",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/103_LICEO TECNOLOGICO - INDIRIZZO PRODUZIONI BIOLOGICHE E BIOTECNOLOGIE ALIMENTARI",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/104",
        "label": "LICEO TECNOLOGICO - INDIRIZZO TECNOLOGIE TESSILI, DELL’ABBIGLIAMENTO E DELLA MODA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/104_LICEO TECNOLOGICO - INDIRIZZO TECNOLOGIE TESSILI, DELL’ABBIGLIAMENTO E DELLA MODA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/105",
        "label": "CONSERVATORIO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/105_CONSERVATORIO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/106",
        "label": "LICEO MUSICALE E COREUTICO",
        "parent_label": "AHSS",
        "parent_value": "01_AHSS",
        "parent_key": "01",
        "value": "D/106_LICEO MUSICALE E COREUTICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/107",
        "label": "LICEO SPORTIVO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/107_LICEO SPORTIVO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/108",
        "label": "ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - ODONTOTECNICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/108_ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - ODONTOTECNICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/109",
        "label": "ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - OTTICO",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/109_ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - OTTICO",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/110",
        "label": "ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - PROFESSIONI SANITARIE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/110_ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI SOCIOSANITARI - PROFESSIONI SANITARIE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/111",
        "label": "ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI PER L’ENOGASTRONOMIA E L’OSPITALITA’ ALBERGHIERA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/111_ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI PER L’ENOGASTRONOMIA E L’OSPITALITA’ ALBERGHIERA",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/112",
        "label": "ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI PER L’AGRICOLTURA E LO SVILUPPO RURALE",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/112_ISTITUTO PROFESSIONALE - INDIRIZZO SERVIZI PER L’AGRICOLTURA E LO SVILUPPO RURALE",
        "eqf": [4]
    },
    {
        "checked": false,
        "key": "D/113",
        "label": "ISTITUTO TECNICO - INDIRIZZO AGRARIA, AGROALIMENTARE E AGROINDUSTRIA",
        "parent_label": "STEM",
        "parent_value": "00_STEM",
        "parent_key": "00",
        "value": "D/113_ISTITUTO TECNICO - INDIRIZZO AGRARIA, AGROALIMENTARE E AGROINDUSTRIA",
        "eqf": [4]
    }
]


export default TitleDataTree