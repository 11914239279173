import React, { useState, useEffect } from 'react'
import NavBarDesktop from './desktopnavtutorial'
import NavBarMobile from './mobilenavtutorial'

const NavbarContainer = () => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024)
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <div>{isMobile ? <NavBarMobile /> : <NavBarDesktop />}</div>
}

export default NavbarContainer
