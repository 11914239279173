import React, { useState, useEffect } from 'react'
import PrevIcon from 'app/components/Icons/PrevIcon'
import history from 'history.js'
import { CircularProgress } from '@material-ui/core'

function OnboardingButton({
    stepOnboarding,
    setStepOnboarding,
    submit,
    setSubmit,
}) {
    const [loading, setLoading] = useState(false)

    const backPrevPage = (e) => {
        setSubmit(false)
        if (stepOnboarding === 0) history.push('/choose-account')
        else setStepOnboarding((currStep) => currStep - 1)
    }

    const handleContinue = async (e) => {
        e.preventDefault()
        // setLoading(true)
        setSubmit(true)

        // try {
        //     await new Promise((resolve) => setTimeout(resolve, 10000))
        // } catch (error) {
        //     console.error(error)
        // } finally {
        //     setLoading(false)
        //     setSubmit(false)
        // }
    }

    useEffect(() => {
        setLoading(submit)
    }, [submit])

    return (
        <>
            <div className="onboarding-actions">
                <button className="prev-btn" onClick={backPrevPage}>
                    <PrevIcon />
                </button>
                <div>
                    <button
                        className="next-button primary-btn btn"
                        onClick={handleContinue}
                        disabled={loading}
                        style={{ position: 'relative' }}
                    >
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    left: '10%',
                                }}
                                color="inherit"
                                size={25}
                            />
                        )}
                        Prosegui
                    </button>
                </div>
            </div>
        </>
    )
}

export default OnboardingButton
