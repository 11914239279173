import React, { useState, useLayoutEffect, useEffect } from 'react'
import OnboardingEducation from './OnboardingEducation'
import OnboardingWorkExp from './OnboardingWorkExp'
import OnboardingSoftSkills from './OnboardingSoftSkills'
import OnboardingLang from './OnboardingLang'
// import OnboardingHardSkills from './OnboardingHardSkills';
import OnboardingCompleted from './OnboardingCompleted'
import FormTitles from '../../Tables/cand_form_titles'

function Onboarding() {
    const [stepOnboarding, setStepOnboarding] = useState(0)
    const [width, setWidth] = useState('0%')

    const [skipDetails, setSkipDetails] = useState({
        skip_titles: false,
        skip_work: false,
    })

    useEffect(() => {
        const stepSize = 1
        setWidth(
            `${(100 / (FormTitles.length - 2)) * stepSize * stepOnboarding}%`
        )
    }, [stepOnboarding])

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [stepOnboarding])

    return (
        <>
            <div
                className="onboarding-title"
                /*  hidden={FormTitles[stepOnboarding] === ''}*/
            >
                <h1 style={{ marginTop: '1.3rem' }}>
                    {FormTitles[stepOnboarding]}
                </h1>
                <div className="progressbar">
                    <div
                        style={{ width: width, transition: 'width 0.5s ease' }}
                    ></div>
                </div>
            </div>

            {(() => {
                switch (stepOnboarding) {
                    case 0:
                        return (
                            <>
                                <OnboardingSoftSkills
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                />
                            </>
                        )
                    case 1:
                        return (
                            <>
                                <OnboardingEducation
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    skipDetails={skipDetails}
                                    setSkipDetails={setSkipDetails}
                                />
                            </>
                        )
                    case 2:
                        return (
                            <>
                                <OnboardingWorkExp
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    skipDetails={skipDetails}
                                    setSkipDetails={setSkipDetails}
                                />
                            </>
                        )
                    case 3:
                        return (
                            <>
                                <OnboardingLang
                                    stepOnboarding={stepOnboarding}
                                    setStepOnboarding={setStepOnboarding}
                                    skipDetails={skipDetails}
                                />
                            </>
                        )
                    // case 7:
                    //     return (
                    //         <>
                    //             <OnboardingHardSkills stepOnboarding={stepOnboarding} setStepOnboarding={setStepOnboarding} stepOnboardingHardSkills={stepOnboardingHardSkills} setStepOnboardingHardSkills={setStepOnboardingHardSkills}
                    //                 skipDetails={skipDetails}
                    //                 setStepPreviousSection={setStepOnboardingWorkExp}
                    //                 FormTitles={FormTitles}
                    //             />
                    //         </>
                    //     )
                    case 4:
                        return <OnboardingCompleted />
                }
            })()}
        </>
    )
}

export default Onboarding
