import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import history from 'history.js'
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import DateFnsUtils from '@date-io/date-fns'
import AsyncAutocomplete from 'app/components/Autocomplete/AsyncAutocomplete'
import { styled } from '@mui/material/styles'

const patenteData = [
    'Auto/Moto',
    'Camion',
    'Rimorchio',
    'Autobus',
    'Muletto',
    'Gru',
    'Nautica',
]

function JobCardEmpty({ getOffer, offerGuid = null }) {
    const [isSubmit, setSubmit] = useState(false)
    const { user } = useAuth()
    const initOffer = {
        recruiter_guid: user?.uid || '',
        offer_guid: '',
        company_name: '',
        job_name: '',
        sector: [],
        location: '',
        driving_license: [],
        job_type: '',
        job_contract: '',
        protected_category: 'false',
        job_percentage: 100,
        isced: '',
        ral: 0,
        ral_type: true,
        photo_url: user?.photoURL || '',
        description: '',
        hs_weight: 15,
        ss_weight: 15,
        title_weight: 30,
        exp_weight: 40,
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)
    const {
        recruiter_guid,
        offer_guid,
        company_name,
        job_name,
        sector,
        location,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        job_percentage,
        isced,
        ral,
        ral_type,
        photo_url,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        created,
        lastUpdate,
        start_job_date,
    } = offerInfo

    const CustomInputLabel = styled(InputLabel)(({ theme }) => ({}))

    const setGeoLocationInfo = (place, geolocation) => {
        setOfferInfo({
            ...offerInfo,
            location: place,
            geolocation: geolocation,
        })
    }

    const onChange = (event) => {
        event.preventDefault()
        setOfferInfo({
            ...offerInfo,
            [event.target.name]: event.target.value,
        })
        // console.log(offerInfo)
    }

    const handleAddOffer = async () => {
        debugger
        const res = await ApiService.create_offer(offerInfo)
        if (res.ok) {
            getOffer()
            setOfferInfo(initOffer)
        }
        // handleClose()
    }

    const handleDateChange = (date) => {
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }

        setOfferInfo({ ...offerInfo, start_job_date: date })
    }

    const goback = () => {
        try {
            history.push('/profile-recruiter')
        } catch (e) {
            console.log(e)
        }
    }

    const validate = () => {
        const errors = {}

        if (!job_name) errors.job_name = 'Questo campo non può essere nullo'
        if (!location) errors.location = 'Questo campo non può essere nullo'
        if (!job_contract)
            errors.job_contract = 'Questo campo non può essere nullo'
        if (!job_type) errors.job_type = 'Questo campo non può essere nullo'
        if (!ral) errors.ral = 'Questo campo non può essere nullo'
        if (start_job_date && start_job_date < new Date())
            errors.start_job_date =
                'La data non può essere precedente al giorno corrente'
        // if (!start_job_date) errors.start_job_date = "Questo campo non può essere nullo"
        if (
            start_job_date &&
            new Date(start_job_date).toString() === 'Invalid Date'
        )
            errors.start_job_date = 'Inserisci una data valida'
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            // setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleAddOffer()
            }
            setSubmit(false)
        }
    }, [isSubmit, offerInfo])

    useEffect(() => {
        if (user)
            setOfferInfo({
                ...offerInfo,
                recruiter_guid: user?.uid,
                photo_url: user?.photoURL,
            })
    }, [user])

    // useEffect(() => {
    //     setOfferInfo({ ...offerInfo, company_name: companyName })
    // }, [companyName])

    useEffect(() => {
        setOfferInfo({ ...offerInfo, offer_guid: offerGuid })
    }, [offerGuid])

    console.log(offerInfo)
    // useEffect(() => {
    //     if (offerInfo) {
    //         console.log(userInfo)
    //         setOfferInfo({
    //             ...offerInfo,
    //             recruiter_guid: userInfo?.recruiter_guid,
    //             company_name: userInfo?.company_name,
    //             photo_url: userInfo?.photo_url,
    //             sector: userInfo?.sector ?? [],
    //         })
    //     }
    //     console.log(offerInfo)
    // }, [userInfo])

    return (
        <>
            <div className="link2profile">
                <button onClick={goback}>
                    {' '}
                    <KeyboardBackspaceIcon />{' '}
                </button>
                <p>torna al tuo profilo</p>
            </div>
            <div className="job-general-info offer">
                <div
                    className="dropdown-form m1 mt2"
                    style={{ background: '#fff' }}
                >
                    <div className="d-flex align-center">
                        <h3>Quale figura stai cercando?</h3>
                        {/* <button
                            onClick={handleDialogClose}
                            className="close-card"
                        >
                            <CloseIcon />
                        </button> */}
                    </div>

                    <div>
                        <AsyncAutocomplete
                            parentValue={job_name}
                            onChange={(newValue) =>
                                setOfferInfo({
                                    ...offerInfo,
                                    job_name: newValue,
                                })
                            }
                            label="Nome del ruolo (es. cuoco, operaio, impiegato amministrativo..)"
                            apiRequest={ApiService.get_joblist}
                            required
                        />
                        {/* {formErrors.job_name && (
                            <p className="input-error">{formErrors.job_name}</p>
                        )} */}
                    </div>

                    <h3>Cosa offre questo lavoro</h3>
                    <div
                        className="dialog-layout m1 profilerec"
                        style={{ width: '100%' }}
                    >
                        <div>
                            <PlaceAutocomplete
                                setSetlected={setGeoLocationInfo}
                                defaultValue={location}
                                label={'Luogo del lavoro'}
                                onChange={onChange}
                            />
                            {/* {formErrors.location && (
                                <p className="input-error">
                                    {formErrors.location}
                                </p>
                            )} */}
                        </div>
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Tipologia di lavoro
                                </InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={job_type}
                                    onChange={onChange}
                                    inputProps={{
                                        name: 'job_type',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />
                                    <option value={'In sede'}>In sede</option>
                                    <option value={'Ibrido'}>Ibrido</option>
                                    <option value={'Remoto'}>Remoto</option>
                                </Select>
                            </FormControl>
                            {/* {formErrors.job_type && (
                                <p className="input-error">
                                    {formErrors.job_type}
                                </p>
                            )} */}
                        </div>
                        <div>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={itLocale}
                            >
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Data di inizio desiderata"
                                    format="dd/MM/yyyy"
                                    value={start_job_date || null}
                                    autoOk
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    fullWidth
                                    disablePast
                                    invalidDateMessage=""
                                    maxDateMessage=""
                                    minDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                            {/* <p className="input-error">
                                {formErrors.start_job_date}
                            </p> */}
                        </div>
                        <FormControl variant="filled" fullWidth>
                            <CustomInputLabel
                                required
                                htmlFor="filled-age-native-simple"
                            >
                                Impegno orario
                            </CustomInputLabel>
                            <Select
                                native
                                fullWidth
                                required
                                value={job_percentage}
                                onChange={onChange}
                                inputProps={{
                                    name: 'job_percentage',
                                    // id: 'filled-age-native-simple',
                                }}
                            >
                                <option hidden aria-label="None" value={null} />

                                <option value={100}>Tempo pieno</option>
                                <option value={50}>Part-time</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="dialog-layout m2" style={{ width: '100%' }}>
                        <div>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel
                                    required
                                    htmlFor="filled-age-native-simple"
                                >
                                    Tipologia di contratto
                                </InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    required
                                    value={job_contract}
                                    onChange={onChange}
                                    inputProps={{
                                        name: 'job_contract',
                                        // id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option
                                        hidden
                                        aria-label="None"
                                        value={null}
                                    />
                                    <option value={'Part time'}>
                                        Part time
                                    </option>
                                    <option value={'Apprendistato'}>
                                        Apprendistato
                                    </option>
                                    <option value={'Tempo determinato'}>
                                        Tempo determinato
                                    </option>
                                    <option value={'Tempo indeterminato'}>
                                        Tempo indeterminato
                                    </option>
                                    <option value={'Freelance'}>
                                        Freelance
                                    </option>
                                    <option value={'Stage'}>Stage</option>
                                </Select>
                            </FormControl>
                            {/* {formErrors.job_contract && (
                                <p className="input-error">
                                    {formErrors.job_contract}
                                </p>
                            )} */}
                        </div>
                        <div>
                            <div className="input-select d-flex">
                                <TextField
                                    value={ral}
                                    label="Range di salario (20k annuo...)"
                                    name="ral"
                                    variant="filled"
                                    fullWidth
                                    required
                                    color="primary"
                                    onChange={onChange}
                                    className="custom-radius-text"
                                />
                                <FormControl
                                    variant="filled"
                                    fullWidth
                                    className="custom-radius-select"
                                >
                                    {/* <InputLabel htmlFor="salario-select">salario</InputLabel> */}
                                    <Select
                                        native
                                        // fullWidth
                                        value={ral_type || 'false'}
                                        name="ral_type"
                                        onChange={onChange}
                                        inputProps={{
                                            name: 'ral_type',
                                            // name: '',
                                            // id: '',
                                        }}
                                        style={{
                                            width: '10rem',
                                        }}
                                    >
                                        {/* <option hidden aria-label="None" value={null} />                                     */}
                                        <option value={false}>
                                            Annuo (lordo)
                                        </option>
                                        <option value={true}>
                                            Mensile (netto)
                                        </option>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* {formErrors.ral && (
                                <p className="input-error">{formErrors.ral}</p>
                            )} */}
                        </div>
                    </div>

                    <div>
                        <TextField
                            label=""
                            variant="filled"
                            fullWidth
                            required
                            color="primary"
                            multiline
                            rows={7}
                            name="description"
                            value={description}
                            onChange={onChange}
                            placeholder="Descrivi i punti di forza di questa posizione, ad esempio buoni pasto gratuiti, agevolazioni sui servizi, flessibilità oraria (max 500 caratteri)"
                            inputProps={{ maxLength: 1500 }}
                        />
                        <p
                            className={
                                description?.length < 1500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 1500 massimo
                        </p>
                    </div>
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            setSubmit(true)
                        }}
                        color="primary"
                        className="btn-like-mui"
                    >
                        Salva
                    </Button>
                    {/* <Button
                            onClick={(e) => {
                                e.preventDefault()
                                handleAddOffer()
                                setSubmit(true)
                            }}
                            color="primary"
                            className="btn-like-mui"
                        >
                            Salva
                        </Button> */}
                </div>
            </div>
        </>
    )
}

export default JobCardEmpty
