import React from 'react'

function PrevIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.9" cy="19.9" r="19.9" fill="none"/>
        <path d="M25 10L15 20.1923L25 30.3846" stroke="#5058ed" strokeWidth="4"/>
    </svg> 
  )
}

export default PrevIcon