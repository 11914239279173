import React from 'react'

// import 'emoji-mart/css/emoji-mart.css'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

function EmojiPickerMart({ message, setMessage, showEmoji, setShowEmoji}) {


    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        console.log(sym)
        let codeArray = [];
        sym.forEach((el) => codeArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codeArray) ;
        // setMessage(message + emoji);
        setMessage(prevMsg => prevMsg + emoji);
      };

  return (
    <>      
        <span 
            className='choose-emoji'
            onClick={() => setShowEmoji(!showEmoji)}
        >
             <svg width="30" height="30" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.4092 66.5576C52.8606 66.5576 67.8184 51.8821 67.8184 33.7788C67.8184 15.6756 52.8606 1 34.4092 1C15.9578 1 1 15.6756 1 33.7788C1 51.8821 15.9578 66.5576 34.4092 66.5576Z" fill="#fff" stroke="#4b4c55" strokeWidth="2"/>
                <path d="M19.881 40.2875C20.7596 42.5862 22.1914 44.6615 24.0788 46.2792C25.9445 47.8862 28.179 49.0994 30.5871 49.7167C33.006 50.3446 35.5659 50.3552 37.9957 49.738C40.3821 49.1207 42.5949 47.9287 44.4497 46.3324C45.3826 45.5342 46.1961 44.6296 46.912 43.6398C47.6388 42.6394 48.2137 41.5645 48.6693 40.4152C48.7669 40.1597 48.8645 39.915 48.9405 39.6595C49.0381 39.3509 48.973 38.9465 48.8103 38.6804C48.6476 38.4144 48.3439 38.159 48.0293 38.0951C47.3134 37.9355 46.6517 38.3079 46.4239 38.9891C46.3046 39.3509 46.1744 39.7128 46.0226 40.064C46.066 39.9575 46.1094 39.8617 46.1527 39.7553C45.6863 40.8302 45.0789 41.8306 44.363 42.7459C44.4281 42.6607 44.504 42.5756 44.5691 42.4904C43.7989 43.4589 42.9095 44.3316 41.9115 45.0872C41.9983 45.0233 42.0851 44.9489 42.1718 44.885C41.1739 45.6406 40.0675 46.2685 38.9069 46.7581C39.0153 46.7155 39.113 46.6729 39.2214 46.6304C38.0933 47.0986 36.911 47.4179 35.6961 47.5775C35.8154 47.5669 35.9239 47.5456 36.0432 47.535C34.8934 47.684 33.7436 47.684 32.5938 47.535C32.7131 47.5456 32.8216 47.5669 32.9409 47.5775C31.7152 47.4179 30.522 47.088 29.3831 46.6197C29.4915 46.6623 29.5892 46.7049 29.6976 46.7474C28.5261 46.2579 27.4089 45.6193 26.4001 44.8637C26.4869 44.9276 26.5737 45.0021 26.6604 45.0659C25.6734 44.3209 24.7839 43.4483 24.0137 42.4798C24.0788 42.5649 24.1548 42.6501 24.2198 42.7352C23.5148 41.8413 22.929 40.8515 22.4734 39.8085C22.5168 39.915 22.5602 40.0107 22.6036 40.1172C22.5277 39.9469 22.4626 39.7873 22.3975 39.617C22.2782 39.2977 22.1046 39.0316 21.8009 38.8507C21.5189 38.6911 21.1067 38.6272 20.803 38.723C20.4884 38.8188 20.1847 39.0104 20.022 39.3083C19.8593 39.585 19.7617 39.9682 19.881 40.2875Z" fill="#4b4c55"/>
                <path d="M20.3622 27.9254C22.2492 27.9254 23.779 25.9719 23.779 23.562C23.779 21.1522 22.2492 19.1986 20.3622 19.1986C18.4751 19.1986 16.9453 21.1522 16.9453 23.562C16.9453 25.9719 18.4751 27.9254 20.3622 27.9254Z" fill="#4b4c55"/>
                <path d="M47.6961 27.9254C49.5832 27.9254 51.113 25.9719 51.113 23.562C51.113 21.1522 49.5832 19.1986 47.6961 19.1986C45.8091 19.1986 44.2793 21.1522 44.2793 23.562C44.2793 25.9719 45.8091 27.9254 47.6961 27.9254Z" fill="#4b4c55"/>
            </svg>
        </span>
        {showEmoji && (
            <div>
                <Picker 
                    data={data}
                    // onSelect={addEmoji} 
                    // onEmojiSelect={console.log}
                    onEmojiSelect={addEmoji}
                    theme="light"
                    previewPosition="none"
                    // locale="it"
                />
            </div>
         )}
    </>
  )
}

export default EmojiPickerMart