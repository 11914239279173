import React, { createContext, useState, useMemo, useCallback } from 'react'
import {
    setDoc,
    doc,
    addDoc,
    collection,
    onSnapshot,
    query,
    orderBy,
    where,
    getDoc,
    getDocs,
    updateDoc,
    arrayUnion,
    arrayRemove,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { db, functions, payments } from '../../config'
import {
    getProducts,
    createCheckoutSession,
} from '@stripe/firestore-stripe-payments'

const FirestoreContext = createContext({
    method: 'Firebase',
    createMatch: () => Promise.resolve(),
    sendMessage: () => Promise.resolve(),
    getMessages: () => Promise.resolve(),
    getUnreadedMessages: () => Promise.resolve(),
    getNewMatches: () => Promise.resolve(),
    getLikes: () => Promise.resolve(),
    deleteChat: () => Promise.resolve(),
    updateReadMessage: () => Promise.resolve(),
    addUser: () => Promise.resolve(),
    sendLike: () => Promise.resolve(),
    getActiveProducts: () => Promise.resolve(),
    createPaymentSession: () => Promise.resolve(),
    getActiveSubscription: () => Promise.resolve(),
    getNotificationPreferences: () => Promise.resolve(),
    setNotificationPreferences: () => Promise.resolve(),
})

export const FirestoreProvider = ({ children }) => {
    const [messages, setMessages] = useState([])
    const [unreaded, setUnreaded] = useState([])
    const [matches, setNewMatches] = useState([])
    const [likes, setLikes] = useState([])
    // const generateId = (id1, id2) => (id1 > id2 ? id1 + id2: id2 + id1)

    const createMatch = async (
        offerId,
        userid,
        swiped,
        swiped_name,
        isCand = false
    ) => {
        try {
            // console.log(swiped)
            await setDoc(doc(db, 'matches', offerId + userid), {
                usersMatched: [offerId, userid],
                swiped: swiped,
                swiped_name: swiped_name,
                timestamp: new Date(),
                isNew: true,
            })

            let swiped_ref = offerId
            let _swiped = userid
            if (isCand) {
                swiped_ref = userid
                _swiped = offerId
            }
            const docRef = doc(db, 'likes', swiped_ref)

            // Remove the user from the likesReceived array
            await updateDoc(docRef, {
                likesReceived: arrayRemove(_swiped), //check better parameter to add
            })

            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const sendMessage = async (
        offerId,
        userid,
        sender,
        receiver,
        message,
        displayName
    ) => {
        try {
            await addDoc(
                collection(db, 'matches', offerId + userid, 'messages'),
                {
                    timestamp: new Date(),
                    userId: sender,
                    receiverId: receiver,
                    displayName: displayName,
                    message: message,
                    read: false,
                }
            )
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const updateReadMessage = async (offerId, userid, senderId) => {
        try {
            let arrayProvvisorio = [...unreaded]
            const arr = arrayProvvisorio.filter(
                (x) => x.id !== senderId && x.id
            )
            // console.log("questo è l'array: ", arr)
            setUnreaded(arr)
            await updateMessage(offerId, userid, senderId)
            // await getUnreadedMessages(senderId)
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const updateMessage = useCallback(
        async (offerId, userid, senderId) => {
            const userRef = query(
                collection(db, 'matches', offerId + userid, 'messages'),
                where('read', '==', false),
                where('userId', '==', senderId)
            )

            const findValues = await getDocs(userRef)

            findValues.forEach(async (value) => {
                const messageRef = doc(
                    db,
                    'matches',
                    offerId + userid,
                    'messages',
                    value.id
                )

                // Update the 'read' field in the 'messages' subcollection
                await updateDoc(messageRef, {
                    read: true,
                })
            })

            // Now check the 'isNew' field in the parent 'matches' document
            const matchRef = doc(db, 'matches', offerId + userid) // Reference to the parent 'matches' document
            const matchDoc = await getDoc(matchRef) // Fetch the parent document
            if (matchDoc.exists()) {
                const matchData = matchDoc.data() // Get the document data
                if (matchData.isNew === true) {
                    // If 'isNew' is true, update it to false
                    await updateDoc(matchRef, {
                        isNew: false,
                    })
                }
            }
        },
        [unreaded, messages]
    )

    const getMessages = async (offerId, userid) => {
        await onSnapshot(
            query(
                collection(db, 'matches', offerId + userid, 'messages'),
                orderBy('timestamp')
            ),
            { includeMetadataChanges: true },
            (snapshot) => {
                const source = snapshot.metadata.hasPendingWrites
                    ? 'Local'
                    : 'Server'
                // console.log(source);
                setMessages(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                )
            }
        )
    }

    const addUser = async (userid, fcm, displayName, photoURL) => {
        try {
            const docRef = doc(db, 'users', userid)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                // docSnap.data() will be undefined in this case
                console.log('No such document!')
                await setDoc(doc(db, 'users', userid), {
                    displayName: displayName,
                    fcm: fcm,
                    photo: photoURL,
                    timestamp: new Date(),
                })
            } else {
                if (fcm !== docSnap.data().fcm)
                    await updateDoc(doc(db, 'users', userid), {
                        displayName: displayName,
                        fcm: fcm,
                        photo: photoURL,
                        timestamp: new Date(),
                    })
            }

            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const getUnreadedMessages = async (userid) => {
        const q = query(
            collection(db, 'matches'),
            where('usersMatched', 'array-contains', userid)
        )
        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(async (doc) => {
            // console.log('doc: ', doc)
            await onSnapshot(
                query(
                    collection(db, 'matches', doc.id, 'messages'),
                    where('userId', '!=', userid)
                    // where('read', '==', false),
                ),
                { includeMetadataChanges: true },
                (snapshot) => {
                    // console.log("msg: ", snapshot.docs.map((doc1)=>doc1.data()));
                    const source = snapshot.metadata.hasPendingWrites
                        ? 'Local'
                        : 'Server'
                    if (source === 'Server') {
                        const msg = snapshot.docs.map((doc1) => doc1.data())
                        const unread_msg = msg.filter((x) => x.read === false)
                        // console.log("msg: ", unread_msg);
                        // let arrayProvvisorio = [...unreaded]
                        // if (arrayProvvisorio.find(x=>x.id === snapshot.docs.map((doc1)=> {if(doc1.data().userId !== user?.uid) return doc1.data().userId}))?.length > 1)
                        //     arrayProvvisorio.splice(arrayProvvisorio.find(x=>x.id === snapshot.docs.map((doc1)=> {if(doc1.data().userId !== user?.uid) return doc1.data().userId})[0]))
                        // setUnreaded(arrayProvvisorio)
                        ///////TROVARE METODO PER RIPULIRE LA LISTA PRIMA CHE IL METODO VENGA RICHIAMATO, ALTRIMENTI LA LISTA è SEMPRE INCREMENTALE
                        setUnreaded((current) => [
                            ...current,
                            {
                                id:
                                    unread_msg.length > 0
                                        ? unread_msg[0].userId
                                        : null,
                                number: unread_msg.length,
                                timestamp: unread_msg
                                    .map((time) => {
                                        return time.timestamp
                                    })
                                    .sort()
                                    .reverse()[0],
                            },
                        ])
                    }
                }
            )
        })
    }

    const getNewMatches = async (userid) => {
        const q = query(
            collection(db, 'matches'),
            where('usersMatched', 'array-contains', userid)
        )

        onSnapshot(q, (querySnapshot) => {
            const updatedMatches = []

            querySnapshot.forEach((doc) => {
                const docData = doc.data()

                // Check if the match is new (isNew === true)
                if (docData.isNew) {
                    updatedMatches.push({
                        id: docData.usersMatched.find(
                            (element) => element !== userid
                        ),
                        isNew: true,
                    })
                }
            })

            // Update the state with the new matches
            setNewMatches(updatedMatches)
        })
    }

    const getLikes = async (userId) => {
        try {
            const docRef = doc(db, 'likes', userId)

            onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const docData = docSnapshot.data()
                    const updatedLikes = []

                    // Check if the 'likesReceived' array exists and process it
                    if (docData.likesReceived) {
                        docData.likesReceived.forEach((like) => {
                            updatedLikes.push({
                                id: like,
                            })
                        })
                    }

                    // Update the state with the new likes
                    setLikes(updatedLikes)
                } else {
                    setLikes([])
                }
            })
        } catch (error) {
            // console.error("Error fetching notification preferences:", error);
            return setLikes([])
        }
    }

    const deleteChat = async () => {}

    const sendLike = async (userId, swiped, swiped_name, swiped_ref) => {
        try {
            const docRef = doc(db, 'likes', swiped_ref)
            await updateDoc(docRef, {
                likesReceived: arrayUnion(userId),
            })

            const funct = httpsCallable(functions, 'sendlikenotification')
            const res = await funct({
                swiped: swiped,
                swiped_name: swiped_name,
            })
        } catch (error) {
            // If the document doesn't exist, create it with the initial likesReceived array
            if (error.code === 'not-found') {
                try {
                    const docRef = doc(db, 'likes', swiped_ref)
                    await setDoc(docRef, {
                        likesReceived: [userId],
                    })
                    console.debug(
                        'Likes document created and likesReceived array initialized.'
                    )
                } catch (createError) {
                    console.error('Error creating likes document:', createError)
                }
            } else {
                console.error(
                    'Error sending like or updating likesReceived:',
                    error
                )
            }
        }
    }

    const getAllStripeProducts = async () => {
        const products = await getProducts(payments, {
            includePrices: true,
            activeOnly: true,
        })

        return products
    }

    const getActiveProducts = async () => {
        const products = await getAllStripeProducts()
        return products.filter((x) => x.active)
    }

    const createPaymentSession = async (priceId) => {
        if (priceId) {
            const session = await createCheckoutSession(payments, {
                price: priceId,
                // success_url: '/jump',
                // cancel_url: '/settings',
            })
            window.location.assign(session.url)
        } else {
            const products = await getAllStripeProducts()
            const myPriceId = products[0].prices[0].id
            const session = await createCheckoutSession(payments, {
                price: myPriceId,
                // success_url: '/jump',
                // cancel_url: '/settings',
            })
            window.location.assign(session.url)
        }
    }

    const getActiveSubscription = async (uid) => {
        const q = query(
            // currentUser is provided by firebase, via getAuth().currentUser
            collection(db, 'customers', uid, 'subscriptions'),
            where('status', 'in', ['trialing', 'active'])
        )

        // fetch the active subscriptions
        const querySnapshot = await getDocs(q)
        if (querySnapshot.empty) {
            return null
        }
        // assuming user only has one active subscription max
        const subscription = querySnapshot.docs[0].data()
        return subscription
    }

    const getNotificationPreferences = async (uid) => {
        try {
            const docRef = doc(db, 'notificationPreferences', uid)

            const docSnap = await getDoc(docRef)

            if (docSnap.exists()) {
                // Document data exists, read and display the preferences
                const preferences = docSnap.data()
                return preferences
            } else {
                return null
            }
        } catch (error) {
            // console.error("Error fetching notification preferences:", error);
            return null
        }
    }

    const setNotificationPreferences = async (uid, preferences) => {
        try {
            await setDoc(doc(db, 'notificationPreferences', uid), preferences)

            return true
        } catch (error) {
            // console.error("Error updating notification preferences:", error);
            return false
        }
    }

    const memorizedValue = useMemo(
        () => ({
            method: 'Firebase',
            createMatch,
            sendMessage,
            getMessages,
            getUnreadedMessages,
            getNewMatches,
            getLikes,
            updateReadMessage,
            deleteChat,
            addUser,
            sendLike,
            getActiveProducts,
            createPaymentSession,
            getActiveSubscription,
            getNotificationPreferences,
            setNotificationPreferences,
            messages,
            unreaded,
            matches,
            likes,
        }),
        [messages, unreaded, matches, likes]
    )

    return (
        <FirestoreContext.Provider value={memorizedValue}>
            {children}
        </FirestoreContext.Provider>
    )
}

export default FirestoreContext
