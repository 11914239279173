import React from 'react'

function BinIcon() {

    const accentColor = '#5058ed' 

  return (
      <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <g clipPath="url(#clip0_3_30)">
              <path
                  d="M29.9358 11.4857H24.8369V10.1077C24.8369 8.94544 23.9975 8 22.9654 8H17.0346C16.0025 8 15.1628 8.94544 15.1628 10.1077V11.4857H10.0642C9.47653 11.4857 9 11.9703 9 12.568C9 13.1656 9.47653 13.6503 10.0642 13.6503H10.914C10.8935 13.8172 10.8957 13.9878 10.9231 14.1577L13.5705 30.5244C13.7086 31.3796 14.4276 32 15.2796 32H24.7658C25.6179 32 26.3368 31.3792 26.475 30.5244L29.1224 14.1577C29.1498 13.9878 29.152 13.8172 29.1314 13.6503H29.9355C30.5231 13.6503 30.9996 13.1656 30.9996 12.568C30.9996 11.9703 30.5235 11.4857 29.9358 11.4857ZM16.4538 10.1077C16.4538 9.677 16.7197 9.31305 17.0346 9.31305H22.9654C23.2803 9.31305 23.5462 9.677 23.5462 10.1077V11.4857H16.4542V10.1077H16.4538ZM28.0016 13.9698L25.3542 30.3366C25.3066 30.6308 25.0591 30.8443 24.7662 30.8443H15.28C14.9867 30.8443 14.7396 30.6308 14.692 30.3366L12.0442 13.9698C12.0262 13.859 12.0381 13.7501 12.0767 13.6503H27.9691C28.0081 13.7501 28.0196 13.8594 28.0016 13.9698Z"
                  fill={accentColor}
              />
              <path
                  d="M17.5487 26.4128C17.8625 26.4128 18.1168 26.1542 18.1168 25.835V17.3741C18.1168 17.0549 17.8625 16.7962 17.5487 16.7962C17.2348 16.7962 16.9805 17.0549 16.9805 17.3741V25.835C16.9805 26.1542 17.2348 26.4128 17.5487 26.4128Z"
                  fill={accentColor}
              />
              <path
                  d="M22.4972 26.4128C22.811 26.4128 23.0653 26.1542 23.0653 25.835V17.3741C23.0653 17.0549 22.811 16.7962 22.4972 16.7962C22.1833 16.7962 21.929 17.0549 21.929 17.3741V25.835C21.929 26.1542 22.1833 26.4128 22.4972 26.4128Z"
                  fill={accentColor}
              />
          </g>
          <defs>
              <clipPath id="clip0_3_30">
                  <rect
                      width="22"
                      height="24"
                      fill="none"
                      transform="translate(9 8)"
                  />
              </clipPath>
          </defs>
      </svg>
  )
}

export default BinIcon