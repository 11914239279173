import React, { useState, useEffect } from 'react'
import {
    Lock as LockIcon,
    Notifications as NotificationsIcon,
    PermPhoneMsg as PermPhoneMsgIcon,
} from '@material-ui/icons'
import {
    QuestionMark as QuestionMarkIcon,
    TextSnippet as TextSnippetIcon,
    Cookie as CookieIcon,
    GppGood as GppGoodIcon,
} from '@mui/icons-material'
import RegisteredUser from '../RegisteredUser'
import useAuth from 'app/hooks/useAuth'
import NotificationSetting from '../../NotificationSetting' // Import the NotificationSetting component
import EditFieldDialog from './EditField'
import ModifyData from './ModifyData'
import UnsubscribeAccount from './UnsubscribeAccount'
import DisconnectAccount from './DisconnectAccount'
import ApiService from 'app/ApiService'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { authRoles } from 'app/auth/authRoles'

function FooterProfile() {
    const { logout, unsubscribe, user, role } = useAuth()
    const [loading, setLoading] = useState(true)
    const [showUserData, setShowUserData] = useState(false)
    const [showNotificationSettings, setShowNotificationSettings] =
        useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (user && user.uid) {
                try {
                    const response = await ApiService.get_recruiterbyId(
                        user.uid
                    )
                    const body = await response.json()
                    setUserInfo(body)
                    setLoading(false)
                } catch (error) {
                    console.error('Failed to fetch user info:', error)
                }
            }
        }

        fetchUserInfo()
    }, [user])

    const handleShowUserData = () => {
        setShowUserData(true)
        setShowNotificationSettings(false) // Hide notification settings when showing user data
    }

    const handleShowNotificationSettings = () => {
        setShowNotificationSettings(true)
        setShowUserData(false) // Hide user data when showing notification settings
    }

    const handleBackToMenu = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            setShowUserData(false)
            setShowNotificationSettings(false) // Reset both states
        }
    }

    const handleFieldChange = () => {
        setIsEdited(true)
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            setIsEdited(false)
            setShowUserData(false)
            setShowNotificationSettings(false) // Reset both states on confirmation
        }
    }

    return (
        <>
            <footer>
                {showUserData ? (
                    <div style={{ marginTop: '-2rem' }}>
                        <div
                            className="link2profile"
                            style={{ paddingInline: 0 }}
                        >
                            <button
                                onClick={handleBackToMenu}
                                style={{ color: '#4b4c55' }}
                            >
                                <KeyboardBackspaceIcon />
                            </button>
                            <p>torna indietro</p>
                        </div>
                        <RegisteredUser
                            userInfo={userInfo}
                            onFieldChange={handleFieldChange}
                        />
                    </div>
                ) : showNotificationSettings ? (
                    <div style={{ marginTop: '-2rem' }}>
                        <div
                            className="link2profile"
                            style={{ paddingInline: 0 }}
                        >
                            <button
                                onClick={handleBackToMenu}
                                style={{ color: '#4b4c55' }}
                            >
                                <KeyboardBackspaceIcon />
                            </button>
                            <p>torna indietro</p>
                        </div>
                        <NotificationSetting />{' '}
                        {/* Render the NotificationSettings component here */}
                    </div>
                ) : (
                    <div className="settings-menu">
                        <div className="menu-section">
                            <div className="menu-title">Il mio account</div>
                            <ul className="menu-list">
                                {authRoles.recruiter.includes(role) && (
                                    <li onClick={handleShowUserData}>
                                        <LockIcon className="blackIcon" />
                                        Dati utente
                                    </li>
                                )}
                                <li>
                                    <LockIcon className="blackIcon" />
                                    <ModifyData />
                                </li>
                                <li onClick={handleShowNotificationSettings}>
                                    <NotificationsIcon className="blackIcon" />
                                    Impostazioni notifiche
                                </li>
                            </ul>
                        </div>

                        <div className="menu-section">
                            <div className="menu-title">Supporto</div>
                            <ul className="menu-list">
                                <li>
                                    <PermPhoneMsgIcon className="blackIcon" />
                                    <button
                                        className="accent-text-color"
                                        onClick={() =>
                                            window.open(
                                                'https://hylkxrsnso4.typeform.com/kangratscontact',
                                                '_blank'
                                            )
                                        }
                                    >
                                        Contattaci
                                    </button>
                                </li>
                                <li>
                                    <QuestionMarkIcon className="blackIcon" />
                                    <a
                                        className="link"
                                        href="https://kangrats.notion.site/FAQ-Kangrats-599aa0e869384cceb156049d781ebc2b?pvs=73"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        FAQs
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="menu-section">
                            <div className="menu-title">Note legali</div>
                            <ul className="menu-list">
                                <li>
                                    <TextSnippetIcon className="blackIcon" />
                                    <a
                                        className="link"
                                        href="https://kangrats.notion.site/Limyra-srl-Kangrats-Termini-e-Condizioni-efb3b725a09e42c5a220a6d9b0373f8f?pvs=73"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Termini e Condizioni di utilizzo
                                    </a>
                                </li>
                                <li>
                                    <CookieIcon className="blackIcon" />
                                    <a
                                        className="link"
                                        href="https://kangrats.notion.site/Informativa-Cookies-Kangrats-4926e4f61e454f578023e21844686558?pvs=4"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Informativa sui Cookie
                                    </a>
                                </li>
                                <li>
                                    <GppGoodIcon className="blackIcon" />
                                    <a
                                        className="link"
                                        href="https://kangrats.notion.site/Limyra-srl-Kangrats-Privacy-Policy-e9e2afe0f1df41468ee4aa33c89f5a8f?pvs=73"
                                        target="_blank"
                                        title="kangrats.com"
                                    >
                                        Informativa sulla Privacy
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="menu-section lastSection">
                            <ul className="menu-list">
                                <li>
                                    <DisconnectAccount />
                                </li>
                                <li className="delete-account">
                                    <UnsubscribeAccount />
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {/* Edit Field Dialog for Confirmation */}
                <EditFieldDialog
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    onConfirm={() => handleConfirmClose(true)}
                />
            </footer>
        </>
    )
}

export default FooterProfile
