import { authRoles } from '../auth/authRoles'
import SignupCandidateSteps from '../components/SignupComponents/SignupCandidato/SignupCandidateSteps'
import SignupRecruiterSteps from '../components/SignupComponents/SignupRecruiter/SignupRecruiterSteps'

const signupRootes = [
    {
        path: '/signup-candidato',
        auth: authRoles.candidate,
        component: SignupCandidateSteps,
    },
    {
        path: '/signup-recruiter',
        auth: authRoles.recruiter,
        component: SignupRecruiterSteps,
    },
    // {
    //     path: '/session/404',
    //     component: NotFound,
    // },
]

export default signupRootes
