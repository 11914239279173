// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import { getMessaging } from 'firebase/messaging'
import { getFunctions } from 'firebase/functions'
import {
    getStripePayments,
    getProducts,
} from '@stripe/firestore-stripe-payments'

const firebaseConfig = {
    apiKey: 'AIzaSyDiIAevB8DLZyMhQiVwu1biIKb9Aw5gt24',
    authDomain: 'kangrats-auth.firebaseapp.com',
    projectId: 'kangrats-auth',
    storageBucket: 'kangrats-auth.appspot.com',
    messagingSenderId: '328511262049',
    appId: '1:328511262049:web:11d28b2d9b455f6b3f0920',
    measurementId: 'G-X8CLP7275Q',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const auth = getAuth(app)

export const google_auth = new GoogleAuthProvider()
export const facebook_auth = new FacebookAuthProvider()

export const storage = getStorage(app)

export const db = getFirestore(app)

export const messaging = getMessaging(app)

export const functions = getFunctions(app)

export const serverClientId =
    '328511262049-qd6792mpoo3m5ne4uegu9earteuar1fr.apps.googleusercontent.com'
export const androidClientId =
    '328511262049-1pbabbsk9rsobgmnsbm22secbbo88csj.apps.googleusercontent.com'
export const tomtomApiKey = 'drisYIsJXc8VMWAyOqbFYokJB9C5cepo'
export const messageToken =
    'BFeqSnaMEfMapQDNv-nCDDOZdypQmvV0fhwVu-7fpEQPtNBzfyiswo7B4dC9XTqT-Fu9C4A_DkhazyGDiGdYVNk'

export const payments = getStripePayments(app, {
    productsCollection: 'products',
    customersCollection: 'customers',
})

export const azureCode =
    'H2BXxvQ1StYH2_KpJEiuayQwtOvstw0w1K1oAx63a1DmAzFuycbBFQ=='
