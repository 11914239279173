import React, { useState, Fragment } from 'react'
import useAuth from 'app/hooks/useAuth'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { DialogContentText } from '@material-ui/core'

function DisconnectAccount() {
    const { logout } = useAuth()
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <div className="footer-section">
                {/* <hr /> */}
                <button onClick={() => setOpen(true)}>Logout</button>
            </div>

            {/* DIALOG UNSUBSCRIBE */}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                className="small-dialog"
            >
                <DialogTitle id="form-dialog-title">
                    Disconnetti account
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermando effettuerai il logout. Procedere?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={logout} color="primary">
                        Conferma
                    </Button>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DisconnectAccount
