import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import { authRoles } from 'app/auth/authRoles'
import KangratsLogo from 'app/components/Icons/KangratsLogo'
import Badge from './Badge'
import useFirestore from 'app/hooks/useFirestore'
import MatchIcon from 'app/components/Icons/MatchIcon'
import SettingsIcon from 'app/components/Icons/SettingsIcon'
import LikesIcon from 'app/components/Icons/LikesIcon'
import TroviamoUnLavoratoreIcon from 'app/components/Icons/TroviamoUnLavoratoreIcon'

const Sidebar = ({ showlikeBadge, showmatchBadge }) => {
    const [open, setOpen] = useState(false)
    // const [photoURL, setPhotoURL] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png")
    const { role } = useAuth()

    const handleDrawerOpen = () => {
        setOpen(!open)
    }

    return (
        <header
            id="sidebar-fixed"
            className={open ? 'drawer-open' : 'drawer-close'}
        >
            <div className="max-h d-flex justify-between">
                <Link
                    to={role === 'recruiter' ? '/jump-recruiter' : '/jump'}
                    className="logo-box"
                >
                    <KangratsLogo
                        width={265}
                        height={70}
                        cssClasses={'logo-sidebar'}
                    />
                </Link>

                <button className="hideOnDesktop" onClick={handleDrawerOpen}>
                    ☰
                </button>
                <div className="mobileIcon">
                    <NavLink
                        to={
                            authRoles.recruiter.includes(role)
                                ? '/settings-recruiter'
                                : '/settings'
                        }
                    >
                        <SettingsIcon
                            width="38"
                            height="32"
                            viewBox="0 0 38 32"
                        />
                    </NavLink>
                </div>
            </div>

            <nav className="hideOnMobile">
                <ul className="nav-links">
                    <li>
                        <NavLink
                            to={
                                authRoles.recruiter.includes(role)
                                    ? '/jump-recruiter'
                                    : '/jump'
                            }
                            className="jump-link"
                        >
                            <TroviamoUnLavoratoreIcon
                                width="95"
                                height="110"
                                viewBox="0 0 80 80"
                            />
                            Troviamo un{' '}
                            {authRoles.recruiter.includes(role)
                                ? 'collaboratore'
                                : 'lavoro'}{' '}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={
                                authRoles.recruiter.includes(role)
                                    ? '/likes-recruiter'
                                    : '/likes'
                            }
                        >
                            {showlikeBadge ? (
                                <Badge count={2}>
                                    <LikesIcon
                                        viewBox="0 0 800 800"
                                        strokeWidth={35}
                                        strokeMiterlimit={10}
                                    />
                                </Badge>
                            ) : (
                                <LikesIcon
                                    viewBox="0 0 800 800"
                                    strokeWidth={35}
                                    strokeMiterlimit={10}
                                />
                            )}
                            Likes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={
                                authRoles.recruiter.includes(role)
                                    ? '/match-recruiter'
                                    : '/match'
                            }
                        >
                            {showmatchBadge ? (
                                <Badge count={2}>
                                    <MatchIcon
                                        width="49"
                                        height="47"
                                        viewBox="0 0 49 47"
                                    />
                                </Badge>
                            ) : (
                                <MatchIcon
                                    width="49"
                                    height="47"
                                    viewBox="0 0 49 47"
                                />
                            )}
                            I tuoi Match
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={
                                authRoles.recruiter.includes(role)
                                    ? '/settings-recruiter'
                                    : '/settings'
                            }
                        >
                            <SettingsIcon
                                height={32}
                                width={38}
                                viewBox={'0 0 38 32'}
                            />
                            Impostazioni
                        </NavLink>
                    </li>

                    {/* <hr className='hideOnMobile' /> */}

                    <li className="mt1 hideOnMobile d-flex space-between align-center profile">
                        <NavLink
                            to={
                                authRoles.recruiter.includes(role)
                                    ? '/profile-recruiter'
                                    : '/profile'
                            }
                            className="profile-link"
                            id="profilebutton"
                        >
                            <svg
                                className="profileAfatarprogress"
                                width="80"
                                height="80"
                                viewBox="0 0 50 50"
                                xmlns="http://www.w3.org/2000/svg"
                                id="profileIcon"
                                sx={{
                                    color: '#FF6D00',
                                    position: 'relative',
                                    width: '8rem',
                                    height: 'auto',
                                }}
                            >
                                <path
                                    className="activeClass stroke-dark fill-dark"
                                    d="M15.5455 22.0186C19.2063 22.0186 22.6475 23.4484 25.2353 26.0438C27.8232 28.6391 29.2495 32.0911 29.2495 35.7625V36.1532H1.84138V35.7625C1.84138 32.0911 3.26702 28.6398 5.85486 26.0438C8.44341 23.4484 11.8847 22.0186 15.5455 22.0186ZM15.5455 20.1719C6.95969 20.1719 0 27.1518 0 35.7625V37.9999H31.0909V35.7625C31.0909 27.1518 24.1312 20.1719 15.5455 20.1719Z"
                                />
                                <path
                                    className="activeClass stroke-dark fill-dark"
                                    d="M15.5814 1.84673C19.6034 1.84673 22.876 5.12893 22.876 9.16262C22.876 13.1963 19.6034 16.4785 15.5814 16.4785C11.5594 16.4785 8.28669 13.1963 8.28669 9.16262C8.28669 5.12893 11.5594 1.84673 15.5814 1.84673ZM15.5814 0C10.536 0 6.44531 4.10258 6.44531 9.16262C6.44531 14.2227 10.536 18.3252 15.5814 18.3252C20.6267 18.3252 24.7174 14.2227 24.7174 9.16262C24.7174 4.10258 20.6267 0 15.5814 0Z"
                                />
                            </svg>

                            <span className="text-ellipsis-1">
                                Il tuo Profilo
                            </span>
                            {/*<span>Profilo completo 70%</span>*/}
                        </NavLink>
                    </li>

                    {/* </div> */}
                </ul>
            </nav>
        </header>
    )
}

export default Sidebar
