import { Avatar, Box, Button, Slider } from '@material-ui/core'
import React, { useEffect, useRef, useState, useContext } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Cropper from 'react-easy-crop'
import Skeleton from '@material-ui/lab/Skeleton'
import getCroppedImg from './cropImage'
import CloseIcon from 'app/components/Icons/CloseIcon'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import StorageContext from '../../../../../contexts/FirebaseStorageContext'
import useAuth from 'app/hooks/useAuth'

function FotoProfilo({
    file,
    openCrop,

    setFile,
    loading,
}) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [isCropOpen, setIsCropOpen] = useState(false)

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const storageContext = useContext(StorageContext)
    const { user } = useAuth()

    const cropImage = async () => {
        if (!isCropOpen) {
            console.log('Attempt to crop when cropper is closed.')
            handleClose()
            return // Exit the function early if the cropper is not open
        }
        try {
            const { file, url } = await getCroppedImg(
                photoURL,
                croppedAreaPixels
            )
            setPhotoURL(url)
            setFile(file)
        } catch (error) {
            console.log(error)
        }
        setIsCropOpen(false)

        // console.log('ciao da fuori try ma in cropImage');
    }

    // per dialog
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')

    const handleClickOpen = (scrollType) => () => {
        setOpen(true)
        setScroll(scrollType)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            setFile(file)
            setPhotoURL(URL.createObjectURL(file))
            handleOpenCropper()
        }
    }

    const descriptionElementRef = useRef(null)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    const handleOpenCropper = () => {
        setIsCropOpen(true)
    }

    const handleCloseCropper = async () => {
        try {
            const { file, url } = await getCroppedImg(
                photoURL,
                croppedAreaPixels
            )
            setPhotoURL(url)
            setFile(file)
        } catch (error) {
            console.log(error)
        }
        setIsCropOpen(false)
    }

    useEffect(() => {
        setPhotoURL(user.photoURL || defaultPhotoURL)
    }, [user])

    const { deletePhoto, photoURL, setPhotoURL, defaultPhotoURL } =
        useContext(StorageContext)

    const handleRemoveImage = async () => {
        const success = await deletePhoto()
        if (success) {
            setPhotoURL(defaultPhotoURL)
            console.log('Photo deleted and default photo set successfully.')
        } else {
            console.log('Failed to delete photo.')
        }
    }

    return (
        <>
            {loading ? (
                <Skeleton variant="text" width="10%" />
            ) : (
                <h3>Foto Profilo</h3>
            )}
            <div className="dialog-layout">
                <button
                    className="fit-content "
                    style={{ position: 'relative' }}
                >
                    <Avatar
                        variant="rounded"
                        src={photoURL}
                        onClick={handleClickOpen('paper')}
                    />
                    <EditIcon
                        id="iconEditphoto"
                        onClick={handleClickOpen('paper')}
                    />
                </button>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className="MUIDialog"
                id="upload-image"
            >
                <div className="d-flex space-between dialog-title">
                    {loading ? (
                        <Skeleton variant="text" width="10%" />
                    ) : (
                        <DialogTitle>Foto profilo</DialogTitle>
                    )}

                    <Button
                        onClick={cropImage}
                        color="primary"
                        className="close-dialog"
                    >
                        <CloseIcon />
                    </Button>
                </div>

                <DialogContent dividers={scroll === 'paper'}>
                    {!isCropOpen && (
                        <div
                            style={{
                                display: 'flex', // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                            }}
                        >
                            <button
                                className="fit-content"
                                onClick={handleOpenCropper}
                                id="editPhoto"
                            >
                                <Avatar variant="rounded" src={photoURL} />
                                <EditIcon id="iconEditphoto" />
                            </button>
                        </div>
                    )}
                    <br></br>
                    {isCropOpen && (
                        <div>
                            <div className="crop-container">
                                <label htmlFor="profilePhoto">
                                    <input
                                        accept="image/*"
                                        id="profilePhoto"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleChange}
                                    />
                                </label>

                                <Cropper
                                    image={photoURL}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onZoomChange={setZoom}
                                    onCropChange={setCrop}
                                    onCropComplete={cropComplete}
                                />
                            </div>

                            <Box>
                                <p>Zoom: {zoomPercent(zoom)}</p>
                                <Slider
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={zoomPercent}
                                    min={1}
                                    max={3}
                                    step={1}
                                    value={zoom}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </Box>
                        </div>
                    )}

                    {/* {isCropOpen && (
                        <Button onClick={handleCloseCropper}>Salva</Button>
                    )} */}

                    {!isCropOpen && (
                        <Box
                            sx={{
                                display: 'flex', // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                gap: 10, // Add gap between elements
                            }}
                        >
                            <label htmlFor="profilePhoto">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span" // Render button as a label for input
                                    style={{
                                        height: '38px', // uniform height
                                        width: '103px', // uniform width
                                        padding: '8px',
                                        backgroundColor: '#ea6c3a', // Set the background color to orange
                                        color: 'white', // Set the text color to white
                                        // Remove margin and padding if not needed
                                        display: 'flex',
                                    }}
                                    tabIndex={0}
                                >
                                    Carica
                                    <CloudUploadIcon className="iconbutton" />
                                </Button>

                                <input
                                    accept="image/*"
                                    id="profilePhoto"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleChange}
                                />
                            </label>
                            {/* <label htmlFor="profilePhoto">
                                <Button
                                    className="fit-content"
                                    onClick={handleOpenCropper}
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        height: '38px', // uniform height
                                        width: '103px', // uniform width
                                        padding: '8px',
                                        backgroundColor: '#ea6c3a', // Set the background color to orange
                                        color: 'white', // Set the text color to white
                                        // Remove margin and padding if not needed
                                        display: 'flex',
                                    }}
                                    component="span"
                                    tabIndex={1}
                                >
                                    Modifica
                                    <EditIcon className="iconbutton" />
                                </Button>
                            </label> */}
                            <label>
                                <Button
                                    onClick={handleRemoveImage}
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        height: '38px', // uniform height
                                        width: '103px', // uniform width
                                        padding: '8px',
                                        backgroundColor: '#ea6c3a', // Set the background color to orange
                                        color: 'white', // Set the text color to white
                                        // Remove margin and padding if not needed
                                        display: 'flex',
                                    }}
                                    component="span"
                                    tabIndex={2}
                                >
                                    Rimuovi
                                    <DeleteForeverIcon className="iconbutton" />
                                </Button>
                            </label>
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={cropImage}>
                        conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FotoProfilo

const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`
}
