import React from 'react'
import '../FooterHome.scss'
import './Tutorial.scss'

const FooterTut = () => {
    return (
        <div className="footer">
            <p className="footer-text">
                © 2024 Kangrats, LIMYRA S.R.L.
                <span>
                    {' '}
                    Via Alessandro Astesani 56, 20161, Milano (MI) P.IVA:
                    13454080964{' '}
                </span>
            </p>
            <div className="footer-links">
                <a
                    className="link1"
                    href="https://kangrats.notion.site/Limyra-srl-Kangrats-Privacy-Policy-e9e2afe0f1df41468ee4aa33c89f5a8f?pvs=73"
                    target="_blank"
                    title="kangrats.com"
                >
                    Privacy Policy
                </a>
                -
                <a
                    className="link1"
                    href="https://kangrats.notion.site/FAQ-Kangrats-599aa0e869384cceb156049d781ebc2b?pvs=73"
                    target="_blank"
                    title="kangrats.com"
                >
                    FAQs
                </a>
                -
                <a
                    className="link1"
                    href="https://kangrats.notion.site/Limyra-srl-Kangrats-Termini-e-Condizioni-efb3b725a09e42c5a220a6d9b0373f8f?pvs=73"
                    target="_blank"
                    title="kangrats.com"
                >
                    Termini e Condizioni
                </a>
            </div>
        </div>
    )
}
export default FooterTut
