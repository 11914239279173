import React from 'react'
import { Link } from 'react-router-dom'

function ChooseAccount() {
  return (          
    <div className="create-account-form-container choose-account">

        {/* <progress id="file" value="10" max="100"></progress> */}
        
        <div className="create-account-form p-10">
            <div className="form-title">
                <h1>Iniziamo a conoscerci partendo dal tuo bisogno</h1>
            </div>

            <div className="form-body d-flex account-cards">
              
              <Link to="/onboarding" className="account-card-container">
                  <div className="account-card">
                    <img src="./images/candidate-ill.png" alt="" />
                  </div>
                  <h3>Cerco Lavoro</h3>
              </Link>
              <Link to="/onboarding-recruiter" className="account-card-container">
                  <div className="account-card">
                    <img src="./images/recruiter-ill.png" alt="" />
                  </div>
                  <h3>Offro lavoro</h3>
              </Link>

            </div>
            
        </div>
    </div>
  )
}

export default ChooseAccount