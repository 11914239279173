import React from 'react'
import history from 'history.js'

function PasswordCheck() {

  const goback = () => {
    try {
        history.push('/login')
    } catch (e) {
        console.log(e)
    }
}

  return (
    <>
      <div className='overlay'></div>

      <main className='main-container-login'>
          <div className="login-container mail-check">
              <h1>Ben fatto</h1>
              <p>Segui la procedura che hai ricevuto per mail per resettare la password</p>
              <button onClick={goback} className='accent-btn btn big-border-radius'>
                  Continua
              </button>
          </div>
      </main>
    </>
  )
}

export default PasswordCheck