import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'

function Logout({}) {
    const { logout } = useAuth()

    useEffect(() => {
        console.log('logout')
        logout()
    }, [])

    return <></>
}

export default Logout
