import React, { useState } from 'react'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import { CircularProgress } from '@material-ui/core'

function OnboardingCompleted() {
    const { setUserRole } = useAuth()
    const [loading, setLoading] = useState(false)

    const completeProcess = async (clickEvent) => {
        clickEvent.preventDefault() // Prevent form submission or link navigation
        clickEvent.stopPropagation() // Stop the click event from bubbling up
        setLoading(true) // Disable the button and trigger loading state
        const response = await setUserRole('candidate', false)
        setLoading(false)
        if (response) {
            history.push('/signup-candidato')
        } else {
            history.push('/404')
        }
    }

    return (
        <>
            <div className="signup-completed">
                <img src="/logo2.png" alt="" />
                <h1 className="accent-text-color m2">CI SIAMO QUASI!</h1>

                <p>
                    Ancora qualche informazione su di te prima di saltare a
                    bordo!
                </p>
                <button
                    onClick={(e) => {
                        // console.log('Button clicked!')
                        e.preventDefault()
                        completeProcess(e) // Correctly reference the event object here
                    }}
                    disabled={loading}
                    className="btn primary-btn"
                    style={{ position: 'relative' }}
                >
                    {loading && (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                left: '10%',
                            }}
                            color="inherit"
                            size={25}
                        />
                    )}
                    Continua
                </button>
            </div>
        </>
    )
}

export default OnboardingCompleted
