import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const SettingsIcon = (props) => {
    return (
        <svg
            width={props.width ?? '50'}
            height={props.height ?? '50'}
            viewBox={props.viewBox ?? '0 0 50 50'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="activeClass stroke-dark fill-dark"
                d="M1.04568 5.58494H3.57919C4.14637 7.00553 5.45172 8 6.97272 8C8.49307 8 9.79906 7.00553 10.3662 5.58494H36.9543C37.5317 5.58494 38 5.07804 38 4.45304C38 3.82804 37.5317 3.32113 36.9543 3.32113H10.6138C10.3158 1.43508 8.79932 0 6.97272 0C5.14612 0 3.62959 1.43508 3.33164 3.32113H1.04568C0.468293 3.32113 0 3.82735 0 4.45304C0 5.07873 0.468293 5.58494 1.04568 5.58494Z"
            />
            <path
                className="activeClass stroke-dark fill-dark"
                d="M36.9543 14.1132L34.4208 14.1132C33.8536 12.8702 32.5483 12 31.0273 12C29.5069 12 28.2009 12.8702 27.6338 14.1132L1.04568 14.1132C0.468292 14.1132 -3.88444e-07 14.5567 -3.40635e-07 15.1036C-2.92826e-07 15.6505 0.468292 16.094 1.04568 16.094L27.3862 16.094C27.6842 17.7443 29.2007 19 31.0273 19C32.8539 19 34.3704 17.7443 34.6684 16.094L36.9543 16.094C37.5317 16.094 38 15.6511 38 15.1036C38 14.5561 37.5317 14.1132 36.9543 14.1132Z"
            />
            <path
                className="activeClass stroke-dark fill-dark"
                d="M36.9543 26.4151H21.3128C20.7456 24.9945 19.4402 24 17.9192 24C16.3982 24 15.0928 24.9945 14.5256 26.4151H1.0457C0.468301 26.4151 0 26.922 0 27.547C0 28.172 0.468301 28.6789 1.0457 28.6789H14.2781C14.576 30.5642 16.0926 32 17.9192 32C19.7458 32 21.2624 30.5649 21.5603 28.6789H36.9543C37.5317 28.6789 38 28.172 38 27.547C38 26.922 37.5323 26.4151 36.9543 26.4151Z"
            />
        </svg>
    )
}

export default SettingsIcon
