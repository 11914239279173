import React, { useState, useEffect, Fragment, useLayoutEffect } from 'react'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import validator from 'validator'
import {
    InputLabel,
    Select,
    TextField as TextFieldMUI,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Checkbox,
    TextField,
} from '@material-ui/core'
// per la data
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import DateFnsUtils from '@date-io/date-fns'
import PhoneInput from 'react-phone-input-2'
import PrevIcon from 'app/components/Icons/PrevIcon'
import Sectors from '../../Tables/sectors_simplified'
import FormTitles from 'app/components/Tables/recruiter_form_titles'
import StudyTable from 'app/components/Tables/CompanyRoleTable'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import CompanyRoleTable from 'app/components/Tables/CompanyRoleTable'

function SignupRecruiterSteps() {
    const initVal = 7
    const [stepSignup, setStepSignup] = useState(initVal)
    const [isSubmit, setSubmit] = useState(false)
    const [next, setNext] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [width, setWidth] = useState('0%')
    const [userInfo, setUserInfo] = useState({
        recruiter_guid: '',
        name: '',
        surname: '',
        birth_date: '',
        role: '',
        company_name: '',
        headquarters: '',
        phone: '',
        gender: '',
        email: '',
        size: '',
        description: '',
        sector: [],
        created: new Date(),
        lastUpdate: new Date(),
    })

    const {
        recruiter_guid,
        name,
        surname,
        birth_date,
        company_name,
        headquarters,
        role,
        phone,
        gender,
        email,
        photo,
        size,
        description,
        sector,
        created,
        lastUpdate,
    } = userInfo

    const defaultSectors = [
        'Ristorazione ed alloggio',
        'Manufatturiero',
        'Commercio al dettaglio',
        "Commercio all'ingrosso",
        'Edilizia',
        'Logistica e trasporti',
        'Meccanici e carrozzieri',
    ]

    const { user, setUserRole, updateUserName } = useAuth()
    const [showAllSectors, setShowAllSectors] = useState(false)

    const toggleShowAllSectors = () => {
        setShowAllSectors(!showAllSectors)
    }

    const sectorsToShow = showAllSectors ? Sectors : defaultSectors

    const handleStudyClick = (event) => {
        const { value } = event.target
        // const crole = CompanyRoleTable.find((loc) => loc === value)
        setUserInfo({ ...userInfo, role: value })
    }

    const handleAltroRuoloChange = (event) => {
        setUserInfo({ ...userInfo, role: event.target.value })
    }

    const setGeoLocationInfo = (place, geolocation) => {
        // console.log(geolocation)
        setUserInfo({
            ...userInfo,
            headquarters: place,
        })
    }

    const handleSubmit = async () => {
        // event.preventDefault()
        const res = await ApiService.create_recruiter(userInfo)
        // const res = await ApiService.add_user({ "uid": userInfo.recruiter_guid, "role": "recruiter" })
        // console.log(res)
        if (res.ok) {
            const response = await setUserRole('recruiter', true)
            await updateUserName(name)
            // setUserRole("recruiter", false)
            // history.push('/signup-completed-recruiter')
            if (response) history.push('/jump-recruiter')
            else history.push('/404')
        }
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const onChecked = (event) => {
        const categoryName = event.target.name
        const isChecked = event.target.checked
        // console.log(categoryName)

        // console.log(isChecked)
        let array = []
        let prevSelected = sector
        if (isChecked) {
            // If checked, add it to the array
            array = [...prevSelected, categoryName]
        } else {
            // If unchecked, remove it from the array
            array = prevSelected.filter((category) => category !== categoryName)
        }
        // console.log(array)

        setUserInfo({
            ...userInfo,
            sector: array,
        })

        // console.log(userInfo)
    }

    async function handleNext() {
        if (stepSignup === FormTitles.length - 1) await handleSubmit()
        else setStepSignup((prevActiveStep) => prevActiveStep + 1)
    }

    const changeNum = (num) => {
        setUserInfo({
            ...userInfo,
            phone: num,
        })
    }

    const handleDateChange = (date) => {
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }

        setUserInfo({
            ...userInfo,
            birth_date: date,
        })
    }

    const validate = () => {
        const errors = {}
        switch (stepSignup) {
            case initVal:
                if (!company_name)
                    errors.company_name = 'Questo campo non può essere nullo'
                if (!headquarters)
                    errors.headquarters = 'Questo campo non può essere nullo'
                break
            case initVal + 1:
                if (sector?.length <= 0)
                    errors.sector = 'Seleziona almeno un settore'
                break
            case initVal + 2:
                if (!name) errors.name = 'Questo campo non può essere nullo'
                if (!surname)
                    errors.surname = 'Questo campo non può essere nullo'
                if (!birth_date)
                    errors.birth_date = 'Questo campo non può essere nullo'
                if (new Date(birth_date).toString() === 'Invalid Date')
                    errors.birth_date = 'Inserisci una data valida '
                // else if (birth_date && new Date(birth_date) > new Date())
                //     errors.birth_date =
                //         'La data di nascita non può essere successiva al giorno corrente'
                // else if (new Date().getFullYear() - new Date(birth_date).getFullYear() < 16) errors.birth_date = "Devi avere almeno 16 anni di età per iscriverti"
                else if (
                    birth_date &&
                    Math.floor(
                        (new Date().getTime() -
                            new Date(
                                birth_date.getFullYear(),
                                birth_date.getMonth(),
                                birth_date.getDate()
                            ).getTime()) /
                            31557600000
                    ) < 18
                )
                    errors.birth_date =
                        'Devi avere almeno 18 anni di età per iscriverti'
                else if (
                    birth_date &&
                    new Date().getFullYear() -
                        new Date(birth_date).getFullYear() >
                        100
                )
                    errors.birth_date = 'Inserisci una data valida'
                if (!gender) errors.gender = 'Questo campo non può essere nullo'
                break
            case initVal + 3:
                if (!phone) errors.phone = 'Questo campo non può essere nullo'
                else if (!validator.isMobilePhone(phone))
                    errors.phone = 'Il formato di numero inserito non è valido'
                if (!role) {
                    errors.role =
                        'Seleziona almeno un ruolo dalla lista o aggiungine uno'
                }
                break
        }
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleNext()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    //MODIFICARE IN SEGUITO
    useEffect(() => {
        if (user !== null)
            setUserInfo({
                ...userInfo,
                email: user.email,
                recruiter_guid: user.uid,
            })
    }, [user])

    useEffect(() => {
        const stepSize = 2
        setWidth(`${(100 / FormTitles.length) * stepSignup}%`)
    }, [stepSignup])

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [stepSignup])

    return (
        <Fragment>
            {/* <progress id="file" value={100 / FormTitles.length * (stepSignup + 1)} max="100"></progress> */}

            {/* <div className="progressbar">
          <div
            style={{ width: width, transition: "width 0.5s ease" }}
          ></div>
        </div> */}

            <div
                className="onboarding-title"
                hidden={stepSignup === FormTitles.length - 1}
            >
                <h1>{FormTitles[stepSignup]}</h1>
                <div className="progressbar">
                    <div
                        style={{ width: width, transition: 'width 0.5s ease' }}
                    ></div>
                </div>
            </div>

            {/* <form className="create-account-form p-10"> */}
            {/* <div className="form-title">
            <h1>{FormTitles[stepSignup]}</h1>
          </div> */}
            {/* <div className="form-body d-flex flex-column"> */}
            <form>
                <div className="onboarding-grid ">
                    {/* {SignupStepDisplay()} */}
                    {(() => {
                        switch (stepSignup) {
                            case initVal + 2:
                                return (
                                    <>
                                        <div
                                            className="input-container"
                                            style={{ gap: '0.5rem' }}
                                        >
                                            <TextFieldMUI
                                                label="Il tuo nome *"
                                                type="text"
                                                variant="filled"
                                                name="name"
                                                value={name}
                                                onChange={onChange}
                                                fullWidth
                                                color="primary"
                                            />
                                            <p className="input-error text-center">
                                                {formErrors.name}
                                            </p>

                                            <TextFieldMUI
                                                label="Il tuo cognome *"
                                                type="text"
                                                variant="filled"
                                                name="surname"
                                                value={surname}
                                                onChange={onChange}
                                                fullWidth
                                                color="primary"
                                            />
                                            <p className="input-error text-center">
                                                {formErrors.surname}
                                            </p>

                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                                locale={itLocale}
                                            >
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Data di nascita"
                                                    format="dd/MM/yyyy"
                                                    value={birth_date || null}
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label':
                                                            'change date',
                                                    }}
                                                    fullWidth
                                                    autoOk
                                                    required
                                                    disableFuture
                                                    invalidDateMessage=""
                                                    maxDate={new Date()}
                                                    maxDateMessage=""
                                                    minDateMessage=""
                                                />
                                            </MuiPickersUtilsProvider>

                                            <p className="input-error text-center">
                                                {' '}
                                                {formErrors.birth_date}{' '}
                                            </p>

                                            {/* <div id="mui-phone">
                                                <p className="input-error text-center">
                                                    {' '}
                                                    {formErrors.phone}{' '}
                                                </p>
                                                <PhoneInput
                                                    country={'it'}
                                                    preferredCountries={['it']}
                                                    value={phone}
                                                    onChange={changeNum}
                                                    placeholder={
                                                        'Recapito telefonico *'
                                                    }
                                                />
                                            </div>
                                            <p className="input-error text-center">
                                                {' '}
                                                {formErrors.gender}{' '}
                                            </p> */}

                                            <FormControl
                                                variant="filled"
                                                fullWidth
                                            >
                                                <InputLabel
                                                    htmlFor="gender"
                                                    required
                                                >
                                                    Genere
                                                </InputLabel>
                                                <Select
                                                    native
                                                    fullWidth
                                                    // value={gender || ''}
                                                    name="gender"
                                                    value={gender}
                                                    onChange={onChange}
                                                    inputProps={{
                                                        name: 'gender',
                                                        id: 'gender',
                                                    }}
                                                >
                                                    <option
                                                        hidden
                                                        aria-label="None"
                                                        value={null}
                                                    />
                                                    <option value="male">
                                                        Uomo
                                                    </option>
                                                    <option value="female">
                                                        Donna
                                                    </option>
                                                    <option value="other">
                                                        Preferisco non
                                                        specificare
                                                    </option>
                                                </Select>
                                            </FormControl>

                                            <p className="input-error text-center">
                                                {' '}
                                                {formErrors.gender}{' '}
                                            </p>
                                        </div>
                                    </>
                                )
                            case initVal:
                                return (
                                    <>
                                        <div
                                            className="input-container"
                                            style={{ gap: '0.5rem' }}
                                        >
                                            <TextFieldMUI
                                                type="text"
                                                label="Nome azienda *"
                                                name="company_name"
                                                variant="filled"
                                                fullWidth
                                                color="primary"
                                                value={company_name}
                                                onChange={onChange}
                                            />
                                            <p className="input-error text-center">
                                                {formErrors.company_name}
                                            </p>

                                            {/* <TextFieldMUI
                                                type="text"
                                                label="Sede principale azienda *"
                                                variant="filled"
                                                name="headquarters"
                                                fullWidth
                                                color="primary"
                                                value={headquarters}
                                                onChange={onChange}
                                            /> */}
                                            <PlaceAutocomplete
                                                className="input-onboarding"
                                                setSetlected={
                                                    setGeoLocationInfo
                                                }
                                                defaultValue={headquarters}
                                                label={
                                                    'Sede principale azienda'
                                                }
                                            />
                                            <p className="input-error text-center">
                                                {formErrors.headquarters}{' '}
                                            </p>
                                        </div>

                                        {/* <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor="size">Numero dipendenti</InputLabel>
                        <Select
                          native
                          fullWidth
                          required
                          value={size}
                          onChange={onChange}
                          inputProps={{
                            name: 'size',
                            id: 'size',
                          }}
                        >
                          <option value="" disabled hidden defaultValue></option>
                          <option value="0-9">1-9</option>
                          <option value="10-49">10-49</option>
                          <option value="50-249">50-249</option>
                          <option value="250+">250+</option>
                        </Select>
                      </FormControl> */}
                                    </>
                                )
                            // case 2:
                            //   return (
                            //     <>
                            //          <TextFieldMUI
                            //             label="Una breve descrizione della tua azienda"
                            //             variant="filled"
                            //             name='description'
                            //             fullWidth
                            //             color="primary"
                            //             multiline
                            //             rows={7}
                            //             value={description}
                            //             onChange={onChange}
                            //         />
                            //     </>
                            //   )
                            case initVal + 1:
                                return (
                                    <Fragment>
                                        <p className="input-error text-center">
                                            {formErrors.sector}
                                        </p>

                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                        >
                                            <FormGroup
                                                name="sector"
                                                value={sector}
                                                className="sectorsTags"
                                            >
                                                {defaultSectors.map(
                                                    (category, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            label={category}
                                                            name="sector"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        onChecked
                                                                    }
                                                                    name={
                                                                        category
                                                                    }
                                                                    checked={sector.includes(
                                                                        category
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    )
                                                )}
                                                <div
                                                    onClick={
                                                        toggleShowAllSectors
                                                    }
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundColor:
                                                            'transparent',
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        outline: 'none',
                                                        color: '#4b4c55',
                                                        fontWeight: '600',
                                                        cursor: 'pointer',
                                                        gap: '0.5rem',
                                                        width: '100%',
                                                    }}
                                                    className="alto-btn"
                                                >
                                                    <span>
                                                        Vedi altri settori
                                                    </span>
                                                    {showAllSectors ? (
                                                        <ArrowDropUpIcon />
                                                    ) : (
                                                        <ArrowDropDownIcon />
                                                    )}
                                                </div>

                                                {showAllSectors &&
                                                    Sectors.filter(
                                                        (category) =>
                                                            !defaultSectors.includes(
                                                                category
                                                            )
                                                    ).map((category, index) => (
                                                        <FormControlLabel
                                                            key={
                                                                index +
                                                                defaultSectors.length
                                                            }
                                                            label={category}
                                                            name="sector"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        onChecked
                                                                    }
                                                                    name={
                                                                        category
                                                                    }
                                                                    checked={sector.includes(
                                                                        category
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Fragment>
                                )
                            case initVal + 3:
                                return (
                                    <>
                                        <div
                                            className="input-container nomrgmob "
                                            style={{
                                                gap: '0.5rem',
                                                marginTop: '0rem',
                                            }}
                                        >
                                            <FormControl
                                                component="fieldset"
                                                className="tags-check"
                                                style={{ marginBottom: '0' }}
                                            >
                                                <h3
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Qual è il tuo ruolo in
                                                    azienda?
                                                </h3>
                                                <FormGroup>
                                                    <RadioGroup
                                                        name="role"
                                                        value={role}
                                                        onChange={
                                                            handleStudyClick
                                                        }
                                                    >
                                                        <div className="work-list">
                                                            {CompanyRoleTable.map(
                                                                (
                                                                    crole,
                                                                    index
                                                                ) => (
                                                                    <FormControlLabel
                                                                        // className="skill-item study"
                                                                        key={
                                                                            index
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            crole
                                                                        }
                                                                        value={
                                                                            crole
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                        {/* {CompanyRoleTable.map(
                                                        (studyName) => (
                                                            <div
                                                                key={studyName}
                                                                className={`skill-item study ${
                                                                    CompanyRoleTable.includes(
                                                                        studyName
                                                                    )
                                                                        ? 'selected'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    handleStudyClick(
                                                                        studyName
                                                                    )
                                                                }
                                                            >
                                                                {studyName}
                                                            </div>
                                                        )
                                                    )} */}
                                                    </RadioGroup>
                                                </FormGroup>
                                            </FormControl>

                                            <TextField
                                                id="altro-ruolo"
                                                label="Altro ruolo"
                                                value={
                                                    !CompanyRoleTable.includes(
                                                        role
                                                    )
                                                        ? role
                                                        : null
                                                }
                                                onChange={
                                                    handleAltroRuoloChange
                                                }
                                            />

                                            <p className="input-error text-center">
                                                {formErrors.role}
                                            </p>

                                            <h4
                                                style={{
                                                    textAlign: 'center',
                                                    marginTop: '1rem',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Contatto telefonico
                                            </h4>
                                            <div id="mui-phone">
                                                <PhoneInput
                                                    country={'it'}
                                                    preferredCountries={['it']}
                                                    value={phone}
                                                    onChange={changeNum}
                                                    placeholder={
                                                        'Recapito telefonico*'
                                                    }
                                                />
                                            </div>
                                            <p className="input-error text-center">
                                                {formErrors.phone}
                                            </p>
                                        </div>
                                    </>
                                )
                            case initVal + 4:
                                return (
                                    <div className="signup-completed" id="rec">
                                        <img src="/logo2.png" alt="" />
                                        <h1 className="accent-text-color">
                                            CE L’ABBIAMO FATTA!
                                        </h1>
                                        <p>
                                            Preparati a incontrare nuovo
                                            personale. <br></br>
                                            Completa la tua offerta per farti
                                            notare e aumentare le tue
                                            probabilità di successo. <br></br>
                                            Con Kangrats, trovare personale non
                                            è più un lavoro!
                                        </p>
                                        <button
                                            onClick={async (event) => {
                                                event.preventDefault()
                                                // history.push('/jump-recruiter') //on error return 404
                                                setSubmit(true)
                                            }}
                                            className="btn primary-btn"
                                        >
                                            Iniziamo
                                        </button>
                                    </div>
                                )
                            default:
                                return <></>
                        }
                    })()}
                </div>
                {stepSignup !== FormTitles.length - 1 ? (
                    <div className="onboarding-actions">
                        <button
                            className="prev-btn"
                            disabled={stepSignup === initVal}
                            onClick={(e) => {
                                e.preventDefault()
                                setSubmit(false)
                                setStepSignup((currStep) => currStep - 1)
                            }}
                        >
                            <PrevIcon />
                        </button>
                        <div>
                            <button
                                className="next-button primary-btn btn"
                                hidden={stepSignup === FormTitles.length - 1}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSubmit(true)

                                    // stepSignup === (FormTitles.length - 1 ) ? history.push("/signup-completed-recruiter") : console.log('boh' + stepSignup);
                                }}
                            >
                                {/* {stepSignup === FormTitles.length - 1 ? "Invia" : "Continua"} */}
                                Prosegui
                            </button>
                        </div>
                    </div>
                ) : null}
            </form>
        </Fragment>
    )
}

export default SignupRecruiterSteps
