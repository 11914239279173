import React, { useEffect, useState } from 'react'
import {
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import useJWTAuth from 'app/hooks/useJWTAuth'
import history from 'history.js'

function PreloginForm({}) {
    const [preloginKey, setPreloginKey] = useState('')
    const [showKey, setShowKey] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setSubmit] = useState(false)

    const { login } = useJWTAuth()

    const handleClickShowKey = () => setShowKey((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleChange = (event) => {
        setPreloginKey(event.target.value)
    }

    const handleFormSubmit = async () => {
        try {
            await login(preloginKey)
            history.push('/start')
        } catch (e) {
            setFormErrors({
                ...formErrors,
                preloginKey: 'Il codice di invito non è valido.',
            })
        }
    }

    const validate = () => {
        const errors = {}
        if (!preloginKey)
            errors.preloginKey = 'Questo campo non può essere nullo'
        // else if (....) errors.preloginKey = "Codice non valido"
        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleFormSubmit()
            setSubmit(false)
        }
    }, [isSubmit])

    return (
        <>
            <main className="main-container-login">
                <div className="login-container prelogin">
                    <div>
                        <h1>Inserisci il tuo codice di invito</h1>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setSubmit(true)
                        }}
                    >
                        <div>
                            <p className="input-error">
                                {formErrors.preloginKey}
                            </p>
                            <FormControl
                                sx={{ m: 1, width: '25ch' }}
                                variant="filled"
                                fullWidth
                            >
                                <InputLabel htmlFor="filled-adornment-password">
                                    Codice di accesso
                                </InputLabel>
                                <FilledInput
                                    type={showKey ? 'text' : 'password'}
                                    name="preloginKey"
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowKey}
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showKey ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <p className="key-link">
                                Non hai un codice?{' '}
                                <a
                                    href="https://hylkxrsnso4.typeform.com/to/kQRbBmdn"
                                    target="_blank"
                                    title="kangrats.com"
                                >
                                    Richiedilo qui
                                </a>
                            </p>
                        </div>
                        <button
                            className="primary-btn btn big-border-radius prelogin-btn"
                            type="submit"
                        >
                            Invia
                        </button>
                    </form>
                </div>
            </main>
        </>
    )
}

export default PreloginForm
