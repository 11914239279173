import React, { useState, useEffect } from 'react'
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Dialog from '@material-ui/core/Dialog'
import EditIcon2 from '@material-ui/icons/Edit'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from 'react-router-dom'

const progressChecks = {
    photo: false,
    education: false,
    exp: false,
    soft: false,
    hard: false,
    langs: false,
    bio: false,
}

const tasks = [
    {
        id: 'photo',
        label: 'inserisci la foto profilo',
        completed: false,
        link: '/',
    },
    {
        id: 'education',
        label: 'inserisci un titolo di studio completo',
        completed: false,
        link: '/',
    },
    {
        id: 'exp',
        label: "inserisci un'esperienza professionale completa",
        completed: false,
        link: '/',
    },
    {
        id: 'soft',
        label: 'inserisci 5 competenze trasversali',
        completed: false,
        link: '/',
    },
    {
        id: 'hard',
        label: 'inserisci 5 competenze tecniche',
        completed: false,
        link: '/',
    },
    { id: 'langs', label: 'inserisci una lingua', completed: false, link: '/' },
    {
        id: 'bio',
        label: 'completa le informazioni del profilo',
        completed: false,
        link: '/',
    },
]

function ProfileCompletion({ progressChecks }) {
    const [taskList, setTaskList] = useState(tasks)
    const [open, setOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:600px)')

    const handleCloseConfirm = () => {
        setOpen(false)
    }

    useEffect(() => {
        // Update tasks based on progressChecks
        const updatedTasks = tasks.map((task) => ({
            ...task,
            completed: progressChecks[task.id] ?? false,
        }))
        setTaskList(updatedTasks)
    }, [progressChecks])

    return (
        <>
            <div className="btn-profile orange" onClick={() => setOpen(true)}>
                <EditIcon2 />
                Completa il profilo
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                className={
                    isMobile
                        ? 'small-dialog mobile'
                        : 'small-dialog profCompletion'
                }
            >
                <div className="d-flex space-between dialog-title profile-page recCompletprofilo">
                    <DialogTitle className="prof-dialog-title">
                        <EditIcon2 />
                        Completa il profilo
                    </DialogTitle>

                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        className="close-dialog"
                    >
                        <Closebtnblue />
                    </Button>
                </div>
                {taskList.map((task) => (
                    <Box
                        key={task.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 1,
                        }}
                    >
                        {task.completed ? (
                            <CheckCircleIcon
                                className="iconCloseProf"
                                sx={{ color: 'green', marginRight: 1 }}
                            />
                        ) : (
                            <Closebtnblue className="NotComplete" />
                        )}

                        <Typography
                            variant="body1"
                            // Vcomponent={Link}
                            // to={task.link}
                            className="tasks"
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                textAlign: 'left',
                                // cursor: 'pointer',
                            }}
                        >
                            {task.label}
                        </Typography>
                    </Box>
                ))}
            </Dialog>
        </>
    )
}

export default ProfileCompletion
