import React, { createContext, useState, useEffect } from 'react'
import Geolocation from 'react-native-geolocation-service';
import useAuth from "app/hooks/useAuth";
import { authRoles } from 'app/auth/authRoles';
import ApiService from 'app/ApiService';
import getDistanceBetweenPoints from 'app/functions/ComputeDistance';
import { useHistory } from "react-router-dom";

const initLocation = {}
const LocationContext = createContext({
    ...initLocation,
    method: 'GPS',
})

export const GeoLocationProvider = ({ children }) => {

    let history = useHistory();

    const [currentLocation, setCurrentLocation] = useState(initLocation)
    const {
        user,
        role
    } = useAuth()

    const hasPermission = async () => {
        const status = await Geolocation.requestAuthorization("whenInUse");
        console.log(status)

        if (status === 'granted') {
            return true;
        }

        if (status === 'denied') {
            alert('Location permission denied');
        }

        if (status === 'disabled') {
            alert(
                'Turn on Location Services to allow to determine your location.'
            );
        }

        return false;
    };

    const checkUserLocation = async (coord) => {
        const coordinates = JSON.parse(window.localStorage.getItem('geoLocation'))
        if (coordinates) {
            // console.log("getItem", getDistanceBetweenPoints(
            //     coordinates.latitude,
            //     coordinates.longitude, 
            //     coord.latitude, 
            //     coord.longitude))
            if (getDistanceBetweenPoints(
                coordinates.latitude,
                coordinates.longitude, 
                coord.latitude, 
                coord.longitude
                ) > 10)
            {
                const res = await ApiService.update_usergeolocation({
                    candidate_guid: user?.uid,
                    geolocation: {
                        lat: coord.latitude,
                        lon: coord.longitude
                    },
                    lastUpdate: new Date()
                })   
                if (res.ok) {
                    console.log("geolocation updated successfully")  
                    window.localStorage.setItem('geoLocation', JSON.stringify({'latitude': coord.latitude, 'longitude': coord.longitude}))
                } 
            }
        }
        else {
            window.localStorage.setItem('geoLocation', JSON.stringify({'latitude': coord.latitude, 'longitude': coord.longitude}))
            const response = await ApiService.get_usergeolocation(user?.uid)
            if (response.ok){
                const prevGeoLocation = await response.json()
                // console.log("prevGeoLocation: ", prevGeoLocation)
                // console.log("api", getDistanceBetweenPoints(
                //     prevGeoLocation.geolocation?.lat,
                //     prevGeoLocation.geolocation?.lon, 
                //     coord.latitude, 
                //     coord.longitude))
                if (getDistanceBetweenPoints(
                    prevGeoLocation.geolocation?.lat,
                    prevGeoLocation.geolocation?.lon, 
                    coord.latitude, 
                    coord.longitude
                    ) > 10)
                {
                    const res = await ApiService.update_usergeolocation({
                        candidate_guid: user?.uid,
                        geolocation: {
                            lat: coord.latitude,
                            lon: coord.longitude
                        },
                        lastUpdate: new Date()
                    })   
                    if (res.ok) {
                        console.log("geolocation updated successfully")  
                        window.localStorage.setItem('geoLocation', JSON.stringify({'latitude': coord.latitude, 'longitude': coord.longitude}))
                    } 
                }
            }  
        }
    }

    const getCurrentLocation = async () => {
        if (hasPermission) {
            Geolocation.getCurrentPosition(
                getLocation,
                errorCallback,
                {
                    enableHighAccuracy: true,
                    distanceFilter: 10000,
                    useSignificantChanges: true,
                    showLocationDialog: true
                }
            );
        }
    }

    const getLocation = async (position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        setCurrentLocation(position.coords)
    }
    const errorCallback = (error) => {
        console.log("error is: " + error)
        alert('Location permission denied');
        history.push("/geolocalizzazione-negata");
    }

    useEffect(() => {
        if (authRoles.candidate.includes(role)) {
            if (hasPermission) {
                Geolocation.watchPosition(
                    async (position) => {

                        // console.log("Latitude is :", position.coords.latitude);
                        // console.log("Longitude is :", position.coords.longitude);
                        setCurrentLocation(position.coords)
                        await checkUserLocation(position.coords)
                        // console.log(currentLocation)
                    },
                    errorCallback,
                    {
                        enableHighAccuracy: true,
                        distanceFilter: 10000,
                        useSignificantChanges: true,
                        showLocationDialog: true,
                        // maximumAge: 5000,
                        // timeout: 10000
                    }
                );
            }
        }
    }, [role])

    // const memorizedValue = useMemo(
    //     () => ({
    //         ...currentLocation,
    //         method: 'GPS',
    //     }),
    //     [currentLocation]
    // )

    return (
        <LocationContext.Provider
            value={{ currentLocation }}
        >
            {children}
        </LocationContext.Provider>
    )
}

export default LocationContext
