import React, { useEffect, useState, Fragment } from 'react'
import { debounce } from 'lodash'
import { lowerCase } from 'lodash'
import { TextField, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    popper: {
        '& .MuiAutocomplete-listbox': {
            maxHeight: '400px', // Increased max height for the dropdown
            '&::-webkit-scrollbar': {
                width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#5058ed',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#3d46cc',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
            },
        },
    },
}))

const AsyncAutocomplete = ({
    parentValue,
    onChange,
    label,
    apiRequest,
    additionalOpt = null,
    required = true,
    disabled = false,
    filterOptions = [],
    clearOnSelect = false,
}) => {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState(parentValue ?? '')
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(parentValue ?? null)

    // Debounced function
    const debouncedSearch = debounce(async (request) => {
        if (parentValue !== request) {
            let found = false
            let newOptions = []

            const response = await apiRequest(request)
            if (response.ok) {
                const body = await response.json()

                if (body) {
                    const filterOpt = filterOptions.map((x) => lowerCase(x))
                    const filteredBody = filterOpt.length
                        ? body.filter(
                              (el) => !filterOpt.includes(lowerCase(el))
                          )
                        : body

                    filteredBody.forEach((el) => {
                        found = lowerCase(el) === lowerCase(request) || found
                    })

                    newOptions = found
                        ? filteredBody
                        : [request, ...filteredBody]
                }
            }
            setOptions(newOptions)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, 600) // Debouncing for 600ms

    useEffect(() => {
        if (inputValue !== '' && value !== inputValue) {
            setLoading(true)
            debouncedSearch(inputValue) // Call the debounced search function
        }

        if (inputValue === '') setOptions([])

        return () => {
            debouncedSearch.cancel() // Cancel any pending debounced calls on unmount or when input changes
        }
    }, [inputValue])

    useEffect(() => {
        if (parentValue) setValue(parentValue)
    }, [parentValue])

    return (
        <Autocomplete
            className="select-arr"
            classes={{ popper: classes.popper }} // Apply custom popper class
            id="async-autocomplete"
            filterOptions={(x) => x}
            options={options}
            inputValue={inputValue}
            value={value}
            disabled={disabled}
            noOptionsText="Nessun risultato"
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue] : [])
                setValue(newValue)
                onChange(newValue)

                if (clearOnSelect) {
                    setInputValue('')
                    setValue(null)
                }
            }}
            loading={loading}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="filled"
                    fullWidth
                    required={required}
                    color="primary"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default AsyncAutocomplete
