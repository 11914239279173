const FormTitles = [
    'PARTIAMO DALLE TUE COMPETENZE',
    'PASSIAMO ALLA TUA ISTRUZIONE',
    'CONTINUIAMO CON LA TUA ESPERIENZA LAVORATIVA',
    'ANDIAMO ORA ALLE LINGUE',
    // 'Competenze tecniche',
    '',
    'Raccontaci chi sei',
    'Dicci dove e come trovarti',
    'ULTIME INFORMAZIONI (MA IMPORTANTI)',
    'ULTIME INFORMAZIONI (MA IMPORTANTI)',
    '',
]

export default FormTitles
