import React from 'react'

function HomeIcon() {

    const primaryColor = '#ea6c3a';

  return (
    <svg className='card-icon primaryColor'viewBox="0 0 22 22" fill="#ea6c3a" xmlns="http://www.w3.org/2000/svg">
        <path stroke={primaryColor} d="M10 20v-6h4v6h4v-8h3L12 3 3 12h3v8z" />
    </svg>  )
}

export default HomeIcon
