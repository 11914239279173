import React, {
    useContext,
    useEffect,
    useState,
} from 'react'
import { Redirect, useLocation, Route } from 'react-router-dom'
import useJWTAuth from 'app/hooks/useJWTAuth'
import IntroLayout from 'app/Layouts/IntroLayout/IntroLayout';

const AuthLoginGuard = ({ component: Component, ...rest}) => {

    const {
        isJWTAuthenticated
    } = useJWTAuth()

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()

    // const { routes } = useContext(AppContext);
    // const isUserRoleAuthenticated = getUserRoleAuthStatus(pathname, role, routes);
    // let authenticated = isAuthenticated && isUserRoleAuthenticated;

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE
    // let authenticated = isJWTAuthenticated
    // console.log(isJWTAuthenticated)
    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    return (
        <Route
            {...rest}
            render={props => {
                if (isJWTAuthenticated) return (
                    <IntroLayout>                
                        <Component {...props} />
                    </IntroLayout>
                )  
                else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/welcome',
                                state: { redirectUrl: previouseRoute },
                            }}
                        />
                    )
                }              
            }
        }
        />
    );
}

export default AuthLoginGuard
