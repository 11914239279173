const SectorDataTree = [
    {
        checked: false,
        lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Agricoltura, silvicoltura e pesca',
        children: [
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di cereali (escluso il riso)',
                label: '01.11.10 - Coltivazione di cereali (escluso il riso)',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di semi oleosi',
                label: '01.11.20 - Coltivazione di semi oleosi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di legumi da granella',
                label: '01.11.30 - Coltivazione di legumi da granella',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name:
                    'Coltivazioni miste di cereali, legumi da granella e semi oleosi',
                label: '01.11.40 - Coltivazioni miste di cereali, legumi da granella e semi oleosi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di riso',
                label: '01.12.00 - Coltivazione di riso',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name:
                    'Coltivazione di ortaggi (inclusi i meloni) in foglia, a fusto, a frutto, in radici, bulbi e tuberi in piena aria (escluse barbabietola da zucchero e patate)',
                label: '01.13.10 - Coltivazione di ortaggi (inclusi i meloni) in foglia, a fusto, a frutto, in radici, bulbi e tuberi in piena aria (escluse barbabietola da zucchero e patate)',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name:
                    'Coltivazione di ortaggi (inclusi i meloni) in foglia, a fusto, a frutto, in radici, bulbi e tuberi in colture protette (escluse barbabietola da zucchero e patate)',
                label: '01.13.20 - Coltivazione di ortaggi (inclusi i meloni) in foglia, a fusto, a frutto, in radici, bulbi e tuberi in colture protette (escluse barbabietola da zucchero e patate)',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di barbabietola da zucchero',
                label: '01.13.30 - Coltivazione di barbabietola da zucchero',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di patate',
                label: '01.13.40 - Coltivazione di patate',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di canna da zucchero',
                label: '01.14.00 - Coltivazione di canna da zucchero',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di tabacco',
                label: '01.15.00 - Coltivazione di tabacco',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name:
                    'Coltivazione di piante per la preparazione di fibre tessili',
                label: '01.16.00 - Coltivazione di piante per la preparazione di fibre tessili',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di fiori in piena aria',
                label: '01.19.10 - Coltivazione di fiori in piena aria',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name: 'Coltivazione di fiori in colture protette',
                label: '01.19.20 - Coltivazione di fiori in colture protette',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
                lev4_name:
                    'Coltivazione di piante da foraggio e di altre colture non permanenti',
                label: '01.19.90 - Coltivazione di piante da foraggio e di altre colture non permanenti',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name: 'Coltivazione di uva',
                label: '01.21.00 - Coltivazione di uva',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name:
                    'Coltivazione di frutta di origine tropicale e subtropicale',
                label: '01.22.00 - Coltivazione di frutta di origine tropicale e subtropicale',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name: 'Coltivazione di agrumi',
                label: '01.23.00 - Coltivazione di agrumi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name: 'Coltivazione di pomacee e frutta a nocciolo',
                label: '01.24.00 - Coltivazione di pomacee e frutta a nocciolo',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name:
                    'Coltivazione di altri alberi da frutta, frutti di bosco e frutta in guscio',
                label: '01.25.00 - Coltivazione di altri alberi da frutta, frutti di bosco e frutta in guscio',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name: 'Coltivazione di frutti oleosi',
                label: '01.26.00 - Coltivazione di frutti oleosi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name:
                    'Coltivazione di piante per la produzione di bevande',
                label: '01.27.00 - Coltivazione di piante per la produzione di bevande',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name:
                    'Coltivazione di spezie, piante aromatiche e farmaceutiche',
                label: '01.28.00 - Coltivazione di spezie, piante aromatiche e farmaceutiche',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'COLTIVAZIONE DI COLTURE PERMANENTI',
                lev4_name:
                    'Coltivazione di altre colture permanenti (inclusi alberi di Natale)',
                label: '01.29.00 - Coltivazione di altre colture permanenti (inclusi alberi di Natale)',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'RIPRODUZIONE DELLE PIANTE',
                lev4_name: 'Riproduzione delle piante',
                label: '01.30.00 - Riproduzione delle piante',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name:
                    'Allevamento di bovini e bufale da latte, produzione di latte crudo',
                label: '01.41.00 - Allevamento di bovini e bufale da latte, produzione di latte crudo',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di bovini e bufalini da carne',
                label: '01.42.00 - Allevamento di bovini e bufalini da carne',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di cavalli e altri equini',
                label: '01.43.00 - Allevamento di cavalli e altri equini',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di cammelli e camelidi',
                label: '01.44.00 - Allevamento di cammelli e camelidi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di ovini e caprini',
                label: '01.45.00 - Allevamento di ovini e caprini',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di suini',
                label: '01.46.00 - Allevamento di suini',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di pollame',
                label: '01.47.00 - Allevamento di pollame',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di conigli',
                label: '01.49.10 - Allevamento di conigli',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di animali da pelliccia',
                label: '01.49.20 - Allevamento di animali da pelliccia',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Apicoltura',
                label: '01.49.30 - Apicoltura',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Bachicoltura',
                label: '01.49.40 - Bachicoltura',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'ALLEVAMENTO DI ANIMALI',
                lev4_name: 'Allevamento di altri animali nca',
                label: '01.49.90 - Allevamento di altri animali nca',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "COLTIVAZIONI AGRICOLE ASSOCIATE ALL'ALLEVAMENTO DI ANIMALI: ATTIVITÀ MISTA",
                lev4_name:
                    "Coltivazioni agricole associate all'allevamento di animali: attività mista",
                label: "01.50.00 - Coltivazioni agricole associate all'allevamento di animali: attività mista",
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name: 'Attività di supporto alla produzione vegetale',
                label: '01.61.00 - Attività di supporto alla produzione vegetale',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name: 'Attività dei maniscalchi',
                label: '01.62.01 - Attività dei maniscalchi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name:
                    'Altre attività di supporto alla produzione animale (esclusi i servizi veterinari)',
                label: '01.62.09 - Altre attività di supporto alla produzione animale (esclusi i servizi veterinari)',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name: 'Attività che seguono la raccolta',
                label: '01.63.00 - Attività che seguono la raccolta',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name: 'Pulitura e cernita di semi e granaglie',
                label: '01.64.01 - Pulitura e cernita di semi e granaglie',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'AGRICOLTURA E ATTIVITÀ SUCCESSIVE ALLA RACCOLTA",
                lev4_name: 'Altre lavorazioni delle sementi per la semina',
                label: '01.64.09 - Altre lavorazioni delle sementi per la semina',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name:
                    'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
                lev3_name: 'CACCIA, CATTURA DI ANIMALI E SERVIZI CONNESSI',
                lev4_name: 'Caccia, cattura di animali e servizi connessi',
                label: '01.70.00 - Caccia, cattura di animali e servizi connessi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'SILVICOLTURA ED UTILIZZO DI AREE FORESTALI',
                lev3_name: 'SILVICOLTURA ED ALTRE ATTIVITÀ FORESTALI',
                lev4_name: 'Silvicoltura e altre attività forestali',
                label: '02.10.00 - Silvicoltura e altre attività forestali',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'SILVICOLTURA ED UTILIZZO DI AREE FORESTALI',
                lev3_name: 'UTILIZZO DI AREE FORESTALI',
                lev4_name: 'Utilizzo di aree forestali',
                label: '02.20.00 - Utilizzo di aree forestali',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'SILVICOLTURA ED UTILIZZO DI AREE FORESTALI',
                lev3_name: 'RACCOLTA DI PRODOTTI SELVATICI NON LEGNOSI',
                lev4_name: 'Raccolta di prodotti selvatici non legnosi',
                label: '02.30.00 - Raccolta di prodotti selvatici non legnosi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'SILVICOLTURA ED UTILIZZO DI AREE FORESTALI',
                lev3_name: 'SERVIZI DI SUPPORTO PER LA SILVICOLTURA',
                lev4_name: 'Servizi di supporto per la silvicoltura',
                label: '02.40.00 - Servizi di supporto per la silvicoltura',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'PESCA E ACQUACOLTURA',
                lev3_name: 'PESCA',
                lev4_name:
                    'Pesca in acque marine e lagunari e servizi connessi',
                label: '03.11.00 - Pesca in acque marine e lagunari e servizi connessi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'PESCA E ACQUACOLTURA',
                lev3_name: 'PESCA',
                lev4_name: 'Pesca in acque dolci e servizi connessi',
                label: '03.12.00 - Pesca in acque dolci e servizi connessi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'PESCA E ACQUACOLTURA',
                lev3_name: 'ACQUACOLTURA',
                lev4_name:
                    'Acquacoltura in acqua di mare, salmastra o lagunare e servizi connessi',
                label: '03.21.00 - Acquacoltura in acqua di mare, salmastra o lagunare e servizi connessi',
            },
            {
                checked: false,
                lev1_name: 'AGRICOLTURA, SILVICOLTURA E PESCA',
                lev2_name: 'PESCA E ACQUACOLTURA',
                lev3_name: 'ACQUACOLTURA',
                lev4_name: 'Acquacoltura in acque dolci e servizi connessi',
                label: '03.22.00 - Acquacoltura in acque dolci e servizi connessi',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Estrazione di minierali da cave e miniere',
        children: [
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI CARBONE (ESCLUSA TORBA)',
                lev3_name: 'ESTRAZIONE DI ANTRACITE',
                lev4_name: 'Estrazione di antracite e litantrace',
                label: '05.10.00 - Estrazione di antracite e litantrace ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI CARBONE (ESCLUSA TORBA)',
                lev3_name: 'ESTRAZIONE DI LIGNITE',
                lev4_name: 'Estrazione di lignite',
                label: '05.20.00 - Estrazione di lignite ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI PETROLIO GREGGIO E DI GAS NATURALE',
                lev3_name: 'ESTRAZIONE DI PETROLIO GREGGIO',
                lev4_name: 'Estrazione di petrolio greggio',
                label: '06.10.00 - Estrazione di petrolio greggio ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI PETROLIO GREGGIO E DI GAS NATURALE',
                lev3_name: 'ESTRAZIONE DI GAS NATURALE',
                lev4_name: 'Estrazione di gas naturale',
                label: '06.20.00 - Estrazione di gas naturale ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI MINERALI METALLIFERI',
                lev3_name: 'ESTRAZIONE DI MINERALI METALLIFERI FERROSI',
                lev4_name: 'Estrazione di minerali metalliferi ferrosi',
                label: '07.10.00 - Estrazione di minerali metalliferi ferrosi ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI MINERALI METALLIFERI',
                lev3_name: 'ESTRAZIONE DI MINERALI METALLIFERI NON FERROSI',
                lev4_name: 'Estrazione di minerali di uranio e di torio',
                label: '07.21.00 - Estrazione di minerali di uranio e di torio ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: 'ESTRAZIONE DI MINERALI METALLIFERI',
                lev3_name: 'ESTRAZIONE DI MINERALI METALLIFERI NON FERROSI',
                lev4_name:
                    'Estrazione di altri minerali metalliferi non ferrosi',
                label: '07.29.00 - Estrazione di altri minerali metalliferi non ferrosi ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI PIETRA, SABBIA E ARGILLA',
                lev4_name:
                    'Estrazione di pietre ornamentali e da costruzione, calcare, pietra da gesso, creta e ardesia',
                label: '08.11.00 - Estrazione di pietre ornamentali e da costruzione, calcare, pietra da gesso, creta e ardesia ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI PIETRA, SABBIA E ARGILLA',
                lev4_name:
                    'Estrazione di ghiaia, sabbia; estrazione di argille e caolino',
                label: '08.12.00 - Estrazione di ghiaia, sabbia; estrazione di argille e caolino ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE NCA',
                lev4_name:
                    "Estrazione di minerali per l'industria chimica e per la produzione di fertilizzanti",
                label: "08.91.00 - Estrazione di minerali per l'industria chimica e per la produzione di fertilizzanti ",
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE NCA',
                lev4_name: 'Estrazione di torba',
                label: '08.92.00 - Estrazione di torba ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE NCA',
                lev4_name: 'Estrazione di sale',
                label: '08.93.00 - Estrazione di sale ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE NCA',
                lev4_name: 'Estrazione di asfalto e bitume naturale',
                label: '08.99.01 - Estrazione di asfalto e bitume naturale ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name:
                    'ALTRE ATTIVITÀ DI ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev3_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE NCA',
                lev4_name: 'Estrazione di pomice e di altri minerali nca',
                label: '08.99.09 - Estrazione di pomice e di altri minerali nca ',
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: "ATTIVITÀ DEI SERVIZI DI SUPPORTO ALL'ESTRAZIONE",
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO ALL'ESTRAZIONE DI PETROLIO E DI GAS NATURALE",
                lev4_name:
                    "Attività di supporto all'estrazione di petrolio e di gas naturale",
                label: "09.10.00 - Attività di supporto all'estrazione di petrolio e di gas naturale ",
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: "ATTIVITÀ DEI SERVIZI DI SUPPORTO ALL'ESTRAZIONE",
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO PER L'ESTRAZIONE DA CAVE E MINIERE DI ALTRI MINERALI",
                lev4_name:
                    "Attività di supporto all'estrazione di pietre ornamentali, da costruzione, da gesso, di anidrite, per calce e cementi, di dolomite, di ardesia, di ghiaia e sabbia, di argilla, di caolino, di pomice, di altri minerali e prodotti di cava",
                label: "09.90.01 - Attività di supporto all'estrazione di pietre ornamentali, da costruzione, da gesso, di anidrite, per calce e cementi, di dolomite, di ardesia, di ghiaia e sabbia, di argilla, di caolino, di pomice, di altri minerali e prodotti di cava ",
            },
            {
                checked: false,
                lev1_name: 'ESTRAZIONE DI MINERALI DA CAVE E MINIERE',
                lev2_name: "ATTIVITÀ DEI SERVIZI DI SUPPORTO ALL'ESTRAZIONE",
                lev3_name:
                    "ATTIVITÀ DI SUPPORTO PER L'ESTRAZIONE DA CAVE E MINIERE DI ALTRI MINERALI",
                lev4_name: "Altre attività di supporto all'estrazione",
                label: "09.90.09 - Altre attività di supporto all'estrazione ",
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ATTIVITÀ MANIFATTURIERE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività manifatturiere',
        children: [
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE E CONSERVAZIONE DI CARNE E PRODUZIONE DI PRODOTTI A BASE DI CARNE',
                lev4_name:
                    'Produzione di carne non di volatili e di prodotti della macellazione (attività dei mattatoi)',
                label: '10.11.00 - Produzione di carne non di volatili e di prodotti della macellazione (attività dei mattatoi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE E CONSERVAZIONE DI CARNE E PRODUZIONE DI PRODOTTI A BASE DI CARNE',
                lev4_name:
                    'Produzione di carne di volatili e prodotti della loro macellazione (attività dei mattatoi)',
                label: '10.12.00 - Produzione di carne di volatili e prodotti della loro macellazione (attività dei mattatoi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE E CONSERVAZIONE DI CARNE E PRODUZIONE DI PRODOTTI A BASE DI CARNE',
                lev4_name:
                    'Produzione di prodotti a base di carne (inclusa la carne di volatili)',
                label: '10.13.00 - Produzione di prodotti a base di carne (inclusa la carne di volatili) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE E CONSERVAZIONE DI PESCE, CROSTACEI E MOLLUSCHI',
                lev4_name:
                    'Lavorazione e conservazione di pesce, crostacei e molluschi mediante surgelamento, salatura eccetera',
                label: '10.20.00 - Lavorazione e conservazione di pesce, crostacei e molluschi mediante surgelamento, salatura eccetera ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'LAVORAZIONE E CONSERVAZIONE DI FRUTTA E ORTAGGI',
                lev4_name: 'Lavorazione e conservazione delle patate',
                label: '10.31.00 - Lavorazione e conservazione delle patate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'LAVORAZIONE E CONSERVAZIONE DI FRUTTA E ORTAGGI',
                lev4_name: 'Produzione di succhi di frutta e di ortaggi',
                label: '10.32.00 - Produzione di succhi di frutta e di ortaggi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'LAVORAZIONE E CONSERVAZIONE DI FRUTTA E ORTAGGI',
                lev4_name:
                    'Lavorazione e conservazione di frutta e di ortaggi (esclusi i succhi di frutta e di ortaggi)',
                label: '10.39.00 - Lavorazione e conservazione di frutta e di ortaggi (esclusi i succhi di frutta e di ortaggi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI OLI E GRASSI VEGETALI E ANIMALI',
                lev4_name:
                    'Produzione di olio di oliva da olive prevalentemente non di produzione propria',
                label: '10.41.10 - Produzione di olio di oliva da olive prevalentemente non di produzione propria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI OLI E GRASSI VEGETALI E ANIMALI',
                lev4_name:
                    'Produzione di olio raffinato o grezzo da semi oleosi o frutti oleosi prevalentemente non di produzione propria',
                label: '10.41.20 - Produzione di olio raffinato o grezzo da semi oleosi o frutti oleosi prevalentemente non di produzione propria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI OLI E GRASSI VEGETALI E ANIMALI',
                lev4_name:
                    'Produzione di oli e grassi animali grezzi o raffinati',
                label: '10.41.30 - Produzione di oli e grassi animali grezzi o raffinati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI OLI E GRASSI VEGETALI E ANIMALI',
                lev4_name:
                    'Produzione di margarina e di grassi commestibili simili',
                label: '10.42.00 - Produzione di margarina e di grassi commestibili simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'INDUSTRIA LATTIERO-CASEARIA',
                lev4_name: 'Trattamento igienico del latte',
                label: '10.51.10 - Trattamento igienico del latte ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'INDUSTRIA LATTIERO-CASEARIA',
                lev4_name: 'Produzione dei derivati del latte',
                label: '10.51.20 - Produzione dei derivati del latte ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'INDUSTRIA LATTIERO-CASEARIA',
                lev4_name:
                    'Produzione di gelati senza vendita diretta al pubblico',
                label: '10.52.00 - Produzione di gelati senza vendita diretta al pubblico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE DELLE GRANAGLIE, PRODUZIONE DI AMIDI E DI PRODOTTI AMIDACEI',
                lev4_name: 'Molitura del frumento',
                label: '10.61.10 - Molitura del frumento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE DELLE GRANAGLIE, PRODUZIONE DI AMIDI E DI PRODOTTI AMIDACEI',
                lev4_name: 'Molitura di altri cereali',
                label: '10.61.20 - Molitura di altri cereali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE DELLE GRANAGLIE, PRODUZIONE DI AMIDI E DI PRODOTTI AMIDACEI',
                lev4_name: 'Lavorazione del riso',
                label: '10.61.30 - Lavorazione del riso ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE DELLE GRANAGLIE, PRODUZIONE DI AMIDI E DI PRODOTTI AMIDACEI',
                lev4_name: 'Altre lavorazioni di semi e granaglie',
                label: '10.61.40 - Altre lavorazioni di semi e granaglie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    'LAVORAZIONE DELLE GRANAGLIE, PRODUZIONE DI AMIDI E DI PRODOTTI AMIDACEI',
                lev4_name:
                    'Produzione di amidi e di prodotti amidacei (inclusa produzione di olio di mais)',
                label: '10.62.00 - Produzione di amidi e di prodotti amidacei (inclusa produzione di olio di mais) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI PRODOTTI DA FORNO E FARINACEI',
                lev4_name: 'Produzione di prodotti di panetteria freschi',
                label: '10.71.10 - Produzione di prodotti di panetteria freschi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI PRODOTTI DA FORNO E FARINACEI',
                lev4_name: 'Produzione di pasticceria fresca',
                label: '10.71.20 - Produzione di pasticceria fresca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI PRODOTTI DA FORNO E FARINACEI',
                lev4_name:
                    'Produzione di fette biscottate, biscotti; prodotti di pasticceria conservati',
                label: '10.72.00 - Produzione di fette biscottate, biscotti; prodotti di pasticceria conservati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI PRODOTTI DA FORNO E FARINACEI',
                lev4_name:
                    'Produzione di paste alimentari, di cuscus e di prodotti farinacei simili',
                label: '10.73.00 - Produzione di paste alimentari, di cuscus e di prodotti farinacei simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di zucchero',
                label: '10.81.00 - Produzione di zucchero ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name:
                    'Produzione di cacao in polvere, cioccolato, caramelle e confetterie',
                label: '10.82.00 - Produzione di cacao in polvere, cioccolato, caramelle e confetterie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Lavorazione del caffè',
                label: '10.83.01 - Lavorazione del caffè ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Lavorazione del tè e di altri preparati per infusi',
                label: '10.83.02 - Lavorazione del tè e di altri preparati per infusi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di condimenti e spezie',
                label: '10.84.00 - Produzione di condimenti e spezie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name:
                    'Produzione di piatti pronti a base di carne e pollame',
                label: '10.85.01 - Produzione di piatti pronti a base di carne e pollame ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name:
                    'Produzione di piatti pronti a base di pesce, inclusi fish and chips',
                label: '10.85.02 - Produzione di piatti pronti a base di pesce, inclusi fish and chips ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di piatti pronti a base di ortaggi',
                label: '10.85.03 - Produzione di piatti pronti a base di ortaggi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di pizza confezionata',
                label: '10.85.04 - Produzione di pizza confezionata ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di piatti pronti a base di pasta',
                label: '10.85.05 - Produzione di piatti pronti a base di pasta ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name:
                    'Produzione di pasti e piatti pronti di altri prodotti alimentari',
                label: '10.85.09 - Produzione di pasti e piatti pronti di altri prodotti alimentari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name:
                    'Produzione di preparati omogeneizzati e di alimenti dietetici',
                label: '10.86.00 - Produzione di preparati omogeneizzati e di alimenti dietetici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di estratti e succhi di carne',
                label: '10.89.01 - Produzione di estratti e succhi di carne ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name: 'PRODUZIONE DI ALTRI PRODOTTI ALIMENTARI',
                lev4_name: 'Produzione di altri prodotti alimentari nca',
                label: '10.89.09 - Produzione di altri prodotti alimentari nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    "PRODUZIONE DI PRODOTTI PER L'ALIMENTAZIONE DEGLI ANIMALI",
                lev4_name:
                    "Produzione di mangimi per l'alimentazione degli animali da allevamento",
                label: "10.91.00 - Produzione di mangimi per l'alimentazione degli animali da allevamento ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE ALIMENTARI',
                lev3_name:
                    "PRODUZIONE DI PRODOTTI PER L'ALIMENTAZIONE DEGLI ANIMALI",
                lev4_name:
                    "Produzione di prodotti per l'alimentazione degli animali da compagnia",
                label: "10.92.00 - Produzione di prodotti per l'alimentazione degli animali da compagnia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name:
                    'Distillazione, rettifica e miscelatura degli alcolici',
                label: '11.01.00 - Distillazione, rettifica e miscelatura degli alcolici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name: 'Produzione di vini da tavola e v.p.q.r.d.',
                label: '11.02.10 - Produzione di vini da tavola e v.p.q.r.d. ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name: 'Produzione di vino spumante e altri vini speciali',
                label: '11.02.20 - Produzione di vino spumante e altri vini speciali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name:
                    'Produzione di sidro e di altri vini a base di frutta',
                label: '11.03.00 - Produzione di sidro e di altri vini a base di frutta ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name:
                    'Produzione di altre bevande fermentate non distillate',
                label: '11.04.00 - Produzione di altre bevande fermentate non distillate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name: 'Produzione di birra',
                label: '11.05.00 - Produzione di birra ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name: 'Produzione di malto',
                label: '11.06.00 - Produzione di malto ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DELLE BEVANDE',
                lev3_name: 'INDUSTRIA DELLE BEVANDE',
                lev4_name:
                    'Industria delle bibite analcoliche, delle acque minerali e di altre acque in bottiglia',
                label: '11.07.00 - Industria delle bibite analcoliche, delle acque minerali e di altre acque in bottiglia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIA DEL TABACCO',
                lev3_name: 'INDUSTRIA DEL TABACCO',
                lev4_name: 'Industria del tabacco',
                label: '12.00.00 - Industria del tabacco ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'PREPARAZIONE E FILATURA DI FIBRE TESSILI',
                lev4_name: 'Preparazione e filatura di fibre tessili',
                label: '13.10.00 - Preparazione e filatura di fibre tessili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'TESSITURA',
                lev4_name: 'Tessitura',
                label: '13.20.00 - Tessitura ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'FINISSAGGIO DEI TESSILI',
                lev4_name: 'Finissaggio dei tessili',
                label: '13.30.00 - Finissaggio dei tessili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di tessuti a maglia',
                label: '13.91.00 - Fabbricazione di tessuti a maglia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name:
                    "Confezionamento di biancheria da letto, da tavola e per l'arredamento",
                label: "13.92.10 - Confezionamento di biancheria da letto, da tavola e per l'arredamento ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di articoli in materie tessili nca',
                label: '13.92.20 - Fabbricazione di articoli in materie tessili nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di tappeti e moquette',
                label: '13.93.00 - Fabbricazione di tappeti e moquette ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di spago, corde, funi e reti',
                label: '13.94.00 - Fabbricazione di spago, corde, funi e reti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name:
                    'Fabbricazione di tessuti non tessuti e di articoli in tali materie (esclusi gli articoli di abbigliamento)',
                label: '13.95.00 - Fabbricazione di tessuti non tessuti e di articoli in tali materie (esclusi gli articoli di abbigliamento) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name:
                    'Fabbricazione di nastri, etichette e passamanerie di fibre tessili',
                label: '13.96.10 - Fabbricazione di nastri, etichette e passamanerie di fibre tessili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name:
                    'Fabbricazione di altri articoli tessili tecnici ed industriali',
                label: '13.96.20 - Fabbricazione di altri articoli tessili tecnici ed industriali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di ricami',
                label: '13.99.10 - Fabbricazione di ricami ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di tulle, pizzi e merletti',
                label: '13.99.20 - Fabbricazione di tulle, pizzi e merletti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'INDUSTRIE TESSILI',
                lev3_name: 'ALTRE INDUSTRIE TESSILI',
                lev4_name: 'Fabbricazione di feltro e articoli tessili diversi',
                label: '13.99.90 - Fabbricazione di feltro e articoli tessili diversi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name: 'Confezione di abbigliamento in pelle e similpelle',
                label: '14.11.00 - Confezione di abbigliamento in pelle e similpelle ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name:
                    'Confezione di camici, divise ed altri indumenti da lavoro',
                label: '14.12.00 - Confezione di camici, divise ed altri indumenti da lavoro ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name: 'Confezione in serie di abbigliamento esterno',
                label: '14.13.10 - Confezione in serie di abbigliamento esterno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name:
                    'Sartoria e confezione su misura di abbigliamento esterno',
                label: '14.13.20 - Sartoria e confezione su misura di abbigliamento esterno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name:
                    'Confezione di camice, T-shirt, corsetteria e altra biancheria intima',
                label: '14.14.00 - Confezione di camice, T-shirt, corsetteria e altra biancheria intima ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name: "Confezioni varie e accessori per l'abbigliamento",
                label: "14.19.10 - Confezioni varie e accessori per l'abbigliamento ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name:
                    'Fabbricazione di calzature realizzate in materiale tessile senza suole applicate',
                label: '14.19.21 - Fabbricazione di calzature realizzate in materiale tessile senza suole applicate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO (ESCLUSO ABBIGLIAMENTO IN PELLICCIA)',
                lev4_name:
                    'Confezioni di abbigliamento sportivo o di altri indumenti particolari',
                label: '14.19.29 - Confezioni di abbigliamento sportivo o di altri indumenti particolari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name: 'CONFEZIONE DI ARTICOLI IN PELLICCIA',
                lev4_name: 'Confezione di articoli in pelliccia',
                label: '14.20.00 - Confezione di articoli in pelliccia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI MAGLIERIA',
                lev4_name: 'Fabbricazione di articoli di calzetteria in maglia',
                label: '14.31.00 - Fabbricazione di articoli di calzetteria in maglia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'CONFEZIONE DI ARTICOLI DI ABBIGLIAMENTO; CONFEZIONE DI ARTICOLI IN PELLE E PELLICCIA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI MAGLIERIA',
                lev4_name:
                    'Fabbricazione di pullover, cardigan ed altri articoli simili a maglia',
                label: '14.39.00 - Fabbricazione di pullover, cardigan ed altri articoli simili a maglia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ARTICOLI IN PELLE E SIMILI',
                lev3_name:
                    'PREPARAZIONE E CONCIA DEL CUOIO; FABBRICAZIONE DI ARTICOLI DA VIAGGIO, BORSE, PELLETTERIA E SELLERIA; PREPARAZIONE E TINTURA DI PELLICCE',
                lev4_name:
                    'Preparazione e concia del cuoio e pelle; preparazione e tintura di pellicce',
                label: '15.11.00 - Preparazione e concia del cuoio e pelle; preparazione e tintura di pellicce ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ARTICOLI IN PELLE E SIMILI',
                lev3_name:
                    'PREPARAZIONE E CONCIA DEL CUOIO; FABBRICAZIONE DI ARTICOLI DA VIAGGIO, BORSE, PELLETTERIA E SELLERIA; PREPARAZIONE E TINTURA DI PELLICCE',
                lev4_name:
                    'Fabbricazione di frustini e scudisci per equitazione',
                label: '15.12.01 - Fabbricazione di frustini e scudisci per equitazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ARTICOLI IN PELLE E SIMILI',
                lev3_name:
                    'PREPARAZIONE E CONCIA DEL CUOIO; FABBRICAZIONE DI ARTICOLI DA VIAGGIO, BORSE, PELLETTERIA E SELLERIA; PREPARAZIONE E TINTURA DI PELLICCE',
                lev4_name:
                    'Fabbricazione di altri articoli da viaggio, borse e simili, pelletteria e selleria',
                label: '15.12.09 - Fabbricazione di altri articoli da viaggio, borse e simili, pelletteria e selleria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ARTICOLI IN PELLE E SIMILI',
                lev3_name: 'FABBRICAZIONE DI CALZATURE',
                lev4_name: 'Fabbricazione di calzature',
                label: '15.20.10 - Fabbricazione di calzature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ARTICOLI IN PELLE E SIMILI',
                lev3_name: 'FABBRICAZIONE DI CALZATURE',
                lev4_name: 'Fabbricazione di parti in cuoio per calzature',
                label: '15.20.20 - Fabbricazione di parti in cuoio per calzature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name: 'TAGLIO E PIALLATURA DEL LEGNO',
                lev4_name: 'Taglio e piallatura del legno',
                label: '16.10.00 - Taglio e piallatura del legno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di fogli da impiallacciatura e di pannelli a base di legno',
                label: '16.21.00 - Fabbricazione di fogli da impiallacciatura e di pannelli a base di legno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name: 'Fabbricazione di pavimenti in parquet assemblato',
                label: '16.22.00 - Fabbricazione di pavimenti in parquet assemblato ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di porte e finestre in legno (escluse porte blindate)',
                label: '16.23.10 - Fabbricazione di porte e finestre in legno (escluse porte blindate) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    "Fabbricazione di altri elementi in legno e di falegnameria per l'edilizia",
                label: "16.23.20 - Fabbricazione di altri elementi in legno e di falegnameria per l'edilizia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di stand e altre strutture simili per convegni e fiere prevalentemente in legno',
                label: '16.23.21 - Fabbricazione di stand e altre strutture simili per convegni e fiere prevalentemente in legno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di altri elementi in legno e di falegnameria per l’edilizia (esclusi stand e strutture simili per convegni e fiere)',
                label: '16.23.22 - Fabbricazione di altri elementi in legno e di falegnameria per l’edilizia (esclusi stand e strutture simili per convegni e fiere) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name: 'Fabbricazione di imballaggi in legno',
                label: '16.24.00 - Fabbricazione di imballaggi in legno ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name: 'Fabbricazione di parti in legno per calzature',
                label: '16.29.11 - Fabbricazione di parti in legno per calzature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di manici di ombrelli, bastoni e simile',
                label: '16.29.12 - Fabbricazione di manici di ombrelli, bastoni e simile ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di altri prodotti vari in legno (esclusi i mobili)',
                label: '16.29.19 - Fabbricazione di altri prodotti vari in legno (esclusi i mobili) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione dei prodotti della lavorazione del sughero',
                label: '16.29.20 - Fabbricazione dei prodotti della lavorazione del sughero ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name:
                    'Fabbricazione di articoli in paglia e materiali da intreccio',
                label: '16.29.30 - Fabbricazione di articoli in paglia e materiali da intreccio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'INDUSTRIA DEL LEGNO E DEI PRODOTTI IN LEGNO E SUGHERO (ESCLUSI I MOBILI); FABBRICAZIONE DI ARTICOLI IN PAGLIA E MATERIALI DA INTRECCIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN LEGNO, SUGHERO, PAGLIA E MATERIALI DA INTRECCIO',
                lev4_name: 'Laboratori di corniciai',
                label: '16.29.40 - Laboratori di corniciai ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI PASTA-CARTA, CARTA E CARTONE',
                lev4_name: 'Fabbricazione di pasta-carta',
                label: '17.11.00 - Fabbricazione di pasta-carta ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI PASTA-CARTA, CARTA E CARTONE',
                lev4_name: 'Fabbricazione di carta e cartone',
                label: '17.12.00 - Fabbricazione di carta e cartone ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name:
                    'Fabbricazione di carta e cartone ondulato e di imballaggi di carta e cartone (esclusi quelli in carta pressata)',
                label: '17.21.00 - Fabbricazione di carta e cartone ondulato e di imballaggi di carta e cartone (esclusi quelli in carta pressata) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name:
                    'Fabbricazione di prodotti igienico-sanitari e per uso domestico in carta e ovatta di cellulosa',
                label: '17.22.00 - Fabbricazione di prodotti igienico-sanitari e per uso domestico in carta e ovatta di cellulosa ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name:
                    "Fabbricazione di prodotti cartotecnici scolastici e commerciali quando l'attività di stampa non è la principale caratteristica",
                label: "17.23.01 - Fabbricazione di prodotti cartotecnici scolastici e commerciali quando l'attività di stampa non è la principale caratteristica ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name: 'Fabbricazione di altri prodotti cartotecnici',
                label: '17.23.09 - Fabbricazione di altri prodotti cartotecnici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name: 'Fabbricazione di carta da parati',
                label: '17.24.00 - Fabbricazione di carta da parati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI CARTA E DI PRODOTTI DI CARTA',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI DI CARTA E CARTONE',
                lev4_name: 'Fabbricazione di altri articoli di carta e cartone',
                label: '17.29.00 - Fabbricazione di altri articoli di carta e cartone ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'STAMPA E RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev3_name: 'STAMPA E SERVIZI CONNESSI ALLA STAMPA',
                lev4_name: 'Stampa di giornali',
                label: '18.11.00 - Stampa di giornali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'STAMPA E RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev3_name: 'STAMPA E SERVIZI CONNESSI ALLA STAMPA',
                lev4_name: 'Altra stampa',
                label: '18.12.00 - Altra stampa ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'STAMPA E RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev3_name: 'STAMPA E SERVIZI CONNESSI ALLA STAMPA',
                lev4_name: 'Lavorazioni preliminari alla stampa e ai media',
                label: '18.13.00 - Lavorazioni preliminari alla stampa e ai media ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'STAMPA E RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev3_name: 'STAMPA E SERVIZI CONNESSI ALLA STAMPA',
                lev4_name: 'Legatoria e servizi connessi',
                label: '18.14.00 - Legatoria e servizi connessi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'STAMPA E RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev3_name: 'RIPRODUZIONE DI SUPPORTI REGISTRATI',
                lev4_name: 'Riproduzione di supporti registrati',
                label: '18.20.00 - Riproduzione di supporti registrati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name: 'FABBRICAZIONE DI PRODOTTI DI COKERIA',
                lev4_name: 'Fabbricazione di pece e coke di pece',
                label: '19.10.01 - Fabbricazione di pece e coke di pece ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name: 'FABBRICAZIONE DI PRODOTTI DI COKERIA',
                lev4_name: 'Fabbricazione di altri prodotti di cokeria',
                label: '19.10.09 - Fabbricazione di altri prodotti di cokeria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev4_name: 'Raffinerie di petrolio',
                label: '19.20.10 - Raffinerie di petrolio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev4_name:
                    'Preparazione o miscelazione di derivati del petrolio (esclusa la petrolchimica)',
                label: '19.20.20 - Preparazione o miscelazione di derivati del petrolio (esclusa la petrolchimica) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev4_name:
                    'Miscelazione di gas petroliferi liquefatti (GPL) e loro imbottigliamento',
                label: '19.20.30 - Miscelazione di gas petroliferi liquefatti (GPL) e loro imbottigliamento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev4_name:
                    'Fabbricazione di emulsioni di bitume, di catrame e di leganti per uso stradale',
                label: '19.20.40 - Fabbricazione di emulsioni di bitume, di catrame e di leganti per uso stradale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COKE E PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DERIVANTI DALLA RAFFINAZIONE DEL PETROLIO',
                lev4_name:
                    'Fabbricazione di altri prodotti petroliferi raffinati',
                label: '19.20.90 - Fabbricazione di altri prodotti petroliferi raffinati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name: 'Fabbricazione di gas industriali',
                label: '20.11.00 - Fabbricazione di gas industriali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name: 'Fabbricazione di coloranti e pigmenti',
                label: '20.12.00 - Fabbricazione di coloranti e pigmenti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name: 'Fabbricazione di uranio e torio arricchito',
                label: '20.13.01 - Fabbricazione di uranio e torio arricchito ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name:
                    'Fabbricazione di altri prodotti chimici di base inorganici',
                label: '20.13.09 - Fabbricazione di altri prodotti chimici di base inorganici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name:
                    'Fabbricazione di alcol etilico da materiali fermentati',
                label: '20.14.01 - Fabbricazione di alcol etilico da materiali fermentati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name:
                    'Fabbricazione di altri prodotti chimici di base organici nca',
                label: '20.14.09 - Fabbricazione di altri prodotti chimici di base organici nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name:
                    'Fabbricazione di fertilizzanti e composti azotati (esclusa la fabbricazione di compost)',
                label: '20.15.00 - Fabbricazione di fertilizzanti e composti azotati (esclusa la fabbricazione di compost) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name:
                    'Fabbricazione di materie plastiche in forme primarie',
                label: '20.16.00 - Fabbricazione di materie plastiche in forme primarie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI CHIMICI DI BASE, DI FERTILIZZANTI E COMPOSTI AZOTATI, DI MATERIE PLASTICHE E GOMMA SINTETICA IN FORME PRIMARIE',
                lev4_name: 'Fabbricazione di gomma sintetica in forme primarie',
                label: '20.17.00 - Fabbricazione di gomma sintetica in forme primarie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    "FABBRICAZIONE DI AGROFARMACI E DI ALTRI PRODOTTI CHIMICI PER L'AGRICOLTURA",
                lev4_name:
                    "Fabbricazione di agrofarmaci e di altri prodotti chimici per l'agricoltura (esclusi i concimi)",
                label: "20.20.00 - Fabbricazione di agrofarmaci e di altri prodotti chimici per l'agricoltura (esclusi i concimi) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI PITTURE, VERNICI E SMALTI, INCHIOSTRI DA STAMPA E ADESIVI SINTETICI',
                lev4_name:
                    'Fabbricazione di pitture, vernici e smalti, inchiostri da stampa e adesivi sintetici',
                label: '20.30.00 - Fabbricazione di pitture, vernici e smalti, inchiostri da stampa e adesivi sintetici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI SAPONI E DETERGENTI, DI PRODOTTI PER LA PULIZIA E LA LUCIDATURA, DI PROFUMI E COSMETICI',
                lev4_name:
                    'Fabbricazione di saponi, detergenti e di agenti organici tensioattivi (esclusi i prodotti per toletta)',
                label: '20.41.10 - Fabbricazione di saponi, detergenti e di agenti organici tensioattivi (esclusi i prodotti per toletta) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI SAPONI E DETERGENTI, DI PRODOTTI PER LA PULIZIA E LA LUCIDATURA, DI PROFUMI E COSMETICI',
                lev4_name:
                    'Fabbricazione di specialità chimiche per uso domestico e per manutenzione',
                label: '20.41.20 - Fabbricazione di specialità chimiche per uso domestico e per manutenzione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name:
                    'FABBRICAZIONE DI SAPONI E DETERGENTI, DI PRODOTTI PER LA PULIZIA E LA LUCIDATURA, DI PROFUMI E COSMETICI',
                lev4_name:
                    'Fabbricazione di prodotti per toletta: profumi, cosmetici, saponi e simili',
                label: '20.42.00 - Fabbricazione di prodotti per toletta: profumi, cosmetici, saponi e simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Fabbricazione di fiammiferi',
                label: '20.51.01 - Fabbricazione di fiammiferi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Fabbricazione di articoli esplosivi',
                label: '20.51.02 - Fabbricazione di articoli esplosivi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Fabbricazione di colle',
                label: '20.52.00 - Fabbricazione di colle ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Fabbricazione di oli essenziali',
                label: '20.53.00 - Fabbricazione di oli essenziali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti chimici per uso fotografico',
                label: '20.59.10 - Fabbricazione di prodotti chimici per uso fotografico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti chimici organici ottenuti da prodotti di base derivati da processi di fermentazione o da materie prime vegetali',
                label: '20.59.20 - Fabbricazione di prodotti chimici organici ottenuti da prodotti di base derivati da processi di fermentazione o da materie prime vegetali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Trattamento chimico degli acidi grassi',
                label: '20.59.30 - Trattamento chimico degli acidi grassi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti chimici vari per uso industriale (inclusi i preparati antidetonanti e antigelo)',
                label: '20.59.40 - Fabbricazione di prodotti chimici vari per uso industriale (inclusi i preparati antidetonanti e antigelo) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti chimici impiegati per ufficio e per il consumo non industriale',
                label: '20.59.50 - Fabbricazione di prodotti chimici impiegati per ufficio e per il consumo non industriale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti ausiliari per le industrie tessili e del cuoio',
                label: '20.59.60 - Fabbricazione di prodotti ausiliari per le industrie tessili e del cuoio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name:
                    'Fabbricazione di prodotti elettrochimici (esclusa produzione di cloro, soda e potassa) ed elettrotermici',
                label: '20.59.70 - Fabbricazione di prodotti elettrochimici (esclusa produzione di cloro, soda e potassa) ed elettrotermici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI CHIMICI',
                lev4_name: 'Fabbricazione di altri prodotti chimici nca',
                label: '20.59.90 - Fabbricazione di altri prodotti chimici nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI PRODOTTI CHIMICI',
                lev3_name: 'FABBRICAZIONE DI FIBRE SINTETICHE E ARTIFICIALI',
                lev4_name: 'Fabbricazione di fibre sintetiche e artificiali',
                label: '20.60.00 - Fabbricazione di fibre sintetiche e artificiali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI FARMACEUTICI DI BASE E DI PREPARATI FARMACEUTICI',
                lev3_name: 'FABBRICAZIONE DI PRODOTTI FARMACEUTICI DI BASE',
                lev4_name: 'Fabbricazione di prodotti farmaceutici di base',
                label: '21.10.00 - Fabbricazione di prodotti farmaceutici di base ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI FARMACEUTICI DI BASE E DI PREPARATI FARMACEUTICI',
                lev3_name:
                    'FABBRICAZIONE DI MEDICINALI E PREPARATI FARMACEUTICI',
                lev4_name:
                    'Fabbricazione di sostanza diagnostiche radioattive in vivo',
                label: '21.20.01 - Fabbricazione di sostanza diagnostiche radioattive in vivo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI FARMACEUTICI DI BASE E DI PREPARATI FARMACEUTICI',
                lev3_name:
                    'FABBRICAZIONE DI MEDICINALI E PREPARATI FARMACEUTICI',
                lev4_name:
                    'Fabbricazione di medicinali ed altri preparati farmaceutici',
                label: '21.20.09 - Fabbricazione di medicinali ed altri preparati farmaceutici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN GOMMA',
                lev4_name: "Fabbricazione di pneumatici e di camere d'aria",
                label: "22.11.10 - Fabbricazione di pneumatici e di camere d'aria ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN GOMMA',
                lev4_name: 'Rigenerazione e ricostruzione di pneumatici',
                label: '22.11.20 - Rigenerazione e ricostruzione di pneumatici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN GOMMA',
                lev4_name:
                    'Fabbricazione di suole di gomma e altre parti in gomma per calzature',
                label: '22.19.01 - Fabbricazione di suole di gomma e altre parti in gomma per calzature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN GOMMA',
                lev4_name: 'Fabbricazione di altri prodotti in gomma nca',
                label: '22.19.09 - Fabbricazione di altri prodotti in gomma nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    'Fabbricazione di lastre, fogli, tubi e profilati in materie plastiche',
                label: '22.21.00 - Fabbricazione di lastre, fogli, tubi e profilati in materie plastiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name: 'Fabbricazione di imballaggi in materie plastiche',
                label: '22.22.00 - Fabbricazione di imballaggi in materie plastiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    'Fabbricazione di rivestimenti elastici per pavimenti (vinile, linoleum eccetera)',
                label: '22.23.01 - Fabbricazione di rivestimenti elastici per pavimenti (vinile, linoleum eccetera) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    "Fabbricazione di porte, finestre, intelaiature eccetera in plastica per l'edilizia",
                label: "22.23.02 - Fabbricazione di porte, finestre, intelaiature eccetera in plastica per l'edilizia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    "Fabbricazione di altri articoli in plastica per l'edilizia",
                label: "22.23.09 - Fabbricazione di altri articoli in plastica per l'edilizia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name: 'Fabbricazione di parti in plastica per calzature',
                label: '22.29.01 - Fabbricazione di parti in plastica per calzature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    'Fabbricazione di oggetti di cancelleria in plastica',
                label: '22.29.02 - Fabbricazione di oggetti di cancelleria in plastica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ARTICOLI IN GOMMA E MATERIE PLASTICHE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI IN MATERIE PLASTICHE',
                lev4_name:
                    'Fabbricazione di altri articoli in materie plastiche nca',
                label: '22.29.09 - Fabbricazione di altri articoli in materie plastiche nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name: 'Fabbricazione di vetro piano',
                label: '23.11.00 - Fabbricazione di vetro piano ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name: 'Lavorazione e trasformazione del vetro piano',
                label: '23.12.00 - Lavorazione e trasformazione del vetro piano ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name: 'Fabbricazione di vetro cavo',
                label: '23.13.00 - Fabbricazione di vetro cavo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name: 'Fabbricazione di fibre di vetro',
                label: '23.14.00 - Fabbricazione di fibre di vetro ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name:
                    'Fabbricazione di vetrerie per laboratori, per uso igienico, per farmacia',
                label: '23.19.10 - Fabbricazione di vetrerie per laboratori, per uso igienico, per farmacia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name: 'Lavorazione di vetro a mano e a soffio artistico',
                label: '23.19.20 - Lavorazione di vetro a mano e a soffio artistico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI VETRO E DI PRODOTTI IN VETRO',
                lev4_name:
                    'Fabbricazione di altri prodotti in vetro (inclusa la vetreria tecnica)',
                label: '23.19.90 - Fabbricazione di altri prodotti in vetro (inclusa la vetreria tecnica) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'FABBRICAZIONE DI PRODOTTI REFRATTARI',
                lev4_name: 'Fabbricazione di prodotti refrattari',
                label: '23.20.00 - Fabbricazione di prodotti refrattari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI MATERIALI DA COSTRUZIONE IN TERRACOTTA',
                lev4_name:
                    'Fabbricazione di piastrelle in ceramica per pavimenti e rivestimenti',
                label: '23.31.00 - Fabbricazione di piastrelle in ceramica per pavimenti e rivestimenti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI MATERIALI DA COSTRUZIONE IN TERRACOTTA',
                lev4_name:
                    "Fabbricazione di mattoni, tegole ed altri prodotti per l'edilizia in terracotta",
                label: "23.32.00 - Fabbricazione di mattoni, tegole ed altri prodotti per l'edilizia in terracotta ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI IN PORCELLANA E IN CERAMICA',
                lev4_name:
                    'Fabbricazione di prodotti in ceramica per usi domestici e ornamentali',
                label: '23.41.00 - Fabbricazione di prodotti in ceramica per usi domestici e ornamentali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI IN PORCELLANA E IN CERAMICA',
                lev4_name: 'Fabbricazione di articoli sanitari in ceramica',
                label: '23.42.00 - Fabbricazione di articoli sanitari in ceramica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI IN PORCELLANA E IN CERAMICA',
                lev4_name:
                    'Fabbricazione di isolatori e di pezzi isolanti in ceramica',
                label: '23.43.00 - Fabbricazione di isolatori e di pezzi isolanti in ceramica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI IN PORCELLANA E IN CERAMICA',
                lev4_name:
                    'Fabbricazione di altri prodotti in ceramica per uso tecnico e industriale',
                label: '23.44.00 - Fabbricazione di altri prodotti in ceramica per uso tecnico e industriale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI IN PORCELLANA E IN CERAMICA',
                lev4_name: 'Fabbricazione di altri prodotti in ceramica',
                label: '23.49.00 - Fabbricazione di altri prodotti in ceramica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'PRODUZIONE DI CEMENTO, CALCE E GESSO',
                lev4_name: 'Produzione di cemento',
                label: '23.51.00 - Produzione di cemento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'PRODUZIONE DI CEMENTO, CALCE E GESSO',
                lev4_name: 'Produzione di calce',
                label: '23.52.10 - Produzione di calce ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'PRODUZIONE DI CEMENTO, CALCE E GESSO',
                lev4_name: 'Produzione di gesso',
                label: '23.52.20 - Produzione di gesso ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name:
                    "Fabbricazione di prodotti in calcestruzzo per l'edilizia",
                label: "23.61.00 - Fabbricazione di prodotti in calcestruzzo per l'edilizia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name: "Fabbricazione di prodotti in gesso per l'edilizia",
                label: "23.62.00 - Fabbricazione di prodotti in gesso per l'edilizia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name: "Produzione di calcestruzzo pronto per l'uso",
                label: "23.63.00 - Produzione di calcestruzzo pronto per l'uso ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name: 'Produzione di malta',
                label: '23.64.00 - Produzione di malta ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name: 'Fabbricazione di prodotti in fibrocemento',
                label: '23.65.00 - Fabbricazione di prodotti in fibrocemento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI IN CALCESTRUZZO, CEMENTO E GESSO',
                lev4_name:
                    'Fabbricazione di altri prodotti in calcestruzzo, gesso e cemento',
                label: '23.69.00 - Fabbricazione di altri prodotti in calcestruzzo, gesso e cemento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'TAGLIO, MODELLATURA E FINITURA DI PIETRE',
                lev4_name: 'Segagione e lavorazione delle pietre e del marmo',
                label: '23.70.10 - Segagione e lavorazione delle pietre e del marmo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'TAGLIO, MODELLATURA E FINITURA DI PIETRE',
                lev4_name:
                    'Lavorazione artistica del marmo e di altre pietre affini, lavori in mosaico',
                label: '23.70.20 - Lavorazione artistica del marmo e di altre pietre affini, lavori in mosaico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name: 'TAGLIO, MODELLATURA E FINITURA DI PIETRE',
                lev4_name:
                    'Frantumazione di pietre e minerali vari fuori della cava',
                label: '23.70.30 - Frantumazione di pietre e minerali vari fuori della cava ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI ABRASIVI E DI PRODOTTI IN MINERALI NON METALLIFERI NCA',
                lev4_name: 'Produzione di prodotti abrasivi',
                label: '23.91.00 - Produzione di prodotti abrasivi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI ALTRI PRODOTTI DELLA LAVORAZIONE DI MINERALI NON METALLIFERI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI ABRASIVI E DI PRODOTTI IN MINERALI NON METALLIFERI NCA',
                lev4_name:
                    'Fabbricazione di altri prodotti in minerali non metalliferi nca',
                label: '23.99.00 - Fabbricazione di altri prodotti in minerali non metalliferi nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name: 'SIDERURGIA',
                lev4_name:
                    'Siderurgia - Fabbricazione di ferro, acciaio e ferroleghe',
                label: '24.10.00 - Siderurgia - Fabbricazione di ferro, acciaio e ferroleghe ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'FABBRICAZIONE DI TUBI, CONDOTTI, PROFILATI CAVI E RELATIVI ACCESSORI IN ACCIAIO (ESCLUSI QUELLI IN ACCIAIO COLATO)',
                lev4_name: 'Fabbricazione di tubi e condotti senza saldatura',
                label: '24.20.10 - Fabbricazione di tubi e condotti senza saldatura ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'FABBRICAZIONE DI TUBI, CONDOTTI, PROFILATI CAVI E RELATIVI ACCESSORI IN ACCIAIO (ESCLUSI QUELLI IN ACCIAIO COLATO)',
                lev4_name: 'Fabbricazione di tubi e condotti saldati e simili',
                label: '24.20.20 - Fabbricazione di tubi e condotti saldati e simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name: 'Stiratura a freddo di barre',
                label: '24.31.00 - Stiratura a freddo di barre ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name: 'Laminazione a freddo di nastri',
                label: '24.32.00 - Laminazione a freddo di nastri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name: 'Fabbricazione di pannelli stratificati in acciaio',
                label: '24.33.01 - Fabbricazione di pannelli stratificati in acciaio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name:
                    'Profilatura mediante formatura o piegatura a freddo',
                label: '24.33.02 - Profilatura mediante formatura o piegatura a freddo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name: 'Presagomatura dell’acciaio per cemento armato',
                label: '24.33.03 - Presagomatura dell’acciaio per cemento armato ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    "FABBRICAZIONE DI ALTRI PRODOTTI DELLA PRIMA TRASFORMAZIONE DELL'ACCIAIO",
                lev4_name: 'Trafilatura a freddo',
                label: '24.34.00 - Trafilatura a freddo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name: 'Produzione di metalli preziosi e semilavorati',
                label: '24.41.00 - Produzione di metalli preziosi e semilavorati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name: 'Produzione di alluminio e semilavorati',
                label: '24.42.00 - Produzione di alluminio e semilavorati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name:
                    'Produzione di piombo, zinco e stagno e semilavorati',
                label: '24.43.00 - Produzione di piombo, zinco e stagno e semilavorati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name: 'Produzione di rame e semilavorati',
                label: '24.44.00 - Produzione di rame e semilavorati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name:
                    'Produzione di altri metalli non ferrosi e semilavorati',
                label: '24.45.00 - Produzione di altri metalli non ferrosi e semilavorati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name:
                    'PRODUZIONE DI METALLI DI BASE PREZIOSI E ALTRI METALLI NON FERROSI, TRATTAMENTO DEI COMBUSTIBILI NUCLEARI',
                lev4_name:
                    "Trattamento dei combustibili nucleari (escluso l'arricchimento di uranio e torio)",
                label: "24.46.00 - Trattamento dei combustibili nucleari (escluso l'arricchimento di uranio e torio) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name: 'FONDERIE',
                lev4_name:
                    'Fusione di ghisa e produzione di tubi e raccordi in ghisa',
                label: '24.51.00 - Fusione di ghisa e produzione di tubi e raccordi in ghisa ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name: 'FONDERIE',
                lev4_name: 'Fusione di acciaio',
                label: '24.52.00 - Fusione di acciaio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name: 'FONDERIE',
                lev4_name: 'Fusione di metalli leggeri',
                label: '24.53.00 - Fusione di metalli leggeri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'METALLURGIA',
                lev3_name: 'FONDERIE',
                lev4_name: 'Fusione di altri metalli non ferrosi',
                label: '24.54.00 - Fusione di altri metalli non ferrosi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ELEMENTI DA COSTRUZIONE IN METALLO',
                lev4_name:
                    'Fabbricazione di strutture metalliche e parti assemblate di strutture',
                label: '25.11.00 - Fabbricazione di strutture metalliche e parti assemblate di strutture ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ELEMENTI DA COSTRUZIONE IN METALLO',
                lev4_name:
                    'Fabbricazione di porte, finestre e loro telai, imposte e cancelli metallici',
                label: '25.12.10 - Fabbricazione di porte, finestre e loro telai, imposte e cancelli metallici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ELEMENTI DA COSTRUZIONE IN METALLO',
                lev4_name:
                    'Fabbricazione di strutture metalliche per tende da sole, tende alla veneziana e simili',
                label: '25.12.20 - Fabbricazione di strutture metalliche per tende da sole, tende alla veneziana e simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI CISTERNE, SERBATOI, RADIATORI E CONTENITORI IN METALLO',
                lev4_name:
                    'Fabbricazione di radiatori e contenitori in metallo per caldaie per il riscaldamento centrale',
                label: '25.21.00 - Fabbricazione di radiatori e contenitori in metallo per caldaie per il riscaldamento centrale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI CISTERNE, SERBATOI, RADIATORI E CONTENITORI IN METALLO',
                lev4_name:
                    'Fabbricazione di cisterne, serbatoi e contenitori in metallo per impieghi di stoccaggio o di produzione',
                label: '25.29.00 - Fabbricazione di cisterne, serbatoi e contenitori in metallo per impieghi di stoccaggio o di produzione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI GENERATORI DI VAPORE (ESCLUSI I CONTENITORI IN METALLO PER CALDAIE PER IL RISCALDAMENTO CENTRALE AD ACQUA CALDA)',
                lev4_name:
                    'Fabbricazione di generatori di vapore (esclusi i contenitori in metallo per caldaie per il riscaldamento centrale ad acqua calda)',
                label: '25.30.00 - Fabbricazione di generatori di vapore (esclusi i contenitori in metallo per caldaie per il riscaldamento centrale ad acqua calda) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ARMI E MUNIZIONI',
                lev4_name: 'Fabbricazione di armi e munizioni',
                label: '25.40.00 - Fabbricazione di armi e munizioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FUCINATURA, IMBUTITURA, STAMPAGGIO E PROFILATURA DEI METALLI; METALLURGIA DELLE POLVERI',
                lev4_name:
                    'Fucinatura, imbutitura, stampaggio e profilatura dei metalli; metallurgia delle polveri',
                label: '25.50.00 - Fucinatura, imbutitura, stampaggio e profilatura dei metalli; metallurgia delle polveri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'TRATTAMENTO E RIVESTIMENTO DEI METALLI; LAVORI DI MECCANICA GENERALE',
                lev4_name: 'Trattamento e rivestimento dei metalli',
                label: '25.61.00 - Trattamento e rivestimento dei metalli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'TRATTAMENTO E RIVESTIMENTO DEI METALLI; LAVORI DI MECCANICA GENERALE',
                lev4_name: 'Lavori di meccanica generale',
                label: '25.62.00 - Lavori di meccanica generale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ARTICOLI DI COLTELLERIA, UTENSILI E OGGETTI DI FERRAMENTA',
                lev4_name:
                    'Fabbricazione di articoli di coltelleria, posateria ed armi bianche',
                label: '25.71.00 - Fabbricazione di articoli di coltelleria, posateria ed armi bianche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ARTICOLI DI COLTELLERIA, UTENSILI E OGGETTI DI FERRAMENTA',
                lev4_name:
                    'Fabbricazione di serrature e cerniere e ferramenta simili',
                label: '25.72.00 - Fabbricazione di serrature e cerniere e ferramenta simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ARTICOLI DI COLTELLERIA, UTENSILI E OGGETTI DI FERRAMENTA',
                lev4_name:
                    'Fabbricazione di utensileria ad azionamento manuale',
                label: '25.73.11 - Fabbricazione di utensileria ad azionamento manuale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ARTICOLI DI COLTELLERIA, UTENSILI E OGGETTI DI FERRAMENTA',
                lev4_name:
                    'Fabbricazione di parti intercambiabili per macchine utensili',
                label: '25.73.12 - Fabbricazione di parti intercambiabili per macchine utensili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name:
                    'FABBRICAZIONE DI ARTICOLI DI COLTELLERIA, UTENSILI E OGGETTI DI FERRAMENTA',
                lev4_name:
                    'Fabbricazione di stampi, portastampi, sagome, forme per macchine',
                label: '25.73.20 - Fabbricazione di stampi, portastampi, sagome, forme per macchine ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    "Fabbricazione di bidoni in acciaio e contenitori analoghi per il trasporto e l'imballaggio",
                label: "25.91.00 - Fabbricazione di bidoni in acciaio e contenitori analoghi per il trasporto e l'imballaggio ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name: 'Fabbricazione di imballaggi leggeri in metallo',
                label: '25.92.00 - Fabbricazione di imballaggi leggeri in metallo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di prodotti fabbricati con fili metallici',
                label: '25.93.10 - Fabbricazione di prodotti fabbricati con fili metallici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name: 'Fabbricazione di molle',
                label: '25.93.20 - Fabbricazione di molle ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di catene fucinate senza saldatura e stampate',
                label: '25.93.30 - Fabbricazione di catene fucinate senza saldatura e stampate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name: 'Fabbricazione di articoli di bulloneria',
                label: '25.94.00 - Fabbricazione di articoli di bulloneria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di caraffe e bottiglie isolate in metallo',
                label: '25.99.11 - Fabbricazione di caraffe e bottiglie isolate in metallo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    "Fabbricazione di stoviglie, pentolame, vasellame, attrezzi da cucina e altri accessori casalinghi non elettrici, articoli metallici per l'arredamento di stanze da bagno",
                label: "25.99.19 - Fabbricazione di stoviglie, pentolame, vasellame, attrezzi da cucina e altri accessori casalinghi non elettrici, articoli metallici per l'arredamento di stanze da bagno ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di casseforti, forzieri, porte metalliche blindate',
                label: '25.99.20 - Fabbricazione di casseforti, forzieri, porte metalliche blindate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di oggetti in ferro, in rame ed altri metalli',
                label: '25.99.30 - Fabbricazione di oggetti in ferro, in rame ed altri metalli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name: 'Fabbricazione di magneti metallici permanenti',
                label: '25.99.91 - Fabbricazione di magneti metallici permanenti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI PRODOTTI IN METALLO (ESCLUSI MACCHINARI E ATTREZZATURE)',
                lev3_name: 'FABBRICAZIONE DI ALTRI PRODOTTI IN METALLO',
                lev4_name:
                    'Fabbricazione di altri articoli metallici e minuteria metallica nca',
                label: '25.99.99 - Fabbricazione di altri articoli metallici e minuteria metallica nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI COMPONENTI ELETTRONICI E SCHEDE ELETTRONICHE',
                lev4_name:
                    'Fabbricazione di diodi, transistor e relativi congegni elettronici',
                label: '26.11.01 - Fabbricazione di diodi, transistor e relativi congegni elettronici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI COMPONENTI ELETTRONICI E SCHEDE ELETTRONICHE',
                lev4_name: 'Fabbricazione di altri componenti elettronici',
                label: '26.11.09 - Fabbricazione di altri componenti elettronici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI COMPONENTI ELETTRONICI E SCHEDE ELETTRONICHE',
                lev4_name: 'Fabbricazione di schede elettroniche assemblate',
                label: '26.12.00 - Fabbricazione di schede elettroniche assemblate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name: 'FABBRICAZIONE DI COMPUTER E UNITÀ PERIFERICHE',
                lev4_name: 'Fabbricazione di computer e unità periferiche',
                label: '26.20.00 - Fabbricazione di computer e unità periferiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI APPARECCHIATURE PER LE TELECOMUNICAZIONI',
                lev4_name:
                    'Fabbricazione di apparecchi trasmittenti radiotelevisivi (incluse le telecamere)',
                label: '26.30.10 - Fabbricazione di apparecchi trasmittenti radiotelevisivi (incluse le telecamere) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI APPARECCHIATURE PER LE TELECOMUNICAZIONI',
                lev4_name: 'Fabbricazione di sistemi antifurto e antincendio',
                label: '26.30.21 - Fabbricazione di sistemi antifurto e antincendio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI APPARECCHIATURE PER LE TELECOMUNICAZIONI',
                lev4_name:
                    'Fabbricazione di altri apparecchi elettrici ed elettronici per telecomunicazioni',
                label: '26.30.29 - Fabbricazione di altri apparecchi elettrici ed elettronici per telecomunicazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DI ELETTRONICA DI CONSUMO AUDIO E VIDEO',
                lev4_name:
                    'Fabbricazione di apparecchi per la riproduzione e registrazione del suono e delle immagini',
                label: '26.40.01 - Fabbricazione di apparecchi per la riproduzione e registrazione del suono e delle immagini ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI PRODOTTI DI ELETTRONICA DI CONSUMO AUDIO E VIDEO',
                lev4_name:
                    'Fabbricazione di console per videogiochi (esclusi i giochi elettronici)',
                label: '26.40.02 - Fabbricazione di console per videogiochi (esclusi i giochi elettronici) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E APPARECCHI DI MISURAZIONE, PROVA E NAVIGAZIONE; OROLOGI',
                lev4_name:
                    'Fabbricazione di strumenti per navigazione, idrologia, geofisica e meteorologia',
                label: '26.51.10 - Fabbricazione di strumenti per navigazione, idrologia, geofisica e meteorologia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E APPARECCHI DI MISURAZIONE, PROVA E NAVIGAZIONE; OROLOGI',
                lev4_name:
                    "Fabbricazione di rilevatori di fiamma e combustione, di mine, di movimento, generatori d'impulso e metal detector",
                label: "26.51.21 - Fabbricazione di rilevatori di fiamma e combustione, di mine, di movimento, generatori d'impulso e metal detector ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E APPARECCHI DI MISURAZIONE, PROVA E NAVIGAZIONE; OROLOGI',
                lev4_name:
                    'Fabbricazione di altri apparecchi di misura e regolazione, di contatori di elettricità, gas, acqua ed altri liquidi, di bilance analitiche di precisione (incluse parti staccate ed accessori)',
                label: '26.51.29 - Fabbricazione di altri apparecchi di misura e regolazione, di contatori di elettricità, gas, acqua ed altri liquidi, di bilance analitiche di precisione (incluse parti staccate ed accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E APPARECCHI DI MISURAZIONE, PROVA E NAVIGAZIONE; OROLOGI',
                lev4_name: 'Fabbricazione di orologi',
                label: '26.52.00 - Fabbricazione di orologi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI PER IRRADIAZIONE, APPARECCHIATURE ELETTROMEDICALI ED ELETTROTERAPEUTICHE',
                lev4_name:
                    'Fabbricazione di apparecchiature di irradiazione per alimenti e latte',
                label: '26.60.01 - Fabbricazione di apparecchiature di irradiazione per alimenti e latte ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI PER IRRADIAZIONE, APPARECCHIATURE ELETTROMEDICALI ED ELETTROTERAPEUTICHE',
                lev4_name:
                    'Fabbricazione di apparecchi elettromedicali (incluse parti staccate e accessori)',
                label: '26.60.02 - Fabbricazione di apparecchi elettromedicali (incluse parti staccate e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI PER IRRADIAZIONE, APPARECCHIATURE ELETTROMEDICALI ED ELETTROTERAPEUTICHE',
                lev4_name:
                    'Fabbricazione di altri strumenti per irradiazione ed altre apparecchiature elettroterapeutiche',
                label: '26.60.09 - Fabbricazione di altri strumenti per irradiazione ed altre apparecchiature elettroterapeutiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI OTTICI E ATTREZZATURE FOTOGRAFICHE',
                lev4_name:
                    'Fabbricazione di elementi ottici e strumenti ottici di precisione',
                label: '26.70.11 - Fabbricazione di elementi ottici e strumenti ottici di precisione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI OTTICI E ATTREZZATURE FOTOGRAFICHE',
                lev4_name:
                    'Fabbricazione di attrezzature ottiche di misurazione e controllo',
                label: '26.70.12 - Fabbricazione di attrezzature ottiche di misurazione e controllo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI OTTICI E ATTREZZATURE FOTOGRAFICHE',
                lev4_name:
                    'Fabbricazione di apparecchiature fotografiche e cinematografiche',
                label: '26.70.20 - Fabbricazione di apparecchiature fotografiche e cinematografiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI COMPUTER E PRODOTTI DI ELETTRONICA  E OTTICA; APPARECCHI ELETTROMEDICALI, APPARECCHI DI MISURAZIONE E DI OROLOGI',
                lev3_name: 'FABBRICAZIONE DI SUPPORTI MAGNETICI ED OTTICI',
                lev4_name: 'Fabbricazione di supporti magnetici ed ottici',
                label: '26.80.00 - Fabbricazione di supporti magnetici ed ottici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    "FABBRICAZIONE DI MOTORI, GENERATORI E TRASFORMATORI ELETTRICI E DI APPARECCHIATURE PER LA DISTRIBUZIONE E IL CONTROLLO DELL'ELETTRICITÀ",
                lev4_name:
                    'Fabbricazione di motori, generatori e trasformatori elettrici',
                label: '27.11.00 - Fabbricazione di motori, generatori e trasformatori elettrici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    "FABBRICAZIONE DI MOTORI, GENERATORI E TRASFORMATORI ELETTRICI E DI APPARECCHIATURE PER LA DISTRIBUZIONE E IL CONTROLLO DELL'ELETTRICITÀ",
                lev4_name:
                    "Fabbricazione di apparecchiature per le reti di distribuzione e il controllo dell'elettricità",
                label: "27.12.00 - Fabbricazione di apparecchiature per le reti di distribuzione e il controllo dell'elettricità ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI BATTERIE DI PILE ED ACCUMULATORI ELETTRICI',
                lev4_name:
                    'Fabbricazione di batterie di pile ed accumulatori elettrici',
                label: '27.20.00 - Fabbricazione di batterie di pile ed accumulatori elettrici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI CABLAGGI E APPARECCHIATURE DI CABLAGGIO',
                lev4_name:
                    'Fabbricazione di cavi a fibra ottica per la trasmissione di dati o di immagini',
                label: '27.31.01 - Fabbricazione di cavi a fibra ottica per la trasmissione di dati o di immagini ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI CABLAGGI E APPARECCHIATURE DI CABLAGGIO',
                lev4_name: 'Fabbricazione di fibre ottiche',
                label: '27.31.02 - Fabbricazione di fibre ottiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI CABLAGGI E APPARECCHIATURE DI CABLAGGIO',
                lev4_name:
                    'Fabbricazione di altri fili e cavi elettrici ed elettronici',
                label: '27.32.00 - Fabbricazione di altri fili e cavi elettrici ed elettronici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI CABLAGGI E APPARECCHIATURE DI CABLAGGIO',
                lev4_name:
                    'Fabbricazione di apparecchiature in plastica non conduttiva',
                label: '27.33.01 - Fabbricazione di apparecchiature in plastica non conduttiva ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name:
                    'FABBRICAZIONE DI CABLAGGI E APPARECCHIATURE DI CABLAGGIO',
                lev4_name: 'Fabbricazione di altre attrezzature per cablaggio',
                label: '27.33.09 - Fabbricazione di altre attrezzature per cablaggio ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI APPARECCHIATURE PER ILLUMINAZIONE',
                lev4_name:
                    'Fabbricazione di apparecchiature di illuminazione e segnalazione per mezzi di trasporto',
                label: '27.40.01 - Fabbricazione di apparecchiature di illuminazione e segnalazione per mezzi di trasporto ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI APPARECCHIATURE PER ILLUMINAZIONE',
                lev4_name: 'Fabbricazione di luminarie per feste',
                label: '27.40.02 - Fabbricazione di luminarie per feste ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI APPARECCHIATURE PER ILLUMINAZIONE',
                lev4_name:
                    'Fabbricazione di altre apparecchiature per illuminazione',
                label: '27.40.09 - Fabbricazione di altre apparecchiature per illuminazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI APPARECCHI PER USO DOMESTICO',
                lev4_name: 'Fabbricazione di elettrodomestici',
                label: '27.51.00 - Fabbricazione di elettrodomestici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI APPARECCHI PER USO DOMESTICO',
                lev4_name:
                    'Fabbricazione di apparecchi per uso domestico non elettrici',
                label: '27.52.00 - Fabbricazione di apparecchi per uso domestico non elettrici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI ALTRE APPARECCHIATURE ELETTRICHE',
                lev4_name:
                    'Fabbricazione di apparecchiature elettriche per saldature e brasature',
                label: '27.90.01 - Fabbricazione di apparecchiature elettriche per saldature e brasature ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI ALTRE APPARECCHIATURE ELETTRICHE',
                lev4_name:
                    'Fabbricazione di insegne luminose e apparecchiature elettriche di segnalazione',
                label: '27.90.02 - Fabbricazione di insegne luminose e apparecchiature elettriche di segnalazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI ALTRE APPARECCHIATURE ELETTRICHE',
                lev4_name:
                    'Fabbricazione di capacitatori elettrici, resistenze, condensatori e simili, acceleratori',
                label: '27.90.03 - Fabbricazione di capacitatori elettrici, resistenze, condensatori e simili, acceleratori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI APPARECCHIATURE ELETTRICHE ED APPARECCHIATURE PER USO DOMESTICO NON ELETTRICHE',
                lev3_name: 'FABBRICAZIONE DI ALTRE APPARECCHIATURE ELETTRICHE',
                lev4_name:
                    'Fabbricazione di altre apparecchiature elettriche nca',
                label: '27.90.09 - Fabbricazione di altre apparecchiature elettriche nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di motori a combustione interna (esclusi i motori destinati ai mezzi di trasporto su strada e ad aeromobili)',
                label: '28.11.11 - Fabbricazione di motori a combustione interna (esclusi i motori destinati ai mezzi di trasporto su strada e ad aeromobili) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di pistoni, fasce elastiche, carburatori e parti simili di motori a combustione interna',
                label: '28.11.12 - Fabbricazione di pistoni, fasce elastiche, carburatori e parti simili di motori a combustione interna ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di turbine e turboalternatori (incluse parti e accessori)',
                label: '28.11.20 - Fabbricazione di turbine e turboalternatori (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di apparecchiature fluidodinamiche',
                label: '28.12.00 - Fabbricazione di apparecchiature fluidodinamiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di altre pompe e compressori',
                label: '28.13.00 - Fabbricazione di altre pompe e compressori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di altri rubinetti e valvole',
                label: '28.14.00 - Fabbricazione di altri rubinetti e valvole ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di organi di trasmissione (esclusi quelli idraulici e quelli per autoveicoli, aeromobili e motocicli)',
                label: '28.15.10 - Fabbricazione di organi di trasmissione (esclusi quelli idraulici e quelli per autoveicoli, aeromobili e motocicli) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name: 'FABBRICAZIONE DI MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di cuscinetti a sfere',
                label: '28.15.20 - Fabbricazione di cuscinetti a sfere ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di forni, fornaci e bruciatori',
                label: '28.21.10 - Fabbricazione di forni, fornaci e bruciatori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di caldaie per riscaldamento centrale',
                label: '28.21.21 - Fabbricazione di caldaie per riscaldamento centrale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di altre caldaie per riscaldamento',
                label: '28.21.29 - Fabbricazione di altre caldaie per riscaldamento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di ascensori, montacarichi e scale mobili',
                label: '28.22.01 - Fabbricazione di ascensori, montacarichi e scale mobili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di gru, argani, verricelli a mano e a motore, carrelli trasbordatori, carrelli elevatori e piattaforme girevoli',
                label: '28.22.02 - Fabbricazione di gru, argani, verricelli a mano e a motore, carrelli trasbordatori, carrelli elevatori e piattaforme girevoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di carriole',
                label: '28.22.03 - Fabbricazione di carriole ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di altre macchine e apparecchi di sollevamento e movimentazione',
                label: '28.22.09 - Fabbricazione di altre macchine e apparecchi di sollevamento e movimentazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di cartucce toner',
                label: '28.23.01 - Fabbricazione di cartucce toner ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di macchine ed altre attrezzature per ufficio (esclusi computer e periferiche)',
                label: '28.23.09 - Fabbricazione di macchine ed altre attrezzature per ufficio (esclusi computer e periferiche) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name: 'Fabbricazione di utensili portatili a motore',
                label: '28.24.00 - Fabbricazione di utensili portatili a motore ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di attrezzature di uso non domestico per la refrigerazione e la ventilazione; fabbricazione di condizionatori domestici fissi',
                label: '28.25.00 - Fabbricazione di attrezzature di uso non domestico per la refrigerazione e la ventilazione; fabbricazione di condizionatori domestici fissi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di bilance e di macchine automatiche per la vendita e la distribuzione (incluse parti staccate e accessori)',
                label: '28.29.10 - Fabbricazione di bilance e di macchine automatiche per la vendita e la distribuzione (incluse parti staccate e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di macchine e apparecchi per le industrie chimiche, petrolchimiche e petrolifere (incluse parti e accessori)',
                label: '28.29.20 - Fabbricazione di macchine e apparecchi per le industrie chimiche, petrolchimiche e petrolifere (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    "Fabbricazione di macchine automatiche per la dosatura, la confezione e per l'imballaggio (incluse parti e accessori)",
                label: "28.29.30 - Fabbricazione di macchine automatiche per la dosatura, la confezione e per l'imballaggio (incluse parti e accessori) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di apparecchi per depurare e filtrare liquidi e gas per uso non domestico',
                label: '28.29.91 - Fabbricazione di apparecchi per depurare e filtrare liquidi e gas per uso non domestico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di macchine per la pulizia (incluse le lavastoviglie) per uso non domestico',
                label: '28.29.92 - Fabbricazione di macchine per la pulizia (incluse le lavastoviglie) per uso non domestico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di livelle, metri doppi a nastro e utensili simili, strumenti di precisione per meccanica (esclusi quelli ottici)',
                label: '28.29.93 - Fabbricazione di livelle, metri doppi a nastro e utensili simili, strumenti di precisione per meccanica (esclusi quelli ottici) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE DI IMPIEGO GENERALE',
                lev4_name:
                    'Fabbricazione di altro materiale meccanico e di altre macchine di impiego generale nca',
                label: '28.29.99 - Fabbricazione di altro materiale meccanico e di altre macchine di impiego generale nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    "FABBRICAZIONE DI MACCHINE PER L'AGRICOLTURA E LA SILVICOLTURA",
                lev4_name: 'Fabbricazione di trattori agricoli',
                label: '28.30.10 - Fabbricazione di trattori agricoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    "FABBRICAZIONE DI MACCHINE PER L'AGRICOLTURA E LA SILVICOLTURA",
                lev4_name:
                    "Fabbricazione di altre macchine per l'agricoltura, la silvicoltura e la zootecnia",
                label: "28.30.90 - Fabbricazione di altre macchine per l'agricoltura, la silvicoltura e la zootecnia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI MACCHINE PER LA FORMATURA DEI METALLI E DI ALTRE MACCHINE UTENSILI',
                lev4_name:
                    'Fabbricazione di macchine utensili per la formatura dei metalli (incluse parti e accessori ed escluse le parti intercambiabili)',
                label: '28.41.00 - Fabbricazione di macchine utensili per la formatura dei metalli (incluse parti e accessori ed escluse le parti intercambiabili) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI MACCHINE PER LA FORMATURA DEI METALLI E DI ALTRE MACCHINE UTENSILI',
                lev4_name: 'Fabbricazione di macchine per la galvanostegia',
                label: '28.49.01 - Fabbricazione di macchine per la galvanostegia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI MACCHINE PER LA FORMATURA DEI METALLI E DI ALTRE MACCHINE UTENSILI',
                lev4_name:
                    'Fabbricazione di altre macchine utensili (incluse parti e accessori) nca',
                label: '28.49.09 - Fabbricazione di altre macchine utensili (incluse parti e accessori) nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di macchine per la metallurgia (incluse parti e accessori)',
                label: '28.91.00 - Fabbricazione di macchine per la metallurgia (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di macchine per il trasporto a cassone ribaltabile per impiego specifico in miniere, cave e cantieri',
                label: '28.92.01 - Fabbricazione di macchine per il trasporto a cassone ribaltabile per impiego specifico in miniere, cave e cantieri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di altre macchine da miniera, cava e cantiere (incluse parti e accessori)',
                label: '28.92.09 - Fabbricazione di altre macchine da miniera, cava e cantiere (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    "Fabbricazione di macchine per l'industria alimentare, delle bevande e del tabacco (incluse parti e accessori)",
                label: "28.93.00 - Fabbricazione di macchine per l'industria alimentare, delle bevande e del tabacco (incluse parti e accessori) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di macchine tessili, di macchine e di impianti per il trattamento ausiliario dei tessili, di macchine per cucire e per maglieria (incluse parti e accessori)',
                label: '28.94.10 - Fabbricazione di macchine tessili, di macchine e di impianti per il trattamento ausiliario dei tessili, di macchine per cucire e per maglieria (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    "Fabbricazione di macchine e apparecchi per l'industria delle pelli, del cuoio e delle calzature (incluse parti e accessori)",
                label: "28.94.20 - Fabbricazione di macchine e apparecchi per l'industria delle pelli, del cuoio e delle calzature (incluse parti e accessori) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di apparecchiature e di macchine per lavanderie e stirerie (incluse parti e accessori)',
                label: '28.94.30 - Fabbricazione di apparecchiature e di macchine per lavanderie e stirerie (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    "Fabbricazione di macchine per l'industria della carta e del cartone (incluse parti e accessori)",
                label: "28.95.00 - Fabbricazione di macchine per l'industria della carta e del cartone (incluse parti e accessori) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    "Fabbricazione di macchine per l'industria delle materie plastiche e della gomma (incluse parti e accessori)",
                label: "28.96.00 - Fabbricazione di macchine per l'industria delle materie plastiche e della gomma (incluse parti e accessori) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di macchine per la stampa e la legatoria (incluse parti e accessori)',
                label: '28.99.10 - Fabbricazione di macchine per la stampa e la legatoria (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di robot industriali per usi molteplici (incluse parti e accessori)',
                label: '28.99.20 - Fabbricazione di robot industriali per usi molteplici (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di apparecchi per istituti di bellezza e centri di benessere',
                label: '28.99.30 - Fabbricazione di apparecchi per istituti di bellezza e centri di benessere ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di apparecchiature per il lancio di aeromobili, catapulte per portaerei e apparecchiature simili',
                label: '28.99.91 - Fabbricazione di apparecchiature per il lancio di aeromobili, catapulte per portaerei e apparecchiature simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di giostre, altalene ed altre attrezzature per parchi di divertimento',
                label: '28.99.92 - Fabbricazione di giostre, altalene ed altre attrezzature per parchi di divertimento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    "Fabbricazione di apparecchiature per l'allineamento e il bilanciamento delle ruote; altre apparecchiature per il bilanciamento",
                label: "28.99.93 - Fabbricazione di apparecchiature per l'allineamento e il bilanciamento delle ruote; altre apparecchiature per il bilanciamento ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MACCHINARI ED APPARECCHIATURE NCA',
                lev3_name:
                    'FABBRICAZIONE DI ALTRE MACCHINE PER IMPIEGHI SPECIALI',
                lev4_name:
                    'Fabbricazione di altre macchine per impieghi speciali nca (incluse parti e accessori)',
                label: '28.99.99 - Fabbricazione di altre macchine per impieghi speciali nca (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev3_name: 'FABBRICAZIONE DI AUTOVEICOLI',
                lev4_name: 'Fabbricazione di autoveicoli',
                label: '29.10.00 - Fabbricazione di autoveicoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev3_name:
                    'FABBRICAZIONE DI CARROZZERIE PER AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev4_name:
                    'Fabbricazione di carrozzerie per autoveicoli, rimorchi e semirimorchi',
                label: '29.20.00 - Fabbricazione di carrozzerie per autoveicoli, rimorchi e semirimorchi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev3_name:
                    'FABBRICAZIONE DI PARTI ED ACCESSORI PER AUTOVEICOLI E LORO MOTORI',
                lev4_name:
                    'Fabbricazione di apparecchiature elettriche ed elettroniche per autoveicoli e loro motori',
                label: '29.31.00 - Fabbricazione di apparecchiature elettriche ed elettroniche per autoveicoli e loro motori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev3_name:
                    'FABBRICAZIONE DI PARTI ED ACCESSORI PER AUTOVEICOLI E LORO MOTORI',
                lev4_name: 'Fabbricazione di sedili per autoveicoli',
                label: '29.32.01 - Fabbricazione di sedili per autoveicoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'FABBRICAZIONE DI AUTOVEICOLI, RIMORCHI E SEMIRIMORCHI',
                lev3_name:
                    'FABBRICAZIONE DI PARTI ED ACCESSORI PER AUTOVEICOLI E LORO MOTORI',
                lev4_name:
                    'Fabbricazione di altre parti ed accessori per autoveicoli e loro motori nca',
                label: '29.32.09 - Fabbricazione di altre parti ed accessori per autoveicoli e loro motori nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'COSTRUZIONE DI NAVI E IMBARCAZIONI',
                lev4_name: 'Fabbricazione di sedili per navi',
                label: '30.11.01 - Fabbricazione di sedili per navi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'COSTRUZIONE DI NAVI E IMBARCAZIONI',
                lev4_name:
                    'Cantieri navali per costruzioni metalliche e non metalliche (esclusi i sedili per navi)',
                label: '30.11.02 - Cantieri navali per costruzioni metalliche e non metalliche (esclusi i sedili per navi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'COSTRUZIONE DI NAVI E IMBARCAZIONI',
                lev4_name: 'Costruzione di imbarcazioni da diporto e sportive',
                label: '30.12.00 - Costruzione di imbarcazioni da diporto e sportive ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name:
                    'COSTRUZIONE DI LOCOMOTIVE E DI MATERIALE ROTABILE FERRO-TRANVIARIO',
                lev4_name:
                    'Fabbricazione di sedili per tram, filovie e metropolitane',
                label: '30.20.01 - Fabbricazione di sedili per tram, filovie e metropolitane ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name:
                    'COSTRUZIONE DI LOCOMOTIVE E DI MATERIALE ROTABILE FERRO-TRANVIARIO',
                lev4_name:
                    'Costruzione di altro materiale rotabile ferroviario, tranviario, filoviario, per metropolitane e per miniere',
                label: '30.20.02 - Costruzione di altro materiale rotabile ferroviario, tranviario, filoviario, per metropolitane e per miniere ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name:
                    'FABBRICAZIONE DI AEROMOBILI, DI VEICOLI SPAZIALI E DEI RELATIVI DISPOSITIVI',
                lev4_name: 'Fabbricazione di sedili per aeromobili',
                label: '30.30.01 - Fabbricazione di sedili per aeromobili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name:
                    'FABBRICAZIONE DI AEROMOBILI, DI VEICOLI SPAZIALI E DEI RELATIVI DISPOSITIVI',
                lev4_name: 'Fabbricazione di missili balistici',
                label: '30.30.02 - Fabbricazione di missili balistici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name:
                    'FABBRICAZIONE DI AEROMOBILI, DI VEICOLI SPAZIALI E DEI RELATIVI DISPOSITIVI',
                lev4_name:
                    'Fabbricazione di aeromobili, di veicoli spaziali e dei relativi dispositivi nca',
                label: '30.30.09 - Fabbricazione di aeromobili, di veicoli spaziali e dei relativi dispositivi nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI VEICOLI MILITARI DA COMBATTIMENTO',
                lev4_name: 'Fabbricazione di veicoli militari da combattimento',
                label: '30.40.00 - Fabbricazione di veicoli militari da combattimento ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name: 'Fabbricazione di motori per motocicli',
                label: '30.91.11 - Fabbricazione di motori per motocicli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name: 'Fabbricazione di motocicli',
                label: '30.91.12 - Fabbricazione di motocicli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name:
                    'Fabbricazione di accessori e pezzi staccati per motocicli e ciclomotori',
                label: '30.91.20 - Fabbricazione di accessori e pezzi staccati per motocicli e ciclomotori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name:
                    'Fabbricazione e montaggio di biciclette (incluse parti e accessori)',
                label: '30.92.10 - Fabbricazione e montaggio di biciclette (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name: 'Fabbricazione di parti ed accessori per biciclette',
                label: '30.92.20 - Fabbricazione di parti ed accessori per biciclette ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name:
                    'Fabbricazione di veicoli per invalidi (incluse parti e accessori)',
                label: '30.92.30 - Fabbricazione di veicoli per invalidi (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name:
                    'Fabbricazione di carrozzine e passeggini per neonati',
                label: '30.92.40 - Fabbricazione di carrozzine e passeggini per neonati ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI ALTRI MEZZI DI TRASPORTO',
                lev3_name: 'FABBRICAZIONE DI MEZZI DI TRASPORTO NCA',
                lev4_name:
                    'Fabbricazione di veicoli a trazione manuale o animale',
                label: '30.99.00 - Fabbricazione di veicoli a trazione manuale o animale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name:
                    'Fabbricazione di sedie e poltrone per ufficio e negozi',
                label: '31.01.10 - Fabbricazione di sedie e poltrone per ufficio e negozi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name:
                    'Fabbricazione di altri mobili metallici per ufficio e negozi',
                label: '31.01.21 - Fabbricazione di altri mobili metallici per ufficio e negozi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name:
                    'Fabbricazione di altri mobili non metallici per ufficio e negozi',
                label: '31.01.22 - Fabbricazione di altri mobili non metallici per ufficio e negozi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Fabbricazione di mobili per cucina',
                label: '31.02.00 - Fabbricazione di mobili per cucina ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Fabbricazione di materassi',
                label: '31.03.00 - Fabbricazione di materassi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Fabbricazione di mobili per arredo domestico',
                label: '31.09.10 - Fabbricazione di mobili per arredo domestico ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name:
                    'Fabbricazione di sedie e sedili (esclusi quelli per aeromobili, autoveicoli, navi, treni, ufficio e negozi)',
                label: '31.09.20 - Fabbricazione di sedie e sedili (esclusi quelli per aeromobili, autoveicoli, navi, treni, ufficio e negozi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Fabbricazione di poltrone e divani',
                label: '31.09.30 - Fabbricazione di poltrone e divani ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Fabbricazione di parti e accessori di mobili',
                label: '31.09.40 - Fabbricazione di parti e accessori di mobili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name: 'Finitura di mobili',
                label: '31.09.50 - Finitura di mobili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'FABBRICAZIONE DI MOBILI',
                lev3_name: 'FABBRICAZIONE DI MOBILI',
                lev4_name:
                    'Fabbricazione di altri mobili (inclusi quelli per arredo esterno)',
                label: '31.09.90 - Fabbricazione di altri mobili (inclusi quelli per arredo esterno) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI GIOIELLERIA, BIGIOTTERIA E ARTICOLI CONNESSI; LAVORAZIONE DELLE PIETRE PREZIOSE',
                lev4_name: 'Coniazione di monete',
                label: '32.11.00 - Coniazione di monete ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI GIOIELLERIA, BIGIOTTERIA E ARTICOLI CONNESSI; LAVORAZIONE DELLE PIETRE PREZIOSE',
                lev4_name:
                    'Fabbricazione di oggetti di gioielleria ed oreficeria in metalli preziosi o rivestiti di metalli preziosi',
                label: '32.12.10 - Fabbricazione di oggetti di gioielleria ed oreficeria in metalli preziosi o rivestiti di metalli preziosi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI GIOIELLERIA, BIGIOTTERIA E ARTICOLI CONNESSI; LAVORAZIONE DELLE PIETRE PREZIOSE',
                lev4_name:
                    'Lavorazione di pietre preziose e semipreziose per gioielleria e per uso industriale',
                label: '32.12.20 - Lavorazione di pietre preziose e semipreziose per gioielleria e per uso industriale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI GIOIELLERIA, BIGIOTTERIA E ARTICOLI CONNESSI; LAVORAZIONE DELLE PIETRE PREZIOSE',
                lev4_name:
                    'Fabbricazione di cinturini metallici per orologi (esclusi quelli in metalli preziosi)',
                label: '32.13.01 - Fabbricazione di cinturini metallici per orologi (esclusi quelli in metalli preziosi) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI GIOIELLERIA, BIGIOTTERIA E ARTICOLI CONNESSI; LAVORAZIONE DELLE PIETRE PREZIOSE',
                lev4_name: 'Fabbricazione di bigiotteria e articoli simili nca',
                label: '32.13.09 - Fabbricazione di bigiotteria e articoli simili nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'FABBRICAZIONE DI STRUMENTI MUSICALI',
                lev4_name:
                    'Fabbricazione di strumenti musicali (incluse parti e accessori)',
                label: '32.20.00 - Fabbricazione di strumenti musicali (incluse parti e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'FABBRICAZIONE DI ARTICOLI SPORTIVI',
                lev4_name: 'Fabbricazione di articoli sportivi',
                label: '32.30.00 - Fabbricazione di articoli sportivi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'FABBRICAZIONE DI GIOCHI E GIOCATTOLI',
                lev4_name:
                    'Fabbricazione di giochi (inclusi i giochi elettronici)',
                label: '32.40.10 - Fabbricazione di giochi (inclusi i giochi elettronici) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'FABBRICAZIONE DI GIOCHI E GIOCATTOLI',
                lev4_name:
                    'Fabbricazione di giocattoli (inclusi i tricicli e gli strumenti musicali giocattolo)',
                label: '32.40.20 - Fabbricazione di giocattoli (inclusi i tricicli e gli strumenti musicali giocattolo) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di materiale medico-chirurgico e veterinario',
                label: '32.50.11 - Fabbricazione di materiale medico-chirurgico e veterinario ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di apparecchi e strumenti per odontoiatria e di apparecchi medicali per diagnosi (incluse parti staccate e accessori)',
                label: '32.50.12 - Fabbricazione di apparecchi e strumenti per odontoiatria e di apparecchi medicali per diagnosi (incluse parti staccate e accessori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di mobili per uso medico, chirurgico, odontoiatrico e veterinario',
                label: '32.50.13 - Fabbricazione di mobili per uso medico, chirurgico, odontoiatrico e veterinario ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name: 'Fabbricazione di centrifughe per laboratori',
                label: '32.50.14 - Fabbricazione di centrifughe per laboratori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di protesi dentarie (inclusa riparazione)',
                label: '32.50.20 - Fabbricazione di protesi dentarie (inclusa riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di protesi ortopediche, altre protesi ed ausili (inclusa riparazione)',
                label: '32.50.30 - Fabbricazione di protesi ortopediche, altre protesi ed ausili (inclusa riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name: 'Fabbricazione di lenti oftalmiche',
                label: '32.50.40 - Fabbricazione di lenti oftalmiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name:
                    'FABBRICAZIONE DI STRUMENTI E FORNITURE MEDICHE E DENTISTICHE',
                lev4_name:
                    'Fabbricazione di armature per occhiali di qualsiasi tipo; montatura in serie di occhiali comuni',
                label: '32.50.50 - Fabbricazione di armature per occhiali di qualsiasi tipo; montatura in serie di occhiali comuni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name: 'Fabbricazione di scope e spazzole',
                label: '32.91.00 - Fabbricazione di scope e spazzole ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di articoli di vestiario ignifughi e protettivi di sicurezza',
                label: '32.99.11 - Fabbricazione di articoli di vestiario ignifughi e protettivi di sicurezza ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di articoli in plastica per la sicurezza personale',
                label: '32.99.12 - Fabbricazione di articoli in plastica per la sicurezza personale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di articoli in metallo per la sicurezza personale',
                label: '32.99.13 - Fabbricazione di articoli in metallo per la sicurezza personale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di dispositivi per la respirazione artificiale (maschere a gas)',
                label: '32.99.14 - Fabbricazione di dispositivi per la respirazione artificiale (maschere a gas) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di altre attrezzature ed altri articoli di vestiario protettivi di sicurezza',
                label: '32.99.19 - Fabbricazione di altre attrezzature ed altri articoli di vestiario protettivi di sicurezza ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name:
                    'Fabbricazione di ombrelli, bottoni, chiusure lampo, parrucche e affini',
                label: '32.99.20 - Fabbricazione di ombrelli, bottoni, chiusure lampo, parrucche e affini ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name: 'Fabbricazione di oggetti di cancelleria',
                label: '32.99.30 - Fabbricazione di oggetti di cancelleria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name: 'Fabbricazione di casse funebri',
                label: '32.99.40 - Fabbricazione di casse funebri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name: 'ALTRE INDUSTRIE MANIFATTURIERE',
                lev3_name: 'INDUSTRIE MANIFATTURIERE NCA',
                lev4_name: 'Fabbricazione di altri articoli nca',
                label: '32.99.90 - Fabbricazione di altri articoli nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di stampi, portastampi, sagome, forme per macchine',
                label: '33.11.01 - Riparazione e manutenzione di stampi, portastampi, sagome, forme per macchine ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di utensileria ad azionamento manuale',
                label: '33.11.02 - Riparazione e manutenzione di utensileria ad azionamento manuale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    "Riparazione e manutenzione di armi, sistemi d'arma e munizioni",
                label: "33.11.03 - Riparazione e manutenzione di armi, sistemi d'arma e munizioni ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di casseforti, forzieri, porte metalliche blindate',
                label: '33.11.04 - Riparazione e manutenzione di casseforti, forzieri, porte metalliche blindate ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione e manutenzione di armi bianche',
                label: '33.11.05 - Riparazione e manutenzione di armi bianche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione e manutenzione di container',
                label: '33.11.06 - Riparazione e manutenzione di container ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di carrelli per la spesa',
                label: '33.11.07 - Riparazione e manutenzione di carrelli per la spesa ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di altri prodotti in metallo',
                label: '33.11.09 - Riparazione e manutenzione di altri prodotti in metallo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di macchine di impiego generale',
                label: '33.12.10 - Riparazione e manutenzione di macchine di impiego generale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di forni, fornaci e bruciatori',
                label: '33.12.20 - Riparazione e manutenzione di forni, fornaci e bruciatori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di macchine e apparecchi di sollevamento e movimentazione (esclusi ascensori)',
                label: '33.12.30 - Riparazione e manutenzione di macchine e apparecchi di sollevamento e movimentazione (esclusi ascensori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di attrezzature di uso non domestico per la refrigerazione e la ventilazione',
                label: '33.12.40 - Riparazione e manutenzione di attrezzature di uso non domestico per la refrigerazione e la ventilazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di macchine ed attrezzature per ufficio (esclusi computer, periferiche, fax)',
                label: '33.12.51 - Riparazione e manutenzione di macchine ed attrezzature per ufficio (esclusi computer, periferiche, fax) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di bilance e macchine automatiche per la vendita e la distribuzione',
                label: '33.12.52 - Riparazione e manutenzione di bilance e macchine automatiche per la vendita e la distribuzione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di macchine per le industrie chimiche, petrolchimiche e petrolifere',
                label: '33.12.53 - Riparazione e manutenzione di macchine per le industrie chimiche, petrolchimiche e petrolifere ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    "Riparazione e manutenzione di macchine per la dosatura, la confezione e l'imballaggio",
                label: "33.12.54 - Riparazione e manutenzione di macchine per la dosatura, la confezione e l'imballaggio ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di estintori (inclusa la ricarica)',
                label: '33.12.55 - Riparazione e manutenzione di estintori (inclusa la ricarica) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di altre macchine di impiego generale nca',
                label: '33.12.59 - Riparazione e manutenzione di altre macchine di impiego generale nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione e manutenzione di trattori agricoli',
                label: '33.12.60 - Riparazione e manutenzione di trattori agricoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    "Riparazione e manutenzione di altre macchine per l'agricoltura, la silvicoltura e la zootecnia",
                label: "33.12.70 - Riparazione e manutenzione di altre macchine per l'agricoltura, la silvicoltura e la zootecnia ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di parti intercambiabili per macchine utensili',
                label: '33.12.91 - Riparazione e manutenzione di parti intercambiabili per macchine utensili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di altre macchine per impieghi speciali nca (incluse le macchine utensili)',
                label: '33.12.99 - Riparazione e manutenzione di altre macchine per impieghi speciali nca (incluse le macchine utensili) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di apparecchiature ottiche e fotocinematografiche (escluse macchine fotografiche e videocamere)',
                label: '33.13.01 - Riparazione e manutenzione di apparecchiature ottiche e fotocinematografiche (escluse macchine fotografiche e videocamere) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di apparecchiature elettriche',
                label: '33.13.02 - Riparazione e manutenzione di apparecchiature elettriche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di apparecchi medicali per diagnosi, di materiale medico chirurgico e veterinario, di apparecchi e strumenti per odontoiatria',
                label: '33.13.03 - Riparazione e manutenzione di apparecchi medicali per diagnosi, di materiale medico chirurgico e veterinario, di apparecchi e strumenti per odontoiatria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione di apparati di distillazione per laboratori, di centrifughe per laboratori e di macchinari per pulizia ad ultrasuoni per laboratori',
                label: '33.13.04 - Riparazione di apparati di distillazione per laboratori, di centrifughe per laboratori e di macchinari per pulizia ad ultrasuoni per laboratori ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di altre apparecchiature elettroniche (escluse quelle per le telecomunicazioni ed i computer)',
                label: '33.13.09 - Riparazione e manutenzione di altre apparecchiature elettroniche (escluse quelle per le telecomunicazioni ed i computer) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di attrezzature elettriche professionali',
                label: '33.14.01 - Riparazione e manutenzione di attrezzature elettriche professionali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di altre apparecchiature elettriche (esclusi gli elettrodomestici)',
                label: '33.14.09 - Riparazione e manutenzione di altre apparecchiature elettriche (esclusi gli elettrodomestici) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di navi commerciali e imbarcazioni da diporto (esclusi i loro motori)',
                label: '33.15.00 - Riparazione e manutenzione di navi commerciali e imbarcazioni da diporto (esclusi i loro motori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di aeromobili e di veicoli spaziali',
                label: '33.16.00 - Riparazione e manutenzione di aeromobili e di veicoli spaziali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazione e manutenzione di materiale rotabile ferroviario, tranviario, filoviario e per metropolitane (esclusi i loro motori)',
                label: '33.17.00 - Riparazione e manutenzione di materiale rotabile ferroviario, tranviario, filoviario e per metropolitane (esclusi i loro motori) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name:
                    'Riparazioni di pallets e contenitori in legno per trasporto',
                label: '33.19.01 - Riparazioni di pallets e contenitori in legno per trasporto ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione di prodotti in gomma',
                label: '33.19.02 - Riparazione di prodotti in gomma ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione di articoli in vetro',
                label: '33.19.03 - Riparazione di articoli in vetro ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazioni di altri prodotti in legno nca',
                label: '33.19.04 - Riparazioni di altri prodotti in legno nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'RIPARAZIONE E MANUTENZIONE DI PRODOTTI IN METALLO, MACCHINE ED APPARECCHIATURE',
                lev4_name: 'Riparazione di altre apparecchiature nca',
                label: '33.19.09 - Riparazione di altre apparecchiature nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    "Installazione di motori, generatori e trasformatori elettrici; di apparecchiature per la distribuzione e il controllo dell'elettricità (esclusa l'installazione all'interno degli edifici)",
                label: "33.20.01 - Installazione di motori, generatori e trasformatori elettrici; di apparecchiature per la distribuzione e il controllo dell'elettricità (esclusa l'installazione all'interno degli edifici) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    "Installazione di apparecchi elettrici ed elettronici per telecomunicazioni, di apparecchi trasmittenti radiotelevisivi, di impianti di apparecchiature elettriche ed elettroniche (esclusa l'installazione all'interno degli edifici)",
                label: "33.20.02 - Installazione di apparecchi elettrici ed elettronici per telecomunicazioni, di apparecchi trasmittenti radiotelevisivi, di impianti di apparecchiature elettriche ed elettroniche (esclusa l'installazione all'interno degli edifici) ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di strumenti ed apparecchi di misurazione, controllo, prova, navigazione e simili (incluse le apparecchiature di controllo dei processi industriali)',
                label: '33.20.03 - Installazione di strumenti ed apparecchi di misurazione, controllo, prova, navigazione e simili (incluse le apparecchiature di controllo dei processi industriali) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di cisterne, serbatoi e contenitori in metallo',
                label: '33.20.04 - Installazione di cisterne, serbatoi e contenitori in metallo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di generatori di vapore (escluse le caldaie per il riscaldamento centrale ad acqua calda)',
                label: '33.20.05 - Installazione di generatori di vapore (escluse le caldaie per il riscaldamento centrale ad acqua calda) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di macchine per ufficio, di mainframe e computer simili',
                label: '33.20.06 - Installazione di macchine per ufficio, di mainframe e computer simili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di apparecchi medicali per diagnosi, di apparecchi e strumenti per odontoiatria',
                label: '33.20.07 - Installazione di apparecchi medicali per diagnosi, di apparecchi e strumenti per odontoiatria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name: 'Installazione di apparecchi elettromedicali',
                label: '33.20.08 - Installazione di apparecchi elettromedicali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ MANIFATTURIERE',
                lev2_name:
                    'RIPARAZIONE, MANUTENZIONE ED INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE',
                lev3_name:
                    'INSTALLAZIONE DI MACCHINE ED APPARECCHIATURE INDUSTRIALI',
                lev4_name:
                    'Installazione di altre macchine ed apparecchiature industriali',
                label: '33.20.09 - Installazione di altre macchine ed apparecchiature industriali ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Fornitura di energia elettrica, gas, vapore e aria condizionata',
        children: [
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE, TRASMISSIONE E DISTRIBUZIONE DI ENERGIA ELETTRICA',
                lev4_name: 'Produzione di energia elettrica',
                label: 'Produzione di energia elettrica',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE, TRASMISSIONE E DISTRIBUZIONE DI ENERGIA ELETTRICA',
                lev4_name: 'Trasmissione di energia elettrica',
                label: 'Trasmissione di energia elettrica',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE, TRASMISSIONE E DISTRIBUZIONE DI ENERGIA ELETTRICA',
                lev4_name: 'Distribuzione di energia elettrica',
                label: 'Distribuzione di energia elettrica',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE, TRASMISSIONE E DISTRIBUZIONE DI ENERGIA ELETTRICA',
                lev4_name: 'Commercio di energia elettrica',
                label: 'Commercio di energia elettrica',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE DI GAS; DISTRIBUZIONE DI COMBUSTIBILI GASSOSI MEDIANTE CONDOTTE',
                lev4_name: 'Produzione di gas',
                label: 'Produzione di gas',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE DI GAS; DISTRIBUZIONE DI COMBUSTIBILI GASSOSI MEDIANTE CONDOTTE',
                lev4_name:
                    'Distribuzione di combustibili gassosi mediante condotte',
                label: 'Distribuzione di combustibili gassosi mediante condotte',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name:
                    'PRODUZIONE DI GAS; DISTRIBUZIONE DI COMBUSTIBILI GASSOSI MEDIANTE CONDOTTE',
                lev4_name: 'Commercio di gas distribuito mediante condotte',
                label: 'Commercio di gas distribuito mediante condotte',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev2_name:
                    'FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
                lev3_name: 'FORNITURA DI VAPORE E ARIA CONDIZIONATA',
                lev4_name: 'Fornitura di vapore e aria condizionata',
                label: 'Fornitura di vapore e aria condizionata',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'FORNITURA DI ACQUA, RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Fornitura di acqua, reti fognarie, attività di gestione dei rifiuti e risanamento',
        children: [
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name: 'RACCOLTA, TRATTAMENTO E FORNITURA DI ACQUA',
                lev3_name: 'RACCOLTA, TRATTAMENTO E FORNITURA DI ACQUA',
                lev4_name: 'Raccolta, trattamento e fornitura di acqua',
                label: '35.11.00 - Produzione di energia elettrica ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name: 'GESTIONE DELLE RETI FOGNARIE',
                lev3_name: 'GESTIONE DELLE RETI FOGNARIE',
                lev4_name: 'Raccolta e depurazione delle acque di scarico',
                label: '35.12.00 - Trasmissione di energia elettrica ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RACCOLTA DEI RIFIUTI',
                lev4_name: 'Raccolta di rifiuti solidi non pericolosi',
                label: '35.13.00 - Distribuzione di energia elettrica ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RACCOLTA DEI RIFIUTI',
                lev4_name: 'Raccolta di rifiuti pericolosi solidi e non solidi',
                label: '35.14.00 - Commercio di energia elettrica ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'TRATTAMENTO E SMALTIMENTO DEI RIFIUTI',
                lev4_name: 'Produzione di compost',
                label: '35.21.00 - Produzione di gas ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'TRATTAMENTO E SMALTIMENTO DEI RIFIUTI',
                lev4_name:
                    'Trattamento e smaltimento di altri rifiuti non pericolosi',
                label: '35.22.00 - Distribuzione di combustibili gassosi mediante condotte ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'TRATTAMENTO E SMALTIMENTO DEI RIFIUTI',
                lev4_name: 'Trattamento e smaltimento di rifiuti pericolosi',
                label: '35.23.00 - Commercio di gas distribuito mediante condotte ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RECUPERO DEI MATERIALI',
                lev4_name: 'Demolizione di carcasse',
                label: '35.30.00 - Fornitura di vapore e aria condizionata ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RECUPERO DEI MATERIALI',
                lev4_name: 'Cantieri di demolizione navali',
                label: '36.00.00 - Raccolta, trattamento e fornitura di acqua ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RECUPERO DEI MATERIALI',
                lev4_name:
                    'Recupero e preparazione per il riciclaggio di cascami e rottami metallici',
                label: '37.00.00 - Raccolta e depurazione delle acque di scarico ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RECUPERO DEI MATERIALI',
                lev4_name:
                    'Recupero e preparazione per il riciclaggio di materiale plastico per produzione di materie prime plastiche, resine sintetiche',
                label: '38.11.00 - Raccolta di rifiuti solidi non pericolosi ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RACCOLTA, TRATTAMENTO E SMALTIMENTO DEI RIFIUTI; RECUPERO DEI MATERIALI',
                lev3_name: 'RECUPERO DEI MATERIALI',
                lev4_name:
                    'Recupero e preparazione per il riciclaggio dei rifiuti solidi urbani, industriali e biomasse',
                label: '38.12.00 - Raccolta di rifiuti pericolosi solidi e non solidi ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RISANAMENTO E ALTRI SERVIZI DI GESTIONE DEI RIFIUTI',
                lev3_name:
                    'ATTIVITÀ DI RISANAMENTO E ALTRI SERVIZI DI GESTIONE DEI RIFIUTI',
                lev4_name:
                    "Attività di rimozione di strutture ed elementi in amianto specializzata per l'edilizia",
                label: '38.21.01 - Produzione di compost ',
            },
            {
                checked: false,
                lev1_name:
                    'FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
                lev2_name:
                    'ATTIVITÀ DI RISANAMENTO E ALTRI SERVIZI DI GESTIONE DEI RIFIUTI',
                lev3_name:
                    'ATTIVITÀ DI RISANAMENTO E ALTRI SERVIZI DI GESTIONE DEI RIFIUTI',
                lev4_name:
                    'Altre attività di risanamento e altri servizi di gestione dei rifiuti',
                label: '38.21.09 - Trattamento e smaltimento di altri rifiuti non pericolosi ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'COSTRUZIONI',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Costruzioni',
        children: [
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'COSTRUZIONE DI EDIFICI',
                lev3_name: 'SVILUPPO DI PROGETTI IMMOBILIARI',
                lev4_name: 'Sviluppo di progetti immobiliari senza costruzione',
                label: '41.10.00 - Sviluppo di progetti immobiliari senza costruzione ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'COSTRUZIONE DI EDIFICI',
                lev3_name:
                    'COSTRUZIONE DI EDIFICI RESIDENZIALI E NON RESIDENZIALI',
                lev4_name:
                    'Costruzione di edifici residenziali e non residenziali',
                label: '41.20.00 - Costruzione di edifici residenziali e non residenziali ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI STRADE E FERROVIE',
                lev4_name:
                    'Costruzione di strade, autostrade e piste aeroportuali',
                label: '42.11.00 - Costruzione di strade, autostrade e piste aeroportuali ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI STRADE E FERROVIE',
                lev4_name: 'Costruzione di linee ferroviarie e metropolitane',
                label: '42.12.00 - Costruzione di linee ferroviarie e metropolitane ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI STRADE E FERROVIE',
                lev4_name: 'Costruzione di ponti e gallerie',
                label: '42.13.00 - Costruzione di ponti e gallerie ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI OPERE DI PUBBLICA UTILITÀ',
                lev4_name:
                    'Costruzione di opere di pubblica utilità per il trasporto di fluidi',
                label: '42.21.00 - Costruzione di opere di pubblica utilità per il trasporto di fluidi ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI OPERE DI PUBBLICA UTILITÀ',
                lev4_name:
                    "Costruzione di opere di pubblica utilità per l'energia elettrica e le telecomunicazioni",
                label: "42.22.00 - Costruzione di opere di pubblica utilità per l'energia elettrica e le telecomunicazioni ",
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI ALTRE OPERE DI INGEGNERIA CIVILE',
                lev4_name: 'Costruzione di opere idrauliche',
                label: '42.91.00 - Costruzione di opere idrauliche ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI ALTRE OPERE DI INGEGNERIA CIVILE',
                lev4_name:
                    "Lottizzazione dei terreni connessa con l'urbanizzazione",
                label: "42.99.01 - Lottizzazione dei terreni connessa con l'urbanizzazione ",
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'INGEGNERIA CIVILE',
                lev3_name: 'COSTRUZIONE DI ALTRE OPERE DI INGEGNERIA CIVILE',
                lev4_name:
                    'Altre attività di costruzione di altre opere di ingegneria civile nca',
                label: '42.99.09 - Altre attività di costruzione di altre opere di ingegneria civile nca ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'DEMOLIZIONE E PREPARAZIONE DEL CANTIERE EDILE',
                lev4_name: 'Demolizione',
                label: '43.11.00 - Demolizione ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'DEMOLIZIONE E PREPARAZIONE DEL CANTIERE EDILE',
                lev4_name:
                    'Preparazione del cantiere edile e sistemazione del terreno',
                label: '43.12.00 - Preparazione del cantiere edile e sistemazione del terreno ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'DEMOLIZIONE E PREPARAZIONE DEL CANTIERE EDILE',
                lev4_name: 'Trivellazioni e perforazioni',
                label: '43.13.00 - Trivellazioni e perforazioni ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti elettrici in edifici o in altre opere di costruzione (inclusa manutenzione e riparazione)',
                label: '43.21.01 - Installazione di impianti elettrici in edifici o in altre opere di costruzione (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti elettronici (inclusa manutenzione e riparazione)',
                label: '43.21.02 - Installazione di impianti elettronici (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione impianti di illuminazione stradale e dispositivi elettrici di segnalazione, illuminazione delle piste degli aeroporti (inclusa manutenzione e riparazione)',
                label: '43.21.03 - Installazione impianti di illuminazione stradale e dispositivi elettrici di segnalazione, illuminazione delle piste degli aeroporti (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di insegne elettriche e impianti luce (incluse luminarie per feste)',
                label: '43.21.04 - Installazione di insegne elettriche e impianti luce (incluse luminarie per feste) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    "Installazione di impianti idraulici, di riscaldamento e di condizionamento dell'aria (inclusa manutenzione e riparazione) in edifici o in altre opere di costruzione",
                label: "43.22.01 - Installazione di impianti idraulici, di riscaldamento e di condizionamento dell'aria (inclusa manutenzione e riparazione) in edifici o in altre opere di costruzione ",
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti per la distribuzione del gas (inclusa manutenzione e riparazione)',
                label: '43.22.02 - Installazione di impianti per la distribuzione del gas (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti di spegnimento antincendio (inclusi quelli integrati e la manutenzione e riparazione)',
                label: '43.22.03 - Installazione di impianti di spegnimento antincendio (inclusi quelli integrati e la manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti di depurazione per piscine (inclusa manutenzione e riparazione)',
                label: '43.22.04 - Installazione di impianti di depurazione per piscine (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione di impianti di irrigazione per giardini (inclusa manutenzione e riparazione)',
                label: '43.22.05 - Installazione di impianti di irrigazione per giardini (inclusa manutenzione e riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Installazione, riparazione e manutenzione di ascensori e scale mobili',
                label: '43.29.01 - Installazione, riparazione e manutenzione di ascensori e scale mobili ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name:
                    'Lavori di isolamento termico, acustico o antivibrazioni',
                label: '43.29.02 - Lavori di isolamento termico, acustico o antivibrazioni ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name:
                    'INSTALLAZIONE DI IMPIANTI ELETTRICI, IDRAULICI ED ALTRI LAVORI DI COSTRUZIONE E INSTALLAZIONE',
                lev4_name: 'Altri lavori di costruzione e installazione nca',
                label: '43.29.09 - Altri lavori di costruzione e installazione nca ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name: 'Intonacatura e stuccatura',
                label: '43.31.00 - Intonacatura e stuccatura ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name:
                    'Posa in opera di casseforti, forzieri, porte blindate',
                label: '43.32.01 - Posa in opera di casseforti, forzieri, porte blindate ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name:
                    'Posa in opera di infissi, arredi, controsoffitti, pareti mobili e simili',
                label: '43.32.02 - Posa in opera di infissi, arredi, controsoffitti, pareti mobili e simili ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name: 'Rivestimento di pavimenti e di muri',
                label: '43.33.00 - Rivestimento di pavimenti e di muri ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name: 'Tinteggiatura e posa in opera di vetri',
                label: '43.34.00 - Tinteggiatura e posa in opera di vetri ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name:
                    'Attività non specializzate di lavori edili (muratori)',
                label: '43.39.01 - Attività non specializzate di lavori edili (muratori) ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'COMPLETAMENTO E FINITURA DI EDIFICI',
                lev4_name:
                    'Altri lavori di completamento e di finitura degli edifici nca',
                label: '43.39.09 - Altri lavori di completamento e di finitura degli edifici nca ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'ALTRI LAVORI SPECIALIZZATI DI COSTRUZIONE',
                lev4_name: 'Realizzazione di coperture',
                label: '43.91.00 - Realizzazione di coperture ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'ALTRI LAVORI SPECIALIZZATI DI COSTRUZIONE',
                lev4_name:
                    'Pulizia a vapore, sabbiatura e attività simili per pareti esterne di edifici',
                label: '43.99.01 - Pulizia a vapore, sabbiatura e attività simili per pareti esterne di edifici ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'ALTRI LAVORI SPECIALIZZATI DI COSTRUZIONE',
                lev4_name:
                    'Noleggio di gru ed altre attrezzature con operatore per la costruzione o la demolizione',
                label: '43.99.02 - Noleggio di gru ed altre attrezzature con operatore per la costruzione o la demolizione ',
            },
            {
                checked: false,
                lev1_name: 'COSTRUZIONI',
                lev2_name: 'LAVORI DI COSTRUZIONE SPECIALIZZATI',
                lev3_name: 'ALTRI LAVORI SPECIALIZZATI DI COSTRUZIONE',
                lev4_name:
                    'Altre attività di lavori specializzati di costruzione nca',
                label: '43.99.09 - Altre attività di lavori specializzati di costruzione nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: "Commercio all'ingrosso e al dettaglio; Riparazione di autoveicoli e motocicli",
        children: [
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI AUTOVEICOLI',
                lev4_name:
                    "Commercio all'ingrosso e al dettaglio di autovetture e di autoveicoli leggeri",
                label: "45.11.01 - Commercio all'ingrosso e al dettaglio di autovetture e di autoveicoli leggeri ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI AUTOVEICOLI',
                lev4_name:
                    'Intermediari del commercio di autovetture e di autoveicoli leggeri (incluse le agenzie di compravendita)',
                label: '45.11.02 - Intermediari del commercio di autovetture e di autoveicoli leggeri (incluse le agenzie di compravendita) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI AUTOVEICOLI',
                lev4_name:
                    "Commercio all'ingrosso e al dettaglio di altri autoveicoli",
                label: "45.19.01 - Commercio all'ingrosso e al dettaglio di altri autoveicoli ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI AUTOVEICOLI',
                lev4_name:
                    'Intermediari del commercio di altri autoveicoli (incluse le agenzie di compravendita)',
                label: '45.19.02 - Intermediari del commercio di altri autoveicoli (incluse le agenzie di compravendita) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name: 'Riparazioni meccaniche di autoveicoli',
                label: '45.20.10 - Riparazioni meccaniche di autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name: 'Riparazione di carrozzerie di autoveicoli',
                label: '45.20.20 - Riparazione di carrozzerie di autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name: 'Lavaggio autoveicoli',
                label: '45.20.21 - Lavaggio autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name:
                    'Riparazione di impianti elettrici e di alimentazione per autoveicoli',
                label: '45.20.30 - Riparazione di impianti elettrici e di alimentazione per autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name:
                    'Riparazione e sostituzione di pneumatici per autoveicoli',
                label: '45.20.40 - Riparazione e sostituzione di pneumatici per autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name: 'Lavaggio auto',
                label: '45.20.91 - Lavaggio auto ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'MANUTENZIONE E RIPARAZIONE DI AUTOVEICOLI',
                lev4_name:
                    'Altre attività di manutenzione e di riparazione di autoveicoli',
                label: '45.20.99 - Altre attività di manutenzione e di riparazione di autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI PARTI E ACCESSORI DI AUTOVEICOLI',
                lev4_name:
                    "Commercio all'ingrosso di parti e accessori di autoveicoli",
                label: "45.31.01 - Commercio all'ingrosso di parti e accessori di autoveicoli ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI PARTI E ACCESSORI DI AUTOVEICOLI',
                lev4_name:
                    'Intermediari del commercio di parti ed accessori di autoveicoli',
                label: '45.31.02 - Intermediari del commercio di parti ed accessori di autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name: 'COMMERCIO DI PARTI E ACCESSORI DI AUTOVEICOLI',
                lev4_name:
                    'Commercio al dettaglio di parti e accessori di autoveicoli',
                label: '45.32.00 - Commercio al dettaglio di parti e accessori di autoveicoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name:
                    'COMMERCIO, MANUTENZIONE E RIPARAZIONE DI MOTOCICLI E RELATIVE PARTI ED ACCESSORI',
                lev4_name:
                    "Commercio all'ingrosso e al dettaglio di motocicli e ciclomotori",
                label: "45.40.11 - Commercio all'ingrosso e al dettaglio di motocicli e ciclomotori ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name:
                    'COMMERCIO, MANUTENZIONE E RIPARAZIONE DI MOTOCICLI E RELATIVE PARTI ED ACCESSORI',
                lev4_name:
                    'Intermediari del commercio di motocicli e ciclomotori',
                label: '45.40.12 - Intermediari del commercio di motocicli e ciclomotori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name:
                    'COMMERCIO, MANUTENZIONE E RIPARAZIONE DI MOTOCICLI E RELATIVE PARTI ED ACCESSORI',
                lev4_name:
                    "Commercio all'ingrosso e al dettaglio di parti e accessori per motocicli e ciclomotori",
                label: "45.40.21 - Commercio all'ingrosso e al dettaglio di parti e accessori per motocicli e ciclomotori ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name:
                    'COMMERCIO, MANUTENZIONE E RIPARAZIONE DI MOTOCICLI E RELATIVE PARTI ED ACCESSORI',
                lev4_name:
                    'Intermediari del commercio di parti ed accessori di motocicli e ciclomotori',
                label: '45.40.22 - Intermediari del commercio di parti ed accessori di motocicli e ciclomotori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO E RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev3_name:
                    'COMMERCIO, MANUTENZIONE E RIPARAZIONE DI MOTOCICLI E RELATIVE PARTI ED ACCESSORI',
                lev4_name:
                    'Manutenzione e riparazione di motocicli e ciclomotori (inclusi i pneumatici)',
                label: '45.40.30 - Manutenzione e riparazione di motocicli e ciclomotori (inclusi i pneumatici) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di materie prime agricole',
                label: '46.11.01 - Agenti e rappresentanti di materie prime agricole ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di fiori e piante',
                label: '46.11.02 - Agenti e rappresentanti di fiori e piante ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di animali vivi',
                label: '46.11.03 - Agenti e rappresentanti di animali vivi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di fibre tessili gregge e semilavorate, cuoio e pelli',
                label: '46.11.04 - Agenti e rappresentanti di fibre tessili gregge e semilavorate, cuoio e pelli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di materie prime agricole, animali vivi, materie prime e semilavorati tessili; pelli grezze",
                label: "46.11.05 - Procacciatori d'affari di materie prime agricole, animali vivi, materie prime e semilavorati tessili; pelli grezze ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in materie prime agricole, materie prime e semilavorati tessili; pelli grezze',
                label: '46.11.06 - Mediatori in materie prime agricole, materie prime e semilavorati tessili; pelli grezze ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Mediatori in animali vivi',
                label: '46.11.07 - Mediatori in animali vivi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di carburanti, gpl, gas in bombole e simili; lubrificanti',
                label: '46.12.01 - Agenti e rappresentanti di carburanti, gpl, gas in bombole e simili; lubrificanti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di combustibili solidi',
                label: '46.12.02 - Agenti e rappresentanti di combustibili solidi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di minerali, metalli e prodotti semilavorati (esclusi i metalli preziosi)',
                label: '46.12.03 - Agenti e rappresentanti di minerali, metalli e prodotti semilavorati (esclusi i metalli preziosi) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Agenti e rappresentanti di prodotti chimici per l'industria",
                label: "46.12.04 - Agenti e rappresentanti di prodotti chimici per l'industria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Agenti e rappresentanti di prodotti chimici per l'agricoltura (inclusi i fertilizzanti)",
                label: "46.12.05 - Agenti e rappresentanti di prodotti chimici per l'agricoltura (inclusi i fertilizzanti) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di combustibili, minerali, metalli (esclusi i metalli preziosi) e prodotti chimici",
                label: "46.12.06 - Procacciatori d'affari di combustibili, minerali, metalli (esclusi i metalli preziosi) e prodotti chimici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in combustibili, minerali, metalli (esclusi i metalli preziosi) e prodotti chimici',
                label: '46.12.07 - Mediatori in combustibili, minerali, metalli (esclusi i metalli preziosi) e prodotti chimici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di legname, semilavorati in legno e legno artificiale',
                label: '46.13.01 - Agenti e rappresentanti di legname, semilavorati in legno e legno artificiale ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di materiale da costruzione (inclusi gli infissi e gli articoli igienico-sanitari); vetro piano',
                label: '46.13.02 - Agenti e rappresentanti di materiale da costruzione (inclusi gli infissi e gli articoli igienico-sanitari); vetro piano ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di apparecchi idraulico-sanitari, apparecchi ed accessori per riscaldamento e condizionamento e altri prodotti similari (esclusi i condizionatori per uso domestico)',
                label: '46.13.03 - Agenti e rappresentanti di apparecchi idraulico-sanitari, apparecchi ed accessori per riscaldamento e condizionamento e altri prodotti similari (esclusi i condizionatori per uso domestico) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di legname e materiali da costruzione",
                label: "46.13.04 - Procacciatori d'affari di legname e materiali da costruzione ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Mediatori in legname e materiali da costruzione',
                label: '46.13.05 - Mediatori in legname e materiali da costruzione ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Agenti e rappresentanti di macchine, attrezzature ed impianti per l'industria ed il commercio; materiale e apparecchi elettrici ed elettronici per uso non domestico",
                label: "46.14.01 - Agenti e rappresentanti di macchine, attrezzature ed impianti per l'industria ed il commercio; materiale e apparecchi elettrici ed elettronici per uso non domestico ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di macchine per costruzioni edili e stradali',
                label: '46.14.02 - Agenti e rappresentanti di macchine per costruzioni edili e stradali ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di macchine ed attrezzature per ufficio',
                label: '46.14.03 - Agenti e rappresentanti di macchine ed attrezzature per ufficio ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di macchine ed attrezzature per uso agricolo (inclusi i trattori)',
                label: '46.14.04 - Agenti e rappresentanti di macchine ed attrezzature per uso agricolo (inclusi i trattori) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di navi, aeromobili e altri veicoli (esclusi autoveicoli, motocicli, ciclomotori e biciclette)',
                label: '46.14.05 - Agenti e rappresentanti di navi, aeromobili e altri veicoli (esclusi autoveicoli, motocicli, ciclomotori e biciclette) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di macchinari, impianti industriali, navi e aeromobili, macchine agricole, macchine per ufficio e computer",
                label: "46.14.06 - Procacciatori d'affari di macchinari, impianti industriali, navi e aeromobili, macchine agricole, macchine per ufficio e computer ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in macchinari, impianti industriali, navi e aeromobili, macchine agricole, macchine per ufficio e computer',
                label: '46.14.07 - Mediatori in macchinari, impianti industriali, navi e aeromobili, macchine agricole, macchine per ufficio e computer ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di mobili in legno, metallo e materie plastiche',
                label: '46.15.01 - Agenti e rappresentanti di mobili in legno, metallo e materie plastiche ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di articoli di ferramenta e di bricolage',
                label: '46.15.02 - Agenti e rappresentanti di articoli di ferramenta e di bricolage ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di articoli casalinghi, porcellane, articoli in vetro eccetera',
                label: '46.15.03 - Agenti e rappresentanti di articoli casalinghi, porcellane, articoli in vetro eccetera ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di vernici, carte da parati, stucchi  e cornici decorativi',
                label: '46.15.04 - Agenti e rappresentanti di vernici, carte da parati, stucchi  e cornici decorativi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di mobili e oggetti di arredamento per la casa in canna, vimini, giunco, sughero, paglia; scope, spazzole, cesti e simili',
                label: '46.15.05 - Agenti e rappresentanti di mobili e oggetti di arredamento per la casa in canna, vimini, giunco, sughero, paglia; scope, spazzole, cesti e simili ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di mobili, articoli per la casa e ferramenta",
                label: "46.15.06 - Procacciatori d'affari di mobili, articoli per la casa e ferramenta ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in mobili, articoli per la casa e ferramenta',
                label: '46.15.07 - Mediatori in mobili, articoli per la casa e ferramenta ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di vestiario ed accessori di abbigliamento',
                label: '46.16.01 - Agenti e rappresentanti di vestiario ed accessori di abbigliamento ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di pellicce',
                label: '46.16.02 - Agenti e rappresentanti di pellicce ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di tessuti per abbigliamento ed arredamento (incluse merceria e passamaneria)',
                label: '46.16.03 - Agenti e rappresentanti di tessuti per abbigliamento ed arredamento (incluse merceria e passamaneria) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di camicie, biancheria e maglieria intima',
                label: '46.16.04 - Agenti e rappresentanti di camicie, biancheria e maglieria intima ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di calzature ed accessori',
                label: '46.16.05 - Agenti e rappresentanti di calzature ed accessori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di pelletteria, valige ed articoli da viaggio',
                label: '46.16.06 - Agenti e rappresentanti di pelletteria, valige ed articoli da viaggio ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di articoli tessili per la casa, tappeti, stuoie e materassi',
                label: '46.16.07 - Agenti e rappresentanti di articoli tessili per la casa, tappeti, stuoie e materassi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle",
                label: "46.16.08 - Procacciatori d'affari di prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle',
                label: '46.16.09 - Mediatori in prodotti tessili, abbigliamento, pellicce, calzature e articoli in pelle ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di prodotti ortofrutticoli freschi, congelati e surgelati',
                label: '46.17.01 - Agenti e rappresentanti di prodotti ortofrutticoli freschi, congelati e surgelati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di carni fresche, congelate, surgelate, conservate e secche; salumi',
                label: '46.17.02 - Agenti e rappresentanti di carni fresche, congelate, surgelate, conservate e secche; salumi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di latte, burro e formaggi',
                label: '46.17.03 - Agenti e rappresentanti di latte, burro e formaggi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Agenti e rappresentanti di oli e grassi alimentari: olio d'oliva e di semi, margarina ed altri prodotti similari",
                label: "46.17.04 - Agenti e rappresentanti di oli e grassi alimentari: olio d'oliva e di semi, margarina ed altri prodotti similari ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di bevande e prodotti similari',
                label: '46.17.05 - Agenti e rappresentanti di bevande e prodotti similari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di prodotti ittici freschi, congelati, surgelati e conservati e secchi',
                label: '46.17.06 - Agenti e rappresentanti di prodotti ittici freschi, congelati, surgelati e conservati e secchi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di altri prodotti alimentari (incluse le uova e gli alimenti per gli animali domestici); tabacco',
                label: '46.17.07 - Agenti e rappresentanti di altri prodotti alimentari (incluse le uova e gli alimenti per gli animali domestici); tabacco ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di prodotti alimentari, bevande e tabacco",
                label: "46.17.08 - Procacciatori d'affari di prodotti alimentari, bevande e tabacco ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in prodotti alimentari, bevande e tabacco',
                label: '46.17.09 - Mediatori in prodotti alimentari, bevande e tabacco ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di carta e cartone (esclusi gli imballaggi; articoli di cartoleria e cancelleria)',
                label: '46.18.11 - Agenti e rappresentanti di carta e cartone (esclusi gli imballaggi; articoli di cartoleria e cancelleria) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di libri e altre pubblicazioni (inclusi i relativi abbonamenti)',
                label: '46.18.12 - Agenti e rappresentanti di libri e altre pubblicazioni (inclusi i relativi abbonamenti) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di prodotti di carta, cancelleria, libri",
                label: "46.18.13 - Procacciatori d'affari di prodotti di carta, cancelleria, libri ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Mediatori in prodotti di carta, cancelleria, libri',
                label: '46.18.14 - Mediatori in prodotti di carta, cancelleria, libri ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di computer, periferiche, attrezzature per le telecomunicazioni, elettronica di consumo audio e video, materiale elettrico per uso domestico',
                label: '46.18.21 - Agenti e rappresentanti di computer, periferiche, attrezzature per le telecomunicazioni, elettronica di consumo audio e video, materiale elettrico per uso domestico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di apparecchi elettrodomestici',
                label: '46.18.22 - Agenti e rappresentanti di apparecchi elettrodomestici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: "Procacciatori d'affari di prodotti di elettronica",
                label: "46.18.23 - Procacciatori d'affari di prodotti di elettronica ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Mediatori in prodotti di elettronica',
                label: '46.18.24 - Mediatori in prodotti di elettronica ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di prodotti farmaceutici; prodotti di erboristeria per uso medico',
                label: '46.18.31 - Agenti e rappresentanti di prodotti farmaceutici; prodotti di erboristeria per uso medico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di prodotti sanitari ed apparecchi medicali, chirurgici e ortopedici',
                label: '46.18.32 - Agenti e rappresentanti di prodotti sanitari ed apparecchi medicali, chirurgici e ortopedici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di prodotti di profumeria e di cosmetica (inclusi articoli per parrucchieri); prodotti di erboristeria per uso cosmetico',
                label: '46.18.33 - Agenti e rappresentanti di prodotti di profumeria e di cosmetica (inclusi articoli per parrucchieri); prodotti di erboristeria per uso cosmetico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di prodotti farmaceutici e di cosmetici",
                label: "46.18.34 - Procacciatori d'affari di prodotti farmaceutici e di cosmetici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Mediatori in prodotti farmaceutici e cosmetici',
                label: '46.18.35 - Mediatori in prodotti farmaceutici e cosmetici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di attrezzature sportive; biciclette',
                label: '46.18.91 - Agenti e rappresentanti di attrezzature sportive; biciclette ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di orologi, oggetti e metalli preziosi',
                label: '46.18.92 - Agenti e rappresentanti di orologi, oggetti e metalli preziosi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di articoli fotografici, ottici e prodotti simili; strumenti scientifici e per laboratori di analisi',
                label: '46.18.93 - Agenti e rappresentanti di articoli fotografici, ottici e prodotti simili; strumenti scientifici e per laboratori di analisi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di saponi, detersivi, candele e prodotti simili',
                label: '46.18.94 - Agenti e rappresentanti di saponi, detersivi, candele e prodotti simili ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Agenti e rappresentanti di giocattoli',
                label: '46.18.95 - Agenti e rappresentanti di giocattoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di chincaglieria e bigiotteria',
                label: '46.18.96 - Agenti e rappresentanti di chincaglieria e bigiotteria ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di altri prodotti non alimentari nca (inclusi gli imballaggi e gli articoli antinfortunistici, antincendio e pubblicitari)',
                label: '46.18.97 - Agenti e rappresentanti di altri prodotti non alimentari nca (inclusi gli imballaggi e gli articoli antinfortunistici, antincendio e pubblicitari) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di attrezzature sportive, biciclette e altri prodotti nca",
                label: "46.18.98 - Procacciatori d'affari di attrezzature sportive, biciclette e altri prodotti nca ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in attrezzature sportive, biciclette e altri prodotti nca',
                label: '46.18.99 - Mediatori in attrezzature sportive, biciclette e altri prodotti nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Agenti e rappresentanti di vari prodotti senza prevalenza di alcuno',
                label: '46.19.01 - Agenti e rappresentanti di vari prodotti senza prevalenza di alcuno ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    "Procacciatori d'affari di vari prodotti senza prevalenza di alcuno",
                label: "46.19.02 - Procacciatori d'affari di vari prodotti senza prevalenza di alcuno ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name:
                    'Mediatori in vari prodotti senza prevalenza di alcuno',
                label: '46.19.03 - Mediatori in vari prodotti senza prevalenza di alcuno ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: 'INTERMEDIARI DEL COMMERCIO',
                lev4_name: 'Gruppi di acquisto; mandatari agli acquisti; buyer',
                label: '46.19.04 - Gruppi di acquisto; mandatari agli acquisti; buyer ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name: "Commercio all'ingrosso di cereali e legumi secchi",
                label: "46.21.10 - Commercio all'ingrosso di cereali e legumi secchi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name: "Commercio all'ingrosso di tabacco grezzo",
                label: "46.21.21 - Commercio all'ingrosso di tabacco grezzo ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name:
                    "Commercio all'ingrosso di sementi e alimenti per il bestiame (mangimi), piante officinali, semi oleosi, patate da semina",
                label: "46.21.22 - Commercio all'ingrosso di sementi e alimenti per il bestiame (mangimi), piante officinali, semi oleosi, patate da semina ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name: "Commercio all'ingrosso di fiori e piante",
                label: "46.22.00 - Commercio all'ingrosso di fiori e piante ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name: "Commercio all'ingrosso di animali vivi",
                label: "46.23.00 - Commercio all'ingrosso di animali vivi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name:
                    "Commercio all'ingrosso di cuoio e pelli gregge e lavorate (escluse le pelli per pellicceria)",
                label: "46.24.10 - Commercio all'ingrosso di cuoio e pelli gregge e lavorate (escluse le pelli per pellicceria) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI MATERIE PRIME AGRICOLE E DI ANIMALI VIVI",
                lev4_name:
                    "Commercio all'ingrosso di pelli gregge e lavorate per pellicceria",
                label: "46.24.20 - Commercio all'ingrosso di pelli gregge e lavorate per pellicceria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di frutta e ortaggi freschi",
                label: "46.31.10 - Commercio all'ingrosso di frutta e ortaggi freschi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di frutta e ortaggi conservati",
                label: "46.31.20 - Commercio all'ingrosso di frutta e ortaggi conservati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di carne fresca, congelata e surgelata",
                label: "46.32.10 - Commercio all'ingrosso di carne fresca, congelata e surgelata ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di prodotti di salumeria",
                label: "46.32.20 - Commercio all'ingrosso di prodotti di salumeria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di prodotti lattiero-caseari e di uova",
                label: "46.33.10 - Commercio all'ingrosso di prodotti lattiero-caseari e di uova ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di oli e grassi alimentari di origine vegetale o animale",
                label: "46.33.20 - Commercio all'ingrosso di oli e grassi alimentari di origine vegetale o animale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di bevande alcoliche",
                label: "46.34.10 - Commercio all'ingrosso di bevande alcoliche ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di bevande non alcoliche",
                label: "46.34.20 - Commercio all'ingrosso di bevande non alcoliche ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di prodotti del tabacco",
                label: "46.35.00 - Commercio all'ingrosso di prodotti del tabacco ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di zucchero, cioccolato, dolciumi e prodotti da forno",
                label: "46.36.00 - Commercio all'ingrosso di zucchero, cioccolato, dolciumi e prodotti da forno ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di caffè",
                label: "46.37.01 - Commercio all'ingrosso di caffè ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di tè, cacao e spezie",
                label: "46.37.02 - Commercio all'ingrosso di tè, cacao e spezie ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di prodotti della pesca freschi",
                label: "46.38.10 - Commercio all'ingrosso di prodotti della pesca freschi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di prodotti della pesca congelati, surgelati, conservati, secchi",
                label: "46.38.20 - Commercio all'ingrosso di prodotti della pesca congelati, surgelati, conservati, secchi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name: "Commercio all'ingrosso di pasti e piatti pronti",
                label: "46.38.30 - Commercio all'ingrosso di pasti e piatti pronti ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso di altri prodotti alimentari",
                label: "46.38.90 - Commercio all'ingrosso di altri prodotti alimentari ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso non specializzato di prodotti surgelati",
                label: "46.39.10 - Commercio all'ingrosso non specializzato di prodotti surgelati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI PRODOTTI ALIMENTARI, BEVANDE E PRODOTTI DEL TABACCO",
                lev4_name:
                    "Commercio all'ingrosso non specializzato di prodotti alimentari, bevande e tabacco",
                label: "46.39.20 - Commercio all'ingrosso non specializzato di prodotti alimentari, bevande e tabacco ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di tessuti",
                label: "46.41.10 - Commercio all'ingrosso di tessuti ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli di merceria, filati e passamaneria",
                label: "46.41.20 - Commercio all'ingrosso di articoli di merceria, filati e passamaneria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di altri articoli tessili",
                label: "46.41.90 - Commercio all'ingrosso di altri articoli tessili ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di abbigliamento e accessori",
                label: "46.42.10 - Commercio all'ingrosso di abbigliamento e accessori ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di articoli in pelliccia",
                label: "46.42.20 - Commercio all'ingrosso di articoli in pelliccia ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di camicie, biancheria intima, maglieria e simili",
                label: "46.42.30 - Commercio all'ingrosso di camicie, biancheria intima, maglieria e simili ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di calzature e accessori",
                label: "46.42.40 - Commercio all'ingrosso di calzature e accessori ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di elettrodomestici, di elettronica di consumo audio e video",
                label: "46.43.10 - Commercio all'ingrosso di elettrodomestici, di elettronica di consumo audio e video ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di supporti registrati, audio, video (Cd, Dvd e altri supporti)",
                label: "46.43.20 - Commercio all'ingrosso di supporti registrati, audio, video (Cd, Dvd e altri supporti) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli per fotografia, cinematografia e ottica",
                label: "46.43.30 - Commercio all'ingrosso di articoli per fotografia, cinematografia e ottica ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di vetreria e cristalleria",
                label: "46.44.10 - Commercio all'ingrosso di vetreria e cristalleria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di ceramiche e porcellana",
                label: "46.44.20 - Commercio all'ingrosso di ceramiche e porcellana ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di saponi, detersivi e altri prodotti per la pulizia",
                label: "46.44.30 - Commercio all'ingrosso di saponi, detersivi e altri prodotti per la pulizia ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di coltelleria, posateria e pentolame",
                label: "46.44.40 - Commercio all'ingrosso di coltelleria, posateria e pentolame ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di profumi e cosmetici",
                label: "46.45.00 - Commercio all'ingrosso di profumi e cosmetici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di medicinali",
                label: "46.46.10 - Commercio all'ingrosso di medicinali ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di prodotti botanici per uso farmaceutico",
                label: "46.46.20 - Commercio all'ingrosso di prodotti botanici per uso farmaceutico ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli medicali ed ortopedici",
                label: "46.46.30 - Commercio all'ingrosso di articoli medicali ed ortopedici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di mobili di qualsiasi materiale",
                label: "46.47.10 - Commercio all'ingrosso di mobili di qualsiasi materiale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di tappeti",
                label: "46.47.20 - Commercio all'ingrosso di tappeti ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli per l'illuminazione; materiale elettrico vario per uso domestico",
                label: "46.47.30 - Commercio all'ingrosso di articoli per l'illuminazione; materiale elettrico vario per uso domestico ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di orologi e di gioielleria",
                label: "46.48.00 - Commercio all'ingrosso di orologi e di gioielleria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di carta, cartone e articoli di cartoleria",
                label: "46.49.10 - Commercio all'ingrosso di carta, cartone e articoli di cartoleria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di libri, riviste e giornali",
                label: "46.49.20 - Commercio all'ingrosso di libri, riviste e giornali ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name: "Commercio all'ingrosso di giochi e giocattoli",
                label: "46.49.30 - Commercio all'ingrosso di giochi e giocattoli ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli sportivi (incluse le biciclette)",
                label: "46.49.40 - Commercio all'ingrosso di articoli sportivi (incluse le biciclette) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di articoli in pelle; articoli da viaggio in qualsiasi materiale",
                label: "46.49.50 - Commercio all'ingrosso di articoli in pelle; articoli da viaggio in qualsiasi materiale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI BENI DI CONSUMO FINALE",
                lev4_name:
                    "Commercio all'ingrosso di vari prodotti di consumo non alimentare nca",
                label: "46.49.90 - Commercio all'ingrosso di vari prodotti di consumo non alimentare nca ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI APPARECCHIATURE ICT",
                lev4_name:
                    "Commercio all'ingrosso di computer, apparecchiature informatiche periferiche e di software",
                label: "46.51.00 - Commercio all'ingrosso di computer, apparecchiature informatiche periferiche e di software ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI APPARECCHIATURE ICT",
                lev4_name:
                    "Commercio all'ingrosso di apparecchi e materiali telefonici",
                label: "46.52.01 - Commercio all'ingrosso di apparecchi e materiali telefonici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI APPARECCHIATURE ICT",
                lev4_name: "Commercio all'ingrosso di nastri non registrati",
                label: "46.52.02 - Commercio all'ingrosso di nastri non registrati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO DI APPARECCHIATURE ICT",
                lev4_name:
                    "Commercio all'ingrosso di altre apparecchiature elettroniche per telecomunicazioni e di altri componenti elettronici",
                label: "46.52.09 - Commercio all'ingrosso di altre apparecchiature elettroniche per telecomunicazioni e di altri componenti elettronici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di macchine, accessori e utensili agricoli, inclusi i trattori",
                label: "46.61.00 - Commercio all'ingrosso di macchine, accessori e utensili agricoli, inclusi i trattori ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di macchine utensili (incluse le relative parti intercambiabili)",
                label: "46.62.00 - Commercio all'ingrosso di macchine utensili (incluse le relative parti intercambiabili) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di macchine per le miniere, l'edilizia e l'ingegneria civile",
                label: "46.63.00 - Commercio all'ingrosso di macchine per le miniere, l'edilizia e l'ingegneria civile ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di macchine per l'industria tessile, di macchine per cucire e per maglieria",
                label: "46.64.00 - Commercio all'ingrosso di macchine per l'industria tessile, di macchine per cucire e per maglieria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di mobili per ufficio e negozi",
                label: "46.65.00 - Commercio all'ingrosso di mobili per ufficio e negozi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di altre macchine e attrezzature per ufficio",
                label: "46.66.00 - Commercio all'ingrosso di altre macchine e attrezzature per ufficio ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name: "Commercio all'ingrosso di imbarcazioni da diporto",
                label: "46.69.11 - Commercio all'ingrosso di imbarcazioni da diporto ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di altri mezzi ed attrezzature di trasporto",
                label: "46.69.19 - Commercio all'ingrosso di altri mezzi ed attrezzature di trasporto ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di materiale elettrico per impianti di uso industriale",
                label: "46.69.20 - Commercio all'ingrosso di materiale elettrico per impianti di uso industriale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di apparecchiature per parrucchieri, palestre, solarium e centri estetici",
                label: "46.69.30 - Commercio all'ingrosso di apparecchiature per parrucchieri, palestre, solarium e centri estetici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di strumenti e attrezzature di misurazione per uso scientifico",
                label: "46.69.91 - Commercio all'ingrosso di strumenti e attrezzature di misurazione per uso scientifico ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di strumenti e attrezzature di misurazione per uso non scientifico",
                label: "46.69.92 - Commercio all'ingrosso di strumenti e attrezzature di misurazione per uso non scientifico ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di giochi per luna-park e videogiochi per pubblici esercizi",
                label: "46.69.93 - Commercio all'ingrosso di giochi per luna-park e videogiochi per pubblici esercizi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di articoli antincendio e antinfortunistici",
                label: "46.69.94 - Commercio all'ingrosso di articoli antincendio e antinfortunistici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO DI ALTRI MACCHINARI, ATTREZZATURE E FORNITURE",
                lev4_name:
                    "Commercio all'ingrosso di altre macchine ed attrezzature per l'industria, il commercio e la navigazione nca",
                label: "46.69.99 - Commercio all'ingrosso di altre macchine ed attrezzature per l'industria, il commercio e la navigazione nca ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di prodotti petroliferi e lubrificanti per autotrazione, di combustibili per riscaldamento",
                label: "46.71.00 - Commercio all'ingrosso di prodotti petroliferi e lubrificanti per autotrazione, di combustibili per riscaldamento ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di minerali metalliferi, di metalli ferrosi e prodotti semilavorati",
                label: "46.72.10 - Commercio all'ingrosso di minerali metalliferi, di metalli ferrosi e prodotti semilavorati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di metalli non ferrosi e prodotti semilavorati",
                label: "46.72.20 - Commercio all'ingrosso di metalli non ferrosi e prodotti semilavorati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di legname, semilavorati in legno e legno artificiale",
                label: "46.73.10 - Commercio all'ingrosso di legname, semilavorati in legno e legno artificiale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name: "Commercio all'ingrosso di moquette e linoleum",
                label: "46.73.21 - Commercio all'ingrosso di moquette e linoleum ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di altri materiali per rivestimenti (inclusi gli apparecchi igienico-sanitari)",
                label: "46.73.22 - Commercio all'ingrosso di altri materiali per rivestimenti (inclusi gli apparecchi igienico-sanitari) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di infissi e di articoli di arredo urbano",
                label: "46.73.23 - Commercio all'ingrosso di infissi e di articoli di arredo urbano ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di altri materiali da costruzione",
                label: "46.73.29 - Commercio all'ingrosso di altri materiali da costruzione ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name: "Commercio all'ingrosso di vetro piano",
                label: "46.73.30 - Commercio all'ingrosso di vetro piano ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di carta da parati, colori e vernici",
                label: "46.73.40 - Commercio all'ingrosso di carta da parati, colori e vernici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di articoli in ferro e in altri metalli (ferramenta)",
                label: "46.74.10 - Commercio all'ingrosso di articoli in ferro e in altri metalli (ferramenta) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di apparecchi e accessori per impianti idraulici, di riscaldamento e di condizionamento",
                label: "46.74.20 - Commercio all'ingrosso di apparecchi e accessori per impianti idraulici, di riscaldamento e di condizionamento ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di fertilizzanti e di altri prodotti chimici per l'agricoltura",
                label: "46.75.01 - Commercio all'ingrosso di fertilizzanti e di altri prodotti chimici per l'agricoltura ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di prodotti chimici per l'industria",
                label: "46.75.02 - Commercio all'ingrosso di prodotti chimici per l'industria ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di fibre tessili gregge e semilavorate",
                label: "46.76.10 - Commercio all'ingrosso di fibre tessili gregge e semilavorate ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di gomma greggia, materie plastiche in forme primarie e semilavorati",
                label: "46.76.20 - Commercio all'ingrosso di gomma greggia, materie plastiche in forme primarie e semilavorati ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name: "Commercio all'ingrosso di imballaggi",
                label: "46.76.30 - Commercio all'ingrosso di imballaggi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name: "Commercio all'ingrosso di altri prodotti intermedi",
                label: "46.76.90 - Commercio all'ingrosso di altri prodotti intermedi ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di rottami e sottoprodotti della lavorazione industriale metallici",
                label: "46.77.10 - Commercio all'ingrosso di rottami e sottoprodotti della lavorazione industriale metallici ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name:
                    "COMMERCIO ALL'INGROSSO SPECIALIZZATO DI ALTRI PRODOTTI",
                lev4_name:
                    "Commercio all'ingrosso di altri materiali di recupero non metallici (vetro, carta, cartoni eccetera)",
                label: "46.77.20 - Commercio all'ingrosso di altri materiali di recupero non metallici (vetro, carta, cartoni eccetera) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    "COMMERCIO ALL'INGROSSO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)",
                lev3_name: "COMMERCIO ALL'INGROSSO NON SPECIALIZZATO",
                lev4_name: "Commercio all'ingrosso non specializzato",
                label: "46.90.00 - Commercio all'ingrosso non specializzato ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name: 'Ipermercati',
                label: '47.11.10 - Ipermercati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name: 'Supermercati',
                label: '47.11.20 - Supermercati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name: 'Discount di alimentari',
                label: '47.11.30 - Discount di alimentari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name:
                    'Minimercati ed altri esercizi non specializzati di alimentari vari',
                label: '47.11.40 - Minimercati ed altri esercizi non specializzati di alimentari vari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di prodotti surgelati',
                label: '47.11.50 - Commercio al dettaglio di prodotti surgelati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name: 'Grandi magazzini',
                label: '47.19.10 - Grandi magazzini ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio in esercizi non specializzati di computer, periferiche, attrezzature per le telecomunicazioni, elettronica di consumo audio e video, elettrodomestici',
                label: '47.19.20 - Commercio al dettaglio in esercizi non specializzati di computer, periferiche, attrezzature per le telecomunicazioni, elettronica di consumo audio e video, elettrodomestici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO IN ESERCIZI NON SPECIALIZZATI',
                lev4_name:
                    'Empori ed altri negozi non specializzati di vari prodotti non alimentari',
                label: '47.19.90 - Empori ed altri negozi non specializzati di vari prodotti non alimentari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di frutta e verdura fresca',
                label: '47.21.01 - Commercio al dettaglio di frutta e verdura fresca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di frutta e verdura preparata e conservata',
                label: '47.21.02 - Commercio al dettaglio di frutta e verdura preparata e conservata ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di carni e di prodotti a base di carne',
                label: '47.22.00 - Commercio al dettaglio di carni e di prodotti a base di carne ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di pesci, crostacei e molluschi',
                label: '47.23.00 - Commercio al dettaglio di pesci, crostacei e molluschi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di pane',
                label: '47.24.10 - Commercio al dettaglio di pane ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di torte, dolciumi, confetteria',
                label: '47.24.20 - Commercio al dettaglio di torte, dolciumi, confetteria ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di bevande',
                label: '47.25.00 - Commercio al dettaglio di bevande ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di generi di monopolio (tabaccherie)',
                label: '47.26.00 - Commercio al dettaglio di generi di monopolio (tabaccherie) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di latte e di prodotti lattiero-caseari',
                label: '47.29.10 - Commercio al dettaglio di latte e di prodotti lattiero-caseari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di caffè torrefatto',
                label: '47.29.20 - Commercio al dettaglio di caffè torrefatto ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di prodotti macrobiotici e dietetici',
                label: '47.29.30 - Commercio al dettaglio di prodotti macrobiotici e dietetici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI PRODOTTI ALIMENTARI, BEVANDE E TABACCO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di altri prodotti alimentari in esercizi specializzati nca',
                label: '47.29.90 - Commercio al dettaglio di altri prodotti alimentari in esercizi specializzati nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI CARBURANTE PER AUTOTRAZIONE IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di carburante per autotrazione',
                label: '47.30.00 - Commercio al dettaglio di carburante per autotrazione ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI APPARECCHIATURE INFORMATICHE E PER LE TELECOMUNICAZIONI (ICT) IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di computer, unità periferiche, software e attrezzature per ufficio in esercizi specializzati',
                label: '47.41.00 - Commercio al dettaglio di computer, unità periferiche, software e attrezzature per ufficio in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI APPARECCHIATURE INFORMATICHE E PER LE TELECOMUNICAZIONI (ICT) IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di apparecchiature per le telecomunicazioni e la telefonia in esercizi specializzati',
                label: '47.42.00 - Commercio al dettaglio di apparecchiature per le telecomunicazioni e la telefonia in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI APPARECCHIATURE INFORMATICHE E PER LE TELECOMUNICAZIONI (ICT) IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di apparecchi audio e video in esercizi specializzati',
                label: '47.43.00 - Commercio al dettaglio di apparecchi audio e video in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di tessuti per l'abbigliamento, l'arredamento e di biancheria per la casa",
                label: "47.51.10 - Commercio al dettaglio di tessuti per l'abbigliamento, l'arredamento e di biancheria per la casa ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di filati per maglieria e merceria',
                label: '47.51.20 - Commercio al dettaglio di filati per maglieria e merceria ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di ferramenta, vernici, vetro piano e materiale elettrico e termoidraulico',
                label: '47.52.10 - Commercio al dettaglio di ferramenta, vernici, vetro piano e materiale elettrico e termoidraulico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli igienico-sanitari',
                label: '47.52.20 - Commercio al dettaglio di articoli igienico-sanitari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di materiali da costruzione, ceramiche e piastrelle',
                label: '47.52.30 - Commercio al dettaglio di materiali da costruzione, ceramiche e piastrelle ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di macchine, attrezzature e prodotti per l'agricoltura; macchine e attrezzature per il giardinaggio",
                label: "47.52.40 - Commercio al dettaglio di macchine, attrezzature e prodotti per l'agricoltura; macchine e attrezzature per il giardinaggio ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di tende e tendine',
                label: '47.53.11 - Commercio al dettaglio di tende e tendine ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di tappeti',
                label: '47.53.12 - Commercio al dettaglio di tappeti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di carta da parati e rivestimenti per pavimenti (moquette e linoleum)',
                label: '47.53.20 - Commercio al dettaglio di carta da parati e rivestimenti per pavimenti (moquette e linoleum) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di elettrodomestici in esercizi specializzati',
                label: '47.54.00 - Commercio al dettaglio di elettrodomestici in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di mobili per la casa',
                label: '47.59.10 - Commercio al dettaglio di mobili per la casa ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di utensili per la casa, di cristallerie e vasellame',
                label: '47.59.20 - Commercio al dettaglio di utensili per la casa, di cristallerie e vasellame ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di articoli per l'illuminazione",
                label: "47.59.30 - Commercio al dettaglio di articoli per l'illuminazione ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di macchine per cucire e per maglieria per uso domestico',
                label: '47.59.40 - Commercio al dettaglio di macchine per cucire e per maglieria per uso domestico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di sistemi di sicurezza',
                label: '47.59.50 - Commercio al dettaglio di sistemi di sicurezza ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di strumenti musicali e spartiti',
                label: '47.59.60 - Commercio al dettaglio di strumenti musicali e spartiti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli in legno, sughero, vimini e articoli in plastica per uso domestico',
                label: '47.59.91 - Commercio al dettaglio di articoli in legno, sughero, vimini e articoli in plastica per uso domestico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI PER USO DOMESTICO IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di altri articoli per uso domestico nca',
                label: '47.59.99 - Commercio al dettaglio di altri articoli per uso domestico nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di libri nuovi in esercizi specializzati',
                label: '47.61.00 - Commercio al dettaglio di libri nuovi in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di giornali, riviste e periodici',
                label: '47.62.10 - Commercio al dettaglio di giornali, riviste e periodici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli di cartoleria e forniture per ufficio',
                label: '47.62.20 - Commercio al dettaglio di articoli di cartoleria e forniture per ufficio ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di registrazioni musicali e video in esercizi specializzati',
                label: '47.63.00 - Commercio al dettaglio di registrazioni musicali e video in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli sportivi, biciclette e articoli per il tempo libero',
                label: '47.64.10 - Commercio al dettaglio di articoli sportivi, biciclette e articoli per il tempo libero ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di natanti e accessori',
                label: '47.64.20 - Commercio al dettaglio di natanti e accessori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ARTICOLI CULTURALI E RICREATIVI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di giochi e giocattoli (inclusi quelli elettronici)',
                label: '47.65.00 - Commercio al dettaglio di giochi e giocattoli (inclusi quelli elettronici) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di confezioni per adulti',
                label: '47.71.10 - Commercio al dettaglio di confezioni per adulti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di confezioni per bambini e neonati',
                label: '47.71.20 - Commercio al dettaglio di confezioni per bambini e neonati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di biancheria personale, maglieria, camicie',
                label: '47.71.30 - Commercio al dettaglio di biancheria personale, maglieria, camicie ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di pellicce e di abbigliamento in pelle',
                label: '47.71.40 - Commercio al dettaglio di pellicce e di abbigliamento in pelle ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di cappelli, ombrelli, guanti e cravatte',
                label: '47.71.50 - Commercio al dettaglio di cappelli, ombrelli, guanti e cravatte ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di calzature e accessori',
                label: '47.72.10 - Commercio al dettaglio di calzature e accessori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli di pelletteria e da viaggio',
                label: '47.72.20 - Commercio al dettaglio di articoli di pelletteria e da viaggio ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Farmacie',
                label: '47.73.10 - Farmacie ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio in altri esercizi specializzati di medicinali non soggetti a prescrizione medica',
                label: '47.73.20 - Commercio al dettaglio in altri esercizi specializzati di medicinali non soggetti a prescrizione medica ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli medicali e ortopedici in esercizi specializzati',
                label: '47.74.00 - Commercio al dettaglio di articoli medicali e ortopedici in esercizi specializzati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di articoli di profumeria, prodotti per toletta e per l'igiene personale",
                label: "47.75.10 - Commercio al dettaglio di articoli di profumeria, prodotti per toletta e per l'igiene personale ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Erboristerie',
                label: '47.75.20 - Erboristerie ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di fiori e piante',
                label: '47.76.10 - Commercio al dettaglio di fiori e piante ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di piccoli animali domestici',
                label: '47.76.20 - Commercio al dettaglio di piccoli animali domestici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di orologi, articoli di gioielleria e argenteria',
                label: '47.77.00 - Commercio al dettaglio di orologi, articoli di gioielleria e argenteria ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di mobili per ufficio',
                label: '47.78.10 - Commercio al dettaglio di mobili per ufficio ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di materiale per ottica e fotografia',
                label: '47.78.20 - Commercio al dettaglio di materiale per ottica e fotografia ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di oggetti d'arte (incluse le gallerie d'arte)",
                label: "47.78.31 - Commercio al dettaglio di oggetti d'arte (incluse le gallerie d'arte) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: "Commercio al dettaglio di oggetti d'artigianato",
                label: "47.78.32 - Commercio al dettaglio di oggetti d'artigianato ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di arredi sacri ed articoli religiosi',
                label: '47.78.33 - Commercio al dettaglio di arredi sacri ed articoli religiosi ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli da regalo e per fumatori',
                label: '47.78.34 - Commercio al dettaglio di articoli da regalo e per fumatori ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di bomboniere',
                label: '47.78.35 - Commercio al dettaglio di bomboniere ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di chincaglieria e bigiotteria (inclusi gli oggetti ricordo e gli articoli di promozione pubblicitaria)',
                label: '47.78.36 - Commercio al dettaglio di chincaglieria e bigiotteria (inclusi gli oggetti ricordo e gli articoli di promozione pubblicitaria) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli per le belle arti',
                label: '47.78.37 - Commercio al dettaglio di articoli per le belle arti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di combustibile per uso domestico e per riscaldamento',
                label: '47.78.40 - Commercio al dettaglio di combustibile per uso domestico e per riscaldamento ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di armi e munizioni, articoli militari',
                label: '47.78.50 - Commercio al dettaglio di armi e munizioni, articoli militari ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di saponi, detersivi, prodotti per la lucidatura e affini',
                label: '47.78.60 - Commercio al dettaglio di saponi, detersivi, prodotti per la lucidatura e affini ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di filatelia, numismatica e articoli da collezionismo',
                label: '47.78.91 - Commercio al dettaglio di filatelia, numismatica e articoli da collezionismo ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Commercio al dettaglio di spaghi, cordami, tele e sacchi di juta e prodotti per l'imballaggio (esclusi quelli in carta e cartone)",
                label: "47.78.92 - Commercio al dettaglio di spaghi, cordami, tele e sacchi di juta e prodotti per l'imballaggio (esclusi quelli in carta e cartone) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli funerari e cimiteriali',
                label: '47.78.93 - Commercio al dettaglio di articoli funerari e cimiteriali ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di articoli per adulti (sexy shop)',
                label: '47.78.94 - Commercio al dettaglio di articoli per adulti (sexy shop) ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di altri prodotti non alimentari nca',
                label: '47.78.99 - Commercio al dettaglio di altri prodotti non alimentari nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name: 'Commercio al dettaglio di libri di seconda mano',
                label: '47.79.10 - Commercio al dettaglio di libri di seconda mano ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di mobili usati e oggetti di antiquariato',
                label: '47.79.20 - Commercio al dettaglio di mobili usati e oggetti di antiquariato ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    'Commercio al dettaglio di indumenti e altri oggetti usati',
                label: '47.79.30 - Commercio al dettaglio di indumenti e altri oggetti usati ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO DI ALTRI PRODOTTI IN ESERCIZI SPECIALIZZATI',
                lev4_name:
                    "Case d'asta al dettaglio (escluse aste via internet)",
                label: "47.79.40 - Case d'asta al dettaglio (escluse aste via internet) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di prodotti ortofrutticoli',
                label: '47.81.01 - Commercio al dettaglio ambulante di prodotti ortofrutticoli ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di prodotti ittici',
                label: '47.81.02 - Commercio al dettaglio ambulante di prodotti ittici ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name: 'Commercio al dettaglio ambulante di carne',
                label: '47.81.03 - Commercio al dettaglio ambulante di carne ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di altri prodotti alimentari e bevande nca',
                label: '47.81.09 - Commercio al dettaglio ambulante di altri prodotti alimentari e bevande nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di tessuti, articoli tessili per la casa, articoli di abbigliamento',
                label: '47.82.01 - Commercio al dettaglio ambulante di tessuti, articoli tessili per la casa, articoli di abbigliamento ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante a posteggio fisso di calzature e pelletterie',
                label: '47.82.02 - Commercio al dettaglio ambulante a posteggio fisso di calzature e pelletterie ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di fiori, piante, bulbi, semi e fertilizzanti',
                label: '47.89.01 - Commercio al dettaglio ambulante di fiori, piante, bulbi, semi e fertilizzanti ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    "Commercio al dettaglio ambulante di macchine, attrezzature e prodotti per l'agricoltura; attrezzature per il giardinaggio",
                label: "47.89.02 - Commercio al dettaglio ambulante di macchine, attrezzature e prodotti per l'agricoltura; attrezzature per il giardinaggio ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di profumi e cosmetici; saponi, detersivi ed altri detergenti per qualsiasi uso',
                label: '47.89.03 - Commercio al dettaglio ambulante di profumi e cosmetici; saponi, detersivi ed altri detergenti per qualsiasi uso ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di chincaglieria e bigiotteria',
                label: '47.89.04 - Commercio al dettaglio ambulante di chincaglieria e bigiotteria ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di arredamenti per giardino; mobili; tappeti e stuoie; articoli casalinghi; elettrodomestici; materiale elettrico',
                label: '47.89.05 - Commercio al dettaglio ambulante di arredamenti per giardino; mobili; tappeti e stuoie; articoli casalinghi; elettrodomestici; materiale elettrico ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name: 'COMMERCIO AL DETTAGLIO AMBULANTE',
                lev4_name:
                    'Commercio al dettaglio ambulante di altri prodotti nca',
                label: '47.89.09 - Commercio al dettaglio ambulante di altri prodotti nca ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO AL DI FUORI DI NEGOZI, BANCHI E MERCATI',
                lev4_name:
                    'Commercio al dettaglio di qualsiasi tipo di prodotto effettuato via internet',
                label: '47.91.10 - Commercio al dettaglio di qualsiasi tipo di prodotto effettuato via internet ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO AL DI FUORI DI NEGOZI, BANCHI E MERCATI',
                lev4_name:
                    'Commercio al dettaglio di qualsiasi tipo di prodotto effettuato per televisione',
                label: '47.91.20 - Commercio al dettaglio di qualsiasi tipo di prodotto effettuato per televisione ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO AL DI FUORI DI NEGOZI, BANCHI E MERCATI',
                lev4_name:
                    'Commercio al dettaglio di qualsiasi tipo di prodotto per corrispondenza, radio, telefono',
                label: '47.91.30 - Commercio al dettaglio di qualsiasi tipo di prodotto per corrispondenza, radio, telefono ',
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO AL DI FUORI DI NEGOZI, BANCHI E MERCATI',
                lev4_name:
                    "Commercio al dettaglio di prodotti vari, mediante l'intervento di un dimostratore o di un incaricato alla vendita (porta a porta)",
                label: "47.99.10 - Commercio al dettaglio di prodotti vari, mediante l'intervento di un dimostratore o di un incaricato alla vendita (porta a porta) ",
            },
            {
                checked: false,
                lev1_name:
                    "COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
                lev2_name:
                    'COMMERCIO AL DETTAGLIO (ESCLUSO QUELLO DI AUTOVEICOLI E DI MOTOCICLI)',
                lev3_name:
                    'COMMERCIO AL DETTAGLIO AL DI FUORI DI NEGOZI, BANCHI E MERCATI',
                lev4_name:
                    'Commercio effettuato per mezzo di distributori automatici',
                label: '47.99.20 - Commercio effettuato per mezzo di distributori automatici ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Trasporto e magazzinaggio',
        children: [
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO FERROVIARIO DI PASSEGGERI (INTERURBANO)',
                lev4_name: 'Trasporto ferroviario di passeggeri (interurbano)',
                label: '49.10.00 - Trasporto ferroviario di passeggeri (interurbano) ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO FERROVIARIO DI MERCI',
                lev4_name: 'Trasporto ferroviario di merci',
                label: '49.20.00 - Trasporto ferroviario di merci ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'ALTRI TRASPORTI TERRESTRI DI PASSEGGERI',
                lev4_name:
                    'Trasporto terrestre di passeggeri in aree urbane e suburbane',
                label: '49.31.00 - Trasporto terrestre di passeggeri in aree urbane e suburbane ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'ALTRI TRASPORTI TERRESTRI DI PASSEGGERI',
                lev4_name: 'Trasporto con taxi',
                label: '49.32.10 - Trasporto con taxi ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'ALTRI TRASPORTI TERRESTRI DI PASSEGGERI',
                lev4_name:
                    'Trasporto mediante noleggio di autovetture da rimessa con conducente',
                label: '49.32.20 - Trasporto mediante noleggio di autovetture da rimessa con conducente ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'ALTRI TRASPORTI TERRESTRI DI PASSEGGERI',
                lev4_name:
                    'Gestioni di funicolari, ski-lift e seggiovie se non facenti parte dei sistemi di transito urbano o sub-urbano',
                label: '49.39.01 - Gestioni di funicolari, ski-lift e seggiovie se non facenti parte dei sistemi di transito urbano o sub-urbano ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'ALTRI TRASPORTI TERRESTRI DI PASSEGGERI',
                lev4_name:
                    'Altre attività di trasporti terrestri di passeggeri nca',
                label: '49.39.09 - Altre attività di trasporti terrestri di passeggeri nca ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO DI MERCI SU STRADA E SERVIZI DI TRASLOCO',
                lev4_name: 'Trasporto di merci su strada',
                label: '49.41.00 - Trasporto di merci su strada ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO DI MERCI SU STRADA E SERVIZI DI TRASLOCO',
                lev4_name: 'Servizi di trasloco',
                label: '49.42.00 - Servizi di trasloco ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO MEDIANTE CONDOTTE',
                lev4_name: 'Trasporto mediante condotte di gas',
                label: '49.50.10 - Trasporto mediante condotte di gas ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO TERRESTRE E TRASPORTO MEDIANTE CONDOTTE',
                lev3_name: 'TRASPORTO MEDIANTE CONDOTTE',
                lev4_name: 'Trasporto mediante condotte di liquidi',
                label: '49.50.20 - Trasporto mediante condotte di liquidi ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: "TRASPORTO MARITTIMO E PER VIE D'ACQUA",
                lev3_name: 'TRASPORTO MARITTIMO E COSTIERO DI PASSEGGERI',
                lev4_name: 'Trasporto marittimo e costiero di passeggeri',
                label: '50.10.00 - Trasporto marittimo e costiero di passeggeri ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: "TRASPORTO MARITTIMO E PER VIE D'ACQUA",
                lev3_name: 'TRASPORTO MARITTIMO E COSTIERO DI MERCI',
                lev4_name: 'Trasporto marittimo e costiero di merci',
                label: '50.20.00 - Trasporto marittimo e costiero di merci ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: "TRASPORTO MARITTIMO E PER VIE D'ACQUA",
                lev3_name: "TRASPORTO DI PASSEGGERI PER VIE D'ACQUA INTERNE",
                lev4_name:
                    "Trasporto di passeggeri per vie d'acqua interne (inclusi i trasporti lagunari)",
                label: "50.30.00 - Trasporto di passeggeri per vie d'acqua interne (inclusi i trasporti lagunari) ",
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: "TRASPORTO MARITTIMO E PER VIE D'ACQUA",
                lev3_name: "TRASPORTO DI MERCI PER VIE D'ACQUA INTERNE",
                lev4_name: "Trasporto di merci per vie d'acqua interne",
                label: "50.40.00 - Trasporto di merci per vie d'acqua interne ",
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO AEREO',
                lev3_name: 'TRASPORTO AEREO DI PASSEGGERI',
                lev4_name: 'Trasporto aereo di linea di passeggeri',
                label: '51.10.10 - Trasporto aereo di linea di passeggeri ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO AEREO',
                lev3_name: 'TRASPORTO AEREO DI PASSEGGERI',
                lev4_name:
                    'Trasporto aereo non di linea di passeggeri; voli charter',
                label: '51.10.20 - Trasporto aereo non di linea di passeggeri; voli charter ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO AEREO',
                lev3_name: 'TRASPORTO AEREO DI MERCI E TRASPORTO SPAZIALE',
                lev4_name: 'Trasporto aereo di merci',
                label: '51.21.00 - Trasporto aereo di merci ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'TRASPORTO AEREO',
                lev3_name: 'TRASPORTO AEREO DI MERCI E TRASPORTO SPAZIALE',
                lev4_name: 'Trasporto spaziale',
                label: '51.22.00 - Trasporto spaziale ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'MAGAZZINAGGIO E CUSTODIA',
                lev4_name: 'Magazzini di custodia e deposito per conto terzi',
                label: '52.10.10 - Magazzini di custodia e deposito per conto terzi ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'MAGAZZINAGGIO E CUSTODIA',
                lev4_name: 'Magazzini frigoriferi per conto terzi',
                label: '52.10.20 - Magazzini frigoriferi per conto terzi ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Gestione di infrastrutture ferroviarie',
                label: '52.21.10 - Gestione di infrastrutture ferroviarie ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Gestione di strade, ponti, gallerie',
                label: '52.21.20 - Gestione di strade, ponti, gallerie ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Gestione di stazioni per autobus',
                label: '52.21.30 - Gestione di stazioni per autobus ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    'Gestione di centri di movimentazione merci (interporti)',
                label: '52.21.40 - Gestione di centri di movimentazione merci (interporti) ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Gestione di parcheggi e autorimesse',
                label: '52.21.50 - Gestione di parcheggi e autorimesse ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Attività di traino e soccorso stradale',
                label: '52.21.60 - Attività di traino e soccorso stradale ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Altre attività connesse ai trasporti terrestri nca',
                label: '52.21.90 - Altre attività connesse ai trasporti terrestri nca ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    "Liquefazione e rigassificazione di gas a scopo di trasporto marittimo e per vie d'acqua effettuata al di fuori del sito di estrazione",
                label: "52.22.01 - Liquefazione e rigassificazione di gas a scopo di trasporto marittimo e per vie d'acqua effettuata al di fuori del sito di estrazione ",
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    "Altre attività dei servizi connessi al trasporto marittimo e per vie d'acqua",
                label: "52.22.09 - Altre attività dei servizi connessi al trasporto marittimo e per vie d'acqua ",
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Attività dei servizi connessi al trasporto aereo',
                label: '52.23.00 - Attività dei servizi connessi al trasporto aereo ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Movimento merci relativo a trasporti aerei',
                label: '52.24.10 - Movimento merci relativo a trasporti aerei ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    'Movimento merci relativo a trasporti marittimi e fluviali',
                label: '52.24.20 - Movimento merci relativo a trasporti marittimi e fluviali ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Movimento merci relativo a trasporti ferroviari',
                label: '52.24.30 - Movimento merci relativo a trasporti ferroviari ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    'Movimento merci relativo ad altri trasporti terrestri',
                label: '52.24.40 - Movimento merci relativo ad altri trasporti terrestri ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Spedizionieri e agenzie di operazioni doganali',
                label: '52.29.10 - Spedizionieri e agenzie di operazioni doganali ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name: 'Intermediari dei trasporti',
                label: '52.29.21 - Intermediari dei trasporti ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'MAGAZZINAGGIO E ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev3_name: 'ATTIVITÀ DI SUPPORTO AI TRASPORTI',
                lev4_name:
                    'Servizi logistici relativi alla distribuzione delle merci',
                label: '52.29.22 - Servizi logistici relativi alla distribuzione delle merci ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'SERVIZI POSTALI E ATTIVITÀ DI CORRIERE',
                lev3_name:
                    'ATTIVITÀ POSTALI CON OBBLIGO DI SERVIZIO UNIVERSALE',
                lev4_name:
                    'Attività postali con obbligo di servizio universale',
                label: '53.10.00 - Attività postali con obbligo di servizio universale ',
            },
            {
                checked: false,
                lev1_name: 'TRASPORTO E MAGAZZINAGGIO',
                lev2_name: 'SERVIZI POSTALI E ATTIVITÀ DI CORRIERE',
                lev3_name: 'ALTRE ATTIVITÀ POSTALI E DI CORRIERE',
                lev4_name:
                    'Altre attività postali e di corriere senza obbligo di servizio universale',
                label: '53.20.00 - Altre attività postali e di corriere senza obbligo di servizio universale ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività di servizi di alloggio e ristorazione',
        children: [
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name: 'ALBERGHI E STRUTTURE SIMILI',
                lev4_name: 'Alberghi',
                label: '55.10.00 - Alberghi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name: 'Villaggi turistici',
                label: '55.20.10 - Villaggi turistici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name: 'Ostelli della gioventù',
                label: '55.20.20 - Ostelli della gioventù ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name: 'Rifugi di montagna',
                label: '55.20.30 - Rifugi di montagna ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name: 'Colonie marine e montane',
                label: '55.20.40 - Colonie marine e montane ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name:
                    'Affittacamere per brevi soggiorni, case ed appartamenti per vacanze, bed and breakfast, residence',
                label: '55.20.51 - Affittacamere per brevi soggiorni, case ed appartamenti per vacanze, bed and breakfast, residence ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name:
                    'Attività di alloggio connesse alle aziende agricole',
                label: '55.20.52 - Attività di alloggio connesse alle aziende agricole ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'ALLOGGI PER VACANZE E ALTRE STRUTTURE PER BREVI SOGGIORNI',
                lev4_name: 'Attività di alloggio connesse alle aziende ittiche',
                label: '55.20.53 - Attività di alloggio connesse alle aziende ittiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name:
                    'AREE DI CAMPEGGIO E AREE ATTREZZATE PER CAMPER E ROULOTTE',
                lev4_name:
                    'Aree di campeggio e aree attrezzate per camper e roulotte',
                label: '55.30.00 - Aree di campeggio e aree attrezzate per camper e roulotte ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name: 'ALTRI ALLOGGI',
                lev4_name: 'Gestione di vagoni letto',
                label: '55.90.10 - Gestione di vagoni letto ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ALLOGGIO',
                lev3_name: 'ALTRI ALLOGGI',
                lev4_name:
                    'Alloggi per studenti e lavoratori con servizi accessori di tipo alberghiero',
                label: '55.90.20 - Alloggi per studenti e lavoratori con servizi accessori di tipo alberghiero ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name: 'Ristorazione con somministrazione',
                label: '56.10.11 - Ristorazione con somministrazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name:
                    'Attività di ristorazione connesse alle aziende agricole',
                label: '56.10.12 - Attività di ristorazione connesse alle aziende agricole ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name:
                    'Attività di ristorazione connesse alle aziende ittiche',
                label: '56.10.13 - Attività di ristorazione connesse alle aziende ittiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name:
                    'Ristorazione senza somministrazione con preparazione di cibi da asporto',
                label: '56.10.20 - Ristorazione senza somministrazione con preparazione di cibi da asporto ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name: 'Gelaterie e pasticcerie',
                label: '56.10.30 - Gelaterie e pasticcerie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name: 'Gelaterie e pasticcerie ambulanti',
                label: '56.10.41 - Gelaterie e pasticcerie ambulanti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name: 'Ristorazione ambulante',
                label: '56.10.42 - Ristorazione ambulante ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'RISTORANTI E ATTIVITÀ DI RISTORAZIONE MOBILE',
                lev4_name: 'Ristorazione su treni e navi',
                label: '56.10.50 - Ristorazione su treni e navi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name:
                    'FORNITURA DI PASTI PREPARATI (CATERING) E ALTRI SERVIZI DI RISTORAZIONE',
                lev4_name: 'Catering per eventi, banqueting',
                label: '56.21.00 - Catering per eventi, banqueting ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name:
                    'FORNITURA DI PASTI PREPARATI (CATERING) E ALTRI SERVIZI DI RISTORAZIONE',
                lev4_name: 'Mense',
                label: '56.29.10 - Mense ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name:
                    'FORNITURA DI PASTI PREPARATI (CATERING) E ALTRI SERVIZI DI RISTORAZIONE',
                lev4_name: 'Catering continuativo su base contrattuale',
                label: '56.29.20 - Catering continuativo su base contrattuale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
                lev2_name: 'ATTIVITÀ DEI SERVIZI DI RISTORAZIONE',
                lev3_name: 'BAR E ALTRI ESERCIZI SIMILI SENZA CUCINA',
                lev4_name: 'Bar e altri esercizi simili senza cucina',
                label: '56.30.00 - Bar e altri esercizi simili senza cucina ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Servizi di informazione e comunicazione',
        children: [
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Edizione di libri',
                label: '58.11.00 - Edizione di libri ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Pubblicazione di elenchi',
                label: '58.12.01 - Pubblicazione di elenchi ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Pubblicazione di mailing list',
                label: '58.12.02 - Pubblicazione di mailing list ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Edizione di quotidiani',
                label: '58.13.00 - Edizione di quotidiani ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Edizione di riviste e periodici',
                label: '58.14.00 - Edizione di riviste e periodici ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name:
                    'EDIZIONE DI LIBRI, PERIODICI ED ALTRE ATTIVITÀ EDITORIALI',
                lev4_name: 'Altre attività editoriali',
                label: '58.19.00 - Altre attività editoriali ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name: 'EDIZIONE DI SOFTWARE',
                lev4_name: 'Edizione di giochi per computer',
                label: '58.21.00 - Edizione di giochi per computer ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ EDITORIALI',
                lev3_name: 'EDIZIONE DI SOFTWARE',
                lev4_name:
                    'Edizione di altri software a pacchetto (esclusi giochi per computer)',
                label: '58.29.00 - Edizione di altri software a pacchetto (esclusi giochi per computer) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI',
                lev4_name:
                    'Attività di produzione cinematografica, di video e di programmi televisivi',
                label: '59.11.00 - Attività di produzione cinematografica, di video e di programmi televisivi ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI',
                lev4_name:
                    'Attività di post-produzione cinematografica, di video e di programmi televisivi',
                label: '59.12.00 - Attività di post-produzione cinematografica, di video e di programmi televisivi ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI',
                lev4_name:
                    'Attività di distribuzione cinematografica, di video e di programmi televisivi',
                label: '59.13.00 - Attività di distribuzione cinematografica, di video e di programmi televisivi ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI',
                lev4_name: 'Attività di proiezione cinematografica',
                label: '59.14.00 - Attività di proiezione cinematografica ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI REGISTRAZIONE SONORA E DI EDITORIA MUSICALE',
                lev4_name: 'Edizione di registrazioni sonore',
                label: '59.20.10 - Edizione di registrazioni sonore ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI REGISTRAZIONE SONORA E DI EDITORIA MUSICALE',
                lev4_name: 'Edizione di musica stampata',
                label: '59.20.20 - Edizione di musica stampata ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'ATTIVITÀ DI PRODUZIONE CINEMATOGRAFICA, DI VIDEO E DI PROGRAMMI TELEVISIVI, DI REGISTRAZIONI MUSICALI E SONORE',
                lev3_name:
                    'ATTIVITÀ DI REGISTRAZIONE SONORA E DI EDITORIA MUSICALE',
                lev4_name: 'Studi di registrazione sonora',
                label: '59.20.30 - Studi di registrazione sonora ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ DI PROGRAMMAZIONE E TRASMISSIONE',
                lev3_name: 'TRASMISSIONI RADIOFONICHE',
                lev4_name: 'Trasmissioni radiofoniche',
                label: '60.10.00 - Trasmissioni radiofoniche ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'ATTIVITÀ DI PROGRAMMAZIONE E TRASMISSIONE',
                lev3_name:
                    'ATTIVITÀ DI PROGRAMMAZIONE E TRASMISSIONI TELEVISIVE',
                lev4_name: 'Programmazione e trasmissioni televisive',
                label: '60.20.00 - Programmazione e trasmissioni televisive ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'TELECOMUNICAZIONI FISSE',
                lev4_name: 'Telecomunicazioni fisse',
                label: '61.10.00 - Telecomunicazioni fisse ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'TELECOMUNICAZIONI MOBILI',
                lev4_name: 'Telecomunicazioni mobili',
                label: '61.20.00 - Telecomunicazioni mobili ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'TELECOMUNICAZIONI SATELLITARI',
                lev4_name: 'Telecomunicazioni satellitari',
                label: '61.30.00 - Telecomunicazioni satellitari ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'ALTRE ATTIVITÀ DI TELECOMUNICAZIONE',
                lev4_name: 'Erogazione di servizi di accesso ad internet (ISP)',
                label: '61.90.10 - Erogazione di servizi di accesso ad internet (ISP) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'ALTRE ATTIVITÀ DI TELECOMUNICAZIONE',
                lev4_name: 'Posto telefonico pubblico ed Internet Point',
                label: '61.90.20 - Posto telefonico pubblico ed Internet Point ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'ALTRE ATTIVITÀ DI TELECOMUNICAZIONE',
                lev4_name:
                    'Intermediazione in servizi di telecomunicazione e trasmissione dati',
                label: '61.90.91 - Intermediazione in servizi di telecomunicazione e trasmissione dati ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name: 'TELECOMUNICAZIONI',
                lev3_name: 'ALTRE ATTIVITÀ DI TELECOMUNICAZIONE',
                lev4_name: 'Altre attività connesse alle telecomunicazioni nca',
                label: '61.90.99 - Altre attività connesse alle telecomunicazioni nca ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev3_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev4_name: "Produzione di software non connesso all'edizione",
                label: "62.01.00 - Produzione di software non connesso all'edizione ",
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev3_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev4_name:
                    "Consulenza nel settore delle tecnologie dell'informatica",
                label: "62.02.00 - Consulenza nel settore delle tecnologie dell'informatica ",
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev3_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev4_name:
                    'Gestione di strutture e apparecchiature informatiche hardware - housing (esclusa la riparazione)',
                label: '62.03.00 - Gestione di strutture e apparecchiature informatiche hardware - housing (esclusa la riparazione) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev3_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev4_name: 'Configurazione di personal computer',
                label: '62.09.01 - Configurazione di personal computer ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev3_name:
                    'PRODUZIONE DI SOFTWARE, CONSULENZA INFORMATICA E ATTIVITÀ CONNESSE',
                lev4_name:
                    "Altre attività dei servizi connessi alle tecnologie dell'informatica nca",
                label: "62.09.09 - Altre attività dei servizi connessi alle tecnologie dell'informatica nca ",
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name:
                    'ELABORAZIONE DEI DATI, HOSTING E ATTIVITÀ CONNESSE; PORTALI WEB',
                lev4_name:
                    'Elaborazione elettronica di dati contabili (esclusi i Centri di assistenza fiscale - Caf)',
                label: '63.11.11 - Elaborazione elettronica di dati contabili (esclusi i Centri di assistenza fiscale - Caf) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name:
                    'ELABORAZIONE DEI DATI, HOSTING E ATTIVITÀ CONNESSE; PORTALI WEB',
                lev4_name: 'Altre elaborazioni elettroniche di dati',
                label: '63.11.19 - Altre elaborazioni elettroniche di dati ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name:
                    'ELABORAZIONE DEI DATI, HOSTING E ATTIVITÀ CONNESSE; PORTALI WEB',
                lev4_name: 'Gestione database (attività delle banche dati)',
                label: '63.11.20 - Gestione database (attività delle banche dati) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name:
                    'ELABORAZIONE DEI DATI, HOSTING E ATTIVITÀ CONNESSE; PORTALI WEB',
                lev4_name: 'Hosting e fornitura di servizi applicativi (ASP)',
                label: '63.11.30 - Hosting e fornitura di servizi applicativi (ASP) ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name:
                    'ELABORAZIONE DEI DATI, HOSTING E ATTIVITÀ CONNESSE; PORTALI WEB',
                lev4_name: 'Portali web',
                label: '63.12.00 - Portali web ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name: "ALTRE ATTIVITÀ DEI SERVIZI D'INFORMAZIONE",
                lev4_name: 'Attività delle agenzie di stampa',
                label: '63.91.00 - Attività delle agenzie di stampa ',
            },
            {
                checked: false,
                lev1_name: 'SERVIZI DI INFORMAZIONE E COMUNICAZIONE',
                lev2_name:
                    "ATTIVITÀ DEI SERVIZI D'INFORMAZIONE E ALTRI SERVIZI INFORMATICI",
                lev3_name: "ALTRE ATTIVITÀ DEI SERVIZI D'INFORMAZIONE",
                lev4_name: 'Altre attività dei servizi di informazione nca',
                label: '63.99.00 - Altre attività dei servizi di informazione nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività finanziarie e assicurative',
        children: [
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'INTERMEDIAZIONE MONETARIA',
                lev4_name: 'Attività della Banca Centrale',
                label: '64.11.00 - Attività della Banca Centrale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'INTERMEDIAZIONE MONETARIA',
                lev4_name:
                    'Intermediazione monetaria di istituti monetari diverse dalle Banche centrali',
                label: '64.19.10 - Intermediazione monetaria di istituti monetari diverse dalle Banche centrali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'INTERMEDIAZIONE MONETARIA',
                lev4_name: 'Fondi comuni di investimento monetario',
                label: '64.19.20 - Fondi comuni di investimento monetario ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'INTERMEDIAZIONE MONETARIA',
                lev4_name: 'Istituti di moneta elettronica (Imel)',
                label: '64.19.30 - Istituti di moneta elettronica (Imel) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'INTERMEDIAZIONE MONETARIA',
                lev4_name: 'Cassa Depositi e Prestiti',
                label: '64.19.40 - Cassa Depositi e Prestiti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'ATTIVITÀ DELLE SOCIETÀ DI PARTECIPAZIONE (HOLDING)',
                lev4_name: 'Attività delle società di partecipazione (holding)',
                label: '64.20.00 - Attività delle società di partecipazione (holding) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'SOCIETÀ FIDUCIARIE, FONDI E ALTRE SOCIETÀ SIMILI',
                lev4_name:
                    'Fondi comuni di investimento (aperti e chiusi, immobiliari, di mercato mobiliare)',
                label: '64.30.10 - Fondi comuni di investimento (aperti e chiusi, immobiliari, di mercato mobiliare) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name: 'SOCIETÀ FIDUCIARIE, FONDI E ALTRE SOCIETÀ SIMILI',
                lev4_name:
                    'Sicav (Società di investimento a capitale variabile)',
                label: '64.30.20 - Sicav (Società di investimento a capitale variabile) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Leasing finanziario',
                label: '64.91.00 - Leasing finanziario ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività dei consorzi di garanzia collettiva fidi',
                label: '64.92.01 - Attività dei consorzi di garanzia collettiva fidi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Altre attività creditizie nca',
                label: '64.92.09 - Altre attività creditizie nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività di intermediazione mobiliare',
                label: '64.99.10 - Attività di intermediazione mobiliare ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività di factoring',
                label: '64.99.20 - Attività di factoring ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività di merchant bank',
                label: '64.99.30 - Attività di merchant bank ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività delle società veicolo',
                label: '64.99.40 - Attività delle società veicolo ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività di intermediazione in cambi',
                label: '64.99.50 - Attività di intermediazione in cambi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev3_name:
                    'ALTRE ATTIVITÀ DI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Altre intermediazioni finanziarie nca',
                label: '64.99.60 - Altre intermediazioni finanziarie nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'ASSICURAZIONI',
                lev4_name: 'Assicurazioni sulla vita',
                label: '65.11.00 - Assicurazioni sulla vita ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'ASSICURAZIONI',
                lev4_name: 'Assicurazioni diverse da quelle sulla vita',
                label: '65.12.00 - Assicurazioni diverse da quelle sulla vita ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'RIASSICURAZIONI',
                lev4_name: 'Attività di riassicurazione',
                label: '65.20.00 - Attività di riassicurazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'FONDI PENSIONE',
                lev4_name: 'Attività dei fondi pensione aperti',
                label: '65.30.10 - Attività dei fondi pensione aperti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'FONDI PENSIONE',
                lev4_name: 'Attività dei fondi pensione negoziali',
                label: '65.30.20 - Attività dei fondi pensione negoziali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ASSICURAZIONI, RIASSICURAZIONI E FONDI PENSIONE (ESCLUSE LE ASSICURAZIONI SOCIALI OBBLIGATORIE)',
                lev3_name: 'FONDI PENSIONE',
                lev4_name: 'Attività dei fondi pensione preesistenti',
                label: '65.30.30 - Attività dei fondi pensione preesistenti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Amministrazione di mercati finanziari',
                label: '66.11.00 - Amministrazione di mercati finanziari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name:
                    'Attività di negoziazione di contratti relativi a titoli e merci',
                label: '66.12.00 - Attività di negoziazione di contratti relativi a titoli e merci ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name:
                    'Attività di gestione ed elaborazione di pagamenti tramite carta di credito',
                label: '66.19.10 - Attività di gestione ed elaborazione di pagamenti tramite carta di credito ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Promotori finanziari',
                label: '66.19.21 - Promotori finanziari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name:
                    'Agenti, mediatori e procacciatori in prodotti finanziari',
                label: '66.19.22 - Agenti, mediatori e procacciatori in prodotti finanziari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name:
                    'Attività delle società fiduciarie di amministrazione',
                label: '66.19.30 - Attività delle società fiduciarie di amministrazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name: 'Attività di Bancoposta',
                label: '66.19.40 - Attività di Bancoposta ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI (ESCLUSE LE ASSICURAZIONI E I FONDI PENSIONE)',
                lev4_name:
                    'Servizi di trasferimento di denaro (money transfer)',
                label: '66.19.50 - Servizi di trasferimento di denaro (money transfer) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name:
                    'Attività dei periti e liquidatori indipendenti delle assicurazioni',
                label: '66.21.00 - Attività dei periti e liquidatori indipendenti delle assicurazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name: 'Broker di assicurazioni',
                label: '66.22.01 - Broker di assicurazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name: 'Agenti di assicurazioni',
                label: '66.22.02 - Agenti di assicurazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name: 'Sub-agenti di assicurazioni',
                label: '66.22.03 - Sub-agenti di assicurazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name:
                    'Produttori, procacciatori ed altri intermediari delle assicurazioni',
                label: '66.22.04 - Produttori, procacciatori ed altri intermediari delle assicurazioni ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name:
                    'Autorità centrali di vigilanza su assicurazioni e fondi pensione',
                label: '66.29.01 - Autorità centrali di vigilanza su assicurazioni e fondi pensione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name:
                    'ATTIVITÀ AUSILIARIE DELLE ASSICURAZIONI E DEI FONDI PENSIONE',
                lev4_name:
                    'Altre attività ausiliarie delle assicurazioni e dei fondi pensione',
                label: '66.29.09 - Altre attività ausiliarie delle assicurazioni e dei fondi pensione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ FINANZIARIE E ASSICURATIVE',
                lev2_name:
                    'ATTIVITÀ AUSILIARIE DEI SERVIZI FINANZIARI E DELLE ATTIVITÀ ASSICURATIVE',
                lev3_name: 'ATTIVITÀ DI GESTIONE DEI FONDI',
                lev4_name:
                    'Gestione di fondi comuni di investimento e dei fondi pensione',
                label: '66.30.00 - Gestione di fondi comuni di investimento e dei fondi pensione ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ATTIVITÀ IMMOBILIARI',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività immobiliari',
        children: [
            {
                checked: false,
                lev1_name: "ATTIVITA' IMMOBILIARI",
                lev2_name: 'ATTIVITÀ IMMOBILIARI',
                lev3_name:
                    'COMPRAVENDITA DI BENI IMMOBILI EFFETTUATA SU BENI PROPRI',
                lev4_name:
                    'Compravendita di beni immobili effettuata su beni propri',
                label: '68.10.00 - Compravendita di beni immobili effettuata su beni propri ',
            },
            {
                checked: false,
                lev1_name: "ATTIVITA' IMMOBILIARI",
                lev2_name: 'ATTIVITÀ IMMOBILIARI',
                lev3_name:
                    'AFFITTO E GESTIONE DI  IMMOBILI DI PROPRIETÀ O IN LEASING',
                lev4_name: 'Locazione immobiliare di beni propri',
                label: '68.20.01 - Locazione immobiliare di beni propri ',
            },
            {
                checked: false,
                lev1_name: "ATTIVITA' IMMOBILIARI",
                lev2_name: 'ATTIVITÀ IMMOBILIARI',
                lev3_name:
                    'AFFITTO E GESTIONE DI  IMMOBILI DI PROPRIETÀ O IN LEASING',
                lev4_name: 'Affitto di aziende',
                label: '68.20.02 - Affitto di aziende ',
            },
            {
                checked: false,
                lev1_name: "ATTIVITA' IMMOBILIARI",
                lev2_name: 'ATTIVITÀ IMMOBILIARI',
                lev3_name: 'ATTIVITÀ IMMOBILIARI PER CONTO TERZI',
                lev4_name: 'Attività di mediazione immobiliare',
                label: '68.31.00 - Attività di mediazione immobiliare ',
            },
            {
                checked: false,
                lev1_name: "ATTIVITA' IMMOBILIARI",
                lev2_name: 'ATTIVITÀ IMMOBILIARI',
                lev3_name: 'ATTIVITÀ IMMOBILIARI PER CONTO TERZI',
                lev4_name:
                    'Amministrazione di condomini e gestione di beni immobili per conto terzi',
                label: '68.32.00 - Amministrazione di condomini e gestione di beni immobili per conto terzi ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività professionali, scientifiche e tecniche',
        children: [
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name: 'ATTIVITÀ DEGLI STUDI LEGALI',
                lev4_name: 'Attività degli studi legali',
                label: '69.10.10 - Attività degli studi legali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name: 'ATTIVITÀ DEGLI STUDI LEGALI',
                lev4_name: 'Attività degli studi notarili',
                label: '69.10.20 - Attività degli studi notarili ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name: 'Servizi forniti da dottori commercialisti',
                label: '69.20.11 - Servizi forniti da dottori commercialisti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name: 'Servizi forniti da ragionieri e periti commerciali',
                label: '69.20.12 - Servizi forniti da ragionieri e periti commerciali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name:
                    'Servizi forniti da revisori contabili, periti, consulenti ed altri soggetti che svolgono attività in materia di amministrazione, contabilità e tributi',
                label: '69.20.13 - Servizi forniti da revisori contabili, periti, consulenti ed altri soggetti che svolgono attività in materia di amministrazione, contabilità e tributi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name:
                    'Attività svolta dai Centri di assistenza fiscale (Caf)',
                label: '69.20.14 - Attività svolta dai Centri di assistenza fiscale (Caf) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name:
                    'Gestione ed amministrazione del personale per conto terzi',
                label: '69.20.15 - Gestione ed amministrazione del personale per conto terzi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name:
                    'Attività delle società di revisione e certificazione di bilanci',
                label: '69.20.20 - Attività delle società di revisione e certificazione di bilanci ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'ATTIVITÀ LEGALI E CONTABILITÀ',
                lev3_name:
                    'CONTABILITÀ, CONTROLLO E REVISIONE CONTABILE, CONSULENZA IN MATERIA FISCALE E DEL LAVORO',
                lev4_name: 'Attività dei consulenti del lavoro',
                label: '69.20.30 - Attività dei consulenti del lavoro ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ATTIVITÀ DI DIREZIONE AZIENDALE E DI CONSULENZA GESTIONALE',
                lev3_name: 'ATTIVITÀ DI DIREZIONE AZIENDALE',
                lev4_name:
                    'Attività delle holding impegnate nelle attività gestionali (holding operative)',
                label: '70.10.00 - Attività delle holding impegnate nelle attività gestionali (holding operative) ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ATTIVITÀ DI DIREZIONE AZIENDALE E DI CONSULENZA GESTIONALE',
                lev3_name: 'ATTIVITÀ DI CONSULENZA GESTIONALE',
                lev4_name: 'Pubbliche relazioni e comunicazione',
                label: '70.21.00 - Pubbliche relazioni e comunicazione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ATTIVITÀ DI DIREZIONE AZIENDALE E DI CONSULENZA GESTIONALE',
                lev3_name: 'ATTIVITÀ DI CONSULENZA GESTIONALE',
                lev4_name:
                    'Attività di consulenza per la gestione della logistica aziendale',
                label: '70.22.01 - Attività di consulenza per la gestione della logistica aziendale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ATTIVITÀ DI DIREZIONE AZIENDALE E DI CONSULENZA GESTIONALE',
                lev3_name: 'ATTIVITÀ DI CONSULENZA GESTIONALE',
                lev4_name:
                    'Altre attività di consulenza imprenditoriale e altra consulenza amministrativo-gestionale e pianificazione aziendale',
                label: '70.22.09 - Altre attività di consulenza imprenditoriale e altra consulenza amministrativo-gestionale e pianificazione aziendale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name: 'Attività degli studi di architettura',
                label: '71.11.00 - Attività degli studi di architettura ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name: 'Attività degli studi di ingegneria',
                label: '71.12.10 - Attività degli studi di ingegneria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name: 'Servizi di progettazione di ingegneria integrata',
                label: '71.12.20 - Servizi di progettazione di ingegneria integrata ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name: 'Attività tecniche svolte da geometri',
                label: '71.12.30 - Attività tecniche svolte da geometri ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name: 'Attività di cartografia e aerofotogrammetria',
                label: '71.12.40 - Attività di cartografia e aerofotogrammetria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name:
                    'ATTIVITÀ DEGLI STUDI DI ARCHITETTURA, INGEGNERIA ED ALTRI STUDI TECNICI',
                lev4_name:
                    'Attività di studio geologico e di prospezione geognostica e mineraria',
                label: '71.12.50 - Attività di studio geologico e di prospezione geognostica e mineraria ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name: 'COLLAUDI ED ANALISI TECNICHE',
                lev4_name: 'Collaudi e analisi tecniche di prodotti',
                label: '71.20.10 - Collaudi e analisi tecniche di prodotti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name: 'COLLAUDI ED ANALISI TECNICHE',
                lev4_name:
                    'Controllo di qualità e certificazione di prodotti, processi e sistemi',
                label: '71.20.21 - Controllo di qualità e certificazione di prodotti, processi e sistemi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name: 'COLLAUDI ED ANALISI TECNICHE',
                lev4_name:
                    'Attività per la tutela di beni di produzione controllata',
                label: '71.20.22 - Attività per la tutela di beni di produzione controllata ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    "ATTIVITÀ DEGLI STUDI DI ARCHITETTURA E D'INGEGNERIA; COLLAUDI ED ANALISI TECNICHE",
                lev3_name: 'COLLAUDI ED ANALISI TECNICHE',
                lev4_name:
                    'Revisione periodica a norma di legge dell’idoneità alla circolazione degli autoveicoli e motoveicoli',
                label: '71.20.23 - Revisione periodica a norma di legge dell’idoneità alla circolazione degli autoveicoli e motoveicoli ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'RICERCA SCIENTIFICA E SVILUPPO',
                lev3_name:
                    "RICERCA E SVILUPPO SPERIMENTALE NEL CAMPO DELLE SCIENZE NATURALI E DELL'INGEGNERIA",
                lev4_name:
                    'Ricerca e sviluppo sperimentale nel campo delle biotecnologie',
                label: '72.11.00 - Ricerca e sviluppo sperimentale nel campo delle biotecnologie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'RICERCA SCIENTIFICA E SVILUPPO',
                lev3_name:
                    "RICERCA E SVILUPPO SPERIMENTALE NEL CAMPO DELLE SCIENZE NATURALI E DELL'INGEGNERIA",
                lev4_name:
                    'Ricerca e sviluppo sperimentale nel campo della geologia',
                label: '72.19.01 - Ricerca e sviluppo sperimentale nel campo della geologia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'RICERCA SCIENTIFICA E SVILUPPO',
                lev3_name:
                    "RICERCA E SVILUPPO SPERIMENTALE NEL CAMPO DELLE SCIENZE NATURALI E DELL'INGEGNERIA",
                lev4_name:
                    "Ricerca e sviluppo sperimentale nel campo delle altre scienze naturali e dell'ingegneria",
                label: "72.19.09 - Ricerca e sviluppo sperimentale nel campo delle altre scienze naturali e dell'ingegneria ",
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'RICERCA SCIENTIFICA E SVILUPPO',
                lev3_name:
                    'RICERCA E SVILUPPO SPERIMENTALE NEL CAMPO DELLE SCIENZE SOCIALI E UMANISTICHE',
                lev4_name:
                    'Ricerca e sviluppo sperimentale nel campo delle scienze sociali e umanistiche',
                label: '72.20.00 - Ricerca e sviluppo sperimentale nel campo delle scienze sociali e umanistiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'PUBBLICITÀ E RICERCHE DI MERCATO',
                lev3_name: 'PUBBLICITÀ',
                lev4_name: 'Ideazione di campagne pubblicitarie',
                label: '73.11.01 - Ideazione di campagne pubblicitarie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'PUBBLICITÀ E RICERCHE DI MERCATO',
                lev3_name: 'PUBBLICITÀ',
                lev4_name:
                    'Conduzione di campagne di marketing e altri servizi pubblicitari',
                label: '73.11.02 - Conduzione di campagne di marketing e altri servizi pubblicitari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'PUBBLICITÀ E RICERCHE DI MERCATO',
                lev3_name: 'PUBBLICITÀ',
                lev4_name: 'Attività delle concessionarie pubblicitarie',
                label: '73.12.00 - Attività delle concessionarie pubblicitarie ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'PUBBLICITÀ E RICERCHE DI MERCATO',
                lev3_name: 'RICERCHE DI MERCATO E SONDAGGI DI OPINIONE',
                lev4_name: 'Ricerche di mercato e sondaggi di opinione',
                label: '73.20.00 - Ricerche di mercato e sondaggi di opinione ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ DI DESIGN SPECIALIZZATE',
                lev4_name: 'Attività di design di moda e design industriale',
                label: '74.10.10 - Attività di design di moda e design industriale ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ DI DESIGN SPECIALIZZATE',
                lev4_name: 'Attività dei disegnatori grafici di pagine web',
                label: '74.10.21 - Attività dei disegnatori grafici di pagine web ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ DI DESIGN SPECIALIZZATE',
                lev4_name: 'Altre attività dei disegnatori grafici',
                label: '74.10.29 - Altre attività dei disegnatori grafici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ DI DESIGN SPECIALIZZATE',
                lev4_name: 'Attività dei disegnatori tecnici',
                label: '74.10.30 - Attività dei disegnatori tecnici ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ DI DESIGN SPECIALIZZATE',
                lev4_name: 'Altre attività di design',
                label: '74.10.90 - Altre attività di design ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ FOTOGRAFICHE',
                lev4_name: 'Attività di fotoreporter',
                label: '74.20.11 - Attività di fotoreporter ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ FOTOGRAFICHE',
                lev4_name:
                    'Attività di riprese aeree nel campo della fotografia',
                label: '74.20.12 - Attività di riprese aeree nel campo della fotografia ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ FOTOGRAFICHE',
                lev4_name: 'Altre attività di riprese fotografiche',
                label: '74.20.19 - Altre attività di riprese fotografiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'ATTIVITÀ FOTOGRAFICHE',
                lev4_name: 'Laboratori fotografici per lo sviluppo e la stampa',
                label: '74.20.20 - Laboratori fotografici per lo sviluppo e la stampa ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name: 'TRADUZIONE E INTERPRETARIATO',
                lev4_name: 'Traduzione e interpretariato',
                label: '74.30.00 - Traduzione e interpretariato ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Consulenza agraria fornita da agronomi',
                label: '74.90.11 - Consulenza agraria fornita da agronomi ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Consulenza agraria fornita da agrotecnici e periti agrari',
                label: '74.90.12 - Consulenza agraria fornita da agrotecnici e periti agrari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Consulenza agraria fornita da periti agrari',
                label: '74.90.13 - Consulenza agraria fornita da periti agrari ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Consulenza agraria fornita da altri economisti specializzati in agricoltura',
                label: '74.90.14 - Consulenza agraria fornita da altri economisti specializzati in agricoltura ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Consulenza sulla sicurezza ed igiene dei posti di lavoro',
                label: '74.90.21 - Consulenza sulla sicurezza ed igiene dei posti di lavoro ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Altra attività di consulenza in materia di sicurezza',
                label: '74.90.29 - Altra attività di consulenza in materia di sicurezza ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Attività di consulenza in materia di prevenzione e riduzione dell’inquinamento di aria, acqua e suolo; consulenza in materia di gestione dei rifiuti',
                label: '74.90.31 - Attività di consulenza in materia di prevenzione e riduzione dell’inquinamento di aria, acqua e suolo; consulenza in materia di gestione dei rifiuti ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Attività di consulenza in materia di gestione delle risorse energetiche, energie rinnovabili e efficienza energetica',
                label: '74.90.32 - Attività di consulenza in materia di gestione delle risorse energetiche, energie rinnovabili e efficienza energetica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Attività di consulenza in materia di gestione delle risorse idriche, minerali e altre risorse naturali usate per finalità diverse da quella energetica',
                label: '74.90.33 - Attività di consulenza in materia di gestione delle risorse idriche, minerali e altre risorse naturali usate per finalità diverse da quella energetica ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Attività tecniche svolte da periti industriali',
                label: '74.90.91 - Attività tecniche svolte da periti industriali ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Attività riguardanti le previsioni meteorologiche',
                label: '74.90.92 - Attività riguardanti le previsioni meteorologiche ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Altre attività di consulenza tecnica nca',
                label: '74.90.93 - Altre attività di consulenza tecnica nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name:
                    'Agenzie ed agenti o procuratori per lo spettacolo e lo sport',
                label: '74.90.94 - Agenzie ed agenti o procuratori per lo spettacolo e lo sport ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev3_name:
                    'ALTRE ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE NCA',
                lev4_name: 'Altre attività professionali nca',
                label: '74.90.99 - Altre attività professionali nca ',
            },
            {
                checked: false,
                lev1_name: 'ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
                lev2_name: 'SERVIZI VETERINARI',
                lev3_name: 'SERVIZI VETERINARI',
                lev4_name: 'Servizi veterinari',
                label: '75.00.00 - Servizi veterinari ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Noleggio, agenzie di viaggio, servizi di supporto alle imprese',
        children: [
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI AUTOVEICOLI',
                lev4_name: 'Noleggio di autovetture ed autoveicoli leggeri',
                label: '77.11.00 - Noleggio di autovetture ed autoveicoli leggeri ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI AUTOVEICOLI',
                lev4_name: 'Noleggio di autocarri e di altri veicoli pesanti',
                label: '77.12.00 - Noleggio di autocarri e di altri veicoli pesanti ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Noleggio di biciclette',
                label: '77.21.01 - Noleggio di biciclette ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Noleggio senza equipaggio di imbarcazioni da diporto (inclusi i pedalò)',
                label: '77.21.02 - Noleggio senza equipaggio di imbarcazioni da diporto (inclusi i pedalò) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Noleggio di altre attrezzature sportive e ricreative',
                label: '77.21.09 - Noleggio di altre attrezzature sportive e ricreative ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Noleggio di videocassette, Cd, Dvd e dischi contenenti audiovisivi o videogame',
                label: '77.22.00 - Noleggio di videocassette, Cd, Dvd e dischi contenenti audiovisivi o videogame ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Noleggio di biancheria da tavola, da letto, da bagno e di articoli di vestiario',
                label: '77.29.10 - Noleggio di biancheria da tavola, da letto, da bagno e di articoli di vestiario ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name: 'NOLEGGIO DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Noleggio di altri beni per uso personale e domestico nca (escluse le attrezzature sportive e ricreative)',
                label: '77.29.90 - Noleggio di altri beni per uso personale e domestico nca (escluse le attrezzature sportive e ricreative) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name: 'Noleggio di macchine e attrezzature agricole',
                label: '77.31.00 - Noleggio di macchine e attrezzature agricole ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di macchine e attrezzature per lavori edili e di genio civile',
                label: '77.32.00 - Noleggio di macchine e attrezzature per lavori edili e di genio civile ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di macchine e attrezzature per ufficio (inclusi i computer)',
                label: '77.33.00 - Noleggio di macchine e attrezzature per ufficio (inclusi i computer) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di mezzi di trasporto marittimo e fluviale',
                label: '77.34.00 - Noleggio di mezzi di trasporto marittimo e fluviale ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name: 'Noleggio di mezzi di trasporto aereo',
                label: '77.35.00 - Noleggio di mezzi di trasporto aereo ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name: 'Noleggio di altri mezzi di trasporto terrestri',
                label: '77.39.10 - Noleggio di altri mezzi di trasporto terrestri ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di container adibiti ad alloggi o ad uffici',
                label: '77.39.91 - Noleggio di container adibiti ad alloggi o ad uffici ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di container per diverse modalità di trasporto',
                label: '77.39.92 - Noleggio di container per diverse modalità di trasporto ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio senza operatore di attrezzature di sollevamento e movimentazione merci: carrelli elevatori, pallet eccetera',
                label: '77.39.93 - Noleggio senza operatore di attrezzature di sollevamento e movimentazione merci: carrelli elevatori, pallet eccetera ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio di strutture ed attrezzature per manifestazioni e spettacoli: impianti luce ed audio senza operatore, palchi, stand ed addobbi luminosi',
                label: '77.39.94 - Noleggio di strutture ed attrezzature per manifestazioni e spettacoli: impianti luce ed audio senza operatore, palchi, stand ed addobbi luminosi ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'NOLEGGIO DI ALTRE MACCHINE, ATTREZZATURE E BENI MATERIALI',
                lev4_name:
                    'Noleggio senza operatore di altre macchine ed attrezzature nca',
                label: '77.39.99 - Noleggio senza operatore di altre macchine ed attrezzature nca ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI NOLEGGIO E LEASING OPERATIVO',
                lev3_name:
                    'CONCESSIONE DEI DIRITTI DI SFRUTTAMENTO DI PROPRIETÀ INTELLETTUALE E PRODOTTI SIMILI (ESCLUSE LE OPERE PROTETTE DAL COPYRIGHT)',
                lev4_name:
                    'Concessione dei diritti di sfruttamento di proprietà intellettuale e prodotti simili (escluse le opere protette dal copyright)',
                label: '77.40.00 - Concessione dei diritti di sfruttamento di proprietà intellettuale e prodotti simili (escluse le opere protette dal copyright) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DI RICERCA, SELEZIONE, FORNITURA DI PERSONALE',
                lev3_name: 'ATTIVITÀ DI AGENZIE DI COLLOCAMENTO',
                lev4_name:
                    'Servizi di ricerca, selezione, collocamento e supporto per il ricollocamento di personale',
                label: '78.10.00 - Servizi di ricerca, selezione, collocamento e supporto per il ricollocamento di personale ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DI RICERCA, SELEZIONE, FORNITURA DI PERSONALE',
                lev3_name:
                    'ATTIVITÀ DELLE AGENZIE DI LAVORO TEMPORANEO (INTERINALE)',
                lev4_name:
                    'Attività delle agenzie di fornitura di lavoro temporaneo (interinale)',
                label: '78.20.00 - Attività delle agenzie di fornitura di lavoro temporaneo (interinale) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DI RICERCA, SELEZIONE, FORNITURA DI PERSONALE',
                lev3_name:
                    'ALTRE ATTIVITÀ DI FORNITURA E GESTIONE DI RISORSE UMANE',
                lev4_name:
                    'Altre attività di fornitura e gestione di risorse umane (staff leasing)',
                label: '78.30.00 - Altre attività di fornitura e gestione di risorse umane (staff leasing) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DEI SERVIZI DELLE AGENZIE DI VIAGGIO, DEI TOUR OPERATOR E SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev3_name:
                    'ATTIVITÀ DELLE AGENZIE DI VIAGGIO E DEI TOUR OPERATOR',
                lev4_name: 'Attività delle agenzie di viaggio',
                label: '79.11.00 - Attività delle agenzie di viaggio ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DEI SERVIZI DELLE AGENZIE DI VIAGGIO, DEI TOUR OPERATOR E SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev3_name:
                    'ATTIVITÀ DELLE AGENZIE DI VIAGGIO E DEI TOUR OPERATOR',
                lev4_name: 'Attività dei tour operator',
                label: '79.12.00 - Attività dei tour operator ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DEI SERVIZI DELLE AGENZIE DI VIAGGIO, DEI TOUR OPERATOR E SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev3_name: 'ALTRI SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev4_name:
                    "Servizi di biglietteria per eventi teatrali, sportivi ed altri eventi ricreativi e d'intrattenimento",
                label: "79.90.11 - Servizi di biglietteria per eventi teatrali, sportivi ed altri eventi ricreativi e d'intrattenimento ",
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DEI SERVIZI DELLE AGENZIE DI VIAGGIO, DEI TOUR OPERATOR E SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev3_name: 'ALTRI SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev4_name:
                    'Altri servizi di prenotazione e altre attività di assistenza turistica non svolte dalle agenzie di viaggio nca',
                label: '79.90.19 - Altri servizi di prenotazione e altre attività di assistenza turistica non svolte dalle agenzie di viaggio nca ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    'ATTIVITÀ DEI SERVIZI DELLE AGENZIE DI VIAGGIO, DEI TOUR OPERATOR E SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev3_name: 'ALTRI SERVIZI DI PRENOTAZIONE E ATTIVITÀ CONNESSE',
                lev4_name:
                    'Attività delle guide e degli accompagnatori turistici',
                label: '79.90.20 - Attività delle guide e degli accompagnatori turistici ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'SERVIZI DI VIGILANZA E INVESTIGAZIONE',
                lev3_name: 'SERVIZI DI VIGILANZA PRIVATA',
                lev4_name: 'Servizi di vigilanza privata',
                label: '80.10.00 - Servizi di vigilanza privata ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'SERVIZI DI VIGILANZA E INVESTIGAZIONE',
                lev3_name: 'SERVIZI CONNESSI AI SISTEMI DI VIGILANZA',
                lev4_name: 'Servizi connessi ai sistemi di vigilanza',
                label: '80.20.00 - Servizi connessi ai sistemi di vigilanza ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'SERVIZI DI VIGILANZA E INVESTIGAZIONE',
                lev3_name: 'SERVIZI INVESTIGATIVI PRIVATI',
                lev4_name: 'Servizi di investigazione privata',
                label: '80.30.00 - Servizi di investigazione privata ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'SERVIZI INTEGRATI DI GESTIONE AGLI EDIFICI',
                lev4_name: 'Servizi integrati di gestione agli edifici',
                label: '81.10.00 - Servizi integrati di gestione agli edifici ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name: 'Pulizia generale (non specializzata) di edifici',
                label: '81.21.00 - Pulizia generale (non specializzata) di edifici ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name:
                    'Attività di sterilizzazione di attrezzature medico sanitarie',
                label: '81.22.01 - Attività di sterilizzazione di attrezzature medico sanitarie ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name:
                    'Altre attività di pulizia specializzata di edifici e di impianti e macchinari industriali',
                label: '81.22.02 - Altre attività di pulizia specializzata di edifici e di impianti e macchinari industriali ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name: 'Servizi di disinfestazione',
                label: '81.29.10 - Servizi di disinfestazione ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name:
                    'Pulizia e lavaggio di aree pubbliche, rimozione di neve e ghiaccio',
                label: '81.29.91 - Pulizia e lavaggio di aree pubbliche, rimozione di neve e ghiaccio ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'ATTIVITÀ DI PULIZIA E DISINFESTAZIONE',
                lev4_name: 'Altre attività di pulizia nca',
                label: '81.29.99 - Altre attività di pulizia nca ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name: 'ATTIVITÀ DI SERVIZI PER EDIFICI E PAESAGGIO',
                lev3_name: 'CURA E MANUTENZIONE DEL PAESAGGIO',
                lev4_name:
                    'Cura e manutenzione del paesaggio (inclusi parchi, giardini e aiuole)',
                label: '81.30.00 - Cura e manutenzione del paesaggio (inclusi parchi, giardini e aiuole) ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO",
                lev4_name:
                    "Servizi integrati di supporto per le funzioni d'ufficio",
                label: "82.11.01 - Servizi integrati di supporto per le funzioni d'ufficio ",
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO",
                lev4_name: 'Gestione di uffici temporanei, uffici residence',
                label: '82.11.02 - Gestione di uffici temporanei, uffici residence ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO",
                lev4_name:
                    'Spedizione di materiale propagandistico, compilazione e gestione di indirizzi',
                label: '82.19.01 - Spedizione di materiale propagandistico, compilazione e gestione di indirizzi ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO",
                lev4_name:
                    "Servizi di fotocopiatura, preparazione di documenti e altre attività di supporto specializzate per le funzioni d'ufficio",
                label: "82.19.09 - Servizi di fotocopiatura, preparazione di documenti e altre attività di supporto specializzate per le funzioni d'ufficio ",
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'ATTIVITÀ DEI CALL CENTER',
                lev4_name: 'Attività dei call center',
                label: '82.20.00 - Attività dei call center ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'ORGANIZZAZIONE DI CONVEGNI E FIERE',
                lev4_name: 'Organizzazione di convegni e fiere',
                label: '82.30.00 - Organizzazione di convegni e fiere ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Attività di agenzie di recupero crediti',
                label: '82.91.10 - Attività di agenzie di recupero crediti ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Agenzie di informazioni commerciali',
                label: '82.91.20 - Agenzie di informazioni commerciali ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Imballaggio e confezionamento di generi alimentari',
                label: '82.92.10 - Imballaggio e confezionamento di generi alimentari ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Confezionamento di generi non alimentari',
                label: '82.92.20 - Confezionamento di generi non alimentari ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Imprese di gestione esattoriale',
                label: '82.99.10 - Imprese di gestione esattoriale ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name:
                    'Agenzie di distribuzione di libri, giornali e riviste',
                label: '82.99.20 - Agenzie di distribuzione di libri, giornali e riviste ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name:
                    'Servizi di gestione di pubblici mercati e pese pubbliche',
                label: '82.99.30 - Servizi di gestione di pubblici mercati e pese pubbliche ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Richiesta certificati e disbrigo pratiche',
                label: '82.99.40 - Richiesta certificati e disbrigo pratiche ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Servizi di stenotipia',
                label: '82.99.91 - Servizi di stenotipia ',
            },
            {
                checked: false,
                lev1_name:
                    'NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
                lev2_name:
                    "ATTIVITÀ DI SUPPORTO PER LE FUNZIONI D'UFFICIO E ALTRI SERVIZI DI SUPPORTO ALLE IMPRESE",
                lev3_name: 'SERVIZI DI SUPPORTO ALLE IMPRESE NCA',
                lev4_name: 'Altri servizi di supporto alle imprese nca',
                label: '82.99.99 - Altri servizi di supporto alle imprese nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Amministrazione pubblica e difesa; assicurazione sociale obbligatoria',
        children: [
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Attività degli organi legislativi ed esecutivi, centrali e locali; amministrazione finanziaria; amministrazioni regionali, provinciali e comunali',
                label: '84.11.10 - Attività degli organi legislativi ed esecutivi, centrali e locali; amministrazione finanziaria; amministrazioni regionali, provinciali e comunali ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Attività di pianificazione generale e servizi statistici generali',
                label: '84.11.20 - Attività di pianificazione generale e servizi statistici generali ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione dell'attività degli organismi preposti alla sanità",
                label: "84.12.10 - Regolamentazione dell'attività degli organismi preposti alla sanità ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione dell'attività degli organismi preposti all'istruzione",
                label: "84.12.20 - Regolamentazione dell'attività degli organismi preposti all'istruzione ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione dell'attività degli organismi preposti alla gestione di progetti per l'edilizia abitativa e l'assetto del territorio e per la tutela dell'ambiente",
                label: "84.12.30 - Regolamentazione dell'attività degli organismi preposti alla gestione di progetti per l'edilizia abitativa e l'assetto del territorio e per la tutela dell'ambiente ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione dell'attività degli organismi preposti ai servizi ricreativi, culturali e sociali vari",
                label: "84.12.40 - Regolamentazione dell'attività degli organismi preposti ai servizi ricreativi, culturali e sociali vari ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione degli affari concernenti i combustibili e l'energia",
                label: "84.13.10 - Regolamentazione degli affari concernenti i combustibili e l'energia ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    "Regolamentazione degli affari e servizi concernenti l'agricoltura, silvicoltura, caccia e pesca",
                label: "84.13.20 - Regolamentazione degli affari e servizi concernenti l'agricoltura, silvicoltura, caccia e pesca ",
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e dei servizi concernenti le industrie estrattive e le risorse minerarie (eccetto i combustibili) le industrie manifatturiere, le costruzioni e le opere pubbliche ad eccezione delle strade e opere per la navigazione',
                label: '84.13.30 - Regolamentazione degli affari e dei servizi concernenti le industrie estrattive e le risorse minerarie (eccetto i combustibili) le industrie manifatturiere, le costruzioni e le opere pubbliche ad eccezione delle strade e opere per la navigazione ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e servizi concernenti la costruzione di strade',
                label: '84.13.40 - Regolamentazione degli affari e servizi concernenti la costruzione di strade ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e servizi concernenti la costruzione di opere per la navigazione interna e marittima',
                label: '84.13.50 - Regolamentazione degli affari e servizi concernenti la costruzione di opere per la navigazione interna e marittima ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e servizi concernenti i trasporti e le comunicazioni',
                label: '84.13.60 - Regolamentazione degli affari e servizi concernenti i trasporti e le comunicazioni ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e servizi concernenti il commercio interno',
                label: '84.13.70 - Regolamentazione degli affari e servizi concernenti il commercio interno ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione degli affari e servizi concernenti il turismo',
                label: '84.13.80 - Regolamentazione degli affari e servizi concernenti il turismo ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name:
                    'AMMINISTRAZIONE PUBBLICA: AMMINISTRAZIONE GENERALE, ECONOMICA E SOCIALE',
                lev4_name:
                    'Regolamentazione di altri affari e servizi economici',
                label: '84.13.90 - Regolamentazione di altri affari e servizi economici ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Affari esteri',
                label: '84.21.00 - Affari esteri ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Difesa nazionale',
                label: '84.22.00 - Difesa nazionale ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Giustizia ed attività giudiziarie',
                label: '84.23.00 - Giustizia ed attività giudiziarie ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Ordine pubblico e sicurezza nazionale',
                label: '84.24.00 - Ordine pubblico e sicurezza nazionale ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Attività dei vigili del fuoco',
                label: '84.25.10 - Attività dei vigili del fuoco ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'SERVIZI COLLETTIVI DELLE AMMINISTRAZIONI PUBBLICHE',
                lev4_name: 'Attività di protezione civile',
                label: '84.25.20 - Attività di protezione civile ',
            },
            {
                checked: false,
                lev1_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev2_name:
                    'AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev3_name: 'ASSICURAZIONE SOCIALE OBBLIGATORIA',
                lev4_name: 'Assicurazione sociale obbligatoria',
                label: '84.30.00 - Assicurazione sociale obbligatoria ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ISTRUZIONE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Istruzione',
        children: [
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE PRESCOLASTICA',
                lev4_name:
                    "Istruzione di grado preparatorio: scuole dell'infanzia, scuole speciali collegate a quelle primarie",
                label: "85.10.00 - Istruzione di grado preparatorio: scuole dell'infanzia, scuole speciali collegate a quelle primarie ",
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE PRIMARIA',
                lev4_name: 'Istruzione primaria: scuole elementari',
                label: '85.20.00 - Istruzione primaria: scuole elementari ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name: 'Istruzione secondaria di primo grado: scuole medie',
                label: '85.31.10 - Istruzione secondaria di primo grado: scuole medie ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name:
                    'Istruzione secondaria di secondo grado di formazione generale: licei',
                label: '85.31.20 - Istruzione secondaria di secondo grado di formazione generale: licei ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name:
                    'Scuole di vela e navigazione che rilasciano brevetti o patenti commerciali',
                label: '85.32.01 - Scuole di vela e navigazione che rilasciano brevetti o patenti commerciali ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name:
                    'Scuole di volo che rilasciano brevetti o patenti commerciali',
                label: '85.32.02 - Scuole di volo che rilasciano brevetti o patenti commerciali ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name:
                    'Scuole di guida professionale per autisti, ad esempio di autocarri, di autobus e di pullman',
                label: '85.32.03 - Scuole di guida professionale per autisti, ad esempio di autocarri, di autobus e di pullman ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ISTRUZIONE SECONDARIA',
                lev4_name:
                    'Altra istruzione secondaria di secondo grado di formazione tecnica, professionale e artistica',
                label: '85.32.09 - Altra istruzione secondaria di secondo grado di formazione tecnica, professionale e artistica ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name:
                    'ISTRUZIONE POST-SECONDARIA UNIVERSITARIA E NON UNIVERSITARIA',
                lev4_name: 'Istruzione e formazione tecnica superiore (IFTS)',
                label: '85.41.00 - Istruzione e formazione tecnica superiore (IFTS) ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name:
                    'ISTRUZIONE POST-SECONDARIA UNIVERSITARIA E NON UNIVERSITARIA',
                lev4_name:
                    'Istruzione universitaria e post-universitaria; accademie e conservatori',
                label: '85.42.00 - Istruzione universitaria e post-universitaria; accademie e conservatori ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Corsi sportivi e ricreativi',
                label: '85.51.00 - Corsi sportivi e ricreativi ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Corsi di danza',
                label: '85.52.01 - Corsi di danza ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Altra formazione culturale',
                label: '85.52.09 - Altra formazione culturale ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Autoscuole, scuole di pilotaggio e nautiche',
                label: '85.53.00 - Autoscuole, scuole di pilotaggio e nautiche ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Università popolare',
                label: '85.59.10 - Università popolare ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name:
                    'Corsi di formazione e corsi di aggiornamento professionale',
                label: '85.59.20 - Corsi di formazione e corsi di aggiornamento professionale ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Scuole e corsi di lingua',
                label: '85.59.30 - Scuole e corsi di lingua ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: 'ALTRI SERVIZI DI ISTRUZIONE',
                lev4_name: 'Altri servizi di istruzione nca',
                label: '85.59.90 - Altri servizi di istruzione nca ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: "ATTIVITÀ DI SUPPORTO ALL'ISTRUZIONE",
                lev4_name:
                    'Consulenza scolastica e servizi di orientamento scolastico',
                label: '85.60.01 - Consulenza scolastica e servizi di orientamento scolastico ',
            },
            {
                checked: false,
                lev1_name: 'ISTRUZIONE',
                lev2_name: 'ISTRUZIONE',
                lev3_name: "ATTIVITÀ DI SUPPORTO ALL'ISTRUZIONE",
                lev4_name: "Altre attività di supporto all'istruzione",
                label: "85.60.09 - Altre attività di supporto all'istruzione ",
            },
        ],
    },
    {
        checked: false,
        lev1_name: "SANITA' E ASSISTENZA SOCIALE",
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Sanità e assistenza sociale',
        children: [
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI OSPEDALIERI',
                lev4_name: 'Ospedali e case di cura generici',
                label: '86.10.10 - Ospedali e case di cura generici ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI OSPEDALIERI',
                lev4_name: 'Ospedali e case di cura specialistici',
                label: '86.10.20 - Ospedali e case di cura specialistici ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI OSPEDALIERI',
                lev4_name: 'Istituti, cliniche e policlinici universitari',
                label: '86.10.30 - Istituti, cliniche e policlinici universitari ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI OSPEDALIERI',
                lev4_name: 'Ospedali e case di cura per lunga degenza',
                label: '86.10.40 - Ospedali e case di cura per lunga degenza ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Servizi degli studi medici di medicina generale',
                label: '86.21.00 - Servizi degli studi medici di medicina generale ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Prestazioni sanitarie svolte da chirurghi',
                label: '86.22.01 - Prestazioni sanitarie svolte da chirurghi ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name:
                    'Ambulatori e poliambulatori del Servizio Sanitario Nazionale',
                label: '86.22.02 - Ambulatori e poliambulatori del Servizio Sanitario Nazionale ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Attività dei centri di radioterapia',
                label: '86.22.03 - Attività dei centri di radioterapia ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Attività dei centri di dialisi',
                label: '86.22.04 - Attività dei centri di dialisi ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Studi di omeopatia e di agopuntura',
                label: '86.22.05 - Studi di omeopatia e di agopuntura ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Centri di medicina estetica',
                label: '86.22.06 - Centri di medicina estetica ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Altri studi medici specialistici e poliambulatori',
                label: '86.22.09 - Altri studi medici specialistici e poliambulatori ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'SERVIZI DEGLI STUDI MEDICI E ODONTOIATRICI',
                lev4_name: 'Attività degli studi odontoiatrici',
                label: '86.23.00 - Attività degli studi odontoiatrici ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Laboratori radiografici',
                label: '86.90.11 - Laboratori radiografici ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Laboratori di analisi cliniche',
                label: '86.90.12 - Laboratori di analisi cliniche ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Laboratori di igiene e profilassi',
                label: '86.90.13 - Laboratori di igiene e profilassi ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Fisioterapia',
                label: '86.90.21 - Fisioterapia ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Altre attività paramediche indipendenti nca',
                label: '86.90.29 - Altre attività paramediche indipendenti nca ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Attività svolta da psicologi',
                label: '86.90.30 - Attività svolta da psicologi ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name: 'Attività degli ambulatori tricologici',
                label: '86.90.41 - Attività degli ambulatori tricologici ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SANITARIA',
                lev3_name: 'ALTRI SERVIZI DI ASSISTENZA SANITARIA',
                lev4_name:
                    'Servizi di ambulanza, delle banche del sangue e altri servizi sanitari nca',
                label: '86.90.42 - Servizi di ambulanza, delle banche del sangue e altri servizi sanitari nca ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'SERVIZI DI ASSISTENZA SOCIALE RESIDENZIALE',
                lev3_name:
                    'STRUTTURE DI ASSISTENZA INFERMIERISTICA RESIDENZIALE',
                lev4_name:
                    'Strutture di assistenza infermieristica residenziale per anziani',
                label: '87.10.00 - Strutture di assistenza infermieristica residenziale per anziani ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'SERVIZI DI ASSISTENZA SOCIALE RESIDENZIALE',
                lev3_name:
                    'STRUTTURE DI ASSISTENZA RESIDENZIALE PER PERSONE AFFETTE DA RITARDI MENTALI, DISTURBI MENTALI O CHE ABUSANO DI SOSTANZE STUPEFACENTI',
                lev4_name:
                    'Strutture di assistenza residenziale per persone affette da ritardi mentali, disturbi mentali o che abusano di sostanze stupefacenti',
                label: '87.20.00 - Strutture di assistenza residenziale per persone affette da ritardi mentali, disturbi mentali o che abusano di sostanze stupefacenti ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'SERVIZI DI ASSISTENZA SOCIALE RESIDENZIALE',
                lev3_name:
                    'STRUTTURE DI ASSISTENZA RESIDENZIALE PER ANZIANI E DISABILI',
                lev4_name:
                    'Strutture di assistenza residenziale per anziani e disabili',
                label: '87.30.00 - Strutture di assistenza residenziale per anziani e disabili ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'SERVIZI DI ASSISTENZA SOCIALE RESIDENZIALE',
                lev3_name: 'ALTRE STRUTTURE DI ASSISTENZA SOCIALE RESIDENZIALE',
                lev4_name: 'Altre strutture di assistenza sociale residenziale',
                label: '87.90.00 - Altre strutture di assistenza sociale residenziale ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SOCIALE NON RESIDENZIALE',
                lev3_name:
                    'ASSISTENZA SOCIALE NON RESIDENZIALE PER ANZIANI E DISABILI',
                lev4_name:
                    'Assistenza sociale non residenziale per anziani e disabili',
                label: '88.10.00 - Assistenza sociale non residenziale per anziani e disabili ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SOCIALE NON RESIDENZIALE',
                lev3_name:
                    'ALTRE ATTIVITÀ DI ASSISTENZA SOCIALE NON RESIDENZIALE',
                lev4_name:
                    'Servizi di asili nido e assistenza diurna per minori disabili',
                label: '88.91.00 - Servizi di asili nido e assistenza diurna per minori disabili ',
            },
            {
                checked: false,
                lev1_name: "SANITA' E ASSISTENZA SOCIALE",
                lev2_name: 'ASSISTENZA SOCIALE NON RESIDENZIALE',
                lev3_name:
                    'ALTRE ATTIVITÀ DI ASSISTENZA SOCIALE NON RESIDENZIALE',
                lev4_name:
                    'Altre attività di assistenza sociale non residenziale nca',
                label: '88.99.00 - Altre attività di assistenza sociale non residenziale nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività artistiche, sportive, di intrattenimento e divertimento',
        children: [
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name: 'Attività nel campo della recitazione',
                label: '90.01.01 - Attività nel campo della recitazione ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name: 'Altre rappresentazioni artistiche',
                label: '90.01.09 - Altre rappresentazioni artistiche ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name:
                    'Noleggio con operatore di strutture ed attrezzature per manifestazioni e spettacoli',
                label: '90.02.01 - Noleggio con operatore di strutture ed attrezzature per manifestazioni e spettacoli ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name: 'Attività nel campo della regia',
                label: '90.02.02 - Attività nel campo della regia ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name:
                    'Altre attività di supporto alle rappresentazioni artistiche',
                label: '90.02.09 - Altre attività di supporto alle rappresentazioni artistiche ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name: 'Attività dei giornalisti indipendenti',
                label: '90.03.01 - Attività dei giornalisti indipendenti ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name:
                    "Attività di conservazione e restauro di opere d'arte",
                label: "90.03.02 - Attività di conservazione e restauro di opere d'arte ",
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name: 'Altre creazioni artistiche e letterarie',
                label: '90.03.09 - Altre creazioni artistiche e letterarie ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev3_name: 'ATTIVITÀ CREATIVE, ARTISTICHE E DI INTRATTENIMENTO',
                lev4_name:
                    'Gestione di teatri, sale da concerto e altre strutture artistiche',
                label: '90.04.00 - Gestione di teatri, sale da concerto e altre strutture artistiche ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev3_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev4_name: 'Attività di biblioteche ed archivi',
                label: '91.01.00 - Attività di biblioteche ed archivi ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev3_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev4_name: 'Attività di musei',
                label: '91.02.00 - Attività di musei ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev3_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev4_name:
                    'Gestione di luoghi e monumenti storici e attrazioni simili',
                label: '91.03.00 - Gestione di luoghi e monumenti storici e attrazioni simili ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev3_name:
                    'ATTIVITÀ DI BIBLIOTECHE, ARCHIVI, MUSEI ED ALTRE ATTIVITÀ CULTURALI',
                lev4_name:
                    'Attività degli orti botanici, dei giardini zoologici e delle riserve naturali',
                label: '91.04.00 - Attività degli orti botanici, dei giardini zoologici e delle riserve naturali ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev3_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev4_name:
                    'Ricevitorie del Lotto, SuperEnalotto, Totocalcio eccetera',
                label: '92.00.01 - Ricevitorie del Lotto, SuperEnalotto, Totocalcio eccetera ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev3_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev4_name:
                    'Gestione di apparecchi che consentono vincite in denaro funzionanti a moneta o a gettone',
                label: '92.00.02 - Gestione di apparecchi che consentono vincite in denaro funzionanti a moneta o a gettone ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev3_name:
                    'ATTIVITÀ RIGUARDANTI LE LOTTERIE, LE SCOMMESSE, LE CASE DA GIOCO',
                lev4_name:
                    'Altre attività connesse con le lotterie e le scommesse',
                label: '92.00.09 - Altre attività connesse con le lotterie e le scommesse ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Gestione di stadi',
                label: '93.11.10 - Gestione di stadi ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Gestione di piscine',
                label: '93.11.20 - Gestione di piscine ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Gestione di impianti sportivi polivalenti',
                label: '93.11.30 - Gestione di impianti sportivi polivalenti ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Gestione di altri impianti sportivi nca',
                label: '93.11.90 - Gestione di altri impianti sportivi nca ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Attività di club sportivi',
                label: '93.12.00 - Attività di club sportivi ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Gestione di palestre',
                label: '93.13.00 - Gestione di palestre ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name:
                    'Enti e organizzazioni sportive, promozione di eventi sportivi',
                label: '93.19.10 - Enti e organizzazioni sportive, promozione di eventi sportivi ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Ricarica di bombole per attività subacquee',
                label: '93.19.91 - Ricarica di bombole per attività subacquee ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Attività delle guide alpine',
                label: '93.19.92 - Attività delle guide alpine ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ SPORTIVE',
                lev4_name: 'Altre attività sportive nca',
                label: '93.19.99 - Altre attività sportive nca ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name: 'Parchi di divertimento e parchi tematici',
                label: '93.21.00 - Parchi di divertimento e parchi tematici ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name:
                    'Gestione di parchi di divertimento, tematici e acquatici, nei quali sono in genere previsti spettacoli, esibizioni e servizi',
                label: '93.21.01 - Gestione di parchi di divertimento, tematici e acquatici, nei quali sono in genere previsti spettacoli, esibizioni e servizi ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name:
                    'Gestione di attrazioni e attività di spettacolo in forma itinerante (giostre) o di attività dello spettacolo viaggiante svolte con attrezzature smontabili, in spazi pubblici e privati',
                label: '93.21.02 - Gestione di attrazioni e attività di spettacolo in forma itinerante (giostre) o di attività dello spettacolo viaggiante svolte con attrezzature smontabili, in spazi pubblici e privati ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name: 'Discoteche, sale da ballo night-club e simili',
                label: '93.29.10 - Discoteche, sale da ballo night-club e simili ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name:
                    'Gestione di stabilimenti balneari: marittimi, lacuali e fluviali',
                label: '93.29.20 - Gestione di stabilimenti balneari: marittimi, lacuali e fluviali ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name:
                    'Gestione di apparecchi che non consentono vincite in denaro funzionanti a moneta o a gettone',
                label: '93.29.30 - Gestione di apparecchi che non consentono vincite in denaro funzionanti a moneta o a gettone ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
                lev2_name:
                    'ATTIVITÀ SPORTIVE, DI INTRATTENIMENTO E DI DIVERTIMENTO',
                lev3_name: 'ATTIVITÀ RICREATIVE E DI DIVERTIMENTO',
                lev4_name:
                    'Altre attività di intrattenimento e di divertimento nca',
                label: '93.29.90 - Altre attività di intrattenimento e di divertimento nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Altre attività di servizi',
        children: [
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name:
                    'ATTIVITÀ DI ORGANIZZAZIONI ECONOMICHE, DI DATORI DI LAVORO E PROFESSIONALI',
                lev4_name:
                    'Attività di organizzazione di datori di lavoro, federazioni di industria, commercio, artigianato e servizi, associazioni, unioni, federazioni fra istituzioni',
                label: '94.11.00 - Attività di organizzazione di datori di lavoro, federazioni di industria, commercio, artigianato e servizi, associazioni, unioni, federazioni fra istituzioni ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name:
                    'ATTIVITÀ DI ORGANIZZAZIONI ECONOMICHE, DI DATORI DI LAVORO E PROFESSIONALI',
                lev4_name:
                    'Attività di federazioni e consigli di ordini e collegi professionali',
                label: '94.12.10 - Attività di federazioni e consigli di ordini e collegi professionali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name:
                    'ATTIVITÀ DI ORGANIZZAZIONI ECONOMICHE, DI DATORI DI LAVORO E PROFESSIONALI',
                lev4_name: 'Attività di associazioni professionali',
                label: '94.12.20 - Attività di associazioni professionali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DEI SINDACATI DI LAVORATORI DIPENDENTI',
                lev4_name: 'Attività dei sindacati di lavoratori dipendenti',
                label: '94.20.00 - Attività dei sindacati di lavoratori dipendenti ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    "Attività delle organizzazioni religiose nell'esercizio del culto",
                label: "94.91.00 - Attività delle organizzazioni religiose nell'esercizio del culto ",
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    'Attività dei partiti e delle associazioni politiche',
                label: '94.92.00 - Attività dei partiti e delle associazioni politiche ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    'Attività di organizzazioni per la tutela degli interessi e dei diritti dei cittadini',
                label: '94.99.10 - Attività di organizzazioni per la tutela degli interessi e dei diritti dei cittadini ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    'Attività di organizzazioni che perseguono fini culturali, ricreativi e la coltivazione di hobby',
                label: '94.99.20 - Attività di organizzazioni che perseguono fini culturali, ricreativi e la coltivazione di hobby ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    'Attività di organizzazioni patriottiche e associazioni combattentistiche',
                label: '94.99.30 - Attività di organizzazioni patriottiche e associazioni combattentistiche ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    'Attività di organizzazioni per la cooperazione e la solidarietà internazionale',
                label: '94.99.40 - Attività di organizzazioni per la cooperazione e la solidarietà internazionale ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name: 'Attività di organizzazioni per la filantropia',
                label: '94.99.50 - Attività di organizzazioni per la filantropia ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name:
                    "Attività di organizzazioni per la promozione e la difesa degli animali e dell'ambiente",
                label: "94.99.60 - Attività di organizzazioni per la promozione e la difesa degli animali e dell'ambiente ",
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ATTIVITÀ DI ORGANIZZAZIONI ASSOCIATIVE',
                lev3_name: 'ATTIVITÀ DI ALTRE ORGANIZZAZIONI ASSOCIATIVE',
                lev4_name: 'Attività di altre organizzazioni associative nca',
                label: '94.99.90 - Attività di altre organizzazioni associative nca ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI COMPUTER E DI APPARECCHIATURE PER LE COMUNICAZIONI',
                lev4_name:
                    'Riparazione e manutenzione di computer e periferiche',
                label: '95.11.00 - Riparazione e manutenzione di computer e periferiche ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI COMPUTER E DI APPARECCHIATURE PER LE COMUNICAZIONI',
                lev4_name:
                    'Riparazione e manutenzione di telefoni fissi, cordless e cellulari',
                label: '95.12.01 - Riparazione e manutenzione di telefoni fissi, cordless e cellulari ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI COMPUTER E DI APPARECCHIATURE PER LE COMUNICAZIONI',
                lev4_name:
                    'Riparazione e manutenzione di altre apparecchiature per le comunicazioni',
                label: '95.12.09 - Riparazione e manutenzione di altre apparecchiature per le comunicazioni ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Riparazione di prodotti elettronici di consumo audio e video',
                label: '95.21.00 - Riparazione di prodotti elettronici di consumo audio e video ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Riparazione di elettrodomestici e di articoli per la casa',
                label: '95.22.01 - Riparazione di elettrodomestici e di articoli per la casa ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Riparazione di articoli per il giardinaggio',
                label: '95.22.02 - Riparazione di articoli per il giardinaggio ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Riparazione di calzature e articoli da viaggio in pelle, cuoio o in altri materiali simili',
                label: '95.23.00 - Riparazione di calzature e articoli da viaggio in pelle, cuoio o in altri materiali simili ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Riparazione di mobili e di oggetti di arredamento',
                label: '95.24.01 - Riparazione di mobili e di oggetti di arredamento ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Laboratori di tappezzeria',
                label: '95.24.02 - Laboratori di tappezzeria ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Riparazione di orologi e di gioielli',
                label: '95.25.00 - Riparazione di orologi e di gioielli ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name: 'Riparazione di strumenti musicali',
                label: '95.29.01 - Riparazione di strumenti musicali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Riparazione di articoli sportivi (escluse le armi sportive) e attrezzature da campeggio (incluse le biciclette)',
                label: '95.29.02 - Riparazione di articoli sportivi (escluse le armi sportive) e attrezzature da campeggio (incluse le biciclette) ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Modifica e riparazione di articoli di vestiario non effettuate dalle sartorie',
                label: '95.29.03 - Modifica e riparazione di articoli di vestiario non effettuate dalle sartorie ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Servizi di riparazioni rapide, duplicazione chiavi, affilatura coltelli, stampa immediata su articoli tessili, incisioni rapide su metallo non prezioso',
                label: '95.29.04 - Servizi di riparazioni rapide, duplicazione chiavi, affilatura coltelli, stampa immediata su articoli tessili, incisioni rapide su metallo non prezioso ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name:
                    'RIPARAZIONE DI COMPUTER E DI BENI PER USO PERSONALE E PER LA CASA',
                lev3_name:
                    'RIPARAZIONE DI BENI PER USO PERSONALE E PER LA CASA',
                lev4_name:
                    'Riparazione di altri beni di consumo per uso personale e per la casa nca',
                label: '95.29.09 - Riparazione di altri beni di consumo per uso personale e per la casa nca ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Attività delle lavanderie industriali',
                label: '96.01.10 - Attività delle lavanderie industriali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Attività di lavanderie, tintorie tradizionali',
                label: '96.01.20 - Attività di lavanderie, tintorie tradizionali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Attività di lavanderie self-service',
                label: '96.01.30 - Attività di lavanderie self-service ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Servizi dei saloni di barbiere e parrucchiere',
                label: '96.02.01 - Servizi dei saloni di barbiere e parrucchiere ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Servizi degli istituti di bellezza',
                label: '96.02.02 - Servizi degli istituti di bellezza ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Servizi di manicure e pedicure',
                label: '96.02.03 - Servizi di manicure e pedicure ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Servizi di pompe funebri e attività connesse',
                label: '96.03.00 - Servizi di pompe funebri e attività connesse ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name:
                    'Servizi di centri per il benessere fisico (esclusi gli stabilimenti termali)',
                label: '96.04.10 - Servizi di centri per il benessere fisico (esclusi gli stabilimenti termali) ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Stabilimenti termali',
                label: '96.04.20 - Stabilimenti termali ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Attività di sgombero di cantine, solai e garage',
                label: '96.09.01 - Attività di sgombero di cantine, solai e garage ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Attività di tatuaggio e piercing',
                label: '96.09.02 - Attività di tatuaggio e piercing ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: "Agenzie matrimoniali e d'incontro",
                label: "96.09.03 - Agenzie matrimoniali e d'incontro ",
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name:
                    'Servizi di cura degli animali da compagnia (esclusi i servizi veterinari)',
                label: '96.09.04 - Servizi di cura degli animali da compagnia (esclusi i servizi veterinari) ',
            },
            {
                checked: false,
                lev1_name: 'ALTRE ATTIVITÀ DI SERVIZI',
                lev2_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev3_name: 'ALTRE ATTIVITÀ DI SERVIZI PER LA PERSONA',
                lev4_name: 'Altre attività di servizi per la persona nca',
                label: '96.09.09 - Altre attività di servizi per la persona nca ',
            },
        ],
    },
    {
        checked: false,
        lev1_name:
            'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Attività di famiglie e convivenze come datori di lavoro per personale domestico; produzione di beni e servizi indifferenziati per uso proprio da parte di famiglie e convivenze',
        children: [
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev2_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev3_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev4_name:
                    'Attività di famiglie e convivenze come datori di lavoro per personale domestico',
                label: '97.00.00 - Attività di famiglie e convivenze come datori di lavoro per personale domestico ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev2_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev3_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev4_name:
                    'Attività di famiglie e convivenze come datori di lavoro per personale domestico (esclusi i condomini)',
                label: '97.00.01 - Attività di famiglie e convivenze come datori di lavoro per personale domestico (esclusi i condomini) ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev2_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev3_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO',
                lev4_name: 'Attività di condomini',
                label: '97.00.02 - Attività di condomini ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev2_name:
                    'PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI  PER USO PROPRIO DA PARTE DI  FAMIGLIE E CONVIVENZE',
                lev3_name:
                    'PRODUZIONE  DI BENI INDIFFERENZIATI PER USO PROPRIO DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev4_name:
                    'Produzione di beni indifferenziati per uso proprio da parte di famiglie e convivenze',
                label: '98.10.00 - Produzione di beni indifferenziati per uso proprio da parte di famiglie e convivenze ',
            },
            {
                checked: false,
                lev1_name:
                    'ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO  DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev2_name:
                    'PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI  PER USO PROPRIO DA PARTE DI  FAMIGLIE E CONVIVENZE',
                lev3_name:
                    'PRODUZIONE DI SERVIZI INDIFFERENZIATI PER USO PROPRIO DA PARTE DI FAMIGLIE E CONVIVENZE',
                lev4_name:
                    'Produzione di servizi indifferenziati per uso proprio da parte di famiglie e convivenze',
                label: '98.20.00 - Produzione di servizi indifferenziati per uso proprio da parte di famiglie e convivenze ',
            },
        ],
    },
    {
        checked: false,
        lev1_name: 'ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI',
        lev2_name: '',
        lev3_name: '',
        lev4_name: '',
        label: 'Organizzazioni ed organismi extraterritoriali',
        children: [
            {
                checked: false,
                lev1_name: 'ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI',
                lev2_name: 'ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI',
                lev3_name: 'ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI',
                lev4_name: 'Organizzazioni ed organismi extraterritoriali',
                label: '99.00.00 - Organizzazioni ed organismi extraterritoriali',
            },
        ],
    },
]

export default SectorDataTree
