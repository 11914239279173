import React from 'react'
import { Link } from 'react-router-dom'
import './NavHome.scss'
import history from 'history.js'

const NavBarDesktop = () => {
    return (
        <div className="homePage noborder">
            <Link to="/home" className="logo-link">
                <div className="navbar__logo"></div>
            </Link>
            {/* <nav className="homePage__menuItems">
                <ul className="navbar__links">
                    <li className="link">
                        <Link to="/aboutus">Chi siamo</Link>
                    </li>
                    <li className="link">
                        <Link to="/dai-lavoro">Offro lavoro</Link>
                    </li>
                    <li className="link">
                        <Link to="/cerchi-lavoro">Cerchi lavoro</Link>
                    </li>
                    <li>
                        <button
                            className="login"
                            onClick={() => history.push('/tutorial')}
                        >
                            Accedi
                        </button>
                    </li>
                </ul>
            </nav> */}
        </div>
    )
}

export default NavBarDesktop
