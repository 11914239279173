import React from 'react'
import { Link } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';


function GeolocationDenied() {

  const {
    role
  } = useAuth()

  return (
    <div className="full-page">
      <div className='geolocation-page'>
        <div>
        <svg width="84" height="105" viewBox="0 0 84 105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1482_2193)">
          <path d="M42.0007 105C31.1552 105 20.9263 103.512 13.1961 100.81C9.2919 99.4458 6.19011 97.8266 3.9782 95.9983C2.16344 94.4985 0 92.027 0 88.7668C0 85.5066 2.16344 83.0364 3.9782 81.5366C6.19011 79.7083 9.2919 78.0891 13.1961 76.7251C20.9263 74.0227 31.1552 72.5352 42.0007 72.5352C52.8462 72.5352 63.0751 74.0227 70.8053 76.7251C74.7094 78.0891 77.8112 79.7083 80.0231 81.5366C81.8379 83.0364 84.0014 85.508 84.0014 88.7668C84.0014 92.0256 81.8379 94.4972 80.0231 95.997C77.8112 97.8252 74.7094 99.4445 70.8053 100.809C63.0751 103.511 52.8448 105 42.0007 105ZM42.0007 77.2856C31.6668 77.2856 21.9858 78.6809 14.7402 81.2136C8.55415 83.3757 4.71192 86.2694 4.71192 88.7668C4.71192 91.2642 8.55415 94.1592 14.7389 96.3214C21.9845 98.854 31.6668 100.249 41.9993 100.249C52.3319 100.249 62.0142 98.854 69.2598 96.3214C75.4445 94.1592 79.2867 91.2655 79.2867 88.7668C79.2867 86.268 75.4445 83.3743 69.2598 81.2122C62.0156 78.6809 52.3332 77.2856 42.0007 77.2856Z" fill="#5058ED"/>
          <path d="M42.0015 0C22.1468 0 7.07812 13.023 7.07812 32.5555C7.07812 60.0471 42.0015 90.1922 42.0015 90.1922C42.0015 90.1922 76.9249 60.0471 76.9249 32.5555C76.9235 13.023 61.8548 0 42.0015 0ZM42.0015 36.1727C38.17 36.1727 35.0642 33.0415 35.0642 29.1787C35.0642 25.3159 38.17 22.1846 42.0015 22.1846C45.833 22.1846 48.9388 25.3159 48.9388 29.1787C48.9388 33.0415 45.8316 36.1727 42.0015 36.1727Z" fill="#5058ED"/>
          </g>
          <defs>
          <clipPath id="clip0_1482_2193">
          <rect width="84" height="105" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        </div>
        <div className="">
          <span>OPS!</span>
          <h1>Sembra che tu no sia geolocalizzato/a!</h1>
          <p>Attiva la geolocalizzazione e inizia a trovare match</p>
        </div>
        <Link to={role === 'recruiter' ? '/jump-recruiter' : '/jump'} className='btn-like-mui'>torna ai match</Link>
      </div>
    </div>
  )
}

export default GeolocationDenied