import React from 'react'

const Badge = ({ count, children }) => {
    const isMobile = window.innerWidth <= 500
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {children}
            {/*{count > 0 && (*/}
            <span
                style={{
                    position: 'absolute',
                    top: isMobile ? '-2px' : '-13px',
                    right: '-8px',
                    backgroundColor: '#ea6c3a',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '1px 5px',
                    fontSize: '8px',
                    width: '10px', // Size of the circle
                    height: '10px', // Make it perfectly round
                }}
            >
                {/*  {count}*/}
            </span>
            {/* )}*/}
        </div>
    )
}

export default Badge
