const years_ranges = [
    {
        label: "meno di un anno",
        months: 6,
    },
    {
        label: "da 1 a 2 anni",
        months: 18,
    },
    {
        label: "da 2 a 5 anni",
        months: 30,
    },
    {
        label: "da 5 a 10 anni",
        months: 90,
    },
    {
        label: "più di 10 anni",
        months: 120,
    }
];

export default years_ranges;