import Onboarding from '../components/OnboardingComponents/Onboarding/Onboarding'
import OnboardingRecruiter from '../components/OnboardingComponents/OnboardingRecruiter/OnboardingRecruiter'
import OnboardingCompleted from '../components/OnboardingComponents/Onboarding/OnboardingCompleted'
import OnboardingCompletedRecruiter from '../components/OnboardingComponents/OnboardingRecruiter/OnboardingCompletedRecruiter'
import ChooseAccount from '../components/OnboardingComponents/ChooseAccount'

const onboardingRoutes = [
    {
        path: '/onboarding',
        component: Onboarding,
    },
    {
        path: '/onboarding-recruiter',
        component: OnboardingRecruiter,
    },
    {
        path: '/onboarding-completed',
        component: OnboardingCompleted,
    },
    {
        path: '/onboarding-completed-recruiter',
        component: OnboardingCompletedRecruiter,
    },
    {
        path: '/choose-account',
        component: ChooseAccount,
    },
]

export default onboardingRoutes