import React, { useEffect, useState, useCallback, useRef } from 'react'
import BioCompany from './ProfileComponents/BioCompany'
import RegisteredUser from './ProfileComponents/RegisteredUser'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'
import PreviewCardJob from './ProfileComponents/JobCard/PreviewCardJob'
import PopupCreateJob from './ProfileComponents/JobCard/PopupCreateJob'
import BioRecruiterPlaceholder from '../Placeholders/BioRecruiterPlaceholder'
import CardsPlaceholder from '../Placeholders/CardsPlaceholder'
import JobCreated from './ProfileComponents/JobCreated'
import { useHistory } from 'react-router-dom'
import JobCardEmpty from './ProfileComponents/JobCardEmpty'
import AddIcon from '@material-ui/icons/Add'
import { v4 as uuidv4 } from 'uuid'

function ProfileRecruiter() {
    const [appState, setAppState] = useState(false)
    const [offerInfo, setOfferInfo] = useState([])
    const [open, setOpen] = useState(false)
    const descriptionElementRef = useRef(null)
    const history = useHistory()
    const [userInfo, setUserInfo] = useState({
        recruiter_guid: '',
        name: '',
        surname: '',
        birth_date: '',
        company_name: '',
        headquarters: '',
        phone: '',
        gender: '',
        email: '',
        size: '',
        photo_url: '',
        description: '',
        sector: [],
        created: new Date(),
        lastUpdate: new Date(),
    })

    const {
        recruiter_guid,
        name,
        surname,
        birth_date,
        company_name,
        headquarters,
        phone,
        gender,
        email,
        size,
        description,
        sector,
        photo_url,
        created,
        lastUpdate,
    } = userInfo

    const initOffer = {
        recruiter_guid: recruiter_guid || '',
        offer_guid: '',
        company_name: company_name || '',
        job_name: '',
        sector: [],
        location: '',
        driving_license: '',
        job_percentage: 0,
        isced: '',
        ral: 0,
        photo_url: '',
        description: '',
        hs_weight: 15,
        ss_weight: 15,
        title_weight: 30,
        exp_weight: 40,
        created: new Date(),
        lastUpdate: new Date(),
    }
    const {
        // recruiter_guid,
        offer_guid,
        // company_name,
        job_name,
        // sector,
        location,
        driving_license,
        job_percentage,
        isced,
        ral,
        // description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        // created,
        // lastUpdate,
    } = offerInfo

    const { user, updateUserName } = useAuth()

    const getUserInfo = async () => {
        try {
            const response = await ApiService.get_recruiterbyId(recruiter_guid)
            const body = await response.json()
            console.log(body)

            setUserInfo({
                ...userInfo,
                name: body.name,
                surname: body.surname,
                phone: body.phone,
                gender: body.gender,
                birth_date: new Date(body.birth_date),
                company_name: body.company_name,
                headquarters: body.headquarters,
                size: body.size,
                description: body.description,
                sector: JSON.parse(body.sector),
                created: body.created,
                lastUpdate: body.lastUpdate,
                photo_url: body.photo_url,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const getOfferInfo = async (e, index) => {
        try {
            const response = await ApiService.get_offersbyguid(recruiter_guid)
            const body = await response.json()
            // console.log(body)

            setOfferInfo(body)
        } catch (err) {
            console.error(err.message)
        }
    }
    const handleAddOfferClick = () => {
        const newOfferGuid = uuidv4()
        history.push(`/offertaTab/${newOfferGuid}`)
    }
    const onChange = (event) => {
        event.preventDefault()
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        // console.log(userInfo)
    }

    const changeNum = (num) => {
        setUserInfo({
            ...userInfo,
            phone: num,
        })
    }

    const handleClose = () => {
        setAppState(true)
        setOpen(false)
    }

    const handleOpen = () => {
        setUserInfo({ ...userInfo, lastUpdate: new Date() })
    }

    const onNodeSelect = (event, value) => {
        setUserInfo({
            ...userInfo,
            sector: value,
        })
    }

    const handleSubmit = async (event) => {
        // event.preventDefault();
        await updateUserName(name)
        // console.log(userInfo)
        const res = await ApiService.update_recruiter(userInfo)
        if (res.ok) setAppState(true)
    }

    const getOffer = useCallback(() => {
        getOfferInfo()
    }, [userInfo])

    async function getData() {
        await getUserInfo()
        await getOfferInfo()
    }

    //appState
    useEffect(() => {
        if (appState === true) {
            getData()
            // await getOfferInfo();
            setAppState(false)
            // const interval = setInterval(() => {
            //     setappState(false)
            // }, 5000)
        }
    }, [appState]) // includes empty dependency array

    useEffect(() => {
        if (user !== null) {
            setUserInfo({
                ...userInfo,
                email: user.email,
                recruiter_guid: user.uid,
                photo_url: user?.photoURL,
            })
            setAppState(true)

            // if (user?.photoURL) setPhotoURL(user.photoURL)
        }
    }, [user])

    const handleAddOffer = async (event) => {
        event.preventDefault()
        debugger
        const res = await ApiService.create_offer(offerInfo)
        if (res.ok) getOffer()

        getOfferInfo()
        history.push('/job-created')
        handleClose()
    }

    const handleDeleteJob = async (offerId) => {
        // console.log(offerId);
        const res = await ApiService.delete_offer(offerId)
        if (res.ok) getOffer()
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        setOfferInfo({
            ...offerInfo,
            recruiter_guid: recruiter_guid,
            company_name: company_name,
        })
    }, [])

    return (
        <>
            <div className="Content-scroll-sticky">
                <main className="profile">
                    <div className="padding-inline">
                        <h1 className="m1">{company_name}</h1>
                        {appState ? (
                            <BioRecruiterPlaceholder />
                        ) : (
                            <BioCompany
                                user={user}
                                userInfo={userInfo}
                                onChange={onChange}
                                onNodeSelect={onNodeSelect}
                                handleSubmit={handleSubmit}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                setUserInfo={setUserInfo}
                            />
                        )}
                        {/* {appState ? (
                            <CardsPlaceholder />
                        ) : (
                            <RegisteredUser
                                userInfo={userInfo}
                                onChange={onChange}
                                handleDateChange={handleDateChange}
                                changeNum={changeNum}
                                handleSubmit={handleSubmit}
                                onOpen={handleOpen}
                                onClose={handleClose}
                            />
                        )} */}
                    </div>
                    <section className="padding-inline">
                        <div className="section-title custom-section-title m1">
                            <div>
                                <h2 style={{ color: '#4b4c55' }}>
                                    {offerInfo?.length <= 0
                                        ? 'Non hai pubblicato ancora nessuna offerta.'
                                        : offerInfo?.length === 1
                                        ? 'Ecco la tua offerta.'
                                        : 'Ecco le tue offerte.'}
                                </h2>
                                <p
                                    style={{
                                        marginTop: '2.5rem',
                                        fontWeight: '600',
                                    }}
                                >
                                    {offerInfo?.length <= 0
                                        ? 'Clicca sul + per creare la tua prima offerta di lavoro.'
                                        : null}
                                    {/* Clicca sul + per pubblicarne un’altra. */}
                                </p>
                            </div>
                            {offerInfo?.length <= 0 && (
                                <button
                                    // to={`/offertaTab/${offer_guid}`}
                                    onClick={handleAddOfferClick}
                                    className="icon-btn"
                                >
                                    <AddIcon
                                        style={{
                                            color: '#5058ed',
                                            width: '3rem',
                                            height: '2.5rem',
                                        }}
                                    />
                                </button>
                            )}

                            {/* <JobCardEmpty
                                userInfo={userInfo}
                                handleSubmit={handleSubmit}
                                getOffer={getOffer}
                                handleAddOffer={handleAddOffer}
                                setOfferInfo={setOfferInfo}
                                onClose={handleClose}
                                getOfferInfo={getOfferInfo}
                                recruiterguid={recruiter_guid}
                                companyName={company_name}
                                offerInfoLength={offerInfo}
                            /> */}
                        </div>
                        <div className="box-preview">
                            {appState
                                ? Array.from({ length: 3 }).map((_, index) => (
                                      <CardsPlaceholder key={index} />
                                  ))
                                : offerInfo?.length > 0
                                ? offerInfo?.map((offer, index) => (
                                      <PreviewCardJob
                                          key={index}
                                          user={user}
                                          getOfferList={getOfferInfo}
                                          offer={offer}
                                          handleDeleteJob={handleDeleteJob}
                                          showSwitch={offerInfo?.length > 1}
                                      />
                                  ))
                                : null}
                        </div>
                    </section>
                </main>
            </div>
        </>
    )
}

export default ProfileRecruiter
