const SoftSkillsTable = [
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Affidabilità',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Ascolto',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Creatività',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Curiosità',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Flessibilità',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Ottimismo',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Pazienza',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Puntualità',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Resilienza',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Responsabilità',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Umiltà',
    },
    {
        soft_lv1: 'Qualità Personali',
        soft_lv2: 'Integrità',
    },
    {
        soft_lv1: 'Competenze Cognitive',
        soft_lv2: 'Capacità Decisionale',
    },
    {
        soft_lv1: 'Competenze Cognitive',
        soft_lv2: 'Organizzazione',
    },
    {
        soft_lv1: 'Competenze Cognitive',
        soft_lv2: 'Pensiero Laterale',
    },
    {
        soft_lv1: 'Competenze Cognitive',
        soft_lv2: 'Problem Solving',
    },
    {
        soft_lv1: 'Competenze Cognitive',
        soft_lv2: 'Gestione del Tempo',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Autocontrollo',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Autonomia',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Comunicazione Scritta',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Comunicazione Verbale',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Diligenza',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Gestione dello Stress',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Intraprendenza',
    },
    {
        soft_lv1: 'Efficacia Personale',
        soft_lv2: 'Orientamento al Risultato',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Grinta',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Leadership',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Persuasione',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Professionalità',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Public Speaking',
    },
    {
        soft_lv1: 'Capacità di Influenza',
        soft_lv2: 'Mentoring',
    },
    {
        soft_lv1: 'Abilità Relazionali',
        soft_lv2: 'Collaborazione',
    },
    {
        soft_lv1: 'Abilità Relazionali',
        soft_lv2: 'Diplomazia',
    },
    {
        soft_lv1: 'Abilità Relazionali',
        soft_lv2: 'Empatia',
    },
    {
        soft_lv1: 'Abilità Relazionali',
        soft_lv2: 'Gestione dei Conflitti',
    },
]

export default SoftSkillsTable
