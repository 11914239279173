import React from 'react'

function CloseIconWhite({ className }) {
    const accentColor2 = '#fff'

    return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
        <svg
            className={className}
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.8066 19.6777L40.1943 40.0658"
                stroke={accentColor2}
                strokeWidth="3"
            />
            <path
                d="M19.8066 40.0664L40.1947 19.6787"
                stroke={accentColor2}
                strokeWidth="3"
            />
        </svg>
    )
}

export default CloseIconWhite
