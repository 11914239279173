const Sector = [
    'Agricoltura, allevamento e pesca',
    'Servizi per la persona',
    'Sportivo, artistico ed intrattenimento',
    'Ristorazione ed alloggio',
    'Finanziario ed assicurativo',
    'Immobiliare',
    'Manufatturiero',
    'Fiscale, Legale e Tributario',
    'Commercio al dettaglio',
    "Commercio all'ingrosso",
    'Meccanici e carrozzieri',
    'Estrazione minerarie',
    'Idrico e smaltimento rifiuti',
    'Luce, Gas ed energia',
    'Istruzione e formazione',
    'Ricerca e selezione di personale',
    'Servizi di pulizie',
    'Sanità ed assistenza locale',
    'Servizi di informazione e comunicazione',
    'Logistica e trasporti',
    'Edilizia',
]

export default Sector
