import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
    return (
        <div className="page404 fill-page d-flex flex-column align-center">
            <img src="./images/ill-404page.png" alt="" />
            <h1 className="text-center">
                Mmmh... <br /> forse ci siamo persi...
            </h1>
            <Link to={'/'} className="btn-like-mui">
                torna alla home
            </Link>
        </div>
    )
}

export default Page404
