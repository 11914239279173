import KangratsLogo from 'app/components/Icons/KangratsLogo'
import React from 'react'

const SignupLayout = ({ children }) => {
    return (
        <div>
            <div className="bubble bubble1"></div>
            <div className=" bubble bubble2"></div>
            <div className="bubble bubble3"></div>
            <div className="bubble bubble4"></div>

            <div className="create-account-main-container">
                <header className="header-create-account">
                    <KangratsLogo
                        width={265}
                        height={70}
                        cssClasses={'margin-inline-auto'}
                    />
                </header>

                <>{children}</>
                <footer className="d-flex align-center justify-center">
                    <small> © Kangrats 2024</small>
                </footer>
            </div>
        </div>
    )
}

export default SignupLayout
