import { TextField, InputLabel, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import DateFnsUtils from '@date-io/date-fns'
import PhoneInput from 'react-phone-input-2'
import validator from 'validator'

function RegisteredUser({ userInfo: initialUserInfo, onFieldChange }) {
    const [userInfo, setUserInfo] = useState(initialUserInfo || {})
    const [formErrors, setFormErrors] = useState({})

    useEffect(() => {
        setUserInfo(initialUserInfo || {})
    }, [initialUserInfo])

    const handleFieldChange = (e) => {
        const { name, value } = e.target
        setUserInfo((prev) => ({ ...prev, [name]: value }))
        onFieldChange()
    }

    const handleDateChange = (date) => {
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }
        setUserInfo((prev) => ({ ...prev, birth_date: date }))
        onFieldChange()
    }

    const changeNum = (num) => {
        setUserInfo((prev) => ({ ...prev, phone: num }))
        onFieldChange()
    }

    const validate = () => {
        const errors = {}
        if (!userInfo.name) errors.name = 'Questo campo non può essere nullo'
        if (!userInfo.surname)
            errors.surname = 'Questo campo non può essere nullo'
        if (!userInfo.birth_date)
            errors.birth_date = 'Questo campo non può essere nullo'
        if (userInfo.birth_date && new Date(userInfo.birth_date) > new Date()) {
            errors.birth_date =
                'La data di nascita non può essere successiva al giorno corrente'
        }
        if (userInfo.phone && !validator.isMobilePhone(userInfo.phone)) {
            errors.phone = 'Il formato di numero inserito non è valido'
        }
        return errors
    }

    const handleSubmit = () => {
        const errors = validate()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
        }
    }

    return (
        <div className="user-data-form">
            <h2>Dati Utente</h2>

            <div className="form-row">
                <div>
                    <InputLabel>Nome</InputLabel>
                    <TextField
                        label="Nome"
                        variant="filled"
                        value={userInfo.name || ''}
                        name="name"
                        fullWidth
                        required
                        onChange={handleFieldChange}
                    />
                    {formErrors.name && (
                        <p className="input-error">{formErrors.name}</p>
                    )}
                </div>
                <div>
                    <InputLabel>Cognome</InputLabel>
                    <TextField
                        label="Cognome"
                        variant="filled"
                        value={userInfo.surname || ''}
                        name="surname"
                        fullWidth
                        required
                        onChange={handleFieldChange}
                    />
                    {formErrors.surname && (
                        <p className="input-error">{formErrors.surname}</p>
                    )}
                </div>
            </div>

            <div className="form-row">
                <div>
                    <InputLabel>Data di Nascita</InputLabel>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={itLocale}
                    >
                        <KeyboardDatePicker
                            onChange={handleDateChange}
                            margin="normal"
                            label="Data di nascita"
                            format="dd/MM/yyyy"
                            value={
                                userInfo.birth_date
                                    ? new Date(userInfo.birth_date)
                                    : null
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            fullWidth
                            autoOk
                            required
                            disableFuture
                        />
                    </MuiPickersUtilsProvider>
                    {formErrors.birth_date && (
                        <p className="input-error">{formErrors.birth_date}</p>
                    )}
                </div>
                <div>
                    <InputLabel>Recapito telefonico</InputLabel>
                    <div id="mui-phone" className="userdata">
                        <PhoneInput
                            country={'it'}
                            preferredCountries={['it']}
                            value={userInfo.phone || ''}
                            onChange={changeNum}
                            placeholder={'Recapito telefonico*'}
                        />
                        {formErrors.phone && (
                            <p className="input-error">{formErrors.phone}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div>
                    <InputLabel>Ruolo in azienda</InputLabel>
                    <TextField
                        label="Ruolo in azienda"
                        variant="filled"
                        type="email"
                        value={userInfo.role || ''}
                        name="email"
                        fullWidth
                        required
                        onChange={handleFieldChange}
                    />
                </div>
                <div>
                    <InputLabel>Email</InputLabel>
                    <TextField
                        label="Email"
                        variant="filled"
                        type="email"
                        value={userInfo.email || ''}
                        name="email"
                        fullWidth
                        required
                        onChange={handleFieldChange}
                    />
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '2rem',
                    marginTop: '2rem',
                }}
            >
                <Button
                    variant="contained"
                    className="btn-primary"
                    onClick={handleSubmit}
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    Salva
                </Button>
            </div>
        </div>
    )
}

export default RegisteredUser
