import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Autocomplete from '../../../Autocomplete/AsyncAutocomplete'
import Skeleton from '@material-ui/lab/Skeleton'

function JobCardHardSkills({ hslist, offerguid, getHsList, appState }) {
    // per dialog
    const descriptionElementRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [hsList, setHsList] = useState([])
    const [hsMappedList, setHsMappedList] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showUnsavedDialog, setShowUnsavedDialog] = useState(false)
    const initUserInfo = {
        offer_guid: offerguid || '',
        skill_name: '',
        isced: '',
        score: 1,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)

    const { offer_guid, skill_name, isced, created, lastUpdate } = userInfo

    const handleClickOpen = () => () => {
        setOpen(true)
        setUserInfo({
            ...userInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = () => {
        if (unsavedChanges) {
            setShowUnsavedDialog(true)
        } else {
            closeDialog()
        }
    }

    const closeDialog = () => {
        setOpen(false)
        setUserInfo(initUserInfo)
        setFormErrors({})
        setUnsavedChanges(false)
    }

    const handleAddSkill = async () => {
        const updatedArray = hsList.map((skill) => {
            if (
                hsMappedList.some(
                    (otherSkill) => skill.skill_name === otherSkill
                )
            ) {
                return {
                    id: skill.id,
                    offer_guid: offer_guid,
                    checked: true,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            } else {
                return {
                    id: skill.id,
                    offer_guid: offer_guid,
                    checked: false,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            }
        })

        hsMappedList.forEach((skillName) => {
            if (!hsList.some((skill) => skill.skill_name === skillName)) {
                updatedArray.push({
                    offer_guid: offer_guid,
                    skill_name: skillName,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        const res = await ApiService.create_multipleofferhs(updatedArray)
        // if (res.ok)
        {
            await getHsList()
        }
        closeDialog()
    }

    const handleSubmit = async () => {
        const errors = validate()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            await handleAddSkill()
            closeDialog()
        }
    }

    const handleDelete = (id) => async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_offerhs(id)
        if (res.ok) getHsList()
        // filter_hs()
    }

    const onSelect = (value) => {
        if (!value || value === '') return

        setUnsavedChanges(true)

        setHsMappedList((prevList) => {
            const isAlreadySelected = prevList.includes(value)

            if (isAlreadySelected) {
                return prevList.filter((skill) => skill !== value)
            } else {
                if (prevList.length >= 12) {
                    setFormErrors({
                        ...formErrors,
                        skill_name:
                            'Puoi aggiungere massimo 12 competenze, rimuovi quelle che non sono realmente importanti per te',
                    })
                    return prevList
                } else return [...prevList, value]
            }
        })
    }

    const validate = () => {
        const errors = {}
        return errors
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (isSubmit) {
            handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit])

    useEffect(() => {
        setHsList(hslist)
        setHsMappedList(hslist.map((x) => x.skill_name))
    }, [hslist])

    return (
        <>
            <div className="">
                <h3 style={{ color: '#5058ed' }}>
                    Deve avere queste competenze tecniche.
                </h3>
                <div className="section-title">
                    <p>Clicca sul + e per aggiungere più caratteristiche .</p>
                    <button
                        disabled={hsList?.length > 12 ? true : false}
                        className="icon-btn"
                        onClick={handleClickOpen()}
                    >
                        <AddIcon />
                    </button>
                </div>

                {/* {!appState && hsList.length <= 0 && !open && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Inserisci le competenze tecniche richieste
                                    per svolgere il ruolo
                                </p>
                                <button
                                    onClick={handleClickOpen()}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )} */}

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <div>
                                <h3>Competenze tecniche richieste</h3>
                                <p>
                                    Scegli quelle che ti servono dal menu a
                                    tendina.
                                </p>
                            </div>
                            <button
                                onClick={() => handleClose()}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end profilerec">
                            <ul className="btns-card grid-2-columns skill-container tags-container">
                                {appState ? (
                                    <div className="new-box">
                                        <div className="d-flex align-center tags">
                                            <div className="d-flex align-center tags">
                                                <Skeleton
                                                    variant="rect"
                                                    width={100}
                                                    height={30}
                                                />
                                            </div>
                                            <div className="d-flex align-center tags">
                                                <Skeleton
                                                    variant="rect"
                                                    width={100}
                                                    height={30}
                                                />
                                            </div>
                                            <div className="d-flex align-center tags">
                                                <Skeleton
                                                    variant="rect"
                                                    width={100}
                                                    height={30}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    hsMappedList.map((skill, index) => (
                                        <div key={index} className="new-box">
                                            <div className="d-flex align-center tags TagWhite">
                                                <p> {skill} </p>
                                                <button
                                                    className="icon-btn"
                                                    onClick={() =>
                                                        onSelect(skill)
                                                    }
                                                >
                                                    <CloseIcon />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </ul>
                            <div>
                                <Autocomplete
                                    parentValue={skill_name}
                                    onChange={onSelect}
                                    label={'Cerca una parola chiave'}
                                    apiRequest={ApiService.get_hslist}
                                    filterOptions={hsMappedList}
                                    clearOnSelect={true}
                                />
                                <p className="input-error">
                                    {formErrors.skill_name}
                                </p>
                            </div>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSubmit(true)
                                }}
                                color="primary"
                                className="btn-like-mui m0"
                            >
                                salva
                            </Button>
                        </div>
                    </div>
                )}
                {!open && (
                    <ul className="btns-card grid-2-columns skill-container tags-container bottom">
                        {appState ? (
                            <div className="new-box">
                                <div className="d-flex align-center tags">
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            hsList.map((skill, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="d-flex  align-center new-box tags"
                                    >
                                        <p> {skill.skill_name} </p>
                                        <button
                                            onClick={handleDelete(skill.id)}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </li>
                                )
                            })
                        )}

                        {}
                    </ul>
                )}
            </div>
        </>
    )
}

export default JobCardHardSkills
