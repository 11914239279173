import React, { createContext, useMemo } from 'react'
import { getToken, onMessage, isSupported } from 'firebase/messaging'
import { messaging, messageToken } from '../../config'
import { useEffect } from 'react'
import addNotification from 'react-push-notification'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
// import history from 'history.js'

const FirebaseFCMContext = createContext({})

export const FirebaseFCMProvider = ({ children }) => {
    const { user } = useAuth()
    const { addUser } = useFirestore()

    function isIOS() {
        const browserInfo = navigator.userAgent.toLowerCase()
        console.log('borwserinfo: ', browserInfo)
        if (
            browserInfo.match('iphone') ||
            browserInfo.match('ipad') ||
            browserInfo.match('mobile')
        ) {
            return true
        }
        if (
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'mobile',
            ].includes(navigator.userAgent.platform)
        ) {
            return true
        }
        if (isSupported()) return false
        return false
    }

    function requestPermission() {
        if (isIOS()) return
        // console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                // console.log('Notification permission granted.')
                getToken(messaging, { vapidKey: messageToken })
                    .then((currentToken) => {
                        if (currentToken) {
                            // console.log("current token: ",currentToken )
                            if (user?.uid)
                                addUser(
                                    user.uid,
                                    currentToken,
                                    user?.displayName,
                                    user?.photoURL
                                )
                            // Send the token to your server and update the UI if necessary
                            // ...
                        } else {
                            // Show permission request UI
                            console.log(
                                'No registration token available. Request permission to generate one.'
                            )
                            // ...
                        }
                    })
                    .catch((err) => {
                        console.log(
                            'An error occurred while retrieving token. ',
                            err
                        )
                        // ...
                    })
            }
        })
    }

    onMessage(messaging, (payload) => {
        console.log('messaggio ricevuto: ', payload)
        if (payload?.data?.action !== 'message') {
            addNotification({
                title: payload.data.title,
                message: payload.data.body,
                duration: 5000,
                icon: payload?.data?.icon,
                native: true,
            })
        }
    })

    useEffect(() => {
        if (user?.uid) requestPermission()
    }, [user])

    const memorizedValue = useMemo(
        () => ({
            // createMatch,
            // sendMessage,
            // getMessages,
            // getUnreadedMessages,
            // updateReadMessage,
            // deleteChat,
            // messages,
            // unreaded
        }),
        []
    )

    return (
        <FirebaseFCMContext.Provider value={memorizedValue}>
            {children}
        </FirebaseFCMContext.Provider>
    )
}

export default FirebaseFCMContext
