import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import './Tutorial.scss'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import CommonElement from './commonElement.js'
import NavbarContainer from '../Navtutorialcontainer.js'
import '../FooterHome.scss'
import FooterTut from './footer-tutorial.js'

//imgs
import photo1 from '../media/1.jpg'
import photo2 from '../media/2.jpg'
import photo3 from '../media/3.jpg'
import photo4 from '../media/4.jpg'
import photo5 from '../media/5.jpg'
import photoslide1 from '../media/photoslide1.png'
import imgslide1 from '../media/lap2-mo-of.png'
import imgslide2 from '../media/slide2-n.png'
import imgslide3 from '../media/slide3.png'
import imgslide4 from '../media/slide4.png'
import next from '../media/next.png'
import prev from '../media/prev.png'

const PrevButton = (props) => {
    const { className, onClick } = props
    return (
        <div className={`swiper-button-prev ${className}`} onClick={onClick}>
            <img src={prev} alt="Previous" />
        </div>
    )
}

const NextButton = (props) => {
    const { className, onClick } = props
    return (
        <div className={`swiper-button-next ${className}`} onClick={onClick}>
            <img src={next} alt="Next" />
        </div>
    )
}

SwiperCore.use([Navigation, Pagination])

const Tutorial = ({ navbarContainer }) => {
    return (
        <>
            <NavbarContainer />

            <div className="swipperSecond-container">
                <div className="swipperSecond">
                    <Swiper
                        className="swiperSecondSlides"
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                        pagination={{
                            clickable: true,
                            renderCustom: (swiper) => (
                                <CommonElement swiper={swiper} />
                            ),
                        }}
                        loop={false}
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        style={{ height: '100vh' }}
                    >
                        <SwiperSlide
                            className="slide"
                            style={{
                                backgroundImage: `url(${photo1})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="img-slide">
                                <img src={photoslide1} alt="img" />
                            </div>
                            <div className="text-firstSlide">
                                <h1>Trovare lavoro non è più un lavoro!</h1>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide
                            className="slide"
                            style={{
                                backgroundImage: `url(${photo2})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="img-slide">
                                <img src={imgslide1} alt="img" />
                            </div>
                            <div className="text">
                                <h1>Ti interessa?</h1>
                                <p className="slide-text">
                                    Salta avanti per candidarti ad un'offerta o
                                    approfondire la conoscenza con un candidato.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide
                            className="slide"
                            style={{
                                backgroundImage: `url(${photo3})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="img-slide">
                                <img src={imgslide2} alt="img" />
                            </div>
                            <div className="text-slide3">
                                <h1>Non fa per te?</h1>
                                <p className="slide-text">
                                    Salta all'offerta successiva o al prossimo
                                    candidato più in linea
                                </p>
                            </div>
                            <div className="gradient-overlay" />
                        </SwiperSlide>
                        <SwiperSlide
                            className="slide"
                            style={{
                                backgroundImage: `url(${photo4})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="img-slide">
                                <img src={imgslide3} alt="img" />
                            </div>
                            <div className="text-slide2">
                                <h1>Qualcuno ha mostrato interesse</h1>
                                <p className="slide-text">
                                    Sei sulla buona strada. Potresti aver
                                    trovato l'opportunità o il candidato che fa
                                    al caso tuo.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide
                            className="slide"
                            style={{
                                backgroundImage: `url(${photo5})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <div className="img-slide">
                                <img src={imgslide4} alt="img" />
                            </div>
                            <div className="text-matchslide">
                                <h1>Kangrats hai fatto match!</h1>
                                <p className="slide-text">
                                    Non ti resta che entrare in contatto con
                                    l'azienda o il candidato per conoscervi.
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <PrevButton className="swiper-button" />
                    <NextButton className="swiper-button" />
                    <CommonElement />
                </div>
                <FooterTut />
            </div>
        </>
    )
}

export default Tutorial
