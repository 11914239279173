import React, { useEffect, useRef, useState } from 'react'
import useAuth from 'app/hooks/useAuth'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CloseIcon from 'app/components/Icons/CloseIcon'

function ModifyData() {
    const { logout } = useAuth()
    const [open, setOpen] = useState(false)
    const [applied, setApplied] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)

    const handlePasswordClick = () => {
        setOpen(true)
        setScroll('paper')
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    const handleChange = (e) => {
        const { name, value } = e.target
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setApplied(true)
    }

    return (
        <>
            <div className="footer-section">
                <button
                    className="accent-text-color"
                    onClick={handlePasswordClick}
                >
                    Modifica password
                </button>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="form-dialog-title"
                maxWidth={'md'}
                fullWidth={true}
                className="small-dialog dilogmd"
            >
                <div className="d-flex space-between dialog-title">
                    <DialogTitle>modifica password</DialogTitle>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className="close-dialog"
                    >
                        <CloseIcon />
                    </Button>
                </div>

                {!applied ? (
                    <DialogContent dividers={scroll === 'paper'}>
                        <p className="input-error">{formErrors.email}</p>
                        <TextField
                            label="Indirizzo mail corrente"
                            variant="filled"
                            name="email"
                            fullWidth
                            required
                            className="m1"
                            color="primary"
                            onChange={handleChange}
                        />
                        <TextField
                            label="Password corrente"
                            variant="filled"
                            name="currentPassword"
                            fullWidth
                            required
                            className="m1"
                            color="primary"
                            onChange={handleChange}
                        />
                        <TextField
                            label="Nuova password"
                            variant="filled"
                            name="newPassword"
                            fullWidth
                            required
                            className="m1"
                            color="primary"
                            onChange={handleChange}
                        />
                        <TextField
                            label="Ripeti nuova password"
                            variant="filled"
                            name="confirmNewPassword"
                            fullWidth
                            required
                            className="m1"
                            color="primary"
                            onChange={handleChange}
                        />
                        <p>
                            Una conferma verrà inviata a questo account. Clicca
                            sul link di conferma per verificare e aggiungere
                            questo indirizzo email.
                        </p>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <h1>Ben fatto!</h1>
                        <p>
                            Segui la procedura che hai ricevuto per mail per
                            modificare la password
                        </p>
                    </DialogContent>
                )}

                <DialogActions>
                    {!applied ? (
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            fullWidth
                        >
                            modifica password
                        </Button>
                    ) : (
                        <Button
                            onClick={logout}
                            className="accent-btn btn big-border-radius"
                        >
                            Continua
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModifyData
