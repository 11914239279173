import React from 'react'
import SoftSkillsTable from '../../Tables/soft_skills_table'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Box from '@material-ui/core/Box'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

function OnboardingSoftSkills({ stepOnboarding, setStepOnboarding }) {
    const [stepOnboardingSoftSkills, setStepOnboardingSoftSkills] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [showAllSkills, setShowAllSkills] = useState(false)

    const max_skills = 10

    const [mostraEffettiveSoftSkills, setMostraEffettiveSoftSkills] = useState(
        []
    )
    const [appState, setAppState] = useState(false)
    const initUserInfo = {
        candidate_guid: '',
        skill_name: '',
        score: 1,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const { candidate_guid, skill_name, score, created, lastUpdate } = userInfo

    const {
        user,
        // user
    } = useAuth()

    const SectionTitles = ['Che cosa ti caratterizza al lavoro?']
    const SectionSubtitle = [
        'Selezionare fino a 10 tra tutte le seguenti caratteristiche',
    ]
    const defaultSkills = [
        'Affidabilità',
        'Autonomia',
        'Collaborazione',
        'Flessibilità',
        'Gestione dello Stress',
        'Organizzazione',
        'Pazienza',
        'Professionalità',
        'Puntualità',
        'Responsabilità',
    ]

    const skillCategories = SoftSkillsTable.reduce((acc, skill) => {
        acc[skill.soft_lv1] = acc[skill.soft_lv1] || []
        acc[skill.soft_lv1].push(skill.soft_lv2)
        return acc
    }, {})

    const filteredSkillCategories = {}
    Object.keys(skillCategories).forEach((category) => {
        filteredSkillCategories[category] = skillCategories[category].filter(
            (skillName) => !defaultSkills.includes(skillName)
        )
    })

    const getSsList = async () => {
        try {
            const response = await ApiService.get_candssbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            const initialSkills = body.map((saved_skill) => {
                const parsedSkill = JSON.parse(saved_skill.skill_name)
                return {
                    soft_lv1: parsedSkill.soft_lv1,
                    soft_lv2: parsedSkill.soft_lv2,
                    id: saved_skill.id,
                    candidate_guid: candidate_guid,
                    checked: true,
                    score: 5,
                    skill_name: parsedSkill,
                    lastUpdate: new Date(),
                }
            })

            setMostraEffettiveSoftSkills((prevSkills) => {
                const updatedSkills = [...prevSkills, ...initialSkills]
                const uniqueSkills = Array.from(
                    new Set(updatedSkills.map((a) => JSON.stringify(a)))
                ).map((s) => JSON.parse(s))
                return uniqueSkills
            })

            if (
                initialSkills.some(
                    (skill) => !defaultSkills.includes(skill.soft_lv2)
                )
            )
                setShowAllSkills(true)
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleSkillClick = (skill) => {
        // console.log('Clicked skill:', skill)

        let updatedSkillsArray = [...mostraEffettiveSoftSkills]
        const skillIndex = updatedSkillsArray.findIndex(
            (item) =>
                item.soft_lv2 === skill.soft_lv2 &&
                item.soft_lv1 === skill.soft_lv1
        )

        if (skillIndex >= 0) {
            updatedSkillsArray[skillIndex].checked =
                !updatedSkillsArray[skillIndex].checked
        } else {
            const checkedSkillsCount = updatedSkillsArray.filter(
                (item) => item.checked
            ).length
            if (checkedSkillsCount < max_skills) {
                updatedSkillsArray.push({
                    ...skill,
                    candidate_guid: candidate_guid,
                    checked: true,
                    score: 5,
                    skill_name: {
                        soft_lv1: skill.soft_lv1,
                        soft_lv2: skill.soft_lv2,
                    },
                    created: new Date(),
                    lastUpdate: new Date(),
                })
            } else {
                setFormErrors({
                    ...formErrors,
                    max: 'Seleziona un massimo di 10 skills. Potrai aggiungerne altre successivamente',
                })
                return
            }
        }

        updatedSkillsArray = Array.from(
            new Set(updatedSkillsArray.map((a) => JSON.stringify(a)))
        ).map((s) => JSON.parse(s))

        const checkedSkillsCount = updatedSkillsArray.filter(
            (item) => item.checked
        ).length
        // console.log('Updated array:', updatedSkillsArray)
        // console.log('Count of checked skills:', checkedSkillsCount)

        if (checkedSkillsCount > max_skills) {
            setFormErrors({
                ...formErrors,
                max: 'Seleziona un massimo di 10 skills. Potrai aggiungerne altre successivamente',
            })
        } else {
            setFormErrors({})
            setMostraEffettiveSoftSkills(updatedSkillsArray)
        }
    }

    async function handleNext() {
        try {
            let countChecked = mostraEffettiveSoftSkills.filter(
                (item) =>
                    item.checked === true ||
                    (item.checked === false && item?.id)
            )

            const addRes = await ApiService.create_multiplecandss(countChecked)
            if (!addRes.ok) {
                throw new Error('Failed to update checked skills')
            }
            setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
            setStepOnboardingSoftSkills((prevActiveStep) =>
                prevActiveStep !== SectionTitles.length - 1
                    ? prevActiveStep + 1
                    : prevActiveStep
            )
        } catch (error) {
            console.error(error.message)
        }
    }

    const validate = () => {
        const errors = {}

        if (
            mostraEffettiveSoftSkills.filter((item) => item.checked === true)
                .length <= 0
        )
            errors.max = 'Seleziona una o più caratteristiche tra i campi sotto'

        return errors
    }
    async function getData() {
        await getSsList()
        setAppState(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    // useEffect(() => {
    //     console.log(mostraEffettiveSoftSkills)
    // }, [mostraEffettiveSoftSkills])

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({ ...userInfo, candidate_guid: user?.uid })
            setAppState(true)
        }
    }, [user])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div>
                <h3 className="headerskillsonboarding smallheader">
                    {SectionTitles[stepOnboardingSoftSkills]}
                </h3>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: '100%', height: '100%' }}
                >
                    <h3
                        style={{
                            textAlign: 'center',
                            width: '100%',
                            marginBottom: '1rem',
                        }}
                        className="smallheader"
                    >
                        {SectionSubtitle}
                    </h3>
                </Box>

                <div className="skills-container-Boarding">
                    {formErrors.max && (
                        <p className="error-message">{formErrors.max}</p>
                    )}
                    <div className="skills-list default">
                        {defaultSkills.map((skillName) => {
                            const skill = SoftSkillsTable.find(
                                (s) => s.soft_lv2 === skillName
                            )

                            return (
                                <div
                                    key={skillName}
                                    className={`skill-item ${
                                        mostraEffettiveSoftSkills.some(
                                            (selectedSkill) =>
                                                selectedSkill.soft_lv2 ===
                                                    skill.soft_lv2 &&
                                                selectedSkill.soft_lv1 ===
                                                    skill.soft_lv1 &&
                                                selectedSkill.checked
                                        )
                                            ? 'selected'
                                            : ''
                                    }`}
                                    onClick={() => handleSkillClick(skill)}
                                >
                                    {skillName}
                                </div>
                            )
                        })}
                    </div>

                    <button
                        onClick={() => setShowAllSkills((prev) => !prev)}
                        className="alto-btn"
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                            outline: 'none',
                            color: '#4b4c55',
                            fontweight: '600',
                            gap: '0',
                            display: 'flex',
                        }}
                    >
                        Altro
                        {}
                        {showAllSkills ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </button>

                    {showAllSkills && (
                        <div className="all-skills">
                            {Object.keys(filteredSkillCategories).map(
                                (category) => {
                                    const skillsInCategory =
                                        filteredSkillCategories[category]

                                    const filteredSkills =
                                        skillsInCategory.filter(
                                            (skillName) =>
                                                !defaultSkills.includes(
                                                    skillName
                                                )
                                        )

                                    if (filteredSkills.length === 0) return null

                                    return (
                                        <div
                                            key={category}
                                            className="skills-category"
                                        >
                                            <h3>{category}</h3>
                                            <div className="skills-list">
                                                {filteredSkills.map(
                                                    (skillName) => {
                                                        const skill =
                                                            SoftSkillsTable.find(
                                                                (s) =>
                                                                    s.soft_lv2 ===
                                                                    skillName
                                                            )

                                                        return (
                                                            <div
                                                                key={skillName}
                                                                className={`skill-item ${
                                                                    mostraEffettiveSoftSkills.some(
                                                                        (
                                                                            selectedSkill
                                                                        ) =>
                                                                            selectedSkill.soft_lv2 ===
                                                                                skill.soft_lv2 &&
                                                                            selectedSkill.soft_lv1 ===
                                                                                skill.soft_lv1 &&
                                                                            selectedSkill.checked
                                                                    )
                                                                        ? 'selected'
                                                                        : ''
                                                                }`}
                                                                onClick={() =>
                                                                    handleSkillClick(
                                                                        skill
                                                                    )
                                                                }
                                                            >
                                                                {skillName}
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingSoftSkills
