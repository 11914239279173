import PreloginForm from '../components/LoginComponents/PreloginForm'
import Page404 from '../components/Homepage/Page404'
import GeolocationDenied from '../components/Homepage/GeolocationDenied'

const welcomeRoutes = [
    {
        path: '/welcome',
        component: PreloginForm,
    },
    {
        path: '/404',
        component: Page404,
    },
    {
        path: '/geolocalizzazione-negata',
        component: GeolocationDenied,
    },
]

export default welcomeRoutes
