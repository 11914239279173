import React, { useEffect, useRef, useState } from 'react'
// import Slider from '@material-ui/core/Slider'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import ApiService from 'app/ApiService'
import LanguagesTable from '../../Tables/languages_table'
// import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from 'app/components/Icons/EditIcon'
import EditFieldDialog from './FooterProfile/EditField'

function LanguageSkills({
    languagelist,
    candidateguid,
    getLanguageList,
    loading,
}) {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const openPopover = Boolean(anchorEl)
    const skillsPopover = open ? 'simple-popover' : undefined
    const [dialogText, setDialogText] = useState('Salva')
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [languageList, setLanguageList] = useState([])
    const [langMappedList, setLangMappedList] = useState([])
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [showUnsavedDialog, setShowUnsavedDialog] = useState(false)
    const [backuplansMappedList, setBackuplanMappedList] = useState([])

    const [inputValue, setInputValue] = useState('')
    const descriptionElementRef = useRef(null)

    const initUserInfo = {
        candidate_guid: candidateguid || '',
        skill_name: '',
        score: 6,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)

    const { candidate_guid, skill_name, score, created, lastUpdate } = userInfo

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleClickOpen = () => () => {
        setDialogText('Salva')
        setOpen(true)
        setBackuplanMappedList([...langMappedList])
        setUserInfo({
            ...userInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = () => {
        if (unsavedChanges) {
            setShowUnsavedDialog(true)
        } else {
            closeDialog()
        }
    }
    const closeDialog = () => {
        setOpen(false)
        // resetValue(SoftSDataTree)
        setUserInfo(initUserInfo)
        setFormErrors({})
        setUnsavedChanges(false)
    }
    const handleDialogConfirm = (confirm) => {
        if (confirm) {
            setLangMappedList(backuplansMappedList)
            closeDialog()
        }
        setShowUnsavedDialog(false)
    }
    const handleAddSkill = async () => {
        const updatedArray = languageList.map((skill) => {
            if (
                langMappedList.some(
                    (otherSkill) => skill.skill_name === otherSkill
                )
            ) {
                return {
                    id: skill.id,
                    candidate_guid: candidate_guid,
                    score: skill.score,
                    checked: true,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            } else {
                return {
                    id: skill.id,
                    candidate_guid: candidate_guid,
                    score: skill.score,
                    checked: false,
                    skill_name: skill.skill_name,
                    lastUpdate: new Date(),
                }
            }
        })

        langMappedList.forEach((skillName) => {
            if (!languageList.some((skill) => skill.skill_name === skillName)) {
                updatedArray.push({
                    candidate_guid: candidateguid,
                    skill_name: skillName,
                    score: 6,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        const res = await ApiService.create_multiplecandLang(updatedArray)
        if (res.ok) {
            await getLanguageList()
        }
        closeDialog()
    }

    const handleDelete = (id) => async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candLang(id)
        if (res.ok) getLanguageList()
    }

    const handleSubmit = async () => {
        const errors = validate()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            await handleAddSkill()
            closeDialog()
        }
    }

    const onSelect = (event, value) => {
        if (!value || value === '') return
        setUnsavedChanges(true)

        setLangMappedList((prevList) => {
            const isAlreadySelected = prevList.includes(value)

            if (isAlreadySelected) {
                return prevList.filter((skill) => skill !== value)
            } else {
                return [...prevList, value]
            }
        })

        setInputValue('')
    }

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}

        // if (!duplicatedSkill && !skill_name) {
        //     errors.skill_name = 'Questo campo non può essere nullo'
        //     setDuplicatedSkill(false)
        // } else if (duplicatedSkill) {
        //     errors.skill_name = 'Questa voce è già stata inserita'
        // }

        if (langMappedList?.length <= 0) {
            errors.skill_name = 'Questo campo non può essere nullo'
            // setDuplicatedSkill(false)
            // } else if (duplicatedSkill) {
            //     errors.skill_name = 'Questa voce è già stata inserita'
        }
        // else if (langMappedList?.length > 12) {
        //     errors.skill_name = 'Non puoi aggiungere più di 12 competenze'
        // }

        return errors
    }

    useEffect(() => {
        if (Array.isArray(languagelist)) {
            setLanguageList(languagelist)
            setLangMappedList(languagelist.map((x) => x.skill_name))
        }
    }, [languagelist])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Salva') handleAddSkill()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        //     setLanguageList(...languageList, languageList)
        // }, [languageList])
        setLanguageList(languagelist)
        setLangMappedList(languagelist.map((x) => x.skill_name))
    }, [languagelist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    return (
        <>
            {/*<h2 className="tab-lan-skills">Ecco cosa sai fare.</h2>*/}
            <div className="skills-container">
                <div className="section-title">
                    <div className="d-flex popoverSkills">
                        <h2>In quali lingue sei in grado di comunicare?</h2>
                        <div className="popover">
                            <p>
                                Aggiungine altre che ti senti a tuo agio a
                                parlare in un ambiente di lavoro.
                            </p>
                            <Popover
                                id={skillsPopover}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <p>Competenze linguistiche</p>
                                <p>
                                    lingue straniere che conosci, ti consigliamo
                                    di specificare anche la tua lingua madre
                                    come può essere l'italiano
                                </p>
                            </Popover>
                        </div>
                    </div>

                    <div className="d-flex align-center">
                        <button
                            className="icon-btn"
                            hidden={open}
                            onClick={handleClickOpen('paper')}
                        >
                            <EditIcon />
                        </button>
                    </div>
                </div>

                {!loading && languageList.length <= 0 && !open && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Inserisci le tue competenze tecniche per
                                    avere più successo
                                </p>
                                <button
                                    onClick={handleClickOpen('paper')}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3 className="m0">Seleziona una o più lingue</h3>
                            <button
                                onClick={handleClose}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="skill-container tags-container smallwidth">
                            {/* {languageList.map((skill, index) => { */}

                            {loading ? (
                                <div className="new-box">
                                    <div className="d-flex align-center tags">
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                        <div className="d-flex align-center tags">
                                            <Skeleton
                                                variant="rect"
                                                width={100}
                                                height={30}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                langMappedList.map((skill, index) => {
                                    return (
                                        <div key={index} className="new-box">
                                            <div className="d-flex align-center tags TagWhite">
                                                <p>
                                                    {' '}
                                                    {skill}
                                                    {/* <span> - {valueLabelFormat(skill[2])} </span>  */}
                                                </p>
                                                <button
                                                    className="icon-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onSelect(e, skill)
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </button>
                                            </div>
                                            {/* <Slider
                                            defaultValue={1}
                                            getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            value={skill[2]}
                                            step={1}
                                            marks
                                            min={0}
                                            max={6}
                                            disabled
                                        /> */}
                                        </div>
                                    )
                                })
                            )}
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end NoGap">
                            <div>
                                {/* <h2>Lingua</h2> */}
                                {formErrors.skill_name && (
                                    <p className="input-error">
                                        {formErrors.skill_name}
                                    </p>
                                )}
                                <Autocomplete
                                    id="grouped-demo"
                                    className="select-arr"
                                    options={LanguagesTable.filter(
                                        (option) =>
                                            !langMappedList.includes(option) && // Exclude already selected languages
                                            option
                                                .toLowerCase()
                                                .includes(
                                                    inputValue.toLowerCase()
                                                ) // Manual filtering based on input
                                    )}
                                    getOptionLabel={(option) => option}
                                    inputValue={inputValue} // Control inputValue
                                    value={skill_name || null} // Selected value
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue) // Update inputValue on change
                                    }}
                                    onChange={(event, value) =>
                                        onSelect(event, value)
                                    } // Handle option selection
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Altre lingue"
                                        />
                                    )}
                                />

                                {/*
                              <Autocomplete
                                    multiple
                                    className="select-arr"
                                    limitTags={4}
                                    id="grouped-demo"
                                    filterSelectedOptions
                                    disableCloseOnSelect
                                    noOptionsText={'Nessun risultato'}
                                    options={LanguagesTable}
                                    getOptionLabel={(option) => option}
                                    value={langMappedList || null}
                                    onChange={onSelect}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={index}
                                                label={option}
                                                className="custom-tag"
                                                {...getTagProps({ index })}
                                                style={{
                                                    padding: '0.3rem 0.5rem',
                                                    backgroundColor: '#f0f0f3',
                                                    borderRadius: '0.65rem',
                                                    marginRight: '0.5rem',
                                                    fontSize: '0.9375rem',
                                                    marginBottom: '4px',
                                                    marginTop: '8px',
                                                }}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            className="no-outline"
                                            label="Aggiungi Competenze"
                                            placeholder="Digita qui *"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    minWidth: 200,
                                                },
                                            }}
                                        />
                                    )}
                                />*/}
                                {/* <div className='media-margin'>
                                 <h2 className='m1'>Autovalutazione</h2>
                                 <p>Scorri la barra per valutare la tua competenza</p>
                                 <Slider
                                     value={score}
                                     name="score"
                                     onChange={handleChangeSlider}
                                     defaultValue={2}
                                     // getAriaValueText={valuetext}
                                     aria-labelledby="discrete-slider"
                                     valueLabelDisplay="auto"
                                     getAriaValueText={valueLabelFormat}
                                     valueLabelFormat={valueLabelFormat}
                                     step={1}
                                     min={0}
                                     max={6}
                                 />
                             </div> */}
                            </div>
                        </div>
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setSubmit(true)
                            }}
                            color="primary"
                            className="btn-like-mui"
                            id="wideSavebtn"
                        >
                            {dialogText}
                        </Button>
                    </div>
                )}
                {!open && (
                    <div className="skill-container tags-container">
                        {/* {languageList.map((skill, index) => { */}

                        {loading ? (
                            <div className="new-box">
                                <div className="d-flex align-center tags">
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            languageList.map((skill, index) => {
                                return (
                                    <div key={index} className="new-box">
                                        <div className="d-flex align-center tags">
                                            <p>
                                                {skill.skill_name}
                                                {/* <span> - {valueLabelFormat(skill[2])} </span>  */}
                                            </p>
                                            <button
                                                className="icon-btn"
                                                onClick={handleDelete(skill.id)}
                                            >
                                                <CloseIcon />
                                            </button>
                                        </div>
                                        {/* <Slider
                                            defaultValue={1}
                                            getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            value={skill[2]}
                                            step={1}
                                            marks
                                            min={0}
                                            max={6}
                                            disabled
                                        /> */}
                                    </div>
                                )
                            })
                        )}
                    </div>
                )}
            </div>
            {showUnsavedDialog && (
                <EditFieldDialog
                    open={showUnsavedDialog}
                    onClose={handleDialogConfirm}
                />
            )}
        </>
    )
}

export default LanguageSkills
