import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual'

export default class Container extends Component {
  constructor(props){
    super(props)
    this.state = { data: props.data }
  }

  // componentWillReceiveProps = (nextProps) => {
  //   if(!isEqual(nextProps.data, this.state.data)) {
  //     this.setState({ data: nextProps.data })
  //   }
  // }

  componentDidUpdate = (nextProps) => {
    if(!isEqual(nextProps.data, this.state.data)) {
      this.setState({data: nextProps.data })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data)
  }

  render() {
    const onChange = (currentNode, selectedNodes) => {
      this.props.onChange(currentNode, selectedNodes)
    }

    const { data, ...rest } = this.props
    return (
      <DropdownTreeSelect data={this.state.data} {...rest} onChange={onChange} keepTreeOnSearch/>
    )
  }
}
