import React from 'react'

function DislikeIcon() {
  return (
    <svg width="78" height="56" viewBox="0 0 78 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="76.557" height="56" fill="none"/>
        <path d="M73.5609 54.4138C68.709 30.6199 45.7453 18.6077 35.3067 17.3956V2.83594L3.68988 25.7692L35.3055 48.7024V33.6433C56.4324 34.8492 66.5782 46.8677 71.1325 55.3102C71.8489 56.637 73.8622 55.8935 73.5609 54.4138Z" fill="#5058ED"/>
    </svg>  
)
}

export default DislikeIcon