import React from 'react'

function LikeIcon() {
    return (
        <svg
            width="78"
            height="56"
            viewBox="0 0 78 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="76.557" height="56" fill="none" />
            <path
                d="M3.22052 51.5779C8.07241 27.784 31.0362 15.7718 41.4747 14.5597V0L73.0916 22.9332L41.4759 45.8665V30.8074C20.3491 32.0133 10.2032 44.0317 5.64891 52.4743C4.93252 53.8011 2.91927 53.0575 3.22052 51.5779Z"
                fill="#EA6C3A"
            />
        </svg>
    )
}

export default LikeIcon
