import React, { useEffect, useRef, useState } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    DialogContentText,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import AddIcon from 'app/components/Icons/AddIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Skeleton from '@material-ui/lab/Skeleton'

const certification_table = [
    'Certificazione',
    'Attestato di frequenza',
    'Corso di formazione',
    'Portfolio',
    'Abilitazione professionale',
    'Pubblicazione',
    'Altro',
]

function JobCardCertification({ certlist, offerguid, getCertList, appState }) {
    // per dialog
    const descriptionElementRef = useRef(null)
    // per dialog di conferma (vuoi eliminare definitivamente?)
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [certList, setCertList] = useState([])
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [titleObjects, setTitleObjects] = useState([])
    const initUserInfo = {
        offer_guid: offerguid || '',
        cert_type: '',
        cert_title: '',
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const { offer_guid, cert_type, cert_title, created, lastUpdate } = userInfo

    const handleClickOpen = () => () => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setTitleObjects(newTitleObjects)

        setDialogText('Aggiungi')
        setOpen(true)
        setUserInfo({
            ...initUserInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClickUpdateDialogOpen = (titleInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        setUserInfo({
            ...userInfo,
            id: titleInfo.id,
            cert_type: titleInfo.cert_type,
            cert_title: titleInfo.cert_title,
            // date_end: new Date(titleInfo[2]) || '',
            // description: titleInfo[3],
            lastUpdate: new Date(),
        })
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        resetValue(certification_table)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const handleAddTitle = async () => {
        const res = await ApiService.create_offerCert(userInfo)
        if (res.ok) getCertList()
        handleClose()
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.cancel_offerCert(userInfo.id)
        if (res.ok) getCertList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        const res = await ApiService.update_offerCert(userInfo)
        if (res.ok) getCertList()
        handleClose()
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    let getValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.key === val) {
                return obj
            }
            if (obj.children) {
                let result = getValue(obj.children, val)
                if (result) {
                    return result
                }
            }
        }
        return undefined
    }

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}
        if (!cert_type) errors.cert_type = 'Questo campo non può essere nullo'
        if (!cert_title) errors.cert_title = 'Questo campo non può essere nullo'
        return errors
    }

    const toggleOpen = (index) => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setTitleObjects(newTitleObjects)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddTitle()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        setCertList(certlist)

        const newTitleObjects = certlist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setTitleObjects(newTitleObjects)
    }, [certlist])

    const renderForm = (index) => {
        // console.log(index)
        return (
            <div className="dropdown-form">
                <div className="d-flex align-center m1">
                    <h3>Certificazione</h3>
                    <button
                        onClick={() => handleClose(index)}
                        className="close-card"
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className="dialog-layout m1">
                    <div>
                        <FormControl
                            variant="filled"
                            fullWidth
                            className="select-arr"
                        >
                            <InputLabel
                                required
                                htmlFor="filled-age-native-simple"
                            >
                                Scegli la tipologia
                            </InputLabel>
                            <Select
                                native
                                fullWidth
                                required
                                name="cert_type"
                                value={cert_type}
                                // onChange={onCertSelect}
                                onChange={onChange}
                                inputProps={{
                                    name: 'cert_type',
                                    className: 'smaller-text',
                                }}
                            >
                                <option hidden aria-label="None" value={null} />
                                {certification_table.map((title) => {
                                    return (
                                        <option key={title} value={title}>
                                            {title}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <p className="input-error">{formErrors.cert_type}</p>
                    </div>

                    <div className="hideOnMobile"></div>
                </div>

                <div>
                    <TextField
                        label="Titolo"
                        variant="filled"
                        name="cert_title"
                        value={cert_title}
                        onChange={onChange}
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        required
                        color="primary"
                        // multiline
                        // rows={2}
                    />
                    <p className="input-error">{formErrors.cert_title}</p>
                </div>

                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                >
                    {dialogText}
                </Button>
            </div>
        )
    }

    return (
        <>
            <section>
                <div className="">
                    <div className="section-title">
                        <h3>Certificazioni e Corsi</h3>
                        <button
                            className="icon-btn"
                            onClick={handleClickOpen()}
                        >
                            <AddIcon />
                        </button>
                    </div>

                    {open && renderForm()}
                    {!appState && certList.length <= 0 && !open && (
                        <div className="p1">
                            <div className="placeholder-card">
                                <div className="bg-el"></div>
                                <div className="d-flex flex-column justify-center align-center text-center h-100">
                                    <p className="accent-text-color">
                                        Inserisci una certificazione utile a
                                        svolgere il ruolo
                                    </p>
                                    <button
                                        onClick={handleClickOpen()}
                                        className="primary-text-color"
                                    >
                                        Inizia qui
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="box-preview">
                        {appState ? (
                            <div className="card-preview new-box">
                                <div
                                    className="section-lightGray"
                                    id="skeltonExperience"
                                >
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="50%"
                                            height="35px"
                                        />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="70%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="90%" />
                                    </div>

                                    <div>
                                        {' '}
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            certList.map((title, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="card-preview new-box"
                                    >
                                        {titleObjects[index].open ? (
                                            renderForm(index)
                                        ) : (
                                            <div className="required-card">
                                                <div className="d-flex actions-btns">
                                                    <button
                                                        onClick={handleClickUpdateDialogOpen(
                                                            title,
                                                            index
                                                        )}
                                                        className="icon-btn"
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                    <button
                                                        className="icon-btn"
                                                        onClick={() =>
                                                            handleClickOpenConfirm(
                                                                title.id
                                                            )
                                                        }
                                                    >
                                                        <BinIcon />
                                                    </button>
                                                </div>
                                                <div>
                                                    <h3 className="bold text-ellipsis-2">
                                                        {title.cert_type}
                                                    </h3>
                                                    <p className="text-ellipsis-2">
                                                        {title.cert_title}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </section>

            {/* DIALOG PER ELIMINARE LA CARD */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="primary">
                        Si, elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default JobCardCertification
