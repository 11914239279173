const IscedDataTree = [
    {
        "checked": false,
        "label": "Generic programmes and qualifications",
        "value": "00_Generic programmes and qualifications",
        "key": "00",
        "children": [
            {
                "checked": false,
                "label": "Generic programmes and qualifications not further defined",
                "value": "000_Generic programmes and qualifications not further defined",
                "key": "000",
                "children": [
                    {
                        "checked": false,
                        "label": "Generic programmes and qualifications not further defined",
                        "value": "0000_Generic programmes and qualifications not further defined",
                        "key": "0000"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Basic programmes and qualifications",
                "value": "001_Basic programmes and qualifications",
                "key": "001",
                "children": [
                    {
                        "checked": false,
                        "label": "Basic programmes and qualifications",
                        "value": "0011_Basic programmes and qualifications",
                        "key": "0011"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Literacy and numeracy",
                "value": "002_Literacy and numeracy",
                "key": "002",
                "children": [
                    {
                        "checked": false,
                        "label": "Literacy and numeracy",
                        "value": "0021_Literacy and numeracy",
                        "key": "0021"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Personal skills and development",
                "value": "003_Personal skills and development",
                "key": "003",
                "children": [
                    {
                        "checked": false,
                        "label": "Personal skills and development",
                        "value": "0031_Personal skills and development",
                        "key": "0031"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Generic programmes and qualifications not elsewhere classified",
                "value": "009_Generic programmes and qualifications not elsewhere classified",
                "key": "009",
                "children": [
                    {
                        "checked": false,
                        "label": "Generic programmes and qualifications not elsewhere classified",
                        "value": "0099_Generic programmes and qualifications not elsewhere classified",
                        "key": "0099"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Education",
        "value": "01_Education",
        "key": "01",
        "children": [
            {
                "checked": false,
                "label": "Education",
                "value": "011_Education",
                "key": "011",
                "children": [
                    {
                        "checked": false,
                        "label": "Education not further defined",
                        "value": "0110_Education not further defined",
                        "key": "0110"
                    },
                    {
                        "checked": false,
                        "label": "Education science",
                        "value": "0111_Education science",
                        "key": "0111"
                    },
                    {
                        "checked": false,
                        "label": "Training for pre-school teachers",
                        "value": "0112_Training for pre-school teachers",
                        "key": "0112"
                    },
                    {
                        "checked": false,
                        "label": "Teacher training without subject specialisation",
                        "value": "0113_Teacher training without subject specialisation",
                        "key": "0113"
                    },
                    {
                        "checked": false,
                        "label": "Teacher training with subject specialisation",
                        "value": "0114_Teacher training with subject specialisation",
                        "key": "0114"
                    },
                    {
                        "checked": false,
                        "label": "Education not elsewhere classified",
                        "value": "0119_Education not elsewhere classified",
                        "key": "0119"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes and qualifications involving education",
                "value": "018_Inter-disciplinary programmes and qualifications involving education",
                "key": "018",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving education",
                        "value": "0188_Inter-disciplinary programmes and qualifications involving education",
                        "key": "0188"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Arts and humanities",
        "value": "02_Arts and humanities",
        "key": "02",
        "children": [
            {
                "checked": false,
                "label": "Arts and humanities not further defined",
                "value": "020_Arts and humanities not further defined",
                "key": "020",
                "children": [
                    {
                        "checked": false,
                        "label": "Arts and humanities not further defined",
                        "value": "0200_Arts and humanities not further defined",
                        "key": "0200"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Arts",
                "value": "021_Arts",
                "key": "021",
                "children": [
                    {
                        "checked": false,
                        "label": "Arts not further defined",
                        "value": "0210_Arts not further defined",
                        "key": "0210"
                    },
                    {
                        "checked": false,
                        "label": "Audio-visual techniques and media production",
                        "value": "0211_Audio-visual techniques and media production",
                        "key": "0211"
                    },
                    {
                        "checked": false,
                        "label": "Fashion, interior and industrial design",
                        "value": "0212_Fashion, interior and industrial design",
                        "key": "0212"
                    },
                    {
                        "checked": false,
                        "label": "Fine arts",
                        "value": "0213_Fine arts",
                        "key": "0213"
                    },
                    {
                        "checked": false,
                        "label": "Handicrafts",
                        "value": "0214_Handicrafts",
                        "key": "0214"
                    },
                    {
                        "checked": false,
                        "label": "Music and performing arts",
                        "value": "0215_Music and performing arts",
                        "key": "0215"
                    },
                    {
                        "checked": false,
                        "label": "Arts not elsewhere classified",
                        "value": "0219_Arts not elsewhere classified",
                        "key": "0219"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Humanities (except languages)",
                "value": "022_Humanities (except languages)",
                "key": "022",
                "children": [
                    {
                        "checked": false,
                        "label": "Humanities (except languages) not further defined",
                        "value": "0220_Humanities (except languages) not further defined",
                        "key": "0220"
                    },
                    {
                        "checked": false,
                        "label": "Religion and theology",
                        "value": "0221_Religion and theology",
                        "key": "0221"
                    },
                    {
                        "checked": false,
                        "label": "History and archaeology",
                        "value": "0222_History and archaeology",
                        "key": "0222"
                    },
                    {
                        "checked": false,
                        "label": "Philosophy and ethics",
                        "value": "0223_Philosophy and ethics",
                        "key": "0223"
                    },
                    {
                        "checked": false,
                        "label": "Humanities (except languages) not elsewhere classified",
                        "value": "0229_Humanities (except languages) not elsewhere classified",
                        "key": "0229"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Languages",
                "value": "023_Languages",
                "key": "023",
                "children": [
                    {
                        "checked": false,
                        "label": "Languages not further defined",
                        "value": "0230_Languages not further defined",
                        "key": "0230"
                    },
                    {
                        "checked": false,
                        "label": "Language acquisition",
                        "value": "0231_Language acquisition",
                        "key": "0231"
                    },
                    {
                        "checked": false,
                        "label": "Literature and linguistics",
                        "value": "0232_Literature and linguistics",
                        "key": "0232"
                    },
                    {
                        "checked": false,
                        "label": "Languages not elsewhere classified",
                        "value": "0239_Languages not elsewhere classified",
                        "key": "0239"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes\nand qualifications involving arts and humanities",
                "value": "028_Inter-disciplinary programmes\nand qualifications involving arts and humanities",
                "key": "028",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving arts and humanities",
                        "value": "0288_Inter-disciplinary programmes and qualifications involving arts and humanities",
                        "key": "0288"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Arts and humanities not\nelsewhere classified",
                "value": "029_Arts and humanities not\nelsewhere classified",
                "key": "029",
                "children": [
                    {
                        "checked": false,
                        "label": "Arts and humanities not elsewhere classified",
                        "value": "0299_Arts and humanities not elsewhere classified",
                        "key": "0299"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Social sciences, journalism and information",
        "value": "03_Social sciences, journalism and information",
        "key": "03",
        "children": [
            {
                "checked": false,
                "label": "Social sciences, journalism and\ninformation not further defined",
                "value": "030_Social sciences, journalism and\ninformation not further defined",
                "key": "030",
                "children": [
                    {
                        "checked": false,
                        "label": "Social sciences, journalism and information not further defined",
                        "value": "0300_Social sciences, journalism and information not further defined",
                        "key": "0300"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Social and behavioural sciences",
                "value": "031_Social and behavioural sciences",
                "key": "031",
                "children": [
                    {
                        "checked": false,
                        "label": "Social and behavioural sciences not further defined",
                        "value": "0310_Social and behavioural sciences not further defined",
                        "key": "0310"
                    },
                    {
                        "checked": false,
                        "label": "Economics",
                        "value": "0311_Economics",
                        "key": "0311"
                    },
                    {
                        "checked": false,
                        "label": "Psychology",
                        "value": "0313_Psychology",
                        "key": "0313"
                    },
                    {
                        "checked": false,
                        "label": "Sociology and cultural studies",
                        "value": "0314_Sociology and cultural studies",
                        "key": "0314"
                    },
                    {
                        "checked": false,
                        "label": "Social and behavioural sciences not elsewhere classified",
                        "value": "0319_Social and behavioural sciences not elsewhere classified",
                        "key": "0319"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Journalism and information",
                "value": "032_Journalism and information",
                "key": "032",
                "children": [
                    {
                        "checked": false,
                        "label": "Journalism and information not further defined",
                        "value": "0320_Journalism and information not further defined",
                        "key": "0320"
                    },
                    {
                        "checked": false,
                        "label": "Journalism and reporting",
                        "value": "0321_Journalism and reporting",
                        "key": "0321"
                    },
                    {
                        "checked": false,
                        "label": "Library, information and archival studies",
                        "value": "0322_Library, information and archival studies",
                        "key": "0322"
                    },
                    {
                        "checked": false,
                        "label": "Journalism and information not elsewhere classified",
                        "value": "0329_Journalism and information not elsewhere classified",
                        "key": "0329"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes\nand qualifications involving social sciences, journalism and information",
                "value": "038_Inter-disciplinary programmes\nand qualifications involving social sciences, journalism and information",
                "key": "038",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving social sciences, journalism and information",
                        "value": "0388_Inter-disciplinary programmes and qualifications involving social sciences, journalism and information",
                        "key": "0388"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Social sciences, journalism and\ninformation not elsewhere classified",
                "value": "039_Social sciences, journalism and\ninformation not elsewhere classified",
                "key": "039",
                "children": [
                    {
                        "checked": false,
                        "label": "Social sciences, journalism and information not elsewhere classified",
                        "value": "0399_Social sciences, journalism and information not elsewhere classified",
                        "key": "0399"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Business, administration and law",
        "value": "04_Business, administration and law",
        "key": "04",
        "children": [
            {
                "checked": false,
                "label": "Business, administration and law not further defined",
                "value": "040_Business, administration and law not further defined",
                "key": "040",
                "children": [
                    {
                        "checked": false,
                        "label": "Business, administration and law not further defined",
                        "value": "0400_Business, administration and law not further defined",
                        "key": "0400"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Business and administration",
                "value": "041_Business and administration",
                "key": "041",
                "children": [
                    {
                        "checked": false,
                        "label": "Business and administration not further defined",
                        "value": "0410_Business and administration not further defined",
                        "key": "0410"
                    },
                    {
                        "checked": false,
                        "label": "Accounting and taxation",
                        "value": "0411_Accounting and taxation",
                        "key": "0411"
                    },
                    {
                        "checked": false,
                        "label": "Finance, banking and insurance",
                        "value": "0412_Finance, banking and insurance",
                        "key": "0412"
                    },
                    {
                        "checked": false,
                        "label": "Management and administration",
                        "value": "0413_Management and administration",
                        "key": "0413"
                    },
                    {
                        "checked": false,
                        "label": "Marketing and advertising",
                        "value": "0414_Marketing and advertising",
                        "key": "0414"
                    },
                    {
                        "checked": false,
                        "label": "Secretarial and office work",
                        "value": "0415_Secretarial and office work",
                        "key": "0415"
                    },
                    {
                        "checked": false,
                        "label": "Wholesale and retail sales",
                        "value": "0416_Wholesale and retail sales",
                        "key": "0416"
                    },
                    {
                        "checked": false,
                        "label": "Work skills",
                        "value": "0417_Work skills",
                        "key": "0417"
                    },
                    {
                        "checked": false,
                        "label": "Business and administration not elsewhere classified",
                        "value": "0419_Business and administration not elsewhere classified",
                        "key": "0419"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Law",
                "value": "042_Law",
                "key": "042",
                "children": [
                    {
                        "checked": false,
                        "label": "Law",
                        "value": "0421_Law",
                        "key": "0421"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes and qualifications involving business, administration and law",
                "value": "048_Inter-disciplinary programmes and qualifications involving business, administration and law",
                "key": "048",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving business, administration and law",
                        "value": "0488_Inter-disciplinary programmes and qualifications involving business, administration and law",
                        "key": "0488"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Business, administration and law not elsewhere classified",
                "value": "049_Business, administration and law not elsewhere classified",
                "key": "049",
                "children": [
                    {
                        "checked": false,
                        "label": "Business, administration and law not elsewhere classified",
                        "value": "0499_Business, administration and law not elsewhere classified",
                        "key": "0499"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Natural sciences, mathematics and statistics",
        "value": "05_Natural sciences, mathematics and statistics",
        "key": "05",
        "children": [
            {
                "checked": false,
                "label": "Natural sciences, mathematics\nand statistics not further defined",
                "value": "050_Natural sciences, mathematics\nand statistics not further defined",
                "key": "050",
                "children": [
                    {
                        "checked": false,
                        "label": "Natural sciences, mathematics and statistics not further defined",
                        "value": "0500_Natural sciences, mathematics and statistics not further defined",
                        "key": "0500"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Biological and related sciences",
                "value": "051_Biological and related sciences",
                "key": "051",
                "children": [
                    {
                        "checked": false,
                        "label": "Biological and related sciences not further defined",
                        "value": "0510_Biological and related sciences not further defined",
                        "key": "0510"
                    },
                    {
                        "checked": false,
                        "label": "Biology",
                        "value": "0511_Biology",
                        "key": "0511"
                    },
                    {
                        "checked": false,
                        "label": "Biochemistry",
                        "value": "0512_Biochemistry",
                        "key": "0512"
                    },
                    {
                        "checked": false,
                        "label": "Biological and related sciences not elsewhere classified",
                        "value": "0519_Biological and related sciences not elsewhere classified",
                        "key": "0519"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Environment",
                "value": "052_Environment",
                "key": "052",
                "children": [
                    {
                        "checked": false,
                        "label": "Environment not further defined 0521 Environmental sciences",
                        "value": "0520_Environment not further defined 0521 Environmental sciences",
                        "key": "0520"
                    },
                    {
                        "checked": false,
                        "label": "Natural environments and wildlife",
                        "value": "0522_Natural environments and wildlife",
                        "key": "0522"
                    },
                    {
                        "checked": false,
                        "label": "Environment not elsewhere classified",
                        "value": "0529_Environment not elsewhere classified",
                        "key": "0529"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Physical sciences",
                "value": "053_Physical sciences",
                "key": "053",
                "children": [
                    {
                        "checked": false,
                        "label": "Physical sciences not further defined",
                        "value": "0530_Physical sciences not further defined",
                        "key": "0530"
                    },
                    {
                        "checked": false,
                        "label": "Chemistry",
                        "value": "0531_Chemistry",
                        "key": "0531"
                    },
                    {
                        "checked": false,
                        "label": "Earth sciences",
                        "value": "0532_Earth sciences",
                        "key": "0532"
                    },
                    {
                        "checked": false,
                        "label": "Physics",
                        "value": "0533_Physics",
                        "key": "0533"
                    },
                    {
                        "checked": false,
                        "label": "Physical sciences not elsewhere classified",
                        "value": "0539_Physical sciences not elsewhere classified",
                        "key": "0539"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Mathematics and statistics",
                "value": "054_Mathematics and statistics",
                "key": "054",
                "children": [
                    {
                        "checked": false,
                        "label": "Mathematics and statistics not further defined",
                        "value": "0540_Mathematics and statistics not further defined",
                        "key": "0540"
                    },
                    {
                        "checked": false,
                        "label": "Mathematics",
                        "value": "0541_Mathematics",
                        "key": "0541"
                    },
                    {
                        "checked": false,
                        "label": "Statistics",
                        "value": "0542_Statistics",
                        "key": "0542"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes\nand qualifications involving natural sciences, mathematics and statistics",
                "value": "058_Inter-disciplinary programmes\nand qualifications involving natural sciences, mathematics and statistics",
                "key": "058",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics",
                        "value": "0588_Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics",
                        "key": "0588"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Natural sciences, mathematics and statistics not elsewhere classified",
                "value": "059_Natural sciences, mathematics and statistics not elsewhere classified",
                "key": "059",
                "children": [
                    {
                        "checked": false,
                        "label": "Natural sciences, mathematics and statistics not elsewhere classified",
                        "value": "0599_Natural sciences, mathematics and statistics not elsewhere classified",
                        "key": "0599"
                    }
                ]
            }
        ]
    },
    {
        "checked": false,
        "label": "Information and Communication Technologies (ICTs)",
        "value": "06_Information and Communication Technologies (ICTs)",
        "key": "06",
        "children": [
            {
                "checked": false,
                "label": "Information and Communication Technologies (ICTs)",
                "value": "061_Information and Communication Technologies (ICTs)",
                "key": "061",
                "children": [
                    {
                        "checked": false,
                        "label": "Information and Communication Technologies (ICTs) not further defined",
                        "value": "0610_Information and Communication Technologies (ICTs) not further defined",
                        "key": "0610"
                    },
                    {
                        "checked": false,
                        "label": "Computer use",
                        "value": "0611_Computer use",
                        "key": "0611"
                    },
                    {
                        "checked": false,
                        "label": "Database and network design and administration",
                        "value": "0612_Database and network design and administration",
                        "key": "0612"
                    },
                    {
                        "checked": false,
                        "label": "Software and applications development and analysis",
                        "value": "0613_Software and applications development and analysis",
                        "key": "0613"
                    },
                    {
                        "checked": false,
                        "label": "Information and Communication Technologies (ICTs) not elsewhere classified",
                        "value": "0619_Information and Communication Technologies (ICTs) not elsewhere classified",
                        "key": "0619"
                    }
                ]
            },
            {
                "checked": false,
                "label": "Inter-disciplinary programmes and qualifications involving\nInformation and Communication Technologies (ICTs)",
                "value": "068_Inter-disciplinary programmes and qualifications involving\nInformation and Communication Technologies (ICTs)",
                "key": "068",
                "children": [
                    {
                        "checked": false,
                        "label": "Inter-disciplinary programmes and qualifications involving Information and Communication Technologies (ICTs)",
                        "value": "0688_Inter-disciplinary programmes and qualifications involving Information and Communication Technologies (ICTs)",
                        "key": "0688"
                    }
                ]
            }
        ]
    }
]

export default IscedDataTree