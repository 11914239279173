import SidebarDashboard from 'app/Layouts/SidebarDashboard'
import React, { useState, useEffect } from 'react'
// import { useLocation } from 'react-router-dom';
// import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
// import CloseIcon from '../components/Icons/CloseIcon';
import { NavBottomFixed } from 'app/components/Homepage/NavBottomFixed'
import { useMediaQuery } from '@material-ui/core'
import useFirestore from 'app/hooks/useFirestore'
import useAuth from 'app/hooks/useAuth'

function DashboardLayout({ children }) {
    const [showlikeBadge, setShowlikeBadge] = useState(false)
    const [showmatchBadge, setShowmatchBadge] = useState(false)

    const { user } = useAuth()
    const {
        getUnreadedMessages,
        getNewMatches,
        getLikes,
        unreaded,
        matches,
        likes,
    } = useFirestore()

    const isMobile = useMediaQuery('(max-width: 500px)')

    useEffect(() => {
        //   if (user?.photoURL) setPhotoURL(user.photoURL)

        if (unreaded && unreaded?.length > 0) {
            const unreadedMex = unreaded.filter((x) => x.number > 0 && x.id)

            if (unreadedMex?.length > 0) {
                setShowmatchBadge(true)
                return
            }
        }

        if (matches && matches?.length > 0) {
            setShowmatchBadge(true)
            return
        }

        setShowmatchBadge(false)
    }, [unreaded, matches])

    useEffect(() => {
        if (likes && likes?.length > 0) {
            setShowlikeBadge(true)
        } else setShowlikeBadge(false)
    }, [likes])

    useEffect(() => {
        //   if (user?.photoURL) setPhotoURL(user.photoURL)
        if (user) {
            getUnreadedMessages(user?.uid)
            getNewMatches(user?.uid)
            getLikes(user?.uid)
        }
    }, [user])

    return (
        <>
            <div className="container-grid">
                <div className="bubble bubble1"></div>
                <div className=" bubble bubble2"></div>
                <div className="bubble bubble3"></div>
                <div className="bubble bubble4"></div>

                <SidebarDashboard
                    showlikeBadge={showlikeBadge}
                    showmatchBadge={showmatchBadge}
                />

                <div className="navbottomMobile">
                    <NavBottomFixed
                        showlikeBadge={showlikeBadge}
                        showmatchBadge={showmatchBadge}
                    />
                </div>

                <div className="page-content">
                    {children}
                    {/* <BottomNavigation sx={{width: '500px', position: 'absolute', bottom: 0 }}>
                  <BottomNavigationAction label="Recents" value="recents" />
                  <BottomNavigationAction label="Favorites" value="favorites" icon={<CloseIcon />}/>
                  <BottomNavigationAction label="Nearby" value="nearby"  />
                  <BottomNavigationAction label="Folder" value="folder"  />
              </BottomNavigation> */}
                </div>
            </div>
        </>
    )
}

export default DashboardLayout
