import React from 'react'

function ComebackIcon() {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28" cy="28" r="27" stroke="#5058ED" strokeWidth="2" />
            <path
                d="M29.7041 12.7848C25.2598 12.8269 21.0648 14.5984 17.8919 17.7714C16.0791 19.5841 14.7042 21.7833 13.8623 24.17L12.232 19.5992C12.0143 18.9885 11.3394 18.6797 10.7244 18.9092C10.1095 19.1387 9.78772 19.8197 10.0055 20.4304L12.9868 28.7898C13.1098 29.1339 13.3863 29.3981 13.7367 29.5048C14.0866 29.6109 14.4687 29.5477 14.7713 29.3329L22.1184 24.1167C22.1749 24.0764 22.2272 24.0321 22.2751 23.9843C22.6777 23.5816 22.7496 22.9417 22.4185 22.4687C22.0477 21.9395 21.3123 21.8192 20.7754 22.1998L15.8252 25.7142C16.4982 23.3542 17.7606 21.2111 19.5455 19.4261C25.1659 13.8057 34.2245 13.7188 39.738 19.2322C45.2515 24.7457 45.1645 33.8033 39.5442 39.4237C33.9238 45.044 24.8657 45.1315 19.3517 39.6175C18.8952 39.1609 18.1474 39.1681 17.682 39.6335C17.2166 40.0989 17.2089 40.8472 17.6655 41.3037C20.7781 44.4164 24.9404 46.1071 29.3851 46.0645C33.8299 46.0218 38.0254 44.2508 41.1984 41.0779C44.3713 37.9049 46.1423 33.7094 46.185 29.2646C46.2276 24.8199 44.5369 20.6576 41.4243 17.545C38.3116 14.4323 34.1488 12.7421 29.7041 12.7848Z"
                fill="#5058ED"
            />
        </svg>
    )
}

export default ComebackIcon
