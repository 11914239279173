import React from 'react'

const TroviamoUnLavoratoreIcon = (props) => {
    return (
        <svg
            className="svgjump"
            width={props.width ?? '50'}
            height={props.height ?? '50'}
            viewBox={props.viewBox ?? '2.5 0 78 78'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.641 2C21.4558 8.64445 19.6086 17.7147 29.7627 25.6998C29.7627 25.6998 39.3242 13.294 22.641 2Z"
                fill="white"
                stroke="rgb(75, 76, 85)"
                strokeWidth="3"
                className="activeClass"
            />
            <path
                d="M3 33.1424C5.67999 30.525 17.8841 21.6994 28.1396 31.1208C28.1396 31.1208 25.6892 35.6055 15.1827 36.0736C4.67633 36.5417 3 33.1424 3 33.1424Z"
                fill="white"
                stroke="rgb(75, 76, 85)"
                strokeWidth="3"
                className="activeClass"
            />
            <path
                d="M76.6516 14.2068C76.4322 13.7431 75.881 13.5406 75.4047 13.7271L33.6328 29.7833C33.0228 30.0178 32.8034 30.7692 33.1993 31.2861C44.132 45.7276 43.2169 57.9416 42.6122 67.976C42.5855 68.445 43.051 68.7807 43.4952 68.6155C48.6324 66.7344 53.8499 57.819 55.3429 51.7866C55.9957 49.1435 57.8365 46.9479 60.3784 45.9408C65.4674 43.9211 67.1156 43.3349 72.6595 41.0275C80.9112 37.5903 79.7981 20.852 76.6516 14.2068ZM46.7755 30.8331C48.916 29.9432 51.3829 30.9557 52.2712 33.0873L50.9227 33.6468C50.3394 32.256 48.7287 31.5952 47.332 32.176C45.9353 32.7569 45.2718 34.3609 45.8551 35.7518L44.5066 36.3113C43.6182 34.1744 44.635 31.7177 46.7755 30.8331Z"
                fill="white"
                stroke="rgb(75, 76, 85)"
                strokeWidth="3"
                className="activeClass"
            />
        </svg>
    )
}

export default TroviamoUnLavoratoreIcon
