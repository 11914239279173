import React from 'react'

function KangratsLogo({ width, height, cssClasses = null }) {
    return (
        <svg
            className={cssClasses}
            width={width}
            height={height}
            viewBox="0 0 265 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <rect  width={width} height={height} fill="none"/> */}
            <path
                d="M102.65 40.2176V49.0591H96.5645V19.3267H102.65V36.382L108.539 27.8171H115.842L108.224 38.0631L116.702 49.0538H109.281L102.65 40.2176Z"
                fill="#EA6C3A"
            />
            <path
                d="M126.557 33.5255C124.005 33.5255 121.613 34.2703 119.729 35.1321L119.296 29.0675C121.143 28.0887 123.93 27.3066 127.187 27.3066C133.86 27.3066 136.727 30.3975 136.727 36.1056V49.0541H130.523V46.4739C128.954 48.3518 127.149 49.5648 124.005 49.5648C120.471 49.5648 117.919 47.7241 117.919 44.1651C117.919 39.6273 121.571 38.2974 126.124 37.5526C127.854 37.2387 128.794 37.0047 129.616 36.6908C130.283 36.4195 130.438 36.1056 130.438 35.5577C130.443 34.3075 129.189 33.5255 126.557 33.5255ZM127.577 40.803C125.537 41.2712 124.277 41.7819 124.277 43.5427C124.277 44.9525 125.457 45.6175 126.947 45.6175C128.676 45.6175 129.931 44.5588 130.518 43.2714V39.8242C129.893 40.138 128.874 40.5264 127.577 40.803Z"
                fill="#EA6C3A"
            />
            <path
                d="M140.806 27.8173H146.774V30.5943H146.929C148.264 28.5994 150.228 27.3066 153.287 27.3066C158.471 27.3066 161.141 30.8284 161.141 35.9886V49.0541H155.097V38.5315C155.097 35.6747 153.88 33.6798 151.013 33.6798C148.189 33.6798 146.892 35.6375 146.892 38.6059V49.0487H140.806V27.8173Z"
                fill="#EA6C3A"
            />
            <path
                d="M180.568 45.6121H180.413C179.196 47.4528 176.996 48.6657 174.172 48.6657C168.556 48.6657 164.317 44.8727 164.317 38.3772C164.317 31.3763 168.791 27.3066 174.172 27.3066C176.959 27.3066 179.196 28.4823 180.413 30.7113H180.568V27.8173H186.536V46.5963C186.536 53.2461 182.687 57.1987 175.662 57.1987C173.227 57.1987 171.54 57.0019 170.125 56.6135L170.595 51.6448C171.615 51.9161 172.869 52.0385 174.519 52.0385C178.758 52.0385 180.562 50.4745 180.562 47.2294V45.6121H180.568ZM170.718 38.6911C170.718 41.7819 172.757 43.814 175.469 43.814C178.256 43.814 180.295 41.8989 180.295 38.6911C180.295 35.6002 178.256 33.5255 175.469 33.5255C172.757 33.5255 170.718 35.68 170.718 38.6911Z"
                fill="#EA6C3A"
            />
            <path
                d="M191.09 27.8173H197.059V30.6315H197.214C198.313 28.3653 200.903 27.3066 204.357 27.3066L203.962 34.233C198.938 34.0362 197.171 36.0311 197.171 40.9626V49.0594H191.085V27.8173H191.09Z"
                fill="#EA6C3A"
            />
            <path
                d="M214.521 33.5255C211.97 33.5255 209.578 34.2703 207.693 35.1321L207.261 29.0675C209.108 28.0887 211.895 27.3066 215.151 27.3066C221.825 27.3066 224.692 30.3975 224.692 36.1056V49.0541H218.488V46.4739C216.918 48.3518 215.114 49.5648 211.97 49.5648C208.435 49.5648 205.884 47.7241 205.884 44.1651C205.884 39.6273 209.535 38.2974 214.089 37.5526C215.819 37.2387 216.758 37.0047 217.58 36.6908C218.248 36.4195 218.403 36.1056 218.403 35.5577C218.408 34.3075 217.153 33.5255 214.521 33.5255ZM215.541 40.803C213.502 41.2712 212.242 41.7819 212.242 43.5427C212.242 44.9525 213.422 45.6175 214.911 45.6175C216.641 45.6175 217.895 44.5588 218.483 43.2714V39.8242C217.858 40.138 216.838 40.5264 215.541 40.803Z"
                fill="#EA6C3A"
            />
            <path
                d="M230.457 33.605H227.158V27.817H230.927L231.594 22.46H236.538V27.817H242.111V33.605H236.538V40.7655C236.538 43.1115 237.6 43.9733 239.405 43.9733C240.505 43.9733 241.327 43.819 242.111 43.6222L242.544 49.0218C241.444 49.3729 239.794 49.5698 238.305 49.5698C232.299 49.5698 230.452 46.1651 230.452 41.1219V33.605H230.457Z"
                fill="#EA6C3A"
            />
            <path
                d="M244.984 43.4257C246.436 44.0109 249.068 44.5588 251.737 44.5588C253.856 44.5588 255.228 44.2077 255.228 43.032C255.228 42.0904 254.561 41.7393 250.99 40.9573C246.868 40.0582 244.471 38.5315 244.471 34.3873C244.471 30.3975 247.573 27.3066 253.579 27.3066C256.211 27.3066 258.528 27.8918 259.782 28.4398L259.35 34.5416C257.78 33.9937 255.661 33.5255 253.461 33.5255C251.379 33.5255 250.44 33.9192 250.44 34.8927C250.44 35.829 251.027 36.1429 254.444 36.7334C258.485 37.4781 261 39.122 261 42.7182C261 47.1389 257.935 49.5648 251.929 49.5648C248.79 49.5648 246.238 48.937 244.551 48.2348L244.984 43.4257Z"
                fill="#EA6C3A"
            />
            <path
                d="M22.641 2C21.4558 8.64445 19.6086 17.7147 29.7627 25.6998C29.7627 25.6998 39.3242 13.294 22.641 2Z"
                fill="#EA6C3A"
            />
            <path
                d="M3 33.1424C5.67999 30.525 17.8841 21.6994 28.1396 31.1208C28.1396 31.1208 25.6892 35.6055 15.1827 36.0736C4.67633 36.5417 3 33.1424 3 33.1424Z"
                fill="#EA6C3A"
            />
            <path
                d="M76.6516 14.2068C76.4322 13.7431 75.881 13.5406 75.4047 13.7271L33.6328 29.7833C33.0228 30.0178 32.8034 30.7692 33.1993 31.2861C44.132 45.7276 43.2169 57.9416 42.6122 67.976C42.5855 68.445 43.051 68.7807 43.4952 68.6155C48.6324 66.7344 53.8499 57.819 55.3429 51.7866C55.9957 49.1435 57.8365 46.9479 60.3784 45.9408C65.4674 43.9211 67.1156 43.3349 72.6595 41.0275C80.9112 37.5903 79.7981 20.852 76.6516 14.2068ZM46.7755 30.8331C48.916 29.9432 51.3829 30.9557 52.2712 33.0873L50.9227 33.6468C50.3394 32.256 48.7287 31.5952 47.332 32.176C45.9353 32.7569 45.2718 34.3609 45.8551 35.7518L44.5066 36.3113C43.6182 34.1744 44.635 31.7177 46.7755 30.8331Z"
                fill="#EA6C3A"
            />
        </svg>
    )
}

export default KangratsLogo
