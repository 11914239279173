import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './NavHome.scss'
import history from 'history.js'

const NavBarMobile = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenuHome = () => {
        setIsOpen((open) => !open)
    }

    return (
        <div className="homePage noborder">
            <Link to="/home" className="logo-link">
                <div className="navbar__logo"></div>
            </Link>
            {/* <li className="homePage__Trigger home" onClick={toggleMenuHome}>
                <FaBars />
            </li>
            <li>
                <button
                    className="login mobile"
                    onClick={() => history.push('/tutorial')}
                >
                    Accedi
                </button>
            </li>

            <nav className={`homePage__menuItems ${isOpen ? 'is-open' : ''}`}>
                <ul className="navbar__links">
                    <li className="link">
                        <Link to="/aboutus">Chi siamo</Link>
                    </li>
                    <li className="link">
                        <Link to="/dai-lavoro">Offro lavoro</Link>
                    </li>
                    <li className="link">
                        <Link to="/cerchi-lavoro">Cerchi lavoro</Link>
                    </li>
                </ul>
            </nav> */}
        </div>
    )
}

export default NavBarMobile
