const FormTitles = [
    'INIZIAMO DALLA FIGURA CHE CERCHI',
    'I REQUISITI DELLA FIGURA CHE CERCHI',
    'I REQUISITI DELLA FIGURA CHE CERCHI',
    'I REQUISITI DELLA FIGURA CHE CERCHI',
    'Competenze trasversali',
    // 'Competenze tecniche',
    'CARATTERISTICHE DEL CONTRATTO',
    '',
    'QUALCHE INFORMAZIONE SULL’AZIENDA',
    // "Raccontaci la tua azienda",
    'In quale o quali tra i seguenti settori opera l’azienda ',
    'Qualche info su di te',
    'Raccontaci chi sei',
    '',
]

export default FormTitles
