import React, { useRef, useState, useEffect } from 'react'
import ApiService from 'app/ApiService'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { format } from 'date-fns'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
// scroll orizzontale
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import Slide from '@material-ui/core/Slide'
import Closebtnblue from '../Icons/Closebtnblue'
import ShowMoreIcon from '../Icons/ShowMoreIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import PricingPage from './Pricing/ComingSoonPremPopUp'
import { CompleteFormCandidate } from './CompleteForm/CompleteFormCandidate'
import ItsMatch from './ProfileComponents/Match/ItsMach'

function LikesRecruiter() {
    const [hideCards, setHideCards] = useState(false)

    const [open, setOpen] = useState(false)
    const descriptionElementRef = useRef(null)
    const [offer_guid, setOfferGuid] = useState('')
    const [getCards, setGetCards] = useState(false)
    const [cands, setCandidates] = useState([])
    const [likesReceived, setLikesReceived] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('Like ricevuti')
    const [isPremium, setIsPremium] = useState(false)
    const [showPricing, setShowPricing] = useState(false)
    const [candidate_guid, setCandidateGuid] = useState('')
    const [imageSrc, setImageSrc] = useState('')
    const [sub, setSub] = useState(null)
    const [actions, setActions] = useState(false)
    const { getActiveSubscription, createMatch } = useFirestore()

    const { user } = useAuth()

    const getOfferGuid = async () => {
        try {
            const res = await ApiService.get_activeofferbyguid(user?.uid)
            setSub(res)
            if (res.ok) {
                const body = await res.json()
                // console.log(body)

                setOfferGuid(body?.offer_guid)
                setGetCards(true)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLikesSent = async () => {
        try {
            const response = await ApiService.get_likesSent(offer_guid)
            const body = await response.json()
            console.log(body)

            setCandidates(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLikesReceived = async () => {
        try {
            const response = await ApiService.get_likesReceived(offer_guid)
            const body = await response.json()
            console.log(body)

            setLikesReceived(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    // set last direction and decrease current index
    const swiped = async (direction) => {
        // console.log("direction ", direction)
        const res = await ApiService.update_swipe({
            offer_guid: offer_guid,
            candidate_guid: candidate_guid,
            direction: direction === 'right' ? 1 : 0,
        }) //add direction for swype result
        console.log(res)
        if (res.ok) {
            const body = await res.json()
            if (body?.entity?.match) {
                createMatch(offer_guid, candidate_guid, candidate_guid)
                // console.log('Candidato Matchato: ', body)

                // fa comparire dialog con animazione
                const image2show = likesReceived.find(
                    (x) => x.candidate_guid === candidate_guid
                )
                setImageSrc(
                    image2show &&
                        image2show.length > 0 &&
                        image2show.photo_url !== null &&
                        image2show.photo_url !== '' &&
                        image2show.photo_url !== 'None'
                        ? image2show.photo_url
                        : '/images/prof.png'
                )

                setHideCards(true)
            }
        }
        getLikes()
    }

    ///loading
    async function getLikes() {
        setLoading(true)
        const res = await getActiveSubscription(offer_guid)
        setSub(res)

        // await new Promise((resolve) => setTimeout(resolve, 5000))
        await getLikesReceived()

        // await new Promise((resolve) => setTimeout(resolve, 5000))
        await getLikesSent()

        setGetCards(false)
        setLoading(false)
    }

    useEffect(() => {
        if (getCards) getLikes()
    }, [getCards])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (user?.uid) getOfferGuid()
    }, [user])

    const SettingsSkeleton = {
        autoplay: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        draggable: false,
        swipe: false,
        touchMove: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1290,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1.6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        // centerMode: true,
        // centerPadding: '60px',
        // className: 'like-card-container',
        responsive: [
            {
                breakpoint: 1600, // define the breakpoint
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1290, // define the breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 800, // define another breakpoint
                settings: {
                    slidesToShow: 1.6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 650, // define another breakpoint
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550, // define another breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    const TabNavItem = ({ title, id, activeTab, setActiveTab }) => {
        const handleClick = () => {
            setActiveTab(id)
        }

        return (
            <li
                className={`tab-item ${activeTab === id ? 'active' : ''}`}
                onClick={handleClick}
            >
                {title}
            </li>
        )
    }
    const TabContent = ({ id, activeTab, children }) => {
        return activeTab === id ? (
            <div className="tab-content">{children}</div>
        ) : null
    }

    const getMessage = () => {
        if (
            (sub === null || !sub?.status === 'active') &&
            activeTab === 'Like ricevuti'
        ) {
            return (
                <>
                    <span style={{ color: '#4b4c55' }}>
                        Vuoi vedere a chi piaci?
                    </span>{' '}
                    <span
                        style={{
                            color: '#ea6c3a',
                            display: 'contents',
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowPricing(true)}
                    >
                        Passa a Premium.
                    </span>
                </>
            )
        } else {
            return activeTab === 'Like ricevuti' ? (
                <span style={{ color: '#4b4c55' }}>
                    Chi si è candidato alla tua offerta
                </span>
            ) : (
                <span style={{ color: '#4b4c55' }}>
                    I profili che ti interessano
                </span>
            )
        }
    }

    const handlePricingDisplay = (e) => {
        e.preventDefault()
        setShowPricing(true)
    }

    return (
        <div className={hideCards ? 'AnimationContainer' : 'container'}>
            <PricingPage open={showPricing} setOpen={setShowPricing} />
            {!hideCards && (
                <div className="Content-scroll-sticky">
                    <main
                        className="h-100 d-flex flex-column padding-inline  likes-page "
                        id="likeRecMain"
                    >
                        <div className="tab-section">
                            <ul className="tab-menu plan likes d-flex space-between">
                                <>
                                    <TabNavItem
                                        title="Like ricevuti"
                                        id="Like ricevuti"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="Like inviati"
                                        id="Like inviati"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </>
                            </ul>

                            {loading ? (
                                <div className="header-message">
                                    <Skeleton
                                        variant="text"
                                        width={500}
                                        height={50}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={400}
                                        height={50}
                                    />
                                </div>
                            ) : (
                                <div className="header-message">
                                    <h1 style={{ flexDirection: 'column' }}>
                                        {getMessage()}
                                    </h1>
                                </div>
                            )}

                            <div
                                className={`content-tab plan ${
                                    sub === null || !sub?.status === 'active'
                                        ? 'blurred'
                                        : ''
                                }`}
                            >
                                <TabContent
                                    id="Like ricevuti"
                                    activeTab={activeTab}
                                >
                                    <section className="m2">
                                        {/*<h1>I tuoi Like</h1>*/}
                                        <div>
                                            {/*<div className="section-title">
                                <h2>Like ricevuti</h2>
                            </div>*/}
                                            {loading ? (
                                                <Slider {...SettingsSkeleton}>
                                                    {[...Array(5)].map(
                                                        (_, index) => (
                                                            <div key={index}>
                                                                <div className="like-card d-flex flex-column space-between">
                                                                    <div className="d-flex overflow-hidden">
                                                                        <Skeleton
                                                                            variant="circle"
                                                                            width={
                                                                                100
                                                                            }
                                                                            height={
                                                                                100
                                                                            }
                                                                        />
                                                                        <div className="">
                                                                            <Skeleton
                                                                                variant="text"
                                                                                width={
                                                                                    100
                                                                                }
                                                                            />
                                                                            <Skeleton
                                                                                variant="text"
                                                                                width={
                                                                                    150
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        disabled
                                                                    >
                                                                        <Skeleton
                                                                            variant="text"
                                                                            width={
                                                                                200
                                                                            }
                                                                        />
                                                                    </button>
                                                                    <div className="clip-shape"></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </Slider>
                                            ) : (
                                                <div className="grid-container">
                                                    {likesReceived?.map(
                                                        (character, index) => (
                                                            <div
                                                                key={index}
                                                                className="grid-item"
                                                            >
                                                                <div className="like-card d-flex flex-column space-between">
                                                                    <div className="d-flex overflow-hidden">
                                                                        <img
                                                                            src={
                                                                                character.photo_url !==
                                                                                    'None' &&
                                                                                character.photo_url !==
                                                                                    '' &&
                                                                                character.photo_url !==
                                                                                    null
                                                                                    ? character.photo_url
                                                                                    : '/images/prof.png'
                                                                            }
                                                                            alt="immagine profilo"
                                                                        />
                                                                        <div>
                                                                            <h3>
                                                                                {
                                                                                    character.name
                                                                                }
                                                                            </h3>
                                                                            <h4 className="text-ellipsis-2">
                                                                                {
                                                                                    character.summary
                                                                                }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                sub ===
                                                                                    null ||
                                                                                !sub?.status ===
                                                                                    'active'
                                                                            )
                                                                                setShowPricing(
                                                                                    true
                                                                                )
                                                                            else {
                                                                                e.preventDefault()
                                                                                setActions(
                                                                                    true
                                                                                )
                                                                                setCandidateGuid(
                                                                                    character.candidate_guid
                                                                                )
                                                                                setOpen(
                                                                                    true
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        PROFILO
                                                                        COMPLETO
                                                                        &nbsp;
                                                                        <ShowMoreIcon />
                                                                    </button>
                                                                    <div className="clip-shape"></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {!loading &&
                                                likesReceived.length === 0 && (
                                                    <div className="placeholder-card">
                                                        <div className="bg-el"></div>
                                                        <div className="d-flex flex-column justify-center align-center text-center h-100">
                                                            <p className="accent-text-color">
                                                                Non hai ancora
                                                                ricevuto nessun
                                                                Like
                                                            </p>
                                                            <Link to="/jump">
                                                                Troviamo un
                                                                match!
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </section>
                                </TabContent>
                            </div>
                            <TabContent id="Like inviati" activeTab={activeTab}>
                                <section className="m2">
                                    <div>
                                        {/*<div className="section-title">
                                                    <h2>Like inviati</h2>
                                                </div>*/}
                                        {loading ? (
                                            <Slider {...SettingsSkeleton}>
                                                {Array.from({
                                                    length: 3,
                                                }).map((_, index) => (
                                                    <div key={index}>
                                                        <div className="like-card d-flex flex-column space-between">
                                                            <div className="d-flex overflow-hidden">
                                                                <Skeleton
                                                                    variant="circle"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div className="">
                                                                    <Skeleton
                                                                        variant="text"
                                                                        width={
                                                                            100
                                                                        }
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        width={
                                                                            150
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button disabled>
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            </button>
                                                            <div className="clip-shape"></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        ) : (
                                            <div className="grid-container">
                                                {cands.map(
                                                    (character, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="grid-item"
                                                            >
                                                                <div className="like-card d-flex flex-column space-between">
                                                                    <div className="d-flex overflow-hidden">
                                                                        <img
                                                                            src={
                                                                                character.photo_url !==
                                                                                    'None' &&
                                                                                character.photo_url !==
                                                                                    '' &&
                                                                                character.photo_url !==
                                                                                    null
                                                                                    ? character.photo_url
                                                                                    : '/images/prof.png'
                                                                            }
                                                                            alt="immagine profilo"
                                                                        />
                                                                        <div>
                                                                            <h3>
                                                                                {
                                                                                    character.name
                                                                                }
                                                                            </h3>
                                                                            <h4 className="text-ellipsis-2">
                                                                                {
                                                                                    character.summary
                                                                                }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !sub ||
                                                                                sub.status !==
                                                                                    'active'
                                                                            ) {
                                                                                setShowPricing(
                                                                                    true
                                                                                )
                                                                            } else {
                                                                                setActions(
                                                                                    false
                                                                                )
                                                                                setCandidateGuid(
                                                                                    character.candidate_guid
                                                                                )
                                                                                setOpen(
                                                                                    true
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        PROFILO
                                                                        COMPLETO
                                                                        &nbsp;
                                                                        <ShowMoreIcon />
                                                                    </button>
                                                                    <div className="clip-shape"></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )}

                                        {!loading && cands?.length <= 0 && (
                                            <div className="placeholder-card">
                                                <div className="bg-el"></div>
                                                <div className="d-flex flex-column justify-center align-center text-center h-100">
                                                    <p className="accent-text-color">
                                                        Non hai ancora inviato
                                                        nessun Like
                                                    </p>
                                                    <Link to="/jump-recruiter">
                                                        Troviamo un match!
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </TabContent>
                        </div>

                        <CompleteFormCandidate
                            guid={candidate_guid}
                            swiped={swiped}
                            open={open}
                            setOpen={setOpen}
                            actions={actions}
                        />
                    </main>
                </div>
            )}
            {hideCards && (
                <ItsMatch imageSrc={imageSrc} setOpen={setHideCards} />
            )}
        </div>
    )
}

export default LikesRecruiter
