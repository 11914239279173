import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import KangratsLogo from '../Icons/KangratsLogoNoName'

const LoadingAnimation = () => {
    return (
        <motion.div
            // initial={{ scale: 0, opacity: 0 }} // Initial scale and opacity
            animate={{
                scale: [0.5, 1, 0.5],
                // opacity:  opacity: [1, 0.5, 1] ,
            }} // Target scale and opacity
            transition={{
                duration: 5,
                repeat: Infinity,
                times: [0, 1],
                type: 'keyframes',
                ease: 'easeInOut',
            }} // Animation duration in seconds
            // style={{
            //     position: 'absolute', // Ensures the element is centered within its parent
            //     top: '50%',
            //     left: '50%',
            //     transform: 'translate(-50%, -50%)', // Centering trick
            // }}
        >
            <KangratsLogo
                width={300}
                height={300}
                cssClasses={'logo-sidebar'}
            />
        </motion.div>
    )
}

export default LoadingAnimation
