import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import EffectTinder from './effect-tinder.esm.js'
import './effect-tinder.scss'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper.scss'

const SwiperContainer = forwardRef(
    ({ onTinderSwipe, handleBack, children }, ref) => {
        const swiperRef = useRef(null)

        useImperativeHandle(ref, () => ({
            swiperInstance: swiperRef.current.swiper,
        }))

        return (
            <Swiper
                ref={swiperRef}
                className="swiper-container"
                grabCursor={true}
                // speed={300}
                // longSwipes={true}
                modules={[Autoplay, Navigation, Pagination, EffectTinder]}
                oneWayMovement={true}
                effect="tinder"
                onTinderSwipe={(s, direction) => {
                    onTinderSwipe(direction, s, swiperRef.current.swiper) // -> will be "0" or "1"
                }}
                onInit={(swiper) => {
                    // Attach handleBack function to swiper.params
                    swiper.params.handleBack = handleBack
                }}
            >
                {children}
            </Swiper>
        )
    }
)

export { SwiperSlide }
export default SwiperContainer
