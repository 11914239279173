import React, { useState, useRef, useEffect } from 'react'
import { Button, InputLabel } from '@material-ui/core'
import LikesIcon from '../Icons/LikesIcon'
import MatchIcon from '../Icons/MatchIcon'
import TroviamoUnLavoratoreIcon from '../Icons/TroviamoUnLavoratoreIcon'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import useFirestore from 'app/hooks/useFirestore'
import useAuth from 'app/hooks/useAuth'

function NotificationSetting() {
    const [switchState, setSwitchState] = useState({
        inAppLike: true,
        emailLike: true,
        inAppMatch: true,
        emailMatch: true,
        inAppMsg: true,
        emailMsg: true,
        newsLetter: true,
    })

    const notificationIcons = {
        Like: <LikesIcon />,
        Match: <TroviamoUnLavoratoreIcon />,
        Messaggi: <MatchIcon />,
        Newsletter: <StarBorderRoundedIcon />,
    }

    const { user } = useAuth()
    const { getNotificationPreferences, setNotificationPreferences } =
        useFirestore()

    const getData = async () => {
        const prf = await getNotificationPreferences(user?.uid)
        if (prf) setSwitchState(prf)
    }

    const setPref = async () => {
        const prf = await setNotificationPreferences(user?.uid, switchState)
    }

    const handleSwitchChange = (key, value) => {
        setSwitchState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    useEffect(() => {
        if (user !== null) {
            getData()
        }
    }, [user])

    return (
        <div className="notification-setting-page">
            <h2>Impostazioni notifiche</h2>
            {['Like', 'Match', 'Messaggi', 'Newsletter'].map((item, idx) => {
                const inAppKey =
                    item === 'Messaggi'
                        ? 'inAppMsg'
                        : item === 'Newsletter'
                        ? 'newsLetter'
                        : `inApp${item}`
                const emailKey =
                    item === 'Messaggi'
                        ? 'emailMsg'
                        : item === 'Newsletter'
                        ? 'newsLetter'
                        : `email${item}`

                return (
                    <div className="notification-row" key={idx}>
                        <div className="notification-icon">
                            {notificationIcons[item]}
                            <h3>{item}</h3>
                        </div>
                        <div className="notification-options">
                            {/* In-App Notifications */}
                            {item !== 'Newsletter' && (
                                <div className="switch-row">
                                    <div className="switch-label">In App</div>
                                    <div className="switchOnBoarding">
                                        <div
                                            onClick={() =>
                                                handleSwitchChange(
                                                    inAppKey,
                                                    true
                                                )
                                            }
                                            className={`switchOption ${
                                                switchState[inAppKey] === true
                                                    ? 'active'
                                                    : 'inactive'
                                            }`}
                                        >
                                            Si
                                        </div>
                                        <div
                                            onClick={() =>
                                                handleSwitchChange(
                                                    inAppKey,
                                                    false
                                                )
                                            }
                                            className={`switchOption ${
                                                switchState[inAppKey] === false
                                                    ? 'active'
                                                    : 'inactive'
                                            }`}
                                        >
                                            No
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="switch-row">
                                <div className="switch-label">Emails</div>
                                <div className="switchOnBoarding">
                                    <div
                                        onClick={() =>
                                            handleSwitchChange(emailKey, true)
                                        }
                                        className={`switchOption ${
                                            switchState[emailKey] === true
                                                ? 'active'
                                                : 'inactive'
                                        }`}
                                    >
                                        Si
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleSwitchChange(emailKey, false)
                                        }
                                        className={`switchOption ${
                                            switchState[emailKey] === false
                                                ? 'active'
                                                : 'inactive'
                                        }`}
                                    >
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div
                className="button-group"
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '2rem',
                    marginTop: '2rem',
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => setPref()}
                    className="btn-primary"
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    Salva
                </Button>
            </div>
        </div>
    )
}

export default NotificationSetting
