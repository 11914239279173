import React, {
    useState,
    useEffect,
    useLayoutEffect,
    Fragment,
    useRef,
} from 'react'
import ApiService from 'app/ApiService'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import DateFnsUtils from '@date-io/date-fns'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField as TextFieldMUI,
    Typography,
    FormGroup,
} from '@material-ui/core'
// phone mui
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import validator from 'validator'
import PlaceAutocomplete from 'app/components/PlaceAutocomplete/TomTomPlaceAutocomplete'
import PrevIcon from 'app/components/Icons/PrevIcon'
import FormTitles from '../../Tables/cand_form_titles'
import Popover from '@material-ui/core/Popover'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import KangratsLogo from 'app/components/Icons/KangratsLogo'

const patenteData = {
    firstGroup: ['Auto/Moto'],
    secondGroup: ['Camion', 'Gru'],
    thirdGroup: ['Autobus', 'Muletto'],
    fourthGroup: ['Rimorchio', 'Nautica'],
}

function SignupCandidateSteps() {
    const initVal = 5
    const [stepSignup, setStepSignup] = useState(initVal)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    // per progress bar
    const [width, setWidth] = useState('0%')
    // finish code popover
    const [open, setOpen] = useState(false)
    const [userInfo, setUserInfo] = useState({
        candidate_guid: '',
        name: '',
        surname: '',
        phone: '',
        gender: '',
        birth_date: '',
        email: '',
        location: '',
        driving_license: [],
        ral: 800,
        ral_type: 'true',
        protected_category: '',
        created: new Date(),
        lastUpdate: new Date(),
    })

    const {
        candidate_guid,
        name,
        surname,
        phone,
        gender,
        birth_date,
        email,
        location,
        geolocation,
        driving_license,
        ral,
        ral_type,
        protected_category,
        created,
        lastUpdate,
    } = userInfo
    const [minSalary, setMinSalary] = useState(0)
    const [maxSalary, setMaxSalary] = useState(5000)
    const [isDragging, setIsDragging] = useState(false)
    const radius = 45
    const circumference = 2 * Math.PI * radius
    const svgRef = useRef(null)
    const progress = ((ral - minSalary) / (maxSalary - minSalary)) * 100
    const strokeDashoffset = circumference - (progress / 100) * circumference
    const radToDeg = (rad) => (rad * 180) / Math.PI
    const {
        user,
        setUserRole,
        updateUserName,
        // user
    } = useAuth()

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        // console.log(userInfo)
    }

    const changeNum = (num) => {
        setUserInfo({
            ...userInfo,
            phone: num,
        })
    }
    const handleProtectedCategoryChange = (value) => {
        setUserInfo({
            ...userInfo,
            protected_category: value,
        })
    }
    const setGeoLocationInfo = (place, geolocation) => {
        console.log(geolocation)
        setUserInfo({
            ...userInfo,
            location: place,
            geolocation: geolocation,
        })
    }

    const handleDateChange = (date) => {
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }

        setUserInfo({
            ...userInfo,
            birth_date: date,
        })
    }
    const firstGroupLocations = [
        { label: 'Milano', geolocation: { lat: 45.463618, lon: 9.188116 } },
    ]
    const secondGroupLocations = [
        { label: 'Monza', geolocation: { lat: 45.583132, lon: 9.272965 } },
        { label: 'Sesto S.G.', geolocation: { lat: 45.535858, lon: 9.238889 } },
        {
            label: 'Cologno Monzese',
            geolocation: { lat: 45.529464, lon: 9.278397 },
        },
    ]
    const thirdGroupLocations = [
        { label: 'Rho', geolocation: { lat: 45.529783, lon: 9.038125 } },
        {
            label: 'Cinisiello Balsamo',
            geolocation: { lat: 45.554021, lon: 9.224253 },
        },
        { label: 'Segrate', geolocation: { lat: 45.501145, lon: 9.291694 } },
    ]

    const allLocations = [
        ...firstGroupLocations,
        ...secondGroupLocations,
        ...thirdGroupLocations,
    ]

    const handleRadiolocationChange = (event) => {
        const selectedLabel = event.target.value // Get the label (string) of the selected location
        console.log(selectedLabel)

        // Find the full location object based on the selected label
        const selectedLocation = allLocations.find(
            (loc) => loc.label === selectedLabel
        )

        // Update user info with the selected location and geolocation
        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            location: selectedLocation.label,
            geolocation: selectedLocation.geolocation,
        }))
    }

    const handleDrivingLicenseChange = (event) => {
        const { value } = event.target

        setUserInfo((prevUserInfo) => {
            const currentLicenses = prevUserInfo.driving_license || [] // Initialize as empty array if not present
            let updatedLicenses

            if (currentLicenses.includes(value)) {
                // If the value is already in the array, remove it
                updatedLicenses = currentLicenses.filter(
                    (license) => license !== value
                )
            } else {
                // If the value is not in the array, add it
                updatedLicenses = [...currentLicenses, value]
            }

            return {
                ...prevUserInfo,
                driving_license: updatedLicenses,
            }
        })
    }

    async function handleNext() {
        if (stepSignup === FormTitles.length - 1) await handleSubmit()
        else setStepSignup((prevActiveStep) => prevActiveStep + 1)
    }

    const handleSubmit = async () => {
        // event.preventDefault()
        const res = await ApiService.create_candidate(userInfo)
        // const res = await ApiService.add_user({
        //   "uid": userInfo.candidate_guid,
        //   "role": "candidate",
        //   "onboarded": "false"
        // })
        // console.log("result: ", result)
        if (res.ok) {
            // setUserRole("candidate", false)
            // history.push('/signup-completed')
            const response = await setUserRole('candidate', true)
            await updateUserName(name)
            if (response) history.push('/jump')
            else history.push('/404')
        }
    }

    const validate = () => {
        const errors = {}
        switch (stepSignup) {
            case initVal:
                if (!name) errors.name = 'Questo campo non può essere nullo'
                if (!surname)
                    errors.surname = 'Questo campo non può essere nullo'
                if (!birth_date)
                    errors.birth_date = 'Questo campo non può essere nullo'
                if (new Date(birth_date).toString() === 'Invalid Date')
                    errors.birth_date = 'Inserisci una data valida '
                // else if (birth_date && new Date(birth_date) > new Date())
                //     errors.birth_date =
                //         'La data di nascita non può essere successiva al giorno corrente'
                // else if (new Date().getFullYear() - new Date(birth_date).getFullYear() < 16) errors.birth_date = "Devi avere almeno 16 anni di età per iscriverti"
                else if (
                    birth_date &&
                    Math.floor(
                        (new Date().getTime() -
                            new Date(
                                birth_date.getFullYear(),
                                birth_date.getMonth(),
                                birth_date.getDate()
                            ).getTime()) /
                            31557600000
                    ) < 18
                )
                    errors.birth_date =
                        'Devi avere almeno 18 anni di età per iscriverti'
                else if (
                    birth_date &&
                    new Date().getFullYear() -
                        new Date(birth_date).getFullYear() >
                        100
                )
                    errors.birth_date = 'Inserisci una data valida'
                if (!gender) errors.gender = 'Questo campo non può essere nullo'
                if (!phone) errors.phone = 'Questo campo non può essere nullo'
                else if (!validator.isMobilePhone(phone))
                    errors.phone = 'Il formato di numero inserito non è valido'
                break

            case initVal + 1:
                if (!protected_category)
                    errors.protected_category =
                        'Questo campo non può essere nullo'
                break

            case initVal + 2:
                if (!location)
                    errors.location = 'Devi selezionare o aggiungere un luogo'
                break

            case initVal + 3:
                if (!ral)
                    errors.ral = 'Seleziona la Ral minima che accetteresti'
                break
        }
        return errors
    }

    const handleMouseDown = (event) => {
        setIsDragging(true)
        updateSlider(event)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const handleMouseMove = (event) => {
        if (isDragging) {
            updateSlider(event)
        }
    }
    const updateSlider = (event) => {
        const rect = svgRef.current.getBoundingClientRect()
        const centerX = rect.left + rect.width / 2
        const centerY = rect.top + rect.height / 2

        const clientX = event.clientX || event.touches[0].clientX
        const clientY = event.clientY || event.touches[0].clientY

        const angle = Math.atan2(clientY - centerY, clientX - centerX)
        const angleDeg = radToDeg(angle) + 90
        let newAngle = (angleDeg + 360) % 360
        const maxAngle = 320
        if (newAngle > maxAngle) {
            newAngle = maxAngle
        }
        const newSalary =
            minSalary + (maxSalary - minSalary) * (newAngle / maxAngle)

        setUserInfo({ ...userInfo, ral: Math.round(newSalary) })
    }
    const handleSalaryTypeChange = (type) => {
        if (type === 'false') {
            setMinSalary(0)
            setMaxSalary(100000)
            setUserInfo({ ...userInfo, ral_type: type, ral: 24000 })
        } else {
            setMinSalary(0)
            setMaxSalary(5000)
            setUserInfo({ ...userInfo, ral_type: type, ral: 800 })
        }
    }

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove)
            window.addEventListener('mouseup', handleMouseUp)
            window.addEventListener('touchmove', handleMouseMove)
            window.addEventListener('touchend', handleMouseUp)
        } else {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('touchend', handleMouseUp)
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('touchend', handleMouseUp)
        }
    }, [isDragging])

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) handleNext()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    //MODIFICARE IN SEGUITO
    useEffect(() => {
        if (user !== null)
            setUserInfo({
                ...userInfo,
                email: user.email,
                candidate_guid: user.uid,
            })
    }, [user])

    useEffect(() => {
        setWidth(`${(100 / 7) * (stepSignup - 1)}%`)
    }, [stepSignup])

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [stepSignup])

    return (
        <>
            <Fragment>
                {/* <div className="create-account-form-container"> */}

                {/* <progress id="file" value={100 / FormTitles.length * (stepSignup + 1)} max="100"></progress> */}
                {/* 
        <div className="progressbar">
          <div
            style={{ width: width, transition: "width 0.5s ease" }}
          ></div>
        </div> */}

                <div
                    className="onboarding-title tiliteSalary"
                    hidden={stepSignup === FormTitles.length - 1}
                >
                    <h1>{FormTitles[stepSignup]}</h1>
                    <div className="progressbar">
                        <div
                            style={{
                                width: width,
                                transition: 'width 0.5s ease',
                            }}
                        ></div>
                    </div>
                </div>

                {/* <form className="create-account-form p-10"> */}
                {/* <div className="form-title">
            <h1>{FormTitles[stepSignup]}</h1>
          </div> */}
                {/* <div className="form-body d-flex flex-column"> */}
                {/* {SignupStepDisplay()} */}
                <form>
                    <div className="onboarding-grid noMrg ">
                        {(() => {
                            switch (stepSignup) {
                                case initVal:
                                    return (
                                        <>
                                            <div className="input-container nogap">
                                                <TextFieldMUI
                                                    type="text"
                                                    label="Il tuo nome *"
                                                    name="name"
                                                    variant="filled"
                                                    fullWidth
                                                    color="primary"
                                                    onChange={onChange}
                                                    value={name}
                                                />
                                                <p className="input-error onboarding">
                                                    {formErrors.name}
                                                </p>
                                                <TextFieldMUI
                                                    type="text"
                                                    label="Il tuo cognome *"
                                                    variant="filled"
                                                    name="surname"
                                                    fullWidth
                                                    color="primary"
                                                    onChange={onChange}
                                                    value={surname}
                                                />
                                                <p className="input-error onboarding">
                                                    {formErrors.surname}
                                                </p>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                    locale={itLocale}
                                                >
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="Data di nascita"
                                                        format="dd/MM/yyyy"
                                                        value={
                                                            birth_date || null
                                                        }
                                                        onChange={
                                                            handleDateChange
                                                        }
                                                        KeyboardButtonProps={{
                                                            'aria-label':
                                                                'change date',
                                                        }}
                                                        inputVariant="standard"
                                                        fullWidth
                                                        autoOk
                                                        required
                                                        invalidDateMessage=""
                                                        maxDate={new Date()}
                                                        maxDateMessage=""
                                                        minDateMessage=""
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <p className="input-error onboarding">
                                                    {' '}
                                                    {formErrors.birth_date}{' '}
                                                </p>

                                                <div
                                                    id="mui-phone"
                                                    className="mui-phone onboarding"
                                                >
                                                    {/* <div className="input-select d-flex"> */}
                                                    <PhoneInput
                                                        country={'it'}
                                                        preferredCountries={[
                                                            'it',
                                                        ]}
                                                        value={phone}
                                                        onChange={changeNum}
                                                        placeholder={
                                                            'Recapito telefonico *'
                                                        }
                                                    />
                                                    {/*<div className="popover">
                                                        <button
                                                            aria-describedby={
                                                                skillsPopover
                                                            }
                                                            onClick={
                                                                handleClickPopover
                                                            }
                                                        >
                                                            ?
                                                        </button>
                                                        <Popover
                                                            id={skillsPopover}
                                                            open={openPopover}
                                                            anchorEl={anchorEl}
                                                            onClose={
                                                                handleClosePopover
                                                            }
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal:
                                                                    'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal:
                                                                    'left',
                                                            }}
                                                        >
                                                            <p>
                                                                Il tuo numero di
                                                                telefono non
                                                                verrà mostrato e
                                                                usato per fini
                                                                di marketing
                                                            </p>
                                                        </Popover>
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                                <p className="input-error onboarding">
                                                    {' '}
                                                    {formErrors.phone}{' '}
                                                </p>
                                                <FormControl
                                                    variant="filled"
                                                    fullWidth
                                                >
                                                    <InputLabel
                                                        required
                                                        htmlFor="gender"
                                                    >
                                                        Genere
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        fullWidth
                                                        // value={gender || ''}
                                                        name="gender"
                                                        value={gender}
                                                        onChange={onChange}
                                                        inputProps={{
                                                            name: 'gender',
                                                            id: 'gender',
                                                        }}
                                                    >
                                                        <option
                                                            hidden
                                                            aria-label="None"
                                                            value={null}
                                                        />
                                                        <option value="male">
                                                            Uomo
                                                        </option>
                                                        <option value="female">
                                                            Donna
                                                        </option>
                                                        <option value="other">
                                                            Preferisco non
                                                            specificare
                                                        </option>
                                                    </Select>
                                                    <p
                                                        className="input-error onboarding
                                                "
                                                    >
                                                        {' '}
                                                        {formErrors.gender}{' '}
                                                    </p>
                                                </FormControl>
                                            </div>
                                        </>
                                    )
                                case initVal + 1:
                                    return (
                                        <>
                                            <div className="input-container">
                                                <div
                                                    className="input-container location"
                                                    style={{ gap: '0.5rem' }}
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: '700',
                                                            textAlign: 'center',
                                                            marginBottom:
                                                                '0rem',
                                                            marginBottom:
                                                                '2rem',
                                                        }}
                                                        className="headerdriving"
                                                    >
                                                        Seleziona tutte le
                                                        patenti che hai ottenuto
                                                    </h3>
                                                    <FormControl
                                                        component="fieldset"
                                                        className="tags-check"
                                                    >
                                                        <FormGroup>
                                                            <div className="container-lanTag driving">
                                                                <div className="firstchoice driving">
                                                                    {patenteData.firstGroup.map(
                                                                        (
                                                                            license
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    license
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={driving_license.includes(
                                                                                            license
                                                                                        )}
                                                                                        onChange={
                                                                                            handleDrivingLicenseChange
                                                                                        }
                                                                                        value={
                                                                                            license
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    license
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>

                                                                <div className="secondchoice driving">
                                                                    {patenteData.secondGroup.map(
                                                                        (
                                                                            license
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    license
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={driving_license.includes(
                                                                                            license
                                                                                        )}
                                                                                        onChange={
                                                                                            handleDrivingLicenseChange
                                                                                        }
                                                                                        value={
                                                                                            license
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    license
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>

                                                                <div className="thirdchoice driving">
                                                                    {patenteData.thirdGroup.map(
                                                                        (
                                                                            license
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    license
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={driving_license.includes(
                                                                                            license
                                                                                        )}
                                                                                        onChange={
                                                                                            handleDrivingLicenseChange
                                                                                        }
                                                                                        value={
                                                                                            license
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    license
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>

                                                                <div className="fourthchoice driving">
                                                                    {patenteData.fourthGroup.map(
                                                                        (
                                                                            license
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    license
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={driving_license.includes(
                                                                                            license
                                                                                        )} // Ensure it reflects selection
                                                                                        onChange={
                                                                                            handleDrivingLicenseChange
                                                                                        } // Handle change
                                                                                        value={
                                                                                            license
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    license
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </FormControl>
                                                </div>
                                                <FormControl
                                                    component="fieldset"
                                                    className="radio-groups mt1 signup"
                                                >
                                                    <FormLabel
                                                        required
                                                        component="h3"
                                                        style={{
                                                            fontWeight: '700',
                                                            textAlign: 'center',
                                                            marginBottom:
                                                                '0rem',
                                                            marginTop: '2rem',
                                                        }}
                                                    >
                                                        Appartengo a una
                                                        categoria protetta?
                                                    </FormLabel>
                                                    <p className="input-error onboarding">
                                                        {
                                                            formErrors.protected_category
                                                        }
                                                    </p>
                                                    <div className="switchOnBoarding signup">
                                                        <div
                                                            onClick={() =>
                                                                handleProtectedCategoryChange(
                                                                    'true'
                                                                )
                                                            }
                                                            style={{
                                                                padding:
                                                                    '10px 20px',
                                                                border: '1px solid #ccc',
                                                                width: '6rem',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                backgroundColor:
                                                                    protected_category ===
                                                                    'true'
                                                                        ? '#ea6c3a'
                                                                        : 'transparent',
                                                                color:
                                                                    protected_category ===
                                                                    'true'
                                                                        ? 'white'
                                                                        : 'black',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            Si
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                handleProtectedCategoryChange(
                                                                    'false'
                                                                )
                                                            }
                                                            style={{
                                                                padding:
                                                                    '10px 20px',
                                                                border: '1px solid #ccc',
                                                                width: '6rem',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                backgroundColor:
                                                                    protected_category ===
                                                                    'false'
                                                                        ? '#ea6c3a'
                                                                        : 'transparent',
                                                                color:
                                                                    protected_category ===
                                                                    'false'
                                                                        ? 'white'
                                                                        : 'black',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            No
                                                        </div>
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </>
                                    )
                                case initVal + 2:
                                    return (
                                        <>
                                            <div className="input-container location">
                                                <FormControl
                                                    component="fieldset"
                                                    className="tags-check"
                                                >
                                                    <h3
                                                        className="m1"
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                        }}
                                                    >
                                                        DOVE VORRESTI CERCARE
                                                        LAVORO?
                                                    </h3>

                                                    <FormGroup>
                                                        <p className="input-error onboarding">
                                                            {
                                                                formErrors.location
                                                            }
                                                        </p>
                                                        <RadioGroup
                                                            name="location"
                                                            value={
                                                                location.split(
                                                                    ','
                                                                )[0]
                                                            }
                                                            onChange={
                                                                handleRadiolocationChange
                                                            }
                                                        >
                                                            <div className="container-lanTag location ">
                                                                <div className="firstchoice location">
                                                                    {firstGroupLocations.map(
                                                                        (
                                                                            loc
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    loc.label
                                                                                }
                                                                                control={
                                                                                    <Radio />
                                                                                }
                                                                                label={
                                                                                    loc.label
                                                                                }
                                                                                value={
                                                                                    loc.label
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>

                                                                <div className="secondchoice location">
                                                                    {secondGroupLocations.map(
                                                                        (
                                                                            loc
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    loc.label
                                                                                }
                                                                                control={
                                                                                    <Radio />
                                                                                }
                                                                                label={
                                                                                    loc.label
                                                                                }
                                                                                value={
                                                                                    loc.label
                                                                                } // Use the label as the value for the Radio button
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>

                                                                <div className="thirdchoice location">
                                                                    {thirdGroupLocations.map(
                                                                        (
                                                                            loc
                                                                        ) => (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    loc.label
                                                                                }
                                                                                control={
                                                                                    <Radio />
                                                                                }
                                                                                label={
                                                                                    loc.label
                                                                                }
                                                                                value={
                                                                                    loc.label
                                                                                } // Use the label as the value for the Radio button
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </RadioGroup>
                                                    </FormGroup>
                                                </FormControl>
                                            </div>

                                            <div className="input-container location2">
                                                <h3
                                                    className="m1"
                                                    style={{
                                                        fontWeight: '700',
                                                        textAlign: 'center',
                                                        marginBottom: '0rem',
                                                        marginTop: '2rem',
                                                        gap: '0rem',
                                                    }}
                                                >
                                                    Cerchi in altre località?
                                                </h3>
                                                <PlaceAutocomplete
                                                    className="input-onboarding"
                                                    setSetlected={
                                                        setGeoLocationInfo
                                                    }
                                                    defaultValue={
                                                        allLocations.filter(
                                                            (x) =>
                                                                x.label ==
                                                                location.split(
                                                                    ','
                                                                )[0]
                                                        ).length <= 0
                                                            ? location
                                                            : null
                                                    }
                                                    label={
                                                        'Luogo dove vorresti cercare lavoro'
                                                    }
                                                    isCandidate={true}
                                                />
                                            </div>
                                        </>
                                    )
                                case initVal + 3:
                                    return (
                                        <>
                                            <div className="input-container nomr">
                                                <h3
                                                    className=""
                                                    style={{
                                                        fontWeight: '700',
                                                        textAlign: 'center',
                                                        marginBottom: '0rem',
                                                        marginTop: '0rem',
                                                    }}
                                                >
                                                    QUAL È LA TUA ASPETTATIVA
                                                    SALARIALE?
                                                </h3>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <svg
                                                        ref={svgRef}
                                                        height={radius * 2 + 20}
                                                        width={radius * 2 + 20}
                                                        onMouseDown={
                                                            handleMouseDown
                                                        }
                                                        onTouchStart={
                                                            handleMouseDown
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <circle
                                                            stroke="#d3d3d3"
                                                            fill="transparent"
                                                            strokeWidth="12"
                                                            r={radius}
                                                            cx={radius + 10}
                                                            cy={radius + 10}
                                                        />
                                                        <circle
                                                            stroke="#ea6c3a"
                                                            fill="transparent"
                                                            strokeWidth="14"
                                                            strokeDasharray={
                                                                circumference
                                                            }
                                                            strokeDashoffset={
                                                                strokeDashoffset
                                                            }
                                                            strokeLinecap="round"
                                                            r={radius}
                                                            cx={radius + 10}
                                                            cy={radius + 10}
                                                            style={{
                                                                transition:
                                                                    'stroke-dashoffset 0.1s',
                                                                transform:
                                                                    'rotate(-90deg)',
                                                                transformOrigin:
                                                                    '50% 50%',
                                                            }}
                                                        />
                                                        <text
                                                            x="50%"
                                                            y="45%"
                                                            dominantBaseline="middle"
                                                            textAnchor="middle"
                                                            fontSize="16"
                                                            fontWeight="bold"
                                                            fill="#333"
                                                        >
                                                            Minimo
                                                        </text>
                                                        <text
                                                            x="50%"
                                                            y="60%"
                                                            dominantBaseline="middle"
                                                            textAnchor="middle"
                                                            fontSize="24"
                                                            fontWeight="bold"
                                                            fill="#333"
                                                        >
                                                            {new Intl.NumberFormat(
                                                                'de-DE'
                                                            ).format(ral)}
                                                            €
                                                        </text>
                                                    </svg>

                                                    <FormControl
                                                        component="fieldset"
                                                        className="radio-groups mt1 signup"
                                                    >
                                                        <FormLabel
                                                            required
                                                            component="h3"
                                                            style={{
                                                                fontWeight:
                                                                    '700',
                                                                textAlign:
                                                                    'center',
                                                                marginBottom:
                                                                    '0rem',
                                                                marginTop:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Preferisci parlare
                                                            di
                                                        </FormLabel>
                                                        <div className="switchOnBoarding signup">
                                                            <div
                                                                onClick={() =>
                                                                    handleSalaryTypeChange(
                                                                        'true'
                                                                    )
                                                                }
                                                                style={{
                                                                    padding:
                                                                        '10px 20px',
                                                                    border: '1px solid #ccc',
                                                                    width: '10rem',
                                                                    height: 'fit-content',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    backgroundColor:
                                                                        ral_type ===
                                                                        'true'
                                                                            ? '#ea6c3a'
                                                                            : 'transparent',
                                                                    color:
                                                                        ral_type ===
                                                                        'true'
                                                                            ? 'white'
                                                                            : 'black',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Mensile Netto
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleSalaryTypeChange(
                                                                        'false'
                                                                    )
                                                                }
                                                                style={{
                                                                    padding:
                                                                        '10px 20px',
                                                                    border: '1px solid #ccc',
                                                                    width: '10rem',
                                                                    height: 'fit-content',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    backgroundColor:
                                                                        ral_type ===
                                                                        'false'
                                                                            ? '#ea6c3a'
                                                                            : 'transparent',
                                                                    color:
                                                                        ral_type ===
                                                                        'false'
                                                                            ? 'white'
                                                                            : 'black',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Annuo Lordo
                                                            </div>
                                                        </div>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div
                                                className="input-container salarypart"
                                                style={{
                                                    marginTop: '3rem',
                                                    minWidth: 'max-content',
                                                    marginBottom: '2rem',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                <p style={{ display: 'flex' }}>
                                                    <CheckRoundedIcon /> Non
                                                    condividiamo queste
                                                    informazioni con le aziende.
                                                </p>
                                                <p style={{ display: 'flex' }}>
                                                    <CheckRoundedIcon /> Le
                                                    usiamo solo per filtrare le
                                                    offerte e farti risparmiare
                                                    tempo.
                                                </p>
                                                <p style={{ display: 'flex' }}>
                                                    <CheckRoundedIcon /> Se hai
                                                    dubbi, un importo più basso
                                                    non ti fa perdere buone
                                                    opportunità.
                                                </p>
                                            </div>
                                        </>
                                    )
                                case initVal + 4:
                                    return (
                                        <div
                                            className="signup-completed end"
                                            style={{
                                                /*width: '50vw'*/
                                                margin: 'auto',
                                                fontSize: '1rem',
                                                lineHeight: '1.5',
                                                padding: '2rem 1rem 0',
                                                borderRadius: '8px',
                                                maxWidth: '800px',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <KangratsLogo
                                                width={265}
                                                height={70}
                                                cssClasses={'logo-signup'}
                                            />
                                            <h1 className="accent-text-color m1">
                                                CE L’ABBIAMO FATTA!
                                            </h1>
                                            <p>
                                                Preparati a esplorare nuove
                                                opportunità di lavoro.
                                            </p>
                                            <p>
                                                Completa il tuo profilo per
                                                farti notare e aumentare le tue
                                                probabilità di successo.{' '}
                                            </p>
                                            <p>
                                                Con Kangrats, trovare lavoro non
                                                è più un lavoro!
                                            </p>
                                            <button
                                                onClick={async (event) => {
                                                    // history.push('/profile')
                                                    event.preventDefault()
                                                    // history.push('/jump') //on error return 404
                                                    setSubmit(true)
                                                }}
                                                className="btn primary-btn"
                                            >
                                                Iniziamo
                                            </button>
                                        </div>
                                    )
                                default:
                                    return <></>
                            }
                        })()}
                    </div>
                    {stepSignup !== FormTitles.length - 1 ? (
                        <div className="onboarding-actions">
                            <button
                                className="prev-btn"
                                disabled={stepSignup === initVal}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSubmit(false)
                                    setStepSignup((currStep) => currStep - 1)
                                }}
                            >
                                <PrevIcon />
                            </button>
                            <div>
                                <button
                                    className="next-button primary-btn btn"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSubmit(true)
                                    }}
                                >
                                    {/* {stepSignup === FormTitles.length - 1 ? "Invia" : "Continua"} */}
                                    Prosegui
                                </button>
                            </div>
                        </div>
                    ) : null}
                </form>
                {/* </div> */}
            </Fragment>
        </>
    )
}

export default SignupCandidateSteps
