import React from 'react'
import { Outlet } from 'react-router-dom'
import FooterIntro from './FooterIntro'
import HeaderIntro from './HeaderIntro'

const IntroLayout = ({ children }) => {
  return (
    <div>
          <div className="bubble bubble1"></div>
          <div className=" bubble bubble2"></div>
          <div className="bubble bubble3"></div>
          <div className="bubble bubble4"></div>

        <div className='introPage-background'>
          {/* <div className='overlay'></div> */}

            <HeaderIntro />
            {/* <Outlet /> */}
            <>{children}</>
            <FooterIntro />
        </div>
    </div>
  )
}

export default IntroLayout