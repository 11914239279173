import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from 'app/components/Icons/EditIcon'

const BioRecruiterPlaceholder = () => {
    return (
        <article className="profile-card section-lightGray">
            <div className="d-flex align-center">
                <div>
                    <Skeleton variant="circle">
                        <img alt="" className="profile-photo" />
                    </Skeleton>
                </div>
                <ul>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={110} />
                    <Skeleton variant="text" width={120} />
                    <Skeleton variant="text" width={130} />
                    <Skeleton variant="text" width={210} />
                </ul>
                <button className="icon-btn shift-icon">
                    <EditIcon />
                </button>
            </div>
        </article>
    )
}

export default BioRecruiterPlaceholder
