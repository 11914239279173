import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import ApiService from 'app/ApiService'
import SoftSkillsTable from '../../Tables/soft_skills_table'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import AlertDialog from './AlertDialog/AlertDialog'
import { TextField } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from 'app/components/Icons/EditIcon'
import Chip from '@material-ui/core/Chip'
import EditFieldDialog from './FooterProfile/EditField'

function SoftSkills({ sslist, candidateguid, getSsList, loading }) {
    // per dialog
    const [open, setOpen] = useState(false)
    // POPOVER
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClosePopover = () => {
        setAnchorEl(null)
    }
    const openPopover = Boolean(anchorEl)
    const skillsPopover = open ? 'simple-popover' : undefined
    const [dialogText, setDialogText] = useState('Salva')
    // const [appState, setAppState] = useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [ssList, setSsList] = useState([])
    const [ssMappedList, setSsMappedList] = useState([])
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [backupSsMappedList, setBackupSsMappedList] = useState([])

    const initUserInfo = {
        candidate_guid: candidateguid || '',
        skill_name: '',
        score: 5,
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)

    const { candidate_guid, skill_name, score, created, lastUpdate } = userInfo

    const handleClickOpen = () => () => {
        setDialogText('Salva')
        setOpen(true)
        setBackupSsMappedList(ssMappedList)
        setUserInfo({
            ...userInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClose = () => {
        setOpen(false)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const handleAddSkill = async () => {
        const otherSoftLv2Set = new Set(
            ssList.map((item) => JSON.parse(item.skill_name).soft_lv2)
        )

        const updatedArray = ssMappedList.map((skill) => {
            return {
                ...skill,
                candidate_guid: candidate_guid,
                score: 5,
                checked: otherSoftLv2Set.has(skill.soft_lv2),
                skill_name: {
                    soft_lv1: skill.soft_lv1,
                    soft_lv2: skill.soft_lv2,
                },
                created: new Date(),
                lastUpdate: new Date(),
            }
        })

        ssList.forEach((otherSkill) => {
            if (
                !ssMappedList.some(
                    (skill) =>
                        skill.soft_lv2 ===
                        JSON.parse(otherSkill.skill_name).soft_lv2
                )
            ) {
                updatedArray.push({
                    id: otherSkill.id,
                    candidate_guid: candidate_guid,
                    score: 5,
                    checked: false,
                    skill_name: {
                        soft_lv1: JSON.parse(otherSkill.skill_name).soft_lv1,
                        soft_lv2: JSON.parse(otherSkill.skill_name).soft_lv2,
                    },
                    // created: new Date(),
                    lastUpdate: new Date(),
                })
            }
        })

        const res = await ApiService.create_multiplecandss(updatedArray)
        // const res = await ApiService.create_candss(userInfo)
        if (res.ok) {
            await getSsList()
        }
        handleClose()
    }

    const handleDelete = (id) => async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candss(id)
        if (res.ok) getSsList()
    }

    const handleSubmit = async () => {
        const res = await ApiService.update_candss(userInfo)
        if (res.ok) getSsList()
        /* handleClose() */
    }

    const onSelect = (selectedSkill) => {
        setSsMappedList((prevList) => {
            const isAlreadySelected = prevList.some(
                (skill) =>
                    skill.soft_lv1 === selectedSkill.soft_lv1 &&
                    skill.soft_lv2 === selectedSkill.soft_lv2
            )

            if (isAlreadySelected) {
                return prevList.filter(
                    (skill) =>
                        skill.soft_lv1 !== selectedSkill.soft_lv1 ||
                        skill.soft_lv2 !== selectedSkill.soft_lv2
                )
            } else {
                return [...prevList, selectedSkill]
            }
        })
    }

    const descriptionElementRef = useRef(null)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    let resetValue = (arr) => {
        for (let obj of arr) {
            if (obj.checked) {
                obj.checked = false
            }
            if (obj.children) {
                let result = resetValue(obj.children)
                if (result) {
                    result.checked = false
                }
            }
        }
        return undefined
    }

    let findValue = (arr, val) => {
        for (let obj of arr) {
            if (obj.value === val) {
                obj.checked = true
            }
            if (obj.children) {
                let result = findValue(obj.children, val)
                if (result) {
                    result.checked = true
                }
            }
        }
        return undefined
    }

    const validate = () => {
        const errors = {}

        if (ssMappedList?.length <= 0) {
            errors.skill_name = 'Questo campo non può essere nullo'
            // setDuplicatedSkill(false)
            // } else if (duplicatedSkill) {
            //     errors.skill_name = 'Questa voce è già stata inserita'
        } else if (ssMappedList?.length > 12) {
            errors.skill_name = 'Non puoi aggiungere più di 12 competenze'
        }

        return errors
    }

    useEffect(() => {
        if (isSubmit) {
            const errors = validate()
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
                handleAddSkill()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        setSsList(sslist)
        setSsMappedList(
            sslist.map((x) => {
                let parsedData = JSON.parse(x.skill_name)
                return {
                    id: x.id,
                    ...parsedData,
                }
            })
        )
    }, [sslist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    const skillCategories = SoftSkillsTable.reduce((acc, skill) => {
        acc[skill.soft_lv1] = acc[skill.soft_lv1] || []
        acc[skill.soft_lv1].push(skill.soft_lv2)
        return acc
    }, {})

    const handleSkillSelect = (skill) => {
        setIsEdited(true)
        onSelect(skill)
    }

    const handleDialogCloseWithConfirm = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            setSsMappedList(backupSsMappedList)
            handleClose()
        }
    }

    return (
        <>
            <div className="skills-container">
                <div className="section-title">
                    <div className="popoverSkills">
                        <h2>Che cosa ti caratterizza al lavoro?</h2>
                        <p>
                            Seleziona fino a 10 delle seguenti caratteristiche.
                        </p>
                        <Popover
                            id={skillsPopover}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <p>
                                Abilità comportamentali e relazionali come
                                puntualità, lavoro di squadra, risoluzione dei
                                problemi etc.
                            </p>
                            <p>Consigliamo di aggiungere almeno 5 competenze</p>
                        </Popover>
                    </div>

                    <div className="d-flex align-center">
                        <button
                            className="icon-btn"
                            hidden={open}
                            onClick={handleClickOpen('paper')}
                        >
                            <EditIcon />
                        </button>
                    </div>
                </div>

                {!loading && ssList.length <= 0 && !open && (
                    <div className="p1">
                        <div className="placeholder-card">
                            <div className="bg-el"></div>
                            <div className="d-flex flex-column justify-center align-center text-center h-100">
                                <p className="accent-text-color">
                                    Fai clic su quelle che ti appartengono per
                                    farne apparire altre.
                                </p>
                                <button
                                    onClick={handleClickOpen('paper')}
                                    className="primary-text-color"
                                >
                                    Inizia qui
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {open && (
                    <div className="dropdown-form m1">
                        <div className="d-flex align-center m1">
                            <h3 className="m0">
                                Seleziona una o più competenze trasversali
                            </h3>
                            <button
                                onClick={handleDialogCloseWithConfirm}
                                className="close-card"
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="d-flex flex-column dialog-layout align-end skillsprof">
                            <div>
                                <p className="input-error">
                                    {formErrors.skill_name}
                                </p>

                                {/* Render skills grouped by category */}
                                {Object.keys(skillCategories).map(
                                    (category) => (
                                        <div
                                            key={category}
                                            className="skills-category-profile"
                                        >
                                            <h3 className="category-header">
                                                {category}
                                            </h3>
                                            <div className="skills-list-profile">
                                                {skillCategories[category].map(
                                                    (skillName) => {
                                                        const skill =
                                                            SoftSkillsTable.find(
                                                                (s) =>
                                                                    s.soft_lv1 ===
                                                                        category &&
                                                                    s.soft_lv2 ===
                                                                        skillName
                                                            )

                                                        const isSelected =
                                                            ssMappedList.some(
                                                                (
                                                                    selectedSkill
                                                                ) =>
                                                                    selectedSkill.soft_lv1 ===
                                                                        skill.soft_lv1 &&
                                                                    selectedSkill.soft_lv2 ===
                                                                        skill.soft_lv2
                                                            )

                                                        return (
                                                            <div className="tags-container">
                                                                <button
                                                                    key={
                                                                        skillName
                                                                    }
                                                                    className={`d-flex align-center tags TagWhite ${
                                                                        isSelected
                                                                            ? 'selected-skill'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleSkillSelect(
                                                                            skill
                                                                        )
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            color: '#4b4c55',
                                                                        }}
                                                                    >
                                                                        {
                                                                            skillName
                                                                        }
                                                                    </p>
                                                                    {isSelected && (
                                                                        <button
                                                                            className="icon-btn"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation()
                                                                                onSelect(
                                                                                    skill
                                                                                )
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </button>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSubmit(true)
                                }}
                                color="primary"
                                className="btn-like-mui"
                            >
                                {dialogText}
                            </Button>
                        </div>
                    </div>
                )}

                {!open && (
                    <div className="skill-container tags-container">
                        {loading ? (
                            <div className="new-box">
                                <div className="d-flex align-center tags">
                                    <div>
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                    <div className="d-flex align-center tags">
                                        <Skeleton
                                            variant="rect"
                                            width={100}
                                            height={30}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ssList.map((skill, index) => (
                                <div key={index} className="new-box">
                                    <div className="d-flex align-center tags">
                                        <p>
                                            {
                                                JSON.parse(skill.skill_name)
                                                    .soft_lv2
                                            }
                                        </p>
                                        <button
                                            className="icon-btn"
                                            onClick={handleDelete(skill.id)}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default SoftSkills
