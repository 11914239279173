import React from 'react'

function AddIcon() {

    const accentColor = '#5058ed' 

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8984 10.2046V29.5938" stroke={accentColor} strokeWidth="3"/>
        <path d="M10.2051 19.8994L29.5943 19.8994" stroke={accentColor} strokeWidth="3"/>
    </svg>
  )
}

export default AddIcon