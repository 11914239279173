import React from 'react'

function ShowMoreIcon() {

    const primaryColor = '#ea6c3a';

  return (
    <svg className='arrow-icon primaryColor' width="10" height="10" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={primaryColor} d="M2 18.6052L17.4521 3.15308" strokeWidth="4" />
        <path stroke={primaryColor} d="M4.30566 2H18.6046V14.9963" strokeWidth="4" />
    </svg>  )
}

export default ShowMoreIcon