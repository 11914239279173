import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { DialogContentText } from '@material-ui/core'

function EditFieldDialog({ open, onClose }) {
    const handleConfirm = () => {
        onClose(true)
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="form-dialog-title"
            className="small-dialog"
        >
            <DialogTitle id="form-dialog-title">Sicuro?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Confermi di voler uscire senza salvare le modifiche?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary">
                    Conferma
                </Button>
                <Button onClick={() => onClose(false)} color="secondary">
                    Annulla
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditFieldDialog
