import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { Badge, DialogContentText, Menu, MenuItem, TextField } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import DeleteMatch from './DeleteMatch';


const StyledMenu = withStyles({
  paper: {
    boxShadow: '0px 0px 13px 4px rgba(0,0,0,0.1)',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableScrollLock={true}
    {...props}
  />
));


// function KebabMenuChat({ anchorEl, setAnchorEl, handleOpenMenu, handleCloseMenu }) {
function KebabMenuChat({offer_guid, candidate_guid, setAppState, setOpenMessage}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);    
  };

  return (
    <>
      <Button className="align-self-start" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenu}  >
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19.9992" cy="9.5002" r="2.8" fill="#5058ED" />
          <circle cx="19.9992" cy="20.0002" r="2.8" fill="#5058ED" />
          <circle cx="19.9992" cy="30.5002" r="2.8" fill="#5058ED" />
        </svg>
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <DeleteMatch
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          offer_guid={offer_guid}
          candidate_guid={candidate_guid}
          setAppState={setAppState}
          setOpenMessage={setOpenMessage}
        />

      </StyledMenu>
    </>
  )
}

export default KebabMenuChat