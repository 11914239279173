import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import Pagestemplet from '../components/LandingPages/Pagestemplate'

const LandingRoutingGuard = ({ component: Component, ...rest }) => {
    const { pathname } = useLocation()
    // console.log(pathname)
    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return <Component {...props} />
                }}
            />
        </>
    )
}

export default LandingRoutingGuard
