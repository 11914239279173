import React, { createContext, useState } from 'react'
import useAuth from 'app/hooks/useAuth'
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable,
    deleteObject,
} from 'firebase/storage'
import { storage } from '../../config'

const StorageContext = createContext({
    method: 'Firebase',
    deletePhoto: () => Promise.resolve(),
    downloadPhoto: () => Promise.resolve(),
    uploadPhoto: () => Promise.resolve(),
    photoURL: '',
    setPhotoURL: () => {},
})

// Create the file metadata
/** @type {any} */
const metadata = {
    contentType: 'image/jpeg',
}

export const StorageProvider = ({ children }) => {
    const { user, updateUserPhoto } = useAuth()
    const defaultPhotoURL = 'images/prof.png'
    const [photoURL, setPhotoURL] = useState(user?.photoURL || defaultPhotoURL)
    const [fileUrl, setFileUrl] = useState('')
    const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null)

    const uploadPhoto = async (file) => {
        try {
            const storageRef = ref(storage, user.uid)

            const uploadTask = uploadBytesResumable(storageRef, file, metadata)

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused')
                            break
                        case 'running':
                            console.log('Upload is running')
                            break
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break
                        case 'storage/canceled':
                            // User canceled the upload
                            break
                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break
                    }
                    return false
                }
                // () => {
                //     // Upload completed successfully, now we can get the download URL
                //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                //         updateUserPhoto(downloadURL)
                //         console.log('File available at', downloadURL);
                //         return downloadURL;
                //     });
                // }
            )

            await uploadTask

            let downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            updateUserPhoto(downloadURL)
            // console.log('File available at', downloadURL);
            return downloadURL
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const downloadPhoto = async () => {
        // Create a reference to the file to delete
        const storageRef = ref(storage, user.id)

        // Get Url
        getDownloadURL(storageRef)
            .then((url) => {
                return url
            })
            .catch((error) => {
                console.log('Impossible get download url:', error.message)
                return false
            })
    }

    const deletePhoto = async () => {
        try {
            const storageRef = ref(storage, user.uid)
            await deleteObject(storageRef)
            updateUserPhoto(defaultPhotoURL)
            setPhotoURL(defaultPhotoURL)
            return true
        } catch (error) {
            console.error('Error deleting photo:', error)
            return false
        }
    }

    return (
        <StorageContext.Provider
            value={{
                method: 'Firebase',
                uploadPhoto,
                downloadPhoto,
                deletePhoto,
                photoURL,
                setPhotoURL,
                defaultPhotoURL,
            }}
        >
            {children}
        </StorageContext.Provider>
    )
}

export default StorageContext
