const title_type_onboarding = {
    show: [
        {
            label: 'Diploma o Licenza di Scuola Superiore',
            value: 4,
        },
        {
            label: 'Scuole Medie',
            value: 2,
            showMore: false,
        },
        {
            label: 'Laurea Triennale',
            value: 6,
        },
        {
            label: 'Laurea Magistrale o a Ciclo Unico/Vecchio Ordinamento',
            value: 7,
        },
    ],

    showMore: [
        {
            label: 'Scuole Elementari',
            value: 1,
        },
        {
            label: 'Attestato o Qualifica Operatore Professionale',
            value: 3,
        },
        {
            label: 'Diploma di Tecnico Superiore',
            value: 5,
        },
        {
            label: 'Master Universitario di Primo Livello',
            value: 4,
        },
        {
            label: 'Diploma accademico di Primo livello',
            value: 6,
        },
        {
            label: 'Master Universitario di Secondo livello',
            value: 8,
        },
        {
            label: 'Diploma Accademico di Secondo livello',
            value: 7,
        },
        {
            label: 'Diploma Accademico di formazione alla ricerca',
            value: 8,
        },
        {
            label: 'Diploma di Specializzazione',
            value: 9,
        },
        {
            label: 'Dottorato di Ricerca (PhD)',
            value: 10,
        },
    ].sort((a, b) => {
        const order = [
            'Scuole Elementari',
            'Attestato o Qualifica Operatore Professionale',
            'Diploma di Tecnico Superiore',
            'Master Universitario di Primo Livello',
            'Diploma accademico di Primo livello',
            'Master Universitario di Secondo livello',
            'Diploma Accademico di Secondo livello',
            'Diploma Accademico di formazione alla ricerca',
            'Diploma di Specializzazione',
            'Dottorato di Ricerca (PhD)',
        ]
        return order.indexOf(a.label) - order.indexOf(b.label)
    }),
}

export default title_type_onboarding
