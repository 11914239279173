import React, { useRef, useState, useEffect, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ApiService from 'app/ApiService'
import { format } from 'date-fns'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
import { Badge, TextField } from '@material-ui/core'
// scroll orizzontale
import Slider from 'react-slick'
import EmojiPickerMart from './ProfileComponents/Emoji/EmojiPickerMart'
import KebabMenuChat from './ProfileComponents/KebabMenuChat/KebabMenuChat.jsx'
import Closebtnblue from '../Icons/Closebtnblue'
import Skeleton from '@material-ui/lab/Skeleton'
import { CompleteFormCandidate } from './CompleteForm/CompleteFormCandidate'

import { Link } from 'react-router-dom'

function MatchPageRecruiter() {
    const [showEmoji, setShowEmoji] = useState(false)
    const [open, setOpen] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const descriptionElementRef = useRef(null)
    const [cands, setCandidates] = useState([])
    const [offer_guid, setOfferGuid] = useState('')
    const [getCards, setGetCards] = useState(false)
    const [appState, setAppState] = useState(false)
    const [appMessage, setAppMessage] = useState(false)
    const [message, setMessage] = useState('')
    const initUserInfo = {
        candidate_guid: '',
        name: '',
        surname: '',
        phone: '',
        gender: '',
        summary: '',
        birth_date: '',
        email: '',
        location: '',
        photo_url: '',
        description: '',
        driving_license: '',
        ral: '',
        ral_type: true,
        protected_category: 'false',
        job_contract: [],
        created: '',
        lastUpdate: '',
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const { candidate_guid, name, surname, summary } = userInfo

    const { user } = useAuth()
    const {
        messages,
        unreaded,
        sendMessage,
        getMessages,
        getUnreadedMessages,
        updateReadMessage,
    } = useFirestore()

    const getOfferGuid = async () => {
        try {
            const res = await ApiService.get_activeofferbyguid(user?.uid)
            if (res.ok) {
                const body = await res.json()
                // console.log(body)

                setOfferGuid(body?.offer_guid)
                getUnreadedMessages(body?.offer_guid)
                setGetCards(true)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const computeCandidate = async () => {
        try {
            const response = await ApiService.get_matches(offer_guid)
            const body = await response.json()
            console.log(body)

            setCandidates(body)
            setGetCards(false)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getUserInfo = async () => {
        const response = await ApiService.get_candidatebyId(candidate_guid)
        const body = await response.json()
        console.log(body)

        try {
            setUserInfo({
                ...userInfo,
                candidate_guid: body.candidate_guid,
                name: body.name,
                surname: body.surname,
                phone: body.phone,
                gender: body.gender,
                summary: body.summary,
                birth_date: new Date(body.birth_date),
                email: body.email,
                location: body.location,
                photo_url: body.photo_url,
                description: body.description,
                driving_license: body.driving_license,
                ral: body.ral,
                ral_type: body?.ral_type || false,
                protected_category:
                    body?.protected_category?.toString() || 'false',

                job_contract:
                    typeof body.job_contract === 'string'
                        ? JSON.parse(body.job_contract) || []
                        : [],

                created: body.created,
                lastUpdate: body.lastUpdate,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    async function getData() {
        await getUserInfo()
        setAppState(false)
    }

    const handleClickOpen = (event, scrollType, guid) => {
        event.preventDefault()
        setOpen(true)
        setScroll(scrollType)
        setAppState(true)
    }

    const handleClose = () => {
        setOpen(false)
        // setUserInfo(initUserInfo)
    }

    const onMessagingOpen = (event, guid) => {
        event.preventDefault()
        setOpenMessage(!openMessage)
        if (openMessage === false) {
            setUserInfo({ ...userInfo, candidate_guid: guid })
            setAppMessage(true)
        }
    }

    const divToScroll = useRef()
    const scrollToBottom = () => {
        if (messages && openMessage) {
            divToScroll.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const getMessagesbyguid = useCallback(async () => {
        await getUserInfo()
        await getMessages(offer_guid, candidate_guid)
        setAppMessage(false)
        scrollToBottom()
    }, [userInfo, user])

    const groupByDate = (msg) => {
        const groups = msg.reduce((groups, game) => {
            const date = game.timestamp.toDate().toLocaleDateString()
            if (!groups[date]) {
                groups[date] = []
            }
            groups[date].push(game)
            return groups
        }, {})

        return Object.keys(groups).map((date) => {
            return {
                date,
                msg: groups[date],
            }
        })
    }

    const sendMessage2Recruiter = (event) => {
        event.preventDefault()
        if (message) {
            sendMessage(
                offer_guid,
                candidate_guid,
                user.uid,
                candidate_guid,
                message,
                user.displayName
            )
            setMessage('')
            scrollToBottom()
        }
        if (showEmoji) setShowEmoji(false)
    }

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    useEffect(() => {
        if (getCards) computeCandidate()
    }, [getCards])

    useEffect(() => {
        if (appState) getData()

        if (messages) scrollToBottom()
    }, [appState])

    useEffect(() => {
        if (openMessage)
            updateReadMessage(offer_guid, candidate_guid, candidate_guid)

        if (messages) scrollToBottom()
    }, [messages])

    useEffect(() => {
        if (appMessage) getMessagesbyguid()
        if (messages) scrollToBottom()
    }, [appMessage])

    useEffect(() => {
        if (user?.uid) getOfferGuid()
        if (messages) scrollToBottom()
    }, [user])

    // per scroll orizzontale
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 2,
        arrows: true,
        centerMode: false,
        variableWidth: false,

        className: 'avatar-container',
        responsive: [
            {
                breakpoint: 1350, // define the breakpoint
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 950, // define another breakpoint
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // define another breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 400, // define another breakpoint
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <div className="Content-scroll-sticky">
            <div className="match-rec">
                <main className="match-page">
                    <section className="padding-inline">
                        <h1>Messaggi</h1>
                        <Slider {...settings}>
                            {getCards
                                ? Array.from({ length: 3 }).map((_, index) => (
                                      <div
                                          key={index}
                                          className="d-flex flex-column align-center chat-avatar"
                                      >
                                          <Badge
                                              badgeContent={0}
                                              color="default"
                                              anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                              }}
                                          >
                                              <Skeleton
                                                  variant="circle"
                                                  width={80}
                                                  height={80}
                                                  style={{
                                                      marginBottom: '1rem',
                                                  }}
                                              />
                                          </Badge>
                                          <div>
                                              <Skeleton
                                                  variant="text"
                                                  width={80}
                                              />
                                              <Skeleton
                                                  variant="text"
                                                  width={100}
                                              />
                                          </div>
                                      </div>
                                  ))
                                : cands.length > 0
                                ? cands.map((character, index) => {
                                      return (
                                          <div
                                              key={index}
                                              className="d-flex flex-column align-center chat-avatar"
                                          >
                                              <Badge
                                                  onClick={(e) =>
                                                      onMessagingOpen(
                                                          e,
                                                          character.candidate_guid
                                                      )
                                                  }
                                                  badgeContent={
                                                      unreaded
                                                          .filter(
                                                              (x) =>
                                                                  x.id ===
                                                                  character.candidate_guid
                                                          )
                                                          ?.sort(function (
                                                              a,
                                                              b
                                                          ) {
                                                              return (
                                                                  b.timestamp -
                                                                  a.timestamp
                                                              )
                                                          })[0]?.number
                                                  }
                                                  color={
                                                      unreaded
                                                          .filter(
                                                              (x) =>
                                                                  x.id ===
                                                                  character.candidate_guid
                                                          )
                                                          ?.sort(function (
                                                              a,
                                                              b
                                                          ) {
                                                              return (
                                                                  b.timestamp -
                                                                  a.timestamp
                                                              )
                                                          })[0]?.number > 0
                                                          ? 'secondary'
                                                          : 'default'
                                                  }
                                                  max={999}
                                                  anchorOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'left',
                                                  }}
                                              >
                                                  <img
                                                      src={
                                                          character.photo_url !==
                                                              null &&
                                                          character.photo_url !==
                                                              '' &&
                                                          character.photo_url !==
                                                              'None'
                                                              ? character.photo_url
                                                              : '/images/prof.png'
                                                      }
                                                      alt="Immagine profilo"
                                                  />
                                              </Badge>
                                              <div
                                                  onClick={(e) =>
                                                      onMessagingOpen(
                                                          e,
                                                          character.candidate_guid
                                                      )
                                                  }
                                              >
                                                  <p className="bold">
                                                      {character.name}{' '}
                                                      {character.surname}
                                                  </p>
                                                  <p>{character.summary}</p>
                                              </div>
                                          </div>
                                      )
                                  })
                                : null}
                        </Slider>

                        {!getCards && cands.length == 0 && (
                            <div className="m2">
                                <div className="placeholder-card">
                                    <div className="bg-el"></div>
                                    <div className="d-flex flex-column justify-center align-center text-center h-100">
                                        <p className="accent-text-color">
                                            Non hai ancora ricevuto nessun Like
                                        </p>
                                        <Link to="/jump">
                                            Troviamo un match!
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}

                        {openMessage && (
                            <div className="speech-wrapper d-flex flex-column h-100">
                                <div className="header-chat">
                                    <div className="d-flex space-between">
                                        <div className="d-flex flex-column">
                                            <h2>
                                                {name} {surname}
                                            </h2>
                                            <h3>{summary}</h3>
                                            <button
                                                className="primary-text-color align-self-start"
                                                onClick={(e) =>
                                                    handleClickOpen(e, 'paper')
                                                }
                                            >
                                                {' '}
                                                VEDI PROFILO COMPLETO &nbsp;
                                            </button>
                                        </div>
                                        <KebabMenuChat
                                            offer_guid={offer_guid}
                                            candidate_guid={candidate_guid}
                                            setAppState={setGetCards}
                                            setOpenMessage={setOpenMessage}
                                        />
                                    </div>
                                </div>

                                <div className="main-msgs-container">
                                    <div className="msgs-container">
                                        {messages?.length > 0 ? (
                                            groupByDate(messages)
                                                // messages
                                                .map((items, idx) => {
                                                    return (
                                                        <>
                                                            <small
                                                                key={idx}
                                                                className="daystamp"
                                                            >
                                                                {items.date}
                                                            </small>
                                                            {items.msg.map(
                                                                (
                                                                    msg,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        msg.userId ===
                                                                        user.uid
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="bubble-speech"
                                                                            >
                                                                                <div className="txt">
                                                                                    <p className="name">
                                                                                        {
                                                                                            msg.displayName
                                                                                        }
                                                                                    </p>
                                                                                    <p className="message pre-space hyphens">
                                                                                        {
                                                                                            msg.message
                                                                                        }
                                                                                    </p>
                                                                                    <p className="timestamp">
                                                                                        {msg.timestamp
                                                                                            .toDate()
                                                                                            .toLocaleTimeString(
                                                                                                [],
                                                                                                {
                                                                                                    timeStyle:
                                                                                                        'short',
                                                                                                }
                                                                                            )}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="bubble-arrow"></div>
                                                                            </div>
                                                                        )
                                                                    } else
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="bubble-speech alt"
                                                                            >
                                                                                <div className="txt">
                                                                                    <p className="name alt">
                                                                                        {
                                                                                            msg.displayName
                                                                                        }
                                                                                    </p>
                                                                                    <p className="message pre-space hyphens">
                                                                                        {
                                                                                            msg.message
                                                                                        }
                                                                                    </p>
                                                                                    <p className="timestamp">
                                                                                        {msg.timestamp
                                                                                            .toDate()
                                                                                            .toLocaleTimeString(
                                                                                                [],
                                                                                                {
                                                                                                    timeStyle:
                                                                                                        'short',
                                                                                                }
                                                                                            )}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="bubble-arrow alt"></div>
                                                                            </div>
                                                                        )
                                                                }
                                                            )}
                                                        </>
                                                    )
                                                })
                                        ) : (
                                            <div />
                                        )}

                                        {/* <small className='daystamp'>4 giugno 2023</small>
                              <div className="or-line"><small className='newMsg'>Nuovo messaggio</small></div> */}

                                        <div ref={divToScroll}></div>
                                    </div>
                                </div>

                                <form onSubmit={sendMessage2Recruiter}>
                                    <div className="footer-chat">
                                        <div className="d-flex align-end send-msg-box">
                                            <EmojiPickerMart
                                                message={message}
                                                setMessage={setMessage}
                                                showEmoji={showEmoji}
                                                setShowEmoji={setShowEmoji}
                                            />

                                            <TextField
                                                // id="filled-basic"
                                                // label="Scrivi qui il tuo messaggio"
                                                placeholder="Scrivi qui il tuo messaggio"
                                                variant="filled"
                                                name="message"
                                                value={message}
                                                onChange={(event) =>
                                                    setMessage(
                                                        event.target.value
                                                    )
                                                }
                                                fullWidth
                                                color="primary"
                                                multiline
                                                onClick={() =>
                                                    setShowEmoji(false)
                                                }
                                                // rows={1}
                                            />

                                            <button
                                                className="send-btn"
                                                type="submit"
                                                onClick={scrollToBottom}
                                            >
                                                <svg
                                                    width="53"
                                                    height="35"
                                                    viewBox="0 0 53 35"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M53 0L0 3.99571L12.7554 11.4966L33.6889 7.81855L18.759 16.5833L30.6298 35L53 0Z"
                                                        fill="#EA6C3A"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </section>
                    <CompleteFormCandidate
                        guid={candidate_guid}
                        open={open}
                        setOpen={setOpen}
                        actions={false}
                    />
                </main>
            </div>
        </div>
    )
}

export default MatchPageRecruiter
