import React from 'react'
import { useState } from 'react'
import LanguagesTable from '../../Tables/languages_table_onboarding'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect } from 'react'
import OnboardingButton from 'app/Layouts/OnboardingLayout/OnboardingButton'
import useAuth from 'app/hooks/useAuth'
import ApiService from 'app/ApiService'

const SectionTitles = ['In quali tra queste lingue puoi lavorare?']

function OnboardingLang({
    stepOnboarding,
    setStepOnboarding,
    skipDetails,
    setStepPreviousSection,
}) {
    const [stepOnboardingLang, setStepOnboardingLang] = useState(0)
    const [isSubmit, setSubmit] = useState(false)
    const [duplicatedSkill, setDuplicatedSkill] = useState(false)
    // devo renderizzare le lingue in 2 punti diversi, quindi
    const [languages, setLanguages] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [langList, setlangList] = useState([])
    const [appState, setAppState] = useState(false)

    const initUserInfo = {
        candidate_guid: '',
        skill_name: '',
        score: 6,
        created: new Date(),
        lastUpdate: new Date(),
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const { candidate_guid, skill_name, score, created, lastUpdate } = userInfo

    const {
        user,
        // user
    } = useAuth()

    const firstGroup = ['Italiano']
    const secondGroup = ['Inglese', 'Spagnolo']
    const thirdGroup = ['Albanese', 'Rumeno', 'Arabo', 'Francese']

    const getLangList = async () => {
        try {
            const response = await ApiService.get_candLangbyguid(candidate_guid)
            const body = await response.json()
            console.log(body)

            body.map((saved_skill) => {
                // Update the languages state
                setLanguages((prevLanguages) => ({
                    ...prevLanguages,
                    [saved_skill.skill_name]: true,
                }))

                // Update the langList state
                setlangList((prevLangList) => [
                    ...prevLangList,
                    {
                        id: saved_skill.id,
                        candidate_guid: candidate_guid,
                        checked: true,
                        score: 6,
                        skill_name: saved_skill.skill_name,
                        lastUpdate: new Date(),
                    },
                ])
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleChange = (e) => {
        setLanguages({
            ...languages,
            [e.target.name]: e.target.checked,
        })

        const updatedArray = langList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (langList.some((item) => item.skill_name === e.target.name)) {
            setlangList(updatedArray)
        } else {
            setlangList([
                ...updatedArray,
                {
                    candidate_guid: candidate_guid,
                    score: 6,
                    skill_name: e.target.name,
                    checked: e.target.checked,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }

        // console.log(langList);
    }
    // console.log(langList);

    const onSelect = (e, value) => {
        const id_languageToCheck = value
        // console.log(id_languageToCheck);
        if (value == '') return

        // const langSelected = langList.find(obj => obj.name === skill_name);

        if (
            !langList.some((langSelected) => {
                setDuplicatedSkill(true)
                return langSelected === id_languageToCheck
            })
        ) {
            setDuplicatedSkill(false)
        }

        setLanguages({
            ...languages,
            [value]: true,
        })

        const updatedArray = langList.map((skill) => {
            if (skill.skill_name === e.target.name) {
                return {
                    ...skill,
                    checked: e.target.checked,
                    lastUpdate: new Date(),
                }
            }
            return skill
        })

        if (langList.some((item) => item.skill_name === value)) {
            setlangList(updatedArray)
        } else {
            setlangList([
                ...updatedArray,
                {
                    candidate_guid: candidate_guid,
                    score: 6,
                    skill_name: value,
                    checked: true,
                    created: new Date(),
                    lastUpdate: new Date(),
                },
            ])
        }

        setUserInfo({ ...userInfo, skill_name: null })
        // console.log(langList);
    }

    async function handleNext() {
        let countChecked = langList.filter(
            (item) =>
                item.checked === true || (item.checked === false && item?.id)
        )
        const res = await ApiService.create_multiplecandLang(countChecked)
        if (res.ok) {
            setStepOnboarding((prevActiveStep) => prevActiveStep + 1)
        }
    }

    const validate = () => {
        const errors = {}

        if (langList.filter((item) => item.checked === true).length <= 0)
            errors.max = 'Seleziona una o più caratteristiche tra i campi sotto'

        return errors
    }

    async function getData() {
        await getLangList()
        setAppState(false)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0) {
                handleNext()
            }
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    // useEffect(() => {
    //     console.log(langList);
    // }, [langList])

    useEffect(() => {
        if (appState === true) getData()
    }, [appState])

    useEffect(() => {
        if (user !== null) {
            setUserInfo({ ...userInfo, candidate_guid: user?.uid })
            setAppState(true)
        }
    }, [user])

    const availableLanguages = LanguagesTable.others.filter(
        (language) => !langList.some((lang) => lang.skill_name === language)
    )

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="onboarding-grid">
                <h2
                    className="noMrgMob"
                    style={{ fontSize: '1rem', margin: '0 auto 1rem' }}
                >
                    {SectionTitles[stepOnboardingLang]}
                </h2>
                <p className="text-center smallheader">
                    Aggiungi tutte le lingue in cui ti senti a tuo agio in un
                    ambiente di
                </p>
                <p
                    className="text-center smallheader"
                    style={{ marginBottom: '0.5rem' }}
                >
                    lavoro, ci aiuterà a presentarti offerte più rilevanti per
                    te
                </p>

                {(() => {
                    switch (stepOnboardingLang) {
                        case 0:
                            return (
                                <>
                                    <div
                                        className="input-container"
                                        style={{ gap: '0rem' }}
                                    >
                                        <FormControl
                                            component="fieldset"
                                            className="tags-check"
                                        >
                                            {formErrors.max && (
                                                <p className="error-message">
                                                    {formErrors.max}
                                                </p>
                                            )}
                                            <FormGroup>
                                                <div className="container-lanTag">
                                                    <div className="firstchoice">
                                                        {firstGroup.map(
                                                            (language) => (
                                                                <FormControlLabel
                                                                    key={
                                                                        language
                                                                    }
                                                                    label={
                                                                        language
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            name={
                                                                                language
                                                                            }
                                                                            value={
                                                                                language
                                                                            }
                                                                            checked={langList?.some(
                                                                                (
                                                                                    obj
                                                                                ) =>
                                                                                    obj.skill_name ===
                                                                                        language &&
                                                                                    obj.checked ===
                                                                                        true
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="secondchoice">
                                                        {secondGroup.map(
                                                            (language) => (
                                                                <FormControlLabel
                                                                    key={
                                                                        language
                                                                    }
                                                                    label={
                                                                        language
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            name={
                                                                                language
                                                                            }
                                                                            value={
                                                                                language
                                                                            }
                                                                            checked={langList?.some(
                                                                                (
                                                                                    obj
                                                                                ) =>
                                                                                    obj.skill_name ===
                                                                                        language &&
                                                                                    obj.checked ===
                                                                                        true
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="thirdchoice">
                                                        {thirdGroup.map(
                                                            (language) => (
                                                                <FormControlLabel
                                                                    key={
                                                                        language
                                                                    }
                                                                    label={
                                                                        language
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            name={
                                                                                language
                                                                            }
                                                                            value={
                                                                                language
                                                                            }
                                                                            checked={langList?.some(
                                                                                (
                                                                                    obj
                                                                                ) =>
                                                                                    obj.skill_name ===
                                                                                        language &&
                                                                                    obj.checked ===
                                                                                        true
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="newlan">
                                                        {languages &&
                                                            Object.keys(
                                                                languages
                                                            ).map(
                                                                (language) => {
                                                                    if (
                                                                        !LanguagesTable.moreCommon.includes(
                                                                            language
                                                                        )
                                                                    ) {
                                                                        return (
                                                                            <FormControlLabel
                                                                                key={
                                                                                    language
                                                                                }
                                                                                label={
                                                                                    language
                                                                                }
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            languages[
                                                                                                language
                                                                                            ]
                                                                                        }
                                                                                        onChange={
                                                                                            handleChange
                                                                                        }
                                                                                        name={
                                                                                            language
                                                                                        }
                                                                                        value={
                                                                                            languages[
                                                                                                language
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </FormControl>

                                        <Autocomplete
                                            id="grouped-demo"
                                            className="mt2 select-arr mrgmob"
                                            style={{ marginTop: '0.5rem' }}
                                            options={availableLanguages}
                                            getOptionLabel={(option) => option}
                                            value={skill_name || null}
                                            onChange={onSelect}
                                            inputValue={skill_name || ''}
                                            onInputChange={(
                                                event,
                                                newInputValue
                                            ) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    skill_name: newInputValue,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Altre lingue"
                                                />
                                            )}
                                        />
                                    </div>
                                </>
                            )
                    }
                })()}
            </div>

            <OnboardingButton
                stepOnboarding={stepOnboarding}
                setStepOnboarding={setStepOnboarding}
                setSubmit={setSubmit}
                submit={isSubmit}
            />
        </form>
    )
}

export default OnboardingLang
