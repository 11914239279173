import KangratsLogo from 'app/components/Icons/KangratsLogo'
import React from 'react'
import { NavLink } from 'react-router-dom'

function HeaderIntro() {
    return (
        <header className="d-flex space-between align-center">
            <KangratsLogo width={189.29} height={50} cssClasses={false} />

            <nav>
                <ul className="d-flex space-between">
                    <li>
                        <a
                            className="link"
                            href="https://kangrats.notion.site/Limyra-srl-Kangrats-Privacy-Policy-e9e2afe0f1df41468ee4aa33c89f5a8f?pvs=73"
                            target="_blank"
                            title="kangrats.com"
                        >
                            Informativa Privacy
                        </a>
                    </li>
                    <li>
                        <a
                            className="link"
                            href="https://kangrats.notion.site/Limyra-srl-Kangrats-Termini-e-Condizioni-efb3b725a09e42c5a220a6d9b0373f8f?pvs=73"
                            target="_blank"
                            title="kangrats.com"
                        >
                            Termini e Condizioni
                        </a>
                    </li>
                    <li>
                        <a
                            className="link"
                            href="https://kangrats.notion.site/FAQ-Kangrats-599aa0e869384cceb156049d781ebc2b?pvs=73"
                            target="_blank"
                            title="kangrats.com"
                        >
                            FAQs
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default HeaderIntro
