import React, { createContext, useEffect, useReducer, useMemo } from 'react'
import { Capacitor } from '@capacitor/core'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import {
    signInWithEmailAndPassword,
    signInWithCredential,
    signOut,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithPopup,
    deleteUser,
    updateProfile,
    setPersistence,
    indexedDBLocalPersistence,
    inMemoryPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
    onAuthStateChanged,
    getIdTokenResult,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import {
    auth,
    google_auth,
    facebook_auth,
    functions,
    serverClientId,
} from '../../config'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import jwtDecode from 'jwt-decode'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    role: '',
    onboarded: false,
    user: null,
}

GoogleAuth.initialize({
    clientId: serverClientId,
    androidClientId: serverClientId,
    scopes: ['profile', 'email'],
    grantOfflineAccess: true,
})

const setSession = (user, onboard) => {
    if (user) {
        // console.log("user stored")
        // localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('role', JSON.stringify(user))
        localStorage.setItem('onboarded', JSON.stringify(onboard))
        // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        // localStorage.removeItem('user')
        localStorage.removeItem('role')
        localStorage.removeItem('onboarded')
        // delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, role, onboarded } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                role,
                onboarded,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                isInitialised: false,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                role: '',
                onboarded: false,
            }
        }
        case 'REGISTER': {
            // const { user } = action.payload

            return {
                ...state,
                isInitialised: false,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

// Create the file metadata
/** @type {any} */
const metadata = {
    contentType: 'image/jpeg',
}

const AuthContext = createContext({
    ...initialState,
    method: 'Firebase',
    login: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithFacebook: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    unsubscribe: () => Promise.resolve(),
    updateUserPhoto: () => Promise.resolve(),
    updateUserName: () => Promise.resolve(),
    setUserRole: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setcustomPersistence = () => {
        let persitence = browserLocalPersistence
        if (Capacitor.isNativePlatform()) persitence = indexedDBLocalPersistence

        setPersistence(auth, persitence)
            .then(() => {
                return true
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code
                const errorMessage = error.message
                console.err(errorMessage)
                return false
            })
    }

    const login = async (email, password) => {
        try {
            // console.log(auth)
            await setcustomPersistence()

            const { user } = await signInWithEmailAndPassword(
                auth,
                email,
                password
            )
            // console.log("this is user", user)

            // if (user.emailVerified) {
            // const { accessToken, user } = response.data
            // setSession(user)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
            return 'ok'
            // }
        } catch (error) {
            console.log('errore: ' + error.message)
            return error.message
        }
    }

    const signInWithGoogle = async () => {
        try {
            await setcustomPersistence()

            if (Capacitor.isNativePlatform()) {
                const googleUser = await GoogleAuth.signIn()

                // Create a Google credential with the token
                const googleCredential = GoogleAuthProvider.credential(
                    googleUser.credential?.idToken
                )

                console.log('this are credentials', googleCredential)

                const { user } = signInWithCredential(auth, googleCredential)

                console.log('this is capacitor user', user)
                // setSession(user)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })
            } else {
                const { user } = await signInWithPopup(auth, google_auth)

                console.log('this is user', user)
                // setSession(user)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })
            }
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const signInWithFacebook = async () => {
        try {
            await setcustomPersistence()

            // if (Capacitor.isNativePlatform()) {

            //     // 1. Create credentials on the native layer
            //     const result = await FirebaseAuthentication.signInWithFacebook();
            //     // 2. Sign in on the web layer using the access token
            //     const credential = FacebookAuthProvider.credential(
            //         result.credential?.accessToken,
            //     );
            //     const { user } = await signInWithCredential(auth, credential);

            //     console.log("this is user", user)
            //     // const { accessToken, user } = response.data
            //     // setSession(user)
            //     dispatch({
            //         type: 'LOGIN',
            //         payload: {
            //             user,
            //         },
            //     })
            // }
            // else {
            const { user } = await signInWithPopup(auth, facebook_auth)
            console.log('this is user', user)
            // const { accessToken, user } = response.data
            // setSession(user)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
            // }
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const register = async (email, password) => {
        try {
            const { user } = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            )

            // await sendEmailVerification(auth.currentUser)

            // console.log('Verification email sent!')

            // const { accessToken, user } = response.data
            // setSession(accessToken)
            dispatch({
                type: 'REGISTER',
                // payload: {
                //     user,
                // },
            })
            return 'ok'
        } catch (error) {
            console.log('errore: ' + error.message)
            return error.message
        }
    }

    const logout = async () => {
        try {
            await signOut(auth)
            console.log('logout')

            // setSession(null)
            dispatch({ type: 'LOGOUT' })
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            return false
        }
    }

    const unsubscribe = async () => {
        try {
            await deleteUser(auth.currentUser)
            console.log('unsubscribed')

            // setSession(null)
            dispatch({ type: 'LOGOUT' })
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            if (error.code === 'auth/requires-recent-login') {
                alert('Prima di proseguire, rifare il login')
                await signOut(auth)
                // setSession(null)
                dispatch({ type: 'LOGOUT' })
            }
            return false
        }
    }

    const forgotPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email)
            return true
        } catch (error) {
            return false
        }
    }

    const updateUserPhoto = async (photoUrl) => {
        updateProfile(auth.currentUser, {
            photoURL: photoUrl,
        })
            .then(() => {
                return true
            })
            .catch((err) => {
                console.log('impossible to update profile: ', err.message)
                return false
            })
    }

    const updateUserName = async (nameToDisplay) => {
        updateProfile(auth.currentUser, {
            displayName: nameToDisplay,
        })
            .then(() => {
                return true
            })
            .catch((err) => {
                console.log('impossible to update profile: ', err.message)
                return false
            })
    }

    const setUserRole = async (role, onboarded) => {
        let recruiter = false
        let candidate = false
        try {
            // setSession(role, onboarded)

            switch (role) {
                case 'candidate':
                    candidate = true
                    break
                case 'recruiter':
                    recruiter = true
                    break
                case 'admin':
                    candidate = true
                    recruiter = true
                    break
                default:
                    // Handle the case where role doesn't match any of the specified values
                    break
            }
            const funct = httpsCallable(functions, 'addcustomclaims')
            const res = await funct({
                email: state.user?.email,
                onboarded: onboarded,
                recruiter: recruiter,
                candidate: candidate,
            })

            if (res) console.log(res.data)
            console.log('userRole set:', role)

            dispatch({
                type: 'INIT',
                payload: {
                    ...state,
                    role,
                    onboarded,
                },
            })
            return true
        } catch (error) {
            console.log('errore: ' + error.message)
            // return false;
        }
    }
    // onAuthStateChanged(auth, (user) => {
    //     console.log("onAuthStateChanged")
    //     // if (user) {
    //     //     setSession(user)
    //     //     dispatch({
    //     //         type: 'INIT',
    //     //         payload: {
    //     //             isAuthenticated: true,
    //     //             user,
    //     //         },
    //     //     })
    //     // } else {
    //     //     setSession(null)
    //     //     dispatch({
    //     //         type: 'INIT',
    //     //         payload: {
    //     //             isAuthenticated: false,
    //     //             user: null,
    //     //         },
    //     //     })
    //     // }
    //   });

    const getUserRole = async (user) => {
        const { claims } = await user.getIdTokenResult(true)
        console.log('claims:', claims)
        let userRole = ''
        switch (true) {
            case claims?.recruiter && claims?.candidate:
                userRole = 'sa'
                break
            case claims?.recruiter && !claims?.candidate:
                userRole = 'recruiter'
                break
            case !claims?.recruiter && claims?.candidate:
                userRole = 'candidate'
                break
            default:
                userRole = ''
                break
        }
        // if (claims?.recruiter && claims?.candidate) userRole = 'sa'
        // else if (claims?.recruiter && !claims?.candidate) userRole = 'recruiter'
        // else if (!claims?.recruiter && claims?.candidate) userRole = 'candidate'
        // else userRole = ''
        console.log('userRole:', userRole)
        // setUserRole(userRole, false);
        dispatch({
            type: 'INIT',
            payload: {
                ...state,
                isAuthenticated: true,
                user,
                role: userRole,
                onboarded: claims?.onboarded ?? false,
            },
        })
    }

    useEffect(() => {
        ;(async () => {
            // try {
            //     const user = JSON.parse(window.localStorage.getItem('user'))
            //     console.log("authenticated user: ", user)

            //     // const decodedToken = jwtDecode(user.stsTokenManager.accessToken)
            //     // console.log("decoded_token: ", decodedToken)

            //     if (user) {
            //         setSession(user)
            //         // const response = await axios.get('/auth/profile')
            //         // const { user } = response.data

            //         dispatch({
            //             type: 'INIT',
            //             payload: {
            //                 isAuthenticated: true,
            //                 user,
            //             },
            //         })
            //     } else {
            //         dispatch({
            //             type: 'INIT',
            //             payload: {
            //                 isAuthenticated: false,
            //                 user: null,
            //             },
            //         })
            //     }
            // } catch (err) {
            //     console.error(err)
            //     dispatch({
            //         type: 'INIT',
            //         payload: {
            //             isAuthenticated: false,
            //             user: null,
            //         },
            //     })
            // }
            console.log('auth triggered')
            onAuthStateChanged(auth, (user) => {
                // console.log("onAuthStateChanged: ", user)
                if (user) {
                    // const userRole = JSON.parse(window.localStorage.getItem('role'))
                    // const userOnboarded = JSON.parse(window.localStorage.getItem('onboarded'))
                    // console.log("authenticated user: ", user)
                    // console.log("authenticated user role: ", userRole)

                    // addMessage(user?.email)
                    // let userRole = ''
                    // user.getIdTokenResult().then((idToken) => {
                    //     const {claims} = idToken?.claims;
                    //     if (claims?.recruiter && claims?.candidate) userRole = 'sa'
                    //     else if (claims?.recruiter && !claims?.candidate) userRole = 'recruiter'
                    //     else if (!claims?.recruiter && claims?.candidate) userRole = 'candidate'
                    //     else userRole = ''
                    //     return userRole;
                    // })
                    // console.log(userRole)
                    // const userRole = getUserRole(user)
                    getUserRole(user)
                    // setSession(userRole, userOnboarded)
                    // dispatch({
                    //     type: 'INIT',
                    //     payload: {
                    //         ...state,
                    //         isAuthenticated: true,
                    //         user,
                    //         // role: userRole,
                    //         onboarded: userOnboarded
                    //     },
                    // })
                } else {
                    // setSession(null)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            role: '',
                            onboarded: false,
                        },
                    })
                }
            })
        })()
    }, [])

    const memorizedValue = useMemo(
        () => ({
            ...state,
            method: 'Firebase',
            login,
            signInWithGoogle,
            signInWithFacebook,
            logout,
            forgotPassword,
            register,
            unsubscribe,
            updateUserPhoto,
            updateUserName,
            setUserRole,
        }),
        [state]
    )

    return (
        <AuthContext.Provider value={memorizedValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
