export const authRoles = {
    sa: ['sa'], // Only Super Admin has access
    admin: ['sa', 'admin'], // Only sa & admin has access
    recruiter: ['sa', 'admin', 'recruiter'], // Only sa & admin & recruiter has access
    candidate: ['sa', 'admin', 'candidate'], // Only sa & admin & candidate has access
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <===============
//   }
// ];

// Check navigaitons.js

// {
//   name: "Dashboard",
//   path: "/dashboard/analytics",
//   icon: "dashboard",
//   auth: authRoles.admin <=================
// }
